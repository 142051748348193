import products from '../data/products';
import { Configuration, Product } from '../data/types';
import { qp99 } from '../data';

const MATERIAL_NUMBER_PATTERN = /^(MCA|MCE)([0-9.SP]+)-[2-3]xxx-([A-Z0])$/;

export function getProduct(configuration: Configuration): Product | undefined {
  const { displayModule, module, draftShield, certificate } = configuration;

  if (!displayModule || !draftShield || !module || !certificate) {
    return undefined;
  }

  const foundProducts = products.filter((product) => {
    if (product.userInterface !== displayModule.name) {
      return false;
    }

    const result = MATERIAL_NUMBER_PATTERN.exec(product.materialNumber);
    if (result === null) {
      return false;
    }

    const [, displayName, weightingModuleName, draftShieldName] = result;

    return (
      displayModule.name === displayName && module.name === weightingModuleName && draftShield.name === draftShieldName
    );
  });

  if (foundProducts.length > 1) {
    throw new Error('More than one matching product found!');
  }

  if (foundProducts.length === 0) {
    return;
  }

  if (configuration.module?.name !== '125P') {
    let materialNumber = foundProducts[0].materialNumber.replace('xxx', certificate.name);

    if (configuration.softwareOptions) {
      materialNumber += ' ';
      if (configuration.softwareOptions.length === 4) {
        materialNumber += qp99.name;
      } else {
        configuration.softwareOptions.forEach((item) => (materialNumber += item.name + ' '));
      }
      materialNumber = materialNumber.trim();
    }

    if (
      configuration.softwarePackage &&
      configuration.softwarePackage.name !== 'individual' &&
      configuration.softwarePackage.name !== 'none'
    ) {
      materialNumber += ` ${configuration.softwarePackage.name}`;
    }

    if (configuration.hardwarePackage && configuration.hardwarePackage.showOnProductNumber) {
      materialNumber += ` ${configuration.hardwarePackage.code}`;
    }

    if (configuration.accessories) {
      configuration.accessories.forEach((accessory) => (materialNumber += ` & ${accessory.name}`));
    }

    return {
      ...foundProducts[0],
      materialNumber,
    };
  } else {
    let materialNumberCertificatePart = '';

    switch (certificate.name) {
      case 'SØØ':
        materialNumberCertificatePart = 'MTS';
        break;
      case 'SØ1':
        materialNumberCertificatePart = 'S01';
        break;
      case 'CCN':
        materialNumberCertificatePart = 'CCN';
        break;
      default:
        throw new Error('Invalid configuration!');
    }

    const materialNumber =
      materialNumberCertificatePart === 'MTS'
        ? `${displayModule.name}125P-${draftShield.name}-${materialNumberCertificatePart}`
        : `${displayModule.name}125P${draftShield.name}-${materialNumberCertificatePart}`;

    return {
      ...foundProducts[0],
      materialNumber,
    };
  }
}
