import chunk from 'lodash/chunk';
import { useRecoilState } from 'recoil';
import { Configuration, DocumentationAndService } from '../../../data/types';
import { getAllDocumentationsAndServices } from '../../../services/documentationAndService';
import { configurationState, navigationStepStatusState } from '../../../states';

interface UseDocumentationAndServiceSelection {
  config: Configuration;
  areOtherStepsCompleted: boolean;
  moduleHasDocumentationsAndServices: boolean;
  allDocumentationsAndServicesGrouped: DocumentationAndService[][];
  onUndoSelection: (documentationAndService: DocumentationAndService) => () => void;
  onDocumentationAndServiceSelect: (documentationAndService: DocumentationAndService) => () => void;
}

export const useDocumentationAndServiceSelection = (numberOfRows: number): UseDocumentationAndServiceSelection => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [navigationStepStatus] = useRecoilState(navigationStepStatusState);

  const areOtherStepsCompleted =
    navigationStepStatus.step1 == 'complete' &&
    navigationStepStatus.step2 == 'complete' &&
    navigationStepStatus.step3 == 'complete';

  const moduleHasDocumentationsAndServices = !!config.module?.documentationsAndServices;

  const allDocumentationsAndServices = getAllDocumentationsAndServices();
  const allDocumentationsAndServicesGrouped: DocumentationAndService[][] = chunk(allDocumentationsAndServices, numberOfRows);

  const onUndoSelection = (unselectedDocumentationAndService: DocumentationAndService) => () => {
    const currentlySelectedOptions = [...(config.documentationsAndServices ?? [])];
    const removeDocumentationAndService = currentlySelectedOptions.find(
      (documentationAndService) => documentationAndService.label == unselectedDocumentationAndService.label,
    );
    if (removeDocumentationAndService) {
      const removeIndex = currentlySelectedOptions.indexOf(removeDocumentationAndService);
      if (removeIndex > -1) {
        currentlySelectedOptions.splice(removeIndex, 1);
        setConfig({
          ...config,
          documentationsAndServices: currentlySelectedOptions,
        });
      }
    }
  };

  const onDocumentationAndServiceSelect = (documentationAndService: DocumentationAndService) => () => {
    const currentlySelectedOptions = config.documentationsAndServices ?? [];
    setConfig({
      ...config,
      documentationsAndServices: [...currentlySelectedOptions, documentationAndService],
    });
  };

  return {
    config,
    areOtherStepsCompleted,
    moduleHasDocumentationsAndServices,
    allDocumentationsAndServicesGrouped,
    onUndoSelection,
    onDocumentationAndServiceSelect,
  };
};
