/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SoftwareOption } from './SoftwareOption';
import {
    SoftwareOptionFromJSON,
    SoftwareOptionFromJSONTyped,
    SoftwareOptionToJSON,
} from './SoftwareOption';

/**
 * 
 * @export
 * @interface SoftwarePackage
 */
export interface SoftwarePackage {
    /**
     * 
     * @type {string}
     * @memberof SoftwarePackage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SoftwarePackage
     */
    label?: string;
    /**
     * Selected Software Options
     * @type {Array<SoftwareOption>}
     * @memberof SoftwarePackage
     */
    softwareOption?: Array<SoftwareOption>;
}

/**
 * Check if a given object implements the SoftwarePackage interface.
 */
export function instanceOfSoftwarePackage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SoftwarePackageFromJSON(json: any): SoftwarePackage {
    return SoftwarePackageFromJSONTyped(json, false);
}

export function SoftwarePackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwarePackage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'softwareOption': !exists(json, 'softwareOption') ? undefined : ((json['softwareOption'] as Array<any>).map(SoftwareOptionFromJSON)),
    };
}

export function SoftwarePackageToJSON(value?: SoftwarePackage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'softwareOption': value.softwareOption === undefined ? undefined : ((value.softwareOption as Array<any>).map(SoftwareOptionToJSON)),
    };
}

