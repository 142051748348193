import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { none } from '../../shields';

const module_2202s: WeighingModule = {
  name: '2202S',
  scaleInterval: [10],
  maxCapacity: [2200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_2202s;
