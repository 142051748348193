import { WeighingModule } from '../../types';
import { shields125p } from './defaults';
import { certificatesForSemiMicro125P } from '../../certificates';

const module_125p: WeighingModule = {
  name: '125P',
  scaleInterval: [0.01, 0.1],
  maxCapacity: [60, 120],
  compatibleShields: shields125p,
  possibleCertificates: certificatesForSemiMicro125P,
};

export default module_125p;
