import { CButton, CImg, CModal, CModalHeader } from '@coreui/react';
import React, { useState } from 'react';

interface props {
  src: string;
  fluidGrow?: boolean;
  srcFullsize: string;
  muted?: boolean;
}

const ImageWithModal: React.FC<props> = ({ src, fluidGrow, srcFullsize, muted }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="position-relative">
      <CButton
        color="secondary"
        data-testid="expand-btn"
        onClick={toggleShow}
        className="position-absolute"
        style={{ right: 0, top: 0, padding: 0, zIndex: 2 }}
      >
        <i className="icon-expand" />
      </CButton>
      <CImg data-testid="image" src={src} fluidGrow={fluidGrow} className={muted ? 'img-muted' : ''} />
      <CModal data-testid="modal" show={show} onClose={toggleShow} centered={true} size="lg">
        <CModalHeader closeButton>
          <CImg data-testid="fullsize-image" src={srcFullsize} fluidGrow />
        </CModalHeader>
      </CModal>
    </div>
  );
};

export default ImageWithModal;
