import { CButton, CCard, CCardBody, CCardImg, CCardText, CCardTitle, CCol, CRow, CLink } from '@coreui/react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import ExperienceRate from '../components/ExperienceRate';
import { showNavigationState } from '../states';
import styles from './Success.module.scss';
import Image1 from '../assets/images/success/image001.png';
import Image2 from '../assets/images/success/image002.png';
import Image3 from '../assets/images/success/image003.jpg';
import { Trans, useTranslation } from 'react-i18next';
import PlatformContext from '../context/PlatformContext';
import React from 'react';
import { QuoteRequestDTOContextEnum } from '../context/QuoteRequestDTOContextEnum';
const Success = (): JSX.Element => {
  const [, setShowNavigation] = useRecoilState(showNavigationState);
  const { t } = useTranslation('common');

  const platform = React.useContext(PlatformContext);
  const isThermoFisher = QuoteRequestDTOContextEnum.Tf === platform;

  useEffect(() => {
    setShowNavigation(false);
    return () => {
      setShowNavigation(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.successPageMessage}>
        <h1 className="mb-5">{t(`success.header`)}</h1>
        {!isThermoFisher && (
          <p className="font-4xl">
            <Trans i18nKey="success.subheader" t={t}>
              Thank you for submitting your quote request. We emailed you a summary of your configuration and a
              Sartorius Cubis<sup>®</sup> expert will contact you soon.
            </Trans>
          </p>
        )}
      </div>
      <ExperienceRate />
      <div className={styles.successPageBoxes}>
        <CRow>
          <CCol sm="4">
            <CCard className="pt-0">
              <CCardImg src={Image3} title="Brochure: Cubis® II — Your Balance, Your Way" fluidGrow={true} />
              <CCardBody className={`${styles.cardBody} pt-5 pb-1`}>
                <CCardTitle className={styles.boxHeadline}>
                  <Trans i18nKey="success.brochures.first.headline" t={t}>
                    Brochure: Cubis<sup>®</sup> II — Your Balance, Your Way
                  </Trans>
                </CCardTitle>
                <CCardText className="mb-4">
                  <Trans i18nKey="success.brochures.first.content" t={t}>
                    Cubis<sup>®</sup> II lab balance for scientists in R&D or analytical laboratories needing the most
                    reliable lab weighing results.
                  </Trans>
                </CCardText>
                <CLink
                  href="https://www.sartorius.com/resource/blob/64170/f352fa25eefe7daddfb8408c341f6dfb/broch-cubisii-210x280-e-data.pdf"
                  title="Brochure: Cubis® II — Your Balance, Your Way"
                  target="_blank"
                >
                  <CButton
                    shape="square"
                    color="dark"
                    variant="outline"
                    className={`${styles.button} border border-dark`}
                  >
                    {t('success.viewResources')}
                  </CButton>
                </CLink>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="4">
            <CCard className="pt-0">
              <CCardImg src={Image1} title="Brochure: Cubis® II Sample Holders" fluidGrow={true} />
              <CCardBody className={`${styles.cardBody} pt-5 pb-1`}>
                <CCardTitle className={styles.boxHeadline}>
                  <Trans i18nKey="success.brochures.second.headline" t={t}>
                    Brochure: Cubis<sup>®</sup> II Sample Holders
                  </Trans>
                </CCardTitle>
                <CCardText className="mb-4">{t('success.brochures.second.content')}</CCardText>
                <CLink
                  href="https://www.sartorius.com/download/507346/92/cubis-ll-sample-holder-brochure-en-l-sartorius-data.pdf"
                  title="Brochure: Cubis® II Sample Holders"
                  target="_blank"
                >
                  <CButton
                    shape="square"
                    color="dark"
                    variant="outline"
                    className={`${styles.button} border border-dark`}
                  >
                    {t('success.viewResources')}
                  </CButton>
                </CLink>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="4">
            <CCard className="pt-0">
              <CCardImg src={Image2} title="eBook: Cubis® II QApp" fluidGrow={true} />
              <CCardBody className={`${styles.cardBody} pt-5 pb-1`}>
                <CCardTitle className={styles.boxHeadline}>
                  <Trans i18nKey="success.brochures.third.headline" t={t}>
                    eBook: Cubis<sup>®</sup> II QApp
                  </Trans>
                </CCardTitle>
                <CCardText className="mb-4">
                  <Trans i18nKey="success.brochures.third.content" t={t}>
                    More efficiency and fail-safe reliability with QApps for your Cubis<sup>®</sup> II laboratory
                    balance.
                  </Trans>
                </CCardText>
                <CLink
                  href="https://www.sartorius.com/download/671720/3/cubis-ll-q-app-ebook-en-l-sartorius-pdf-data.pdf"
                  title="eBook: Cubis® II QApp"
                  target="_blank"
                >
                  <CButton
                    shape="square"
                    color="dark"
                    variant="outline"
                    className={`${styles.button} border border-dark`}
                  >
                    {t('success.viewResources')}
                  </CButton>
                </CLink>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default Success;
