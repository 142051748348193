/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentationAndService
 */
export interface DocumentationAndService {
    /**
     * 
     * @type {string}
     * @memberof DocumentationAndService
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentationAndService
     */
    label?: string;
}

/**
 * Check if a given object implements the DocumentationAndService interface.
 */
export function instanceOfDocumentationAndService(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentationAndServiceFromJSON(json: any): DocumentationAndService {
    return DocumentationAndServiceFromJSONTyped(json, false);
}

export function DocumentationAndServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentationAndService {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function DocumentationAndServiceToJSON(value?: DocumentationAndService | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sku': value.sku,
        'label': value.label,
    };
}

