/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Accessory } from './Accessory';
import {
    AccessoryFromJSON,
    AccessoryFromJSONTyped,
    AccessoryToJSON,
} from './Accessory';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Certificate } from './Certificate';
import {
    CertificateFromJSON,
    CertificateFromJSONTyped,
    CertificateToJSON,
} from './Certificate';
import type { DisplayModule } from './DisplayModule';
import {
    DisplayModuleFromJSON,
    DisplayModuleFromJSONTyped,
    DisplayModuleToJSON,
} from './DisplayModule';
import type { DocumentationAndService } from './DocumentationAndService';
import {
    DocumentationAndServiceFromJSON,
    DocumentationAndServiceFromJSONTyped,
    DocumentationAndServiceToJSON,
} from './DocumentationAndService';
import type { DraftShield } from './DraftShield';
import {
    DraftShieldFromJSON,
    DraftShieldFromJSONTyped,
    DraftShieldToJSON,
} from './DraftShield';
import type { HardwarePackage } from './HardwarePackage';
import {
    HardwarePackageFromJSON,
    HardwarePackageFromJSONTyped,
    HardwarePackageToJSON,
} from './HardwarePackage';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { SoftwarePackage } from './SoftwarePackage';
import {
    SoftwarePackageFromJSON,
    SoftwarePackageFromJSONTyped,
    SoftwarePackageToJSON,
} from './SoftwarePackage';
import type { WeighingModule } from './WeighingModule';
import {
    WeighingModuleFromJSON,
    WeighingModuleFromJSONTyped,
    WeighingModuleToJSON,
} from './WeighingModule';

/**
 * 
 * @export
 * @interface BalanceConfiguration
 */
export interface BalanceConfiguration {
    /**
     * 
     * @type {Product}
     * @memberof BalanceConfiguration
     */
    product?: Product;
    /**
     * 
     * @type {Category}
     * @memberof BalanceConfiguration
     */
    category?: Category;
    /**
     * 
     * @type {WeighingModule}
     * @memberof BalanceConfiguration
     */
    weighingModule?: WeighingModule;
    /**
     * 
     * @type {DraftShield}
     * @memberof BalanceConfiguration
     */
    draftShield?: DraftShield;
    /**
     * 
     * @type {SoftwarePackage}
     * @memberof BalanceConfiguration
     */
    softwarePackage?: SoftwarePackage;
    /**
     * 
     * @type {DisplayModule}
     * @memberof BalanceConfiguration
     */
    displayModule?: DisplayModule;
    /**
     * 
     * @type {string}
     * @memberof BalanceConfiguration
     */
    country?: string;
    /**
     * 
     * @type {Certificate}
     * @memberof BalanceConfiguration
     */
    certificate?: Certificate;
    /**
     * 
     * @type {Array<Accessory>}
     * @memberof BalanceConfiguration
     */
    accessories?: Array<Accessory>;
    /**
     * Promo code
     * @type {string}
     * @memberof BalanceConfiguration
     */
    promoCode?: string;
    /**
     * 
     * @type {Array<DocumentationAndService>}
     * @memberof BalanceConfiguration
     */
    documentationAndServices?: Array<DocumentationAndService>;
    /**
     * 
     * @type {HardwarePackage}
     * @memberof BalanceConfiguration
     */
    hardwarePackage?: HardwarePackage;
}

/**
 * Check if a given object implements the BalanceConfiguration interface.
 */
export function instanceOfBalanceConfiguration(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BalanceConfigurationFromJSON(json: any): BalanceConfiguration {
    return BalanceConfigurationFromJSONTyped(json, false);
}

export function BalanceConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'weighingModule': !exists(json, 'weighingModule') ? undefined : WeighingModuleFromJSON(json['weighingModule']),
        'draftShield': !exists(json, 'draftShield') ? undefined : DraftShieldFromJSON(json['draftShield']),
        'softwarePackage': !exists(json, 'softwarePackage') ? undefined : SoftwarePackageFromJSON(json['softwarePackage']),
        'displayModule': !exists(json, 'displayModule') ? undefined : DisplayModuleFromJSON(json['displayModule']),
        'country': !exists(json, 'country') ? undefined : json['country'],
        'certificate': !exists(json, 'certificate') ? undefined : CertificateFromJSON(json['certificate']),
        'accessories': !exists(json, 'accessories') ? undefined : ((json['accessories'] as Array<any>).map(AccessoryFromJSON)),
        'promoCode': !exists(json, 'promoCode') ? undefined : json['promoCode'],
        'documentationAndServices': !exists(json, 'documentationAndServices') ? undefined : ((json['documentationAndServices'] as Array<any>).map(DocumentationAndServiceFromJSON)),
        'hardwarePackage': !exists(json, 'hardwarePackage') ? undefined : HardwarePackageFromJSON(json['hardwarePackage']),
    };
}

export function BalanceConfigurationToJSON(value?: BalanceConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': ProductToJSON(value.product),
        'category': CategoryToJSON(value.category),
        'weighingModule': WeighingModuleToJSON(value.weighingModule),
        'draftShield': DraftShieldToJSON(value.draftShield),
        'softwarePackage': SoftwarePackageToJSON(value.softwarePackage),
        'displayModule': DisplayModuleToJSON(value.displayModule),
        'country': value.country,
        'certificate': CertificateToJSON(value.certificate),
        'accessories': value.accessories === undefined ? undefined : ((value.accessories as Array<any>).map(AccessoryToJSON)),
        'promoCode': value.promoCode,
        'documentationAndServices': value.documentationAndServices === undefined ? undefined : ((value.documentationAndServices as Array<any>).map(DocumentationAndServiceToJSON)),
        'hardwarePackage': HardwarePackageToJSON(value.hardwarePackage),
    };
}

