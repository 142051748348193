import { Category, WeighingModule } from '../../types';
import module_124s from './124s';
import module_224s from './224s';
import module_324p from './324p';
import module_324s from './324s';
import module_524p from './524p';
import module_524s from './524s';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const modules: WeighingModule[] = [module_524p, module_524s, module_324p, module_324s, module_224s, module_124s];

const t = i18next.getFixedT(null, 'common');

export const analytical: Category = {
  get name() {
    return t(`category.${this.id}.name`);
  },
  get info() {
    return t(`category.${this.id}.info`);
  },
  modules,
  image: `${env('IMAGE_PREFIX')}/AB_124S_DS_A_MCA.png`,
  id: 'analytical',
};

export default analytical;
