import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_324p: WeighingModule = {
  name: '324P',
  scaleInterval: [0.1, 0.2, 0.5],
  maxCapacity: [80, 160, 320],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_324p;
