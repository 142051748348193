import { CButton, CLabel } from '@coreui/react';
import React, { useState } from 'react';
import CompleteConfigurationButton from './CompleteConfigurationButton';
import ProductNumberButton from './ProductNumberButton';
import SelectionBox from './SelectionBox';
import { ProductImage } from './ProductImage';
import { useTranslation } from 'react-i18next';
import { Configuration, Product } from '../../data/types';
import { ModuleDetails } from '../../services/modules';
import { getProductImageUrl } from '../../data/images/utils';
import { overrideMaterialNumber } from '../../data/products/utils';
import ArrowUpIcon from '../../assets/icons/arrow-up.svg';
import styles from './SelectionSummary.module.scss';

interface SelectionSummaryProps {
  isRecomended: boolean;
  configuration: Configuration;
  moduleDetails: ModuleDetails | undefined;
  goToStep: (stepNumber: number) => () => void;
  product: Product | undefined;
  onCompleteConfigurationClick: () => void;
}

const SelectionSummary: React.FC<SelectionSummaryProps> = ({
  isRecomended,
  configuration,
  moduleDetails,
  goToStep,
  product,
  onCompleteConfigurationClick,
}): JSX.Element => {
  const [areDetailsVisible, setAreDetailsVisible] = useState(true);
  const { innerWidth: width } = window;
  const isOneProductAtRow = width <= 993;
  const { t } = useTranslation('common');

  const renderAccessories = React.useCallback(() => {
    // the selected module has compatible accessories
    if (moduleDetails && moduleDetails.compatibleAccessories) {
      // additional accessories have been selected
      if (configuration.accessories && configuration.accessories.length > 0) {
        return configuration.accessories.map((accessory) => (
          <div key={accessory.name} data-testid={`accessory-${accessory.name}`}>
            {accessory.label}
          </div>
        ));
      }
      // additional accessories have not been selected
      return (
        <div data-testid="accessory-notSelected" className="mb-2">
          {t(`selectionSummary.notSelected`)}
        </div>
      );
    }
    // the selected module does not support additional accessories
    return (
      <div data-testid="accessory-notAvailable" className="mb-2">
        {t(`selectionSummary.notAvailable`)}
      </div>
    );
  }, [configuration.accessories, moduleDetails, t]);

  const renderServicesAndDocumentation = React.useCallback(() => {
    // the selected module has compatible accessories
    if (moduleDetails && moduleDetails.documentationsAndServices) {
      // additional accessories have been selected
      if (configuration.documentationsAndServices && configuration.documentationsAndServices.length > 0) {
        return configuration.documentationsAndServices.map((ds) => (
          <div key={ds.sku} data-testid={`documentationAndService-${ds.sku}`}>
            {ds.label}
          </div>
        ));
      }
      // additional accessories have not been selected
      return (
        <div data-testid="documentationAndService-notSelected" className="mb-2">
          {t(`selectionSummary.notSelected`)}
        </div>
      );
    }
    // the selected module does not support additional accessories
    return (
      <div data-testid="documentationAndService-notAvailable" className="mb-2">
        {t(`selectionSummary.notAvailable`)}
      </div>
    );
  }, [configuration.documentationsAndServices, moduleDetails, t]);

  const productImageUrl = product?.materialNumber && getProductImageUrl(product.materialNumber);
  const imagePadding = !productImageUrl && !isOneProductAtRow ? '4.4svh' : undefined;
  return (
    <div className="bg-light text-dark px-3 py-4 mb-4">
      <h4 className="mb-2">
        <strong>{t(`selectionSummary.yourSelection`)}</strong>
      </h4>

      <div className="mb-4">{product && <ProductImage productNumber={product.materialNumber} />}</div>
      {areDetailsVisible && (
        <>
          <SelectionBox title={t(`selectionSummary.step1.header`)} onEdit={goToStep(1)}>
            <CLabel style={{ paddingTop: imagePadding }}>{t(`selectionSummary.step1.balance`)}</CLabel>
            <div data-testid="balance" className="mb-2">
              {moduleDetails ? moduleDetails.categoryName : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step1.maximumCapacity`)}</CLabel>
            <div data-testid="max-capacity" className="mb-2">
              {moduleDetails ? `${moduleDetails.maxCapacity} g` : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step1.scaleInterval`)}</CLabel>
            <div data-testid="scale-interval">
              {moduleDetails ? `${moduleDetails.scaleInterval} mg` : t(`selectionSummary.notSelected`)}
            </div>
          </SelectionBox>

          <SelectionBox title={t(`selectionSummary.step2.header`)} onEdit={goToStep(2)}>
            <CLabel>{t(`selectionSummary.step2.draftShield`)}</CLabel>
            <div data-testid="draft-shield" className="mb-2">
              {configuration.draftShield ? configuration.draftShield.label : t(`selectionSummary.notSelected`)}
            </div>
          </SelectionBox>

          <SelectionBox title={t(`selectionSummary.step3.header`)} onEdit={goToStep(3)}>
            <CLabel>{t(`selectionSummary.step3.userInterface`)}</CLabel>
            <div data-testid="user-interface" className="mb-2">
              {configuration.displayModule ? configuration.displayModule.label : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step3.softwarePackages`)}</CLabel>
            <div data-testid="software-package" className="mb-2">
              {configuration.displayModule && configuration.displayModule.name === 'MCE' && (
                <span data-testid={`software-package-standard`}>
                  {t(`displayModule.MCE.softwarePackages.standard`)}
                </span>
              )}
              {configuration.displayModule && configuration.displayModule.name === 'MCA' && (
                <span>
                  {configuration.softwarePackage
                    ? `${configuration.softwarePackage.label} ${
                        configuration.softwarePackage.name !== 'none' ? `(${configuration.softwarePackage.name})` : ''
                      }`
                    : t(`selectionSummary.notSelected`)}
                  {configuration.softwareOptions &&
                    configuration.softwareOptions.length > 0 &&
                    configuration.softwareOptions.map((option) => (
                      <div key={option.name} data-testid={`software-package-${option.name}`}>
                        {option.label} ({option.name})
                      </div>
                    ))}
                </span>
              )}
            </div>
            {configuration.hardwarePackage && (
              <>
                <CLabel>{t(`selectionSummary.step3.hardwareOptions`)}</CLabel>
                <span>
                  {configuration.hardwarePackage ? (
                    <div
                      key={configuration.hardwarePackage.name}
                      data-testid={`software-package-${configuration.hardwarePackage.name}`}
                    >
                      {configuration.hardwarePackage.label}
                    </div>
                  ) : (
                    <div>{t(`selectionSummary.notSelected`)}</div>
                  )}
                </span>
              </>
            )}
          </SelectionBox>

          <SelectionBox title={t(`selectionSummary.step4.header`)} borderBottom={false} onEdit={goToStep(4)}>
            <CLabel>{t(`selectionSummary.step4.country`)}</CLabel>
            <div data-testid="country" className="mb-2">
              {configuration.country ? t(`country.${configuration.country}`) : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step4.approval`)}</CLabel>
            <div data-testid="approval" className="mb-2">
              {configuration.certificate ? configuration.certificate.label : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step4.accessories`)}</CLabel>
            {renderAccessories()}
            <CLabel>{t(`selectionSummary.step4.documentationsAndServices`)}</CLabel>
            {renderServicesAndDocumentation()}
          </SelectionBox>

          <div>
            {product ? (
              <ProductNumberButton
                productNumber={!isRecomended ? overrideMaterialNumber(product.materialNumber) : product.materialNumber}
              />
            ) : (
              <CompleteConfigurationButton onClick={onCompleteConfigurationClick} />
            )}
          </div>
        </>
      )}

      <div className={styles.detailsButton}>
        <CButton
          variant="outline"
          color="dark"
          shape="square"
          block={true}
          className="border border-dark"
          onClick={() => {
            setAreDetailsVisible(!areDetailsVisible);
          }}
        >
          {t(areDetailsVisible ? 'general.hideDetails' : 'general.showDetails')}
          <img
            className={`${styles.arrow} ${areDetailsVisible ? '' : styles.arrowRotated}`}
            src={ArrowUpIcon}
            width={30}
            height={30}
            alt=""
            title=""
          />
        </CButton>
      </div>
    </div>
  );
};

export default SelectionSummary;
