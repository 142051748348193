import { useLocation, useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { navigationStepStatusState } from '../states';
import { NavigationStepsEnum, NavigationStepStatus, NavigationStepStatusState } from '../types/navigation';

type UseNavigationSteps = [
  {
    currentlyEditedStep: number;
    navigationStepStatus: NavigationStepStatusState;
    currentlyEditedStepStatus: NavigationStepStatus;
  },
  { onStepSelect: (step: number) => () => void },
];

const useNavigationSteps = (): UseNavigationSteps => {
  const { pathname } = useLocation();
  const currentlyEditedStep = Number(pathname.substr(-1));
  const [navigationStepStatus] = useRecoilState(navigationStepStatusState);
  const currentlyEditedStepStatus = navigationStepStatus[`step${currentlyEditedStep}` as NavigationStepsEnum];
  const history = useHistory();

  function onStepSelect(step: number) {
    return function () {
      history.push(`/step${step}`);
    };
  }

  return [{ currentlyEditedStep, navigationStepStatus, currentlyEditedStepStatus }, { onStepSelect }];
};

export default useNavigationSteps;
