import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { none } from '../../shields';

const module_1202s: WeighingModule = {
  name: '1202S',
  scaleInterval: [10],
  maxCapacity: [1200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_1202s;
