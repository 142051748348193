import { Category, WeighingModule } from '../../types';
import module_36p from './36p';
import module_36s from './36s';
import module_66p from './66p';
import module_66s from './66s';
import module_116s from './116s';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const modules: WeighingModule[] = [module_36p, module_36s, module_66p, module_66s, module_116s];
const t = i18next.getFixedT(null, 'common');

export const highCapacityMicro: Category = {
  get name() {
    return t(`category.${this.id}.name`);
  },
  get info() {
    return t(`category.${this.id}.info`);
  },
  id: 'highCapacityMicro',
  modules,
  image: `${env('IMAGE_PREFIX')}/FU_MCA_36S_50mm.png`,
  newProduct: true,
};

export default highCapacityMicro;
