import { Certificate, Country, WeighingModule } from '../data/types';

export function getCertificatesForCountry(
  country: Country,
  module: WeighingModule | undefined = undefined,
): Certificate[] {
  let filteredCertificates: Certificate[] = [];

  if (module) {
    filteredCertificates = module.possibleCertificates.filter((certificate) => {
      if (certificate.validForCountries === true) {
        return !certificate.invalidForCountries || !certificate.invalidForCountries.includes(country);
      }
      return certificate.validForCountries.includes(country);
    });
  }

  return filteredCertificates;
}
