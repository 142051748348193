import { CButton } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProductNumberButtonProps {
  productNumber: string;
}

const ProductNumberButton: React.FC<ProductNumberButtonProps> = ({ productNumber }): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <CButton
      color="dark"
      block={true}
      className="text-left"
      onClick={() => window.scrollTo(0, 0)}
      data-testid="product-number-button"
    >
      <strong>{t(`selectionSummary.yourProductNumber`)}</strong>
      <br />
      {productNumber}
    </CButton>
  );
};

export default ProductNumberButton;
