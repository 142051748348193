import { CCard, CCardGroup, CCol, CRow } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import DocumentationAndServiceSelectionItem, {
  DocumentationAndServiceSelectionItemDTO,
} from './DocumentationAndServiceSelectionItem';
import { Configuration, DocumentationAndService } from '../../data/types';
import styles from './AccessorySelection.module.scss';

export interface DocumentationAndServiceListProps {
  allDocumentationsAndServicesGrouped: DocumentationAndService[][];
  itemsInRow: number;
  onUndoSelection: (documentationAndService: DocumentationAndService) => () => void;
  onDocumentationAndServiceSelect: (documentationAndService: DocumentationAndService) => () => void;
  config: Configuration;
}

const DocumentationAndServiceList: React.FC<DocumentationAndServiceListProps> = (props): JSX.Element => {
  const { t } = useTranslation('common');
  const { allDocumentationsAndServicesGrouped, itemsInRow, onDocumentationAndServiceSelect, onUndoSelection, config } = props;

  function createSelectionItemData(documentationAndService: DocumentationAndService, index: number) {
    const selectionItemData: DocumentationAndServiceSelectionItemDTO = {
      label: documentationAndService.label,
      sku: documentationAndService.sku,
      imageURL: documentationAndService.image,
      selected: !!config.documentationsAndServices?.find((ds) => documentationAndService.label === ds.label),
      lastInRow: index === itemsInRow - 1,
    };

    return selectionItemData;
  }

  return (
    <>
      <CRow className="mb-4" data-testid="documentationAndService-list">
        <CCol lg="12">{t('documentationAndServiceSelection.header')}</CCol>
        {allDocumentationsAndServicesGrouped.map((documentationAndServiceGroup: DocumentationAndService[], index) => {
          return (
            <CCol lg="12" key={`documentationsAndServices-col-${index}`}>
              <CCardGroup key={`dsgroup-${index}`}>
                {documentationAndServiceGroup.map((documentationAndService: DocumentationAndService, index) => {
                  return (
                    <DocumentationAndServiceSelectionItem
                      key={documentationAndService.label}
                      itemData={createSelectionItemData(documentationAndService, index)}
                      onSelect={onDocumentationAndServiceSelect(documentationAndService)}
                      onUndoSelection={onUndoSelection(documentationAndService)}
                    />
                  );
                })}
                {documentationAndServiceGroup.length < itemsInRow &&
                  _.times(itemsInRow - documentationAndServiceGroup.length, (i) => (
                    <CCard
                      key={`item-${i}`}
                      className={`${styles.hiddenCard} mb-5 ${i === documentationAndServiceGroup.length - 1 ? 'mr-5' : ''}`}
                      data-testid={`card-placeholder-${i}`}
                    />
                  ))}
              </CCardGroup>
            </CCol>
          );
        })}
      </CRow>
    </>
  );
};

export default DocumentationAndServiceList;
