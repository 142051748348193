import { useRecoilState } from 'recoil';
import { configurationState, navigationStepStatusState, selectedBalanceValuesState } from '../../../states';
import { useEffect, useState } from 'react';
import { Category, Configuration, WeighingModule } from '../../../data/types';
import { Location } from 'history';
import { useHistory } from 'react-router-dom';
import { NavigationSlugs, NavigationStepStatusState } from '../../../types/navigation';
import { isCompatibleWithModule } from '../../../services/draftShield';
import { displayIsCompatibleWithModule } from '../../../services/displays';
import { UseModuleSelectionList } from '../types';
import { CapacityAndScaleEvent } from '../../../types/tagManagerEvents';
import { availableMaxCapacity, availableScaleIntervalGroup } from './utils';
import { sendGoogleTagManagerEvent, trackPageImpression } from '../../../services/googleTagManager';
import { getCategoryByModule } from '../../../services/modules';
import { useRef } from 'react';

export const useModuleSelectionList = (): UseModuleSelectionList => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [navigationStepStatus, setNavigationStepStatus] = useRecoilState(navigationStepStatusState);
  const [confirmModule, setConfirmModule] = useState<WeighingModule>();
  const [isModuleSelected, setIsModuleSelected] = useState<boolean>(false);
  const [incompleteConfigWarning, setIncompleteConfigWarning] = useState<boolean>(false);
  const [selectedBalanceValues, setSelectedBalanceValues] = useRecoilState(selectedBalanceValuesState);
  const [targetLocation, setTargetLocation] = useState<Location | undefined>();
  const history = useHistory();

  // the only way to update the page (to update the state)
  // before triggering handleRouteExit of a Prompt component
  const isModuleSelectedRef = useRef(isModuleSelected);
  useEffect(() => {
    isModuleSelectedRef.current = isModuleSelected;
  }, [isModuleSelected]);

  useEffect(() => {
    trackPageImpression(1);
  }, []);

  useEffect(() => {
    return () => {
      trackModuleSelection(config.module);
    };
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    // change step status
    if (navigationStepStatus.step1 === 'not_set') {
      setNavigationStepStatus({ ...navigationStepStatus, step2: 'not_set' });
    }
    // eslint-disable-next-line
  }, []);

  const doModuleSelection = (module: WeighingModule) => {
    const newConfig: Configuration = {
      ...config,
      module,
      accessories: module.defaultSelectedAccessories || undefined,
    };
    const newNavigationStepStatus: NavigationStepStatusState = {
      ...navigationStepStatus,
      step1: 'complete',
    };

    if (config.draftShield && !isCompatibleWithModule(config.draftShield, module)) {
      newConfig.draftShield = undefined;
      newNavigationStepStatus.step2 = 'not_set';
    }

    if (config.displayModule && !displayIsCompatibleWithModule(config.displayModule, module)) {
      newConfig.displayModule = undefined;
      newNavigationStepStatus.step3 = 'not_set';
    }

    setConfig(newConfig);
    setNavigationStepStatus(newNavigationStepStatus);
  };

  const createAndSendTagManagerEvent = (module: WeighingModule) => {
    const maxCapacityGroups = availableMaxCapacity([module]);
    const scaleIntervalGroups = availableScaleIntervalGroup([module]);
    const category = getCategoryByModule(module);
    const categoryName = category?.name || '';

    const event: CapacityAndScaleEvent = {
      event: 'cubisCalculatorSelection',
      'cubis-config-capacity': `${maxCapacityGroups[0].name} g`, // TODO find a better way
      'cubis-config-interval': `${scaleIntervalGroups[0].name} mg`, // TODO find a better way
      'cubis-config-scale': `${categoryName} ${module.name}`,
      'cubis-config-slug': NavigationSlugs[1],
      'cubis-config-step': 1,
    };

    sendGoogleTagManagerEvent(event);
  };

  const trackModuleSelection = (module: WeighingModule | undefined) => {
    if (history.location.pathname.includes('/step2') && module) {
      createAndSendTagManagerEvent(module);
    }
  };

  const handleModuleSelection = (module: WeighingModule) => {
    if (
      (config.draftShield && !isCompatibleWithModule(config.draftShield, module)) ||
      (config.displayModule && !displayIsCompatibleWithModule(config.displayModule, module))
    ) {
      setConfirmModule(module);
    } else {
      doModuleSelection(module);
      setIsModuleSelected(true);
      createAndSendTagManagerEvent(module);
      goToDraftShield();
    }
  };

  const undoModuleSelection = () => {
    setConfig({
      ...config,
      module: undefined,
      draftShield: undefined,
      accessories: undefined,
      maxCapacity: undefined,
      scaleInterval: undefined,
    });
    setSelectedBalanceValues({});
    setNavigationStepStatus({ ...navigationStepStatus, step1: 'not_set', step2: 'not_set' });
  };

  const handleCategoryDropdownSelection = (category: Category) => {
    return function (field: 'maxCapacity' | 'scaleInterval', value: string | undefined) {
      // reset balance and shield selection when one of the currently selected category dropdowns deselected
      if (value === undefined && config.module !== undefined && category.modules.includes(config.module)) {
        undoModuleSelection();
      }

      const updatedCategoryValue = {
        ...selectedBalanceValues[category.name],
        [field]: value,
      };
      setSelectedBalanceValues({ ...selectedBalanceValues, [category.name]: updatedCategoryValue });
    };
  };

  const handleRouteExit = (location: Location) => {
    // this if statement does: go to next page if everything is selected and don't show any prompts
    if (isModuleSelectedRef) return true;

    const configIncomplete =
      !config.module &&
      Object.values(selectedBalanceValues).some(
        ({ maxCapacity, scaleInterval }) => maxCapacity !== undefined || scaleInterval !== undefined,
      );

    if (!targetLocation && configIncomplete) {
      setIncompleteConfigWarning(true);
      setTargetLocation(location);
      return false;
    }
    return true;
  };

  const goToClickedRoute = () => {
    const targetRoute = targetLocation?.pathname || '/step5';
    setTargetLocation(undefined);
    setIncompleteConfigWarning(false);
    history.push(targetRoute);
  };

  const goToDraftShield = () => {
    history.push('/step2');
  };

  return {
    config,
    confirmModule,
    doModuleSelection,
    goToClickedRoute,
    handleCategoryDropdownSelection,
    handleModuleSelection,
    handleRouteExit,
    incompleteConfigWarning,
    selectedBalanceValues,
    setConfirmModule,
    setIncompleteConfigWarning,
    undoModuleSelection,
    targetLocation,
    goToDraftShield,
  };
};
