import { CButton } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import styles from './CompleteConfigurationButton.module.scss';

interface CompleteConfigurationButtonProps {
  onClick: () => void;
}

const CompleteConfigurationButton: React.FC<CompleteConfigurationButtonProps> = ({ onClick }): JSX.Element => {
  const { t } = useTranslation('common');
  const completeConfigurationLabel = t(`requestQuote.form.completeConfiguration`);
  return (
    <CButton
      color="dark"
      variant="outline"
      shape="square"
      block={true}
      className={`${styles.completeConfigurationButton} border-dark`}
      onClick={onClick}
      data-testid="complete-configuration-button"
    >
      <img
        src={ArrowRightIcon}
        width={25}
        height={25}
        style={{ transform: 'scale(-1, -1)' }}
        alt={completeConfigurationLabel}
        title={completeConfigurationLabel}
      />
      {completeConfigurationLabel}
    </CButton>
  );
};

export default CompleteConfigurationButton;
