import { useRecoilState } from 'recoil';
import RequestQuote from '../components/RequestQuote/RequestQuote';
import { useHistory } from 'react-router-dom';
import { currentStepState, navigationStepStatusState } from '../states';
import { useEffect } from 'react';

const Step5 = (): JSX.Element => {
  const [navigationStatus] = useRecoilState(navigationStepStatusState);
  const history = useHistory();
  const [, setCurrentStep] = useRecoilState(currentStepState);

  useEffect(
    () => {
      setCurrentStep(5);
    },
    // eslint-disable-next-line
    [],
  );

  if (navigationStatus.step3 !== 'complete') {
    history.push('/step3');
  }
  return <RequestQuote />;
};

export default Step5;
