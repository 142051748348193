import { WeighingModule } from '../../types';
import { none } from '../../shields';
import { certificates } from '../../certificates';

const module_6202s: WeighingModule = {
  name: '6202S',
  scaleInterval: [10],
  maxCapacity: [6200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_6202s;
