import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { Accessory, Configuration } from '../../../data/types';
import { getProduct } from '../../../services/products';
import { configurationState, navigationStepStatusState } from '../../../states';

interface UseAccessorySelection {
  config: Configuration;
  isOtherStepsCompleted: boolean;
  moduleHasAccessories: boolean;
  productMaterialNumber: string | undefined;
  allAccessoriesGrouped: Accessory[][];
  onUndoSelection: (accessory: Accessory) => () => void;
  onAccessorySelect: (accessory: Accessory) => () => void;
}

export const useAccessorySelectionList = (numberOfRows: number): UseAccessorySelection => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [navigationStepStatus] = useRecoilState(navigationStepStatusState);

  const isOtherStepsCompleted =
    navigationStepStatus.step1 == 'complete' &&
    navigationStepStatus.step2 == 'complete' &&
    navigationStepStatus.step3 == 'complete';
  const moduleHasAccessories = config.module?.compatibleAccessories !== undefined;
  const productMaterialNumber = getProduct(config)?.materialNumber;

  const allAccessoriesGrouped: Accessory[][] = _.chunk(config.module?.compatibleAccessories, numberOfRows);

  const onUndoSelection = (unselectedAccessory: Accessory) => () => {
    const currentlySelectedOptions = [...(config.accessories ?? [])];
    const removeAccessory = currentlySelectedOptions.find((accessory) => accessory.name == unselectedAccessory.name);
    if (removeAccessory) {
      const removeIndex = currentlySelectedOptions.indexOf(removeAccessory);
      if (removeIndex > -1) {
        currentlySelectedOptions.splice(removeIndex, 1);
        setConfig({
          ...config,
          accessories: currentlySelectedOptions,
        });
      }
    }
  };

  const onAccessorySelect = (accessory: Accessory) => () => {
    const currentlySelectedOptions = config.accessories ?? [];
    setConfig({
      ...config,
      accessories: [...currentlySelectedOptions, accessory],
    });
  };

  return {
    config,
    isOtherStepsCompleted,
    moduleHasAccessories,
    productMaterialNumber,
    allAccessoriesGrouped,
    onUndoSelection,
    onAccessorySelect,
  };
};
