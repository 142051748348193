import React, { useRef } from 'react';
import { Certificate } from '../../../data/types';
import certificates from '../../../data/certificates';
import { UseCertificateSelection, UseCertificateSelectionParams } from '../types';

export const useCertificateSelection = ({
  onSelect,
  enabled,
  currentValue,
}: UseCertificateSelectionParams): UseCertificateSelection => {
  const isPristine = enabled && !currentValue;
  const selectRef = useRef<HTMLInputElement>();

  function handleSelect({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (!enabled) {
      return;
    }

    let certificate: Certificate | undefined = undefined;

    if (target.value !== '') {
      certificate = certificates.find((certificate) => certificate.name === target.value);
    }

    onSelect(certificate);
    selectRef.current?.blur();
  }

  return {
    isPristine,
    handleSelect,
    selectRef,
  };
};
