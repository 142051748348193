import { DocumentationAndService } from '../types';
import env from '@beam-australia/react-env';
import PDFIcon from '../../assets/icons/file-pdf.svg';

const createDocumentationOrService = (label: string, image: string, sku: string): DocumentationAndService => {
  return {
    label,
    image,
    sku,
  };
};

export const DSPCR1IQOQFUL1 = createDocumentationOrService(
  'Ingenix Software Qualification Service',
  `${env('IMAGE_PREFIX')}/DSPCR1IQOQFUL1.jpg`,
  'DSPCR1IQOQFUL1',
);

export const DSPCR1VALMAN1 = createDocumentationOrService(
  'Ingenix Software Validation Manual',
  PDFIcon,
  'DSPCR1VALMAN1',
);

export const DSXPCR1EXTVAL1 = createDocumentationOrService(
  'Ingenix Balance Extension Document',
  PDFIcon,
  'DSXPCR1EXTVAL1',
);

export const DSXPCR1REVAL1 = createDocumentationOrService(
  'Ingenix Revalidation Document',
  PDFIcon,
  'DSXPCR1REVAL1',
);

const documentationsAndServices: DocumentationAndService[] = [DSPCR1IQOQFUL1, DSPCR1VALMAN1, DSXPCR1EXTVAL1, DSXPCR1REVAL1];

export default documentationsAndServices;
