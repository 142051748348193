import { CButton } from '@coreui/react';
import React from 'react';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import styles from './MobileNavigationButtons.module.scss';

interface NavigationButtonProps {
  onClick: () => void;
  label: string;
  height: number;
  width: number;
}

interface NavigationButtonNextProps extends NavigationButtonProps {
  label: string;
  disabled?: boolean;
}

export const NavigationButtonNext: React.FC<NavigationButtonNextProps> = ({
  label,
  onClick,
  disabled = false,
  height,
  width,
}): JSX.Element => {
  return (
    <CButton
      data-testid="next-button"
      shape="square"
      color={'dark'}
      variant={'outline'}
      className={`${styles.navigationButton} border border-dark`}
      disabled={disabled}
      onClick={(e: MouseEvent) => {
        onClick();
        (e.currentTarget as HTMLElement).blur();
      }}
    >
      {label} <img src={ArrowRightIcon} width={width} height={height} alt={label} title={label} />
    </CButton>
  );
};

export const NavigationButtonPrevious: React.FC<NavigationButtonProps> = ({
  onClick,
  label,
  width,
  height,
}): JSX.Element => {
  return (
    <CButton
      shape="square"
      color="dark"
      variant="outline"
      className={`${styles.navigationButton} border border-dark`}
      onClick={onClick}
      data-testid="back-button"
    >
      <img src={ArrowLeftIcon} width={width} height={height} alt={label} title={label} />
      {label}
    </CButton>
  );
};
