import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { shields } from './defaults';

const module_70201s: WeighingModule = {
  name: '70201S',
  scaleInterval: [100],
  maxCapacity: [70200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_70201s;
