import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_66s: WeighingModule = {
  name: '6.6S',
  scaleInterval: [0.001],
  maxCapacity: [6.1],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_66s;
