import { CCol, CContainer, CHeader, CHeaderBrand, CImg, CLink, CNav, CRow, CSubheader } from '@coreui/react';
import React from 'react';
import sartoriusLogo from '../sartorius-logo.svg';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NavigationButtonNext, NavigationButtonPrevious } from './Navigation/MobileNavigationButtons';
import useNavigationSteps from '../hooks/useNavigationSteps';
import LanguageDropdown from './LanguageDropdown';

interface MobileHeaderProps {
  currentStep: number;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ currentStep }) => {
  const [{ navigationStepStatus }] = useNavigationSteps();
  const isNextButtonDisabled = currentStep === 3 && navigationStepStatus.step3 !== 'complete';
  const { t } = useTranslation('common');
  const history = useHistory();

  const isLastStep = currentStep === 5;

  return (
    <CHeader data-testid="header" withSubheader={true}>
      <CContainer className={styles.headerContainer}>
        <>
          <CHeaderBrand className="mr-auto">
            <CLink href="https://www.sartorius.com/en/products/weighing/laboratory-balances/cubis-ii" target="_blank">
              <CImg data-testid="logo" src={sartoriusLogo} alt="Sartorius" width={150} height={35} />
            </CLink>
          </CHeaderBrand>
          <CNav className={styles.navContainer}>
            <LanguageDropdown />
          </CNav>
        </>
      </CContainer>

      <CSubheader className="border-0 text-black" data-testid="subheader">
        <CContainer>
          <CRow>
            <CCol lg="12">
              <h3 className={`mt-3 ${styles.headerText}`}>{t('navigationSteps.subheader')}</h3>
            </CCol>
          </CRow>
        </CContainer>
        <CContainer className={styles.subheaderContainer}>
          <CRow>
            <CCol className={styles.stepContainer} lg="12">
              <div className={styles.circleImg}>
                <svg height="100" width="100">
                  <circle cx="50" cy="50" r="40" stroke="#EDEDED" strokeWidth={3} fill="transparent" />
                  {isLastStep ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" x="37" y="37" viewBox="0 0 14 14">
                      <defs>
                        <clipPath id="b">
                          <rect width="14" height="14" />
                        </clipPath>
                      </defs>
                      <g id="a" clip-path="url(#b)">
                        <g transform="translate(0 1)">
                          <path
                            d="M22.158,12,13.708,22.216,9.725,18.667,9,19.511l4.828,4.3L23,12.722Z"
                            transform="translate(-9 -12)"
                            fill-rule="evenodd"
                          />
                        </g>
                      </g>
                    </svg>
                  ) : (
                    <svg height="100" width="100">
                      <circle cx="50" cy="50" r="40" stroke="#EDEDED" strokeWidth={3} fill="transparent" />
                      <text
                        x="50"
                        y="50"
                        fontSize={isLastStep ? '3rem' : '2rem'}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="black"
                      >
                        {`${currentStep} of 4`}
                      </text>

                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        stroke="black"
                        strokeWidth={3}
                        fill="transparent"
                        strokeDasharray={`${currentStep * 63} 282`}
                        transform="rotate(-90, 50, 50)"
                      />
                    </svg>
                  )}
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth={3}
                    fill="transparent"
                    strokeDasharray={`${currentStep * 63} 282`}
                    transform="rotate(-90, 50, 50)"
                  />
                </svg>
              </div>
              <div className={styles.nextButtonAndTextWrapper}>
                <p className={styles.topOfButtonText}>{t(`navigationSteps.step${currentStep}.top`)}</p>
                <div>
                  {currentStep > 1 && (
                    <NavigationButtonPrevious
                      data-testid="navPrev"
                      label={t('general.back')}
                      onClick={() => {
                        history.push(`/step${currentStep - 1}`);
                      }}
                      height={15}
                      width={15}
                    />
                  )}
                  {!isLastStep && (
                    <NavigationButtonNext
                      data-testid="navNext"
                      height={15}
                      width={15}
                      label={currentStep === 4 ? t('requestQuote.form.reviewSelection') : t('general.next')}
                      disabled={isNextButtonDisabled}
                      onClick={() => {
                        history.push(`/step${currentStep + 1}`);
                      }}
                    />
                  )}
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12"></CCol>
          </CRow>
        </CContainer>
        <CContainer className={styles.secondaryHeaderTextContainer}>
          <CRow>
            <CCol lg="12">
              <span className={`${styles.secondaryHeaderText}`}>{t(`navigationSteps.step${currentStep}.bottom`)}</span>
            </CCol>
          </CRow>
        </CContainer>
      </CSubheader>
    </CHeader>
  );
};

export default MobileHeader;
