import { WeighingModule } from '../../types';
import { shields, accessories, defaultSelectedAccessories } from './defaults';
import { certificates } from '../../certificates';
import { mca } from '../../displays';

const module_226s: WeighingModule = {
  name: '226S',
  scaleInterval: [0.005],
  maxCapacity: [220],
  compatibleShields: shields,
  compatibleDisplays: [mca],
  possibleCertificates: certificates,
  compatibleAccessories: accessories,
  defaultSelectedAccessories,
};

export default module_226s;
