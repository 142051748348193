import accessories from '../accessories';

const exactMatchesToOverride: { [key: string]: string } = {
  'MCA2.7S-2SØØ-M QP99': 'MCA2.7S-M-MTS',
  'MCA10.6S-2SØØ-M QP99': 'MCA10.6S-M-MTS',
  'MCE3.6P-2SØØ-M': 'MCE3.6P-M-MTS',
  'MCA225S-2SØØ-I QP99': 'MCA225S-I-MTS',
  'MCA225S-2SØØ-U QP99': 'MCA225S-U-MTS',
  'MCA524S-2SØØ-I QP99': 'MCA524S-I-MTS',
  'MCA224S-2SØØ-I QP99': 'MCA224S-I-MTS',
  'MCE224S-2SØØ-U': 'MCE224S-U-MTS',
  'MCA8202S-2SØØ-0 QP99': 'MCA8202S-0-MTS',
  'MCE8202S-2SØØ-0': 'MCE8202S-0-MTS',
  'MCA36201S-2SØØ-0 QP99': 'MCA36201S-0-MTS',
  'MCE36201S-2SØØ-0': 'MCE36201S-0-MTS',
  'MCA225S-3SØØ-D QP99 HWL': 'MCA225S-I-3-MTS',
  'MCA125S-3SØØ-D QP99 HWL': 'MCA125S-I-3-MTS',
  'MCE125S-3SØØ-D HWL': 'MCE125S-I-3-MTS',
  'MCA226S-3SØØ-D QP99 HWL': 'MCA226S-I-3-MTS',
  'MCA125P-2SØØ-U QP99': 'MCA125P-U-MTS',
  'MCA125P-2SØØ-I QP99': 'MCA125P-I-MTS',
};

const startsWithListToOverride: { [key: string]: string } = {
  'MCE225S-3SØØ-D': 'MCE225S-I-3-MTS',
  'MCA2.7S-2SØØ-M': 'MCA2.7S-M-MTS',
  'MCA6.6S-2SØØ-M': 'MCA10.6S-M-MTS',
  'MCE3.6P-2SØØ-M': 'MCA10.6S-M-MTS',
  'MCA10.6S-2SØØ-M': 'MCA10.6S-M-MTS',
  'MCA524S-2SØØ-I': 'MCA524S-I-MTS',
  'MCA524S-2SØØ-A': 'MCA524S-I-MTS',
  'MCA524S-2SØØ-U': 'MCA524S-I-MTS',
  'MCA524P-2SØØ-I': 'MCA524S-I-MTS',
  'MCA524P-2SØØ-A': 'MCA524S-I-MTS',
  'MCA524P-2SØØ-U': 'MCA524S-I-MTS',
  'MCA324S-2SØØ-I': 'MCA524S-I-MTS',
  'MCA324S-2SØØ-A': 'MCA524S-I-MTS',
  'MCA324S-2SØØ-U': 'MCA524S-I-MTS',
  'MCA324P-2SØØ-I': 'MCA524S-I-MTS',
  'MCA324P-2SØØ-A': 'MCA524S-I-MTS',
  'MCA324P-2SØØ-U': 'MCA524S-I-MTS',
  'MCA224S-2SØØ-I': 'MCA224S-I-MTS',
  'MCA224S-2SØØ-A': 'MCA224S-I-MTS',
  'MCA224S-2SØØ-U': 'MCA224S-I-MTS',
  'MCA124S-2SØØ-I': 'MCA224S-I-MTS',
  'MCA124S-2SØØ-A': 'MCA224S-I-MTS',
  'MCA124S-2SØØ-U': 'MCA224S-I-MTS',
  'MCA8202S-2SØØ-0': 'MCA8202S-0-MTS',
  'MCA6202S-2SØØ-0': 'MCA8202S-0-MTS',
  'MCA4202S-2SØØ-0': 'MCA8202S-0-MTS',
  'MCA2202S-2SØØ-0': 'MCA8202S-0-MTS',
  'MCE6202S-2SØØ-0': 'MCE8202S-0-MTS',
  'MCE4202S-2SØØ-0': 'MCE8202S-0-MTS',
  'MCE2202S-2SØØ-0': 'MCE8202S-0-MTS',
  'MCA36201S-2SØØ-0': 'MCA36201S-0-MTS',
  'MCA20201S-2SØØ-0': 'MCA36201S-0-MTS',
  'MCA36200S-2SØØ-0': 'MCA36201S-0-MTS',
  'MCE20201S-2SØØ-0': 'MCE36201S-0-MTS',
  'MCE36200S-2SØØ-0': 'MCE36201S-0-MTS',
  'MCA225S-3SØØ-D': 'MCA225S-I-3-MTS',
  'MCA225P-3SØØ-D': 'MCA225S-I-3-MTS',
  'MCA125S-3SØØ-D': 'MCA125S-I-3-MTS',
  'MCE225P-3SØØ-D': 'MCE225S-I-3-MTS',
  'MCE125S-3SØØ-D': 'MCE125S-I-3-MTS',
  'MCA226S-3SØØ-D': 'MCA226S-I-3-MTS',
  'MCA125P-U-MTS': 'MCA125P-U-MTS',
  'MCA125P-I-MTS': 'MCA125P-I-MTS',
};

export const overrideMaterialNumber = (materialNumber: string): string => {
  let overridedMaterialNumber = exactMatchesToOverride[materialNumber]?.trim()
    ? exactMatchesToOverride[materialNumber]?.trim()
    : materialNumber?.trim();

  if (overridedMaterialNumber === materialNumber) {
    const key = Object.keys(startsWithListToOverride)
      .find((key) => materialNumber.startsWith(key.trim()))
      ?.trim();
    if (key) {
      overridedMaterialNumber = startsWithListToOverride[key].trim();
    }
  }

  if (overridedMaterialNumber === materialNumber) {
    return materialNumber;
  }

  // for some reason there are duplicated elements in the array and the Set distincts them
  const accessoriesIncluded = Array.from(
    new Set(accessories.filter((x) => materialNumber.includes(`& ${x.name}`)).map((x) => x.name)),
  );

  return accessoriesIncluded.length
    ? overridedMaterialNumber + ' & ' + accessoriesIncluded.join(' & ')
    : overridedMaterialNumber;
};
