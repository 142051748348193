import { Category, WeighingModule } from '../../types';
import module_27s from './27s';
import module_106s from './106s';
import module_36p from './36p';
import module_66s from './66s';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const modules: WeighingModule[] = [module_27s, module_106s, module_66s, module_36p];
const t = i18next.getFixedT(null, 'common');

export const ultramicro_and_micro: Category = {
  get name() {
    return t(`category.${this.id}.name`);
  },
  get info() {
    return t(`category.${this.id}.info`);
  },
  modules,
  image: `${env('IMAGE_PREFIX')}/MB_2_7S_DS_M_MCA.png`,
  id: 'ultraMicroAndMicro',
};

export default ultramicro_and_micro;
