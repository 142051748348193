import { Accessory } from '../types';
import i18next from 'i18next';
import env from '@beam-australia/react-env';

const t = i18next.getFixedT(null, 'common');

const createAccessory = (name: string, image: string, alias: string, hasOwnImage: boolean): Accessory => {
  return {
    name,
    image,
    alias,
    hasOwnImage,
    get label() {
      return t(`accessory.${this.name}.label`);
    },
    get description() {
      return t(`accessory.${this.name}.description`);
    },
  };
};

export const aYDS125A = createAccessory('YDS125A', `${env('IMAGE_PREFIX')}/FU_MCA_66S_50mm_YDS125A.jpg`, '1', true);
export const aYDS125AHighCapacity = createAccessory('YDS125A', `${env('IMAGE_PREFIX')}/FU_MCA_66S50mm_YDS125A.jpg`, '1', true);
export const aYDS125ASemiMicro = createAccessory('YDS125A', `${env('IMAGE_PREFIX')}/FU_MCA_125P_50mm_YDS125A.jpg`, '1', true);
export const aYDS125U = createAccessory('YDS125U', `${env('IMAGE_PREFIX')}/FU_MCE_36S_50mm_YDS125U.jpg`, '2', true);
export const aYDSHR = createAccessory('YDSHR', `${env('IMAGE_PREFIX')}/FU-MCA_116S_90mm_YDSHR.jpg`, '3', true);

export const accessories: Accessory[] = [aYDS125A, aYDS125U, aYDSHR, aYDS125AHighCapacity];

export default accessories;
