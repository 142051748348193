import React from 'react';
import IncompleteStepsNotifier from './IncompleteStepsNotifier';
import DocumentationAndServiceList from './DocumentationAndServiceList';
import { useDocumentationAndServiceSelection } from './hooks/useDocumentationAndServiceSelection';

const ITEMS_IN_ROW = 3;

export const NOTIFIER_TYPE_SERVICE = 'services';

const DocumentationAndServiceSelectionList: React.FC<Record<string, never>> = () => {
  const {
    config,
    areOtherStepsCompleted,
    moduleHasDocumentationsAndServices,
    allDocumentationsAndServicesGrouped,
    onDocumentationAndServiceSelect,
    onUndoSelection,
  } = useDocumentationAndServiceSelection(ITEMS_IN_ROW);

  return (
    <>
      {!areOtherStepsCompleted && <IncompleteStepsNotifier type={NOTIFIER_TYPE_SERVICE} />}
      {moduleHasDocumentationsAndServices && areOtherStepsCompleted && (
        <DocumentationAndServiceList
          allDocumentationsAndServicesGrouped={allDocumentationsAndServicesGrouped}
          onDocumentationAndServiceSelect={onDocumentationAndServiceSelect}
          onUndoSelection={onUndoSelection}
          itemsInRow={ITEMS_IN_ROW}
          config={config}
        />
      )}
    </>
  );
};

export default DocumentationAndServiceSelectionList;
