import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_27s: WeighingModule = {
  name: '2.7S',
  scaleInterval: [0.0001],
  maxCapacity: [2.1],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_27s;
