import { CButton, CCardGroup, CRow } from '@coreui/react';
import { Prompt } from 'react-router-dom';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import { categories } from '../../data';
import ModuleSelection from './ModuleSelectionItem';
import ConfigurationWarning, { computeProceedToStepTitle } from '../ConfigurationWarning';
import { useModuleSelectionList } from './hooks/useModuleSelectionList';
import { Trans, useTranslation } from 'react-i18next';
import HelpTextButton from '../HelpTextButton';
import ModuleSelectionHelpText from './ModuleSelectionHelpText';
import { useRecoilState } from 'recoil';
import { currentStepState } from '../../states';
import { useEffect } from 'react';

const ModuleSelectionList = (): JSX.Element => {
  const {
    config,
    handleModuleSelection,
    undoModuleSelection,
    handleCategoryDropdownSelection,
    handleRouteExit,
    selectedBalanceValues,
    confirmModule,
    setConfirmModule,
    doModuleSelection,
    incompleteConfigWarning,
    setIncompleteConfigWarning,
    goToClickedRoute,
    targetLocation,
    goToDraftShield,
  } = useModuleSelectionList();

  const { t } = useTranslation('common');
  const [, setCurrentStep] = useRecoilState(currentStepState);

  useEffect(
    () => {
      setCurrentStep(1);
    },
    // eslint-disable-next-line
    [],
  );

  const configurationWarningKey = computeProceedToStepTitle(targetLocation?.pathname ?? '');
  const configurationWarningLabel = t(configurationWarningKey);

  return (
    <>
      <HelpTextButton title={t('helpText.step1.heading')} content={<ModuleSelectionHelpText />} />
      <CRow>
        <CCardGroup>
          {categories.map((category) =>
            config.module && category.modules.includes(config.module) ? (
              <ModuleSelection
                key={`category-${category.name}-${config.module?.maxCapacity}-${config.module?.scaleInterval}`}
                category={category}
                onModuleSelection={handleModuleSelection}
                onUndoModuleSelection={undoModuleSelection}
                onDropdownChange={handleCategoryDropdownSelection(category)}
                active
                selectedModule={config.module}
              />
            ) : (
              <ModuleSelection
                key={`category-${category.name}`}
                category={category}
                onModuleSelection={handleModuleSelection}
                onUndoModuleSelection={undoModuleSelection}
                onDropdownChange={handleCategoryDropdownSelection(category)}
                selectedDropdownValues={selectedBalanceValues[category.name]}
              />
            ),
          )}
        </CCardGroup>
      </CRow>

      <Prompt message={handleRouteExit} />

      <ConfigurationWarning
        title={t('configurationWarning.impactItemSelection')}
        show={!!confirmModule}
        buttons={
          <CButton
            color="primary"
            block={true}
            onClick={() => {
              // confirm module
              if (confirmModule) {
                doModuleSelection(confirmModule);
                setConfirmModule(undefined);
                goToDraftShield();
              }
            }}
            data-testid="proceed-button"
          >
            {t('configurationWarning.proceedToDraftShield')}{' '}
            <img src={ArrowRightIcon} width={20} height={20} alt="Proceed" title="Proceed" />
          </CButton>
        }
        onClose={() => {
          // remove module to confirm
          setConfirmModule(undefined);
        }}
      >
        <p>
          <small>{t('configurationWarning.notApplicableForItem')}</small>
        </p>
        <p>
          <small>
            <Trans i18nKey="configurationWarning.selectAnotherDraftShield" t={t}>
              To complete your Cubis <sup>®&nbsp;</sup> II configuration you will need to select another draft shield
            </Trans>
          </small>
        </p>
      </ConfigurationWarning>

      <ConfigurationWarning
        title={t('configurationWarning.incompleteConfiguration')}
        show={incompleteConfigWarning}
        buttons={
          <>
            <CButton
              color="primary"
              block={true}
              onClick={() => {
                setIncompleteConfigWarning(false);
              }}
            >
              {t('configurationWarning.completeConfiguration')}
            </CButton>
            <CButton
              shape="square"
              color="dark"
              variant="outline"
              className="border border-dark"
              block={true}
              onClick={goToClickedRoute}
            >
              {configurationWarningLabel}
              <img
                src={ArrowRightIcon}
                width={20}
                height={20}
                alt={configurationWarningLabel}
                title={configurationWarningLabel}
              />
            </CButton>
          </>
        }
        onClose={() => {
          // remove module to confirm
          setIncompleteConfigWarning(false);
        }}
      >
        <p>
          <small>{t('configurationWarning.incompleteModuleConfiguration')}</small>
        </p>
      </ConfigurationWarning>
    </>
  );
};

export default ModuleSelectionList;
