import { Category, WeighingModule } from '../../types';
import module_1203s from './1203s';
import module_2203p from './2203p';
import module_2203s from './2203s';
import module_3203s from './3203s';
import module_323s from './323s';
import module_5203p from './5203p';
import module_5203s from './5203s';
import module_623p from './623p';
import module_623s from './623s';
import module_1202s from './1202s';
import module_12201s from './12201s';
import module_2202s from './2202s';
import module_10202s from './10202s';
import module_14202p from './14202p';
import module_14202s from './14202s';
import module_32202p from './322202p';
import module_4202s from './4202s';
import module_5202s from './5202s';
import module_6202p from './6202p';
import module_6202s from './6202s';
import module_8202s from './8202s';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const modules: WeighingModule[] = [
  module_5203p,
  module_5203s,
  module_3203s,
  module_2203p,
  module_2203s,
  module_1203s,
  module_623p,
  module_623s,
  module_323s,
  module_2202s,
  module_1202s,
  module_12201s,
  module_4202s,
  module_5202s,
  module_6202p,
  module_6202s,
  module_8202s,
  module_10202s,
  module_14202p,
  module_14202s,
  module_32202p,
];

const t = i18next.getFixedT(null, 'common');

export const precision: Category = {
  get name() {
    return t(`category.${this.id}.name`);
  },
  get info() {
    return t(`category.${this.id}.info`);
  },
  modules,
  image: `${env('IMAGE_PREFIX')}/PB_large_6202_S_P_DS_MCA_NO_KEYS.png`,
  id: 'precision',
};

export default precision;
