/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BalanceConfiguration } from './BalanceConfiguration';
import {
    BalanceConfigurationFromJSON,
    BalanceConfigurationFromJSONTyped,
    BalanceConfigurationToJSON,
} from './BalanceConfiguration';
import type { TFCustomerContactData } from './TFCustomerContactData';
import {
    TFCustomerContactDataFromJSON,
    TFCustomerContactDataFromJSONTyped,
    TFCustomerContactDataToJSON,
} from './TFCustomerContactData';

/**
 * 
 * @export
 * @interface TFQuoteRequestDTO
 */
export interface TFQuoteRequestDTO {
    /**
     * 
     * @type {TFCustomerContactData}
     * @memberof TFQuoteRequestDTO
     */
    customerContactData: TFCustomerContactData;
    /**
     * 
     * @type {BalanceConfiguration}
     * @memberof TFQuoteRequestDTO
     */
    balanceConfiguration?: BalanceConfiguration;
    /**
     * The selected language for the UI by the user
     * @type {string}
     * @memberof TFQuoteRequestDTO
     */
    selectedLanguage: string;
}

/**
 * Check if a given object implements the TFQuoteRequestDTO interface.
 */
export function instanceOfTFQuoteRequestDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerContactData" in value;
    isInstance = isInstance && "selectedLanguage" in value;

    return isInstance;
}

export function TFQuoteRequestDTOFromJSON(json: any): TFQuoteRequestDTO {
    return TFQuoteRequestDTOFromJSONTyped(json, false);
}

export function TFQuoteRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TFQuoteRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerContactData': TFCustomerContactDataFromJSON(json['customerContactData']),
        'balanceConfiguration': !exists(json, 'balanceConfiguration') ? undefined : BalanceConfigurationFromJSON(json['balanceConfiguration']),
        'selectedLanguage': json['selectedLanguage'],
    };
}

export function TFQuoteRequestDTOToJSON(value?: TFQuoteRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerContactData': TFCustomerContactDataToJSON(value.customerContactData),
        'balanceConfiguration': BalanceConfigurationToJSON(value.balanceConfiguration),
        'selectedLanguage': value.selectedLanguage,
    };
}

