import { WeighingModule } from '../../types';
import { none } from '../../shields';
import { s00, s01 } from '../../certificates';

const module_32202p: WeighingModule = {
  name: '32202P',
  scaleInterval: [10, 100],
  maxCapacity: [4200, 32200],
  compatibleShields: [none],
  possibleCertificates: [s00, s01],
};

export default module_32202p;
