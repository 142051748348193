import { CFormGroup, CLabel, CSelect } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { HardwarePackage } from '../../data/types';
import { configurationState } from '../../states';

interface HardwarePackageSelectionProps {
  packages: HardwarePackage[];
  disabled: boolean;
}

const HardwarePackageSelection: React.FC<HardwarePackageSelectionProps> = ({ packages, disabled }) => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [selectedHardware, setSelectedHardware] = React.useState(config.hardwarePackage?.name);

  const { t } = useTranslation('common');

  function onPackageSelect({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    const newConfig = {
      ...config,
      hardwarePackage: packages.find((item) => item.name === value),
    };
    setConfig(newConfig);
    setSelectedHardware(value);
  }

  return (
    <CFormGroup className="mt-4">
      <CLabel className="font-3xl text-dark" htmlFor="hardware-package">
        {t('hardwarePackageSelection.label')}
      </CLabel>
      <CSelect
        id="hardware-package"
        data-testid="hardware-package"
        onChange={onPackageSelect}
        value={selectedHardware}
        disabled={disabled}
        className="data-hj-allow"
      >
        <option value="0">{t('hardwarePackageSelection.selectHardwarePackage')}</option>
        {packages.map(({ name, label }) => (
          <option value={name} key={name} data-testid={`hardware-package-${name}`}>
            {label}
          </option>
        ))}
      </CSelect>
    </CFormGroup>
  );
};

export default HardwarePackageSelection;
