import { CButton, CCard, CCardBody, CCardFooter, CCardHeader } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageWithModal from '../ImageWithModal';
import UndoSelection from '../UndoSelection';

export interface DocumentationAndServiceSelectionItemDTO {
  label: string;
  sku: string;
  imageURL: string;
  selected: boolean;
  lastInRow: boolean;
}

interface DocumentationAndServiceItemProps {
  itemData: DocumentationAndServiceSelectionItemDTO;
  onSelect: () => void;
  onUndoSelection: () => void;
}

const DocumentationAndServiceItem: React.FC<DocumentationAndServiceItemProps> = (
  props: DocumentationAndServiceItemProps,
): JSX.Element => {
  const {
    itemData: { label, imageURL, sku, selected, lastInRow },
    onSelect,
    onUndoSelection,
  } = props;

  const backgroundColor = selected ? 'primary' : 'light';
  const canBeSelected = !selected;
  const { t } = useTranslation('common');

  return (
    <CCard data-testid={`${label}-card`} className={`bg-${backgroundColor} text-dark mb-5 ${!lastInRow ? 'mr-5' : ''}`}>
      <CCardHeader className={`bg-${backgroundColor} text-dark`}>
        <strong className="mr-1">{label}</strong>
      </CCardHeader>
      <CCardBody className="text-center pb-2">
        <ImageWithModal src={imageURL} srcFullsize={imageURL} fluidGrow={true} muted={false} />
      </CCardBody>
      <CCardFooter className={`bg-${backgroundColor} text-dark`}>
        {canBeSelected && (
          <CButton
            data-testid={`${sku}-button`}
            variant="outline"
            color="dark"
            shape="square"
            block={true}
            className="border border-dark"
            onClick={onSelect}
          >
            {t('general.select')}
          </CButton>
        )}
        {selected && <UndoSelection onUndoSelection={onUndoSelection} />}
      </CCardFooter>
    </CCard>
  );
};

export default DocumentationAndServiceItem;
