import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_623s: WeighingModule = {
  name: '623S',
  scaleInterval: [1],
  maxCapacity: [620],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_623s;
