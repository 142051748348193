/* istanbul ignore file */

import { atom, AtomEffect, DefaultValue } from 'recoil';
import { categories } from './data';
import {
  Accessory,
  Certificate,
  Configuration,
  Country,
  DisplayModule,
  DocumentationAndService,
  DraftShield,
  HardwarePackage,
  SoftwareOption,
  SoftwarePackage,
} from './data/types';
import { NavigationStepsEnum, NavigationStepStatus, NavigationStepStatusState } from './types/navigation';
import { SelectedBalance } from './types/types';

interface SavedConfiguration {
  module?: string;
  draftShield?: DraftShield;
  softwareOptions?: SoftwareOption[];
  hardwarePackage?: HardwarePackage;
  displayModule?: DisplayModule;
  softwarePackage?: SoftwarePackage;
  country?: Country;
  certificate?: Certificate;
  accessories?: Accessory[];
  documentationsAndServices?: DocumentationAndService[];
}

type SavedNavigationSteps = {
  [key in NavigationStepsEnum]: NavigationStepStatus;
};

const configurationEffect =
  (key: string): AtomEffect<Configuration> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      const savedConfiguration: SavedConfiguration = JSON.parse(savedValue);
      const rebuildConfiguration: Configuration = {
        module: categories
          .flatMap((category) => category.modules)
          .find((module) => module.name === savedConfiguration.module),
        draftShield: savedConfiguration.draftShield,
        softwarePackage: savedConfiguration.softwarePackage,
        softwareOptions: savedConfiguration.softwareOptions,
        hardwarePackage: savedConfiguration.hardwarePackage,
        country: savedConfiguration.country,
        certificate: savedConfiguration.certificate,
        displayModule: savedConfiguration.displayModule,
        accessories: savedConfiguration.accessories,
        documentationsAndServices: savedConfiguration.documentationsAndServices,
      };
      setSelf(rebuildConfiguration);
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        const savedConfiguration: SavedConfiguration = {
          module: newValue.module ? newValue.module.name : undefined,
          draftShield: newValue.draftShield,
          softwarePackage: newValue.softwarePackage,
          softwareOptions: newValue.softwareOptions,
          hardwarePackage: newValue.hardwarePackage,
          country: newValue.country,
          certificate: newValue.certificate,
          displayModule: newValue.displayModule,
          accessories: newValue.accessories,
          documentationsAndServices: newValue.documentationsAndServices,
        };
        localStorage.setItem(key, JSON.stringify(savedConfiguration));
      }
    });
  };

export const currentStepState = atom<number>({
  key: 'currentStep',
  default: 1,
});

const navigationStepsEffect =
  (key: string): AtomEffect<NavigationStepStatusState> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      const savedState: SavedNavigationSteps = JSON.parse(savedValue);
      const rebuildState: NavigationStepStatusState = {
        step1: savedState.step1,
        step2: savedState.step2,
        step3: savedState.step3,
        step4: savedState.step4,
      };
      setSelf(rebuildState);
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        const savedState: SavedNavigationSteps = {
          step1: newValue.step1,
          step2: newValue.step2,
          step3: newValue.step3,
          step4: newValue.step4,
        };
        localStorage.setItem(key, JSON.stringify(savedState));
      }
    });
  };

export const configurationState = atom<Configuration>({
  key: 'configurationState',
  default: {},
  effects_UNSTABLE: [configurationEffect('current_configuration')],
});

export const navigationStepStatusState = atom<NavigationStepStatusState>({
  key: 'navigationStepStatus',
  default: {
    step1: 'not_set',
    step2: 'not_set',
    step3: 'not_set',
    step4: 'not_set',
  },
  effects_UNSTABLE: [navigationStepsEffect('navigation_steps')],
});

export const selectedBalanceValuesState = atom<SelectedBalance>({
  key: 'selectedBalanceValues',
  default: {},
});

export const showNavigationState = atom<boolean>({
  key: 'showNavigation',
  default: true,
});

export const isRecommendationAvailableState = atom<boolean>({
  key: 'isRecommendationAvailable',
  default: false,
});

export const isCacheClearedState = atom<boolean>({
  key: 'isCacheCleared',
  default: false,
});
