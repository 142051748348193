import {
  CapacityAndScaleEvent,
  CountryApprovalAccessoryEvent,
  DraftShieldEvent,
  NavigationEvent,
  QuoteRequestEvent,
  UISoftwareEvent,
} from '../types/tagManagerEvents';
import { NavigationSlugs } from '../types/navigation';

export const sendGoogleTagManagerEvent = (
  event:
    | NavigationEvent
    | CapacityAndScaleEvent
    | DraftShieldEvent
    | UISoftwareEvent
    | CountryApprovalAccessoryEvent
    | QuoteRequestEvent,
): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push(event);
  }
};

export const trackPageImpression = (step: number): void => {
  const navigationEvent: NavigationEvent = {
    event: 'cubisCalculatorImpression',
    'cubis-config-name': NavigationSlugs[step],
    'cubis-config-step': step,
  };

  sendGoogleTagManagerEvent(navigationEvent);
};
