import { Product } from '../types';

const products: Product[] = [
  {
    materialNumber: 'MCA2.7S-2xxx-F',
    imageName: 'MB_2_7S_DS_F_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2.7S-2xxx-M',
    imageName: 'MB_2_7S_DS_M_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3.6P-2xxx-F',
    imageName: 'MB_3_6P_DS_F_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3.6P-2xxx-M',
    imageName: 'MB_3_6P_DS_M_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA6.6S-2xxx-F',
    imageName: 'MB_6_6S_DS_F_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA6.6S-2xxx-M',
    imageName: 'MB_6_6S_DS_M_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA10.6S-2xxx-F',
    imageName: 'MB_10_6S_DS_F_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA10.6S-2xxx-M',
    imageName: 'MB_10_6S_DS_M_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA125P-2xxx-U',
    imageName: 'FU_MCA_125P_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA125P-2xxx-I',
    imageName: 'FU_MCA_125P_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA125P-2xxx-A',
    imageName: 'FU_MCA_125P_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA125P-2xxx-D',
    imageName: 'FU_MCA_125P_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA124S-2xxx-U',
    imageName: 'AB_124S_DS_U_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA124S-2xxx-I',
    imageName: 'AB_124S_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA124S-2xxx-A',
    imageName: 'AB_124S_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA224S-2xxx-U',
    imageName: 'AB_224S_DS_U_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA224S-2xxx-I',
    imageName: 'AB_224S_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA224S-2xxx-A',
    imageName: 'AB_224S_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA324S-2xxx-U',
    imageName: 'AB_324_S_P_DS_U_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA324S-2xxx-I',
    imageName: 'AB_324_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA324S-2xxx-A',
    imageName: 'AB_324_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA324P-2xxx-U',
    imageName: 'AB_324_S_P_DS_U_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA324P-2xxx-I',
    imageName: 'AB_324_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA324P-2xxx-A',
    imageName: 'AB_324_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA524S-2xxx-U',
    imageName: 'AB_524_S_P_DS_U_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA524S-2xxx-I',
    imageName: 'AB_524_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA524S-2xxx-A',
    imageName: 'AB_524_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA524P-2xxx-U',
    imageName: 'AB_524_S_P_DS_U_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA524P-2xxx-I',
    imageName: 'AB_524_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA524P-2xxx-A',
    imageName: 'AB_524_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA323S-2xxx-A',
    imageName: 'PB_323S_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA323S-2xxx-I',
    imageName: 'PB_323S_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA323S-2xxx-U',
    imageName: 'PB_323S_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA323S-2xxx-E',
    imageName: 'PB_323S_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA323S-2xxx-R',
    imageName: 'PB_323S_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623S-2xxx-A',
    imageName: 'PB_623_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623S-2xxx-I',
    imageName: 'PB_623_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623S-2xxx-U',
    imageName: 'PB_623_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623S-2xxx-E',
    imageName: 'PB_623_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623S-2xxx-R',
    imageName: 'PB_623_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623P-2xxx-A',
    imageName: 'PB_623_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623P-2xxx-I',
    imageName: 'PB_623_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623P-2xxx-U',
    imageName: 'PB_623_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623P-2xxx-E',
    imageName: 'PB_623_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA623P-2xxx-R',
    imageName: 'PB_623_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA1203S-2xxx-A',
    imageName: 'PB_1203S_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA1203S-2xxx-I',
    imageName: 'PB_1203S_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA1203S-2xxx-U',
    imageName: 'PB_1203S_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA1203S-2xxx-E',
    imageName: 'PB_1203S_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA1203S-2xxx-R',
    imageName: 'PB_1203S_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203S-2xxx-A',
    imageName: 'PB_2203_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203S-2xxx-I',
    imageName: 'PB_2203_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203S-2xxx-U',
    imageName: 'PB_2203_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203S-2xxx-E',
    imageName: 'PB_2203_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203S-2xxx-R',
    imageName: 'PB_2203_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203P-2xxx-A',
    imageName: 'PB_2203_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203P-2xxx-I',
    imageName: 'PB_2203_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203P-2xxx-U',
    imageName: 'PB_2203_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203P-2xxx-E',
    imageName: 'PB_2203_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2203P-2xxx-R',
    imageName: 'PB_2203_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3203S-2xxx-A',
    imageName: 'PB_3203_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3203S-2xxx-I',
    imageName: 'PB_3203_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3203S-2xxx-U',
    imageName: 'PB_3203_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3203S-2xxx-E',
    imageName: 'PB_3203_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA3203S-2xxx-R',
    imageName: 'PB_3203_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203S-2xxx-A',
    imageName: 'PB_5203_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203S-2xxx-I',
    imageName: 'PB_5203_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203S-2xxx-U',
    imageName: 'PB_5203_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203S-2xxx-E',
    imageName: 'PB_5203_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203S-2xxx-R',
    imageName: 'PB_5203_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203P-2xxx-A',
    imageName: 'PB_5203_S_P_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203P-2xxx-I',
    imageName: 'PB_5203_S_P_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203P-2xxx-U',
    imageName: 'PB_5203_S_P_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203P-2xxx-E',
    imageName: 'PB_5203_S_P_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5203P-2xxx-R',
    imageName: 'PB_5203_S_P_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5202S-2xxx-A',
    imageName: 'PB_5202S_DS_A_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5202S-2xxx-I',
    imageName: 'PB_5202S_DS_I_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5202S-2xxx-U',
    imageName: 'PB_5202S_DS_U_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5202S-2xxx-E',
    imageName: 'PB_5202S_DS_E_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5202S-2xxx-R',
    imageName: 'PB_5202S_DS_R_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA2202S-2xxx-0',
    imageName: 'PB_large_2202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA4202S-2xxx-0',
    imageName: 'PB_large_4202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA5201S-2xxx-0',
    imageName: 'PB_large_5201S_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA6202S-2xxx-0',
    imageName: 'PB_large_6202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA6202P-2xxx-0',
    imageName: 'PB_large_6202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA8201S-2xxx-0',
    imageName: 'PB_large_8201S_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA8202S-2xxx-0',
    imageName: 'PB_large_8202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA10202S-2xxx-0',
    imageName: 'PB_large_10202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA12201S-2xxx-0',
    imageName: 'PB_large_12201S_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA14202S-2xxx-0',
    imageName: 'PB_large_14202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA14202P-2xxx-0',
    imageName: 'PB_large_14202_S_P_DS_MCA_NO_KEYS',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA11201S-2xxx-0',
    imageName: 'HCB_14201S_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA20201S-2xxx-0',
    imageName: 'HCB_20201S_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36200S-2xxx-0',
    imageName: 'HCB_36200S_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36201S-2xxx-0',
    imageName: 'HCB_36201_S_P_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36201P-2xxx-0',
    imageName: 'HCB_36201_S_P_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA50201S-2xxx-0',
    imageName: 'HCB_50201S_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA70200S-2xxx-0',
    imageName: 'HCB_70200S_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA70201S-2xxx-0',
    imageName: 'HCB_70201S_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA32202P-2xxx-0',
    imageName: 'HCB_round_32202P_MCA',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36S-3xxx-A',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36S-3xxx-I',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36S-3xxx-U',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36S-3xxx-D',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36P-3xxx-A',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36P-3xxx-I',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36P-3xxx-U',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA36P-3xxx-D',
    imageName: 'FU_MCA_36S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66S-3xxx-A',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66S-3xxx-I',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66S-3xxx-U',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66S-3xxx-D',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66P-3xxx-A',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66P-3xxx-I',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66P-3xxx-U',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA66P-3xxx-D',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA116S-3xxx-A',
    imageName: 'FU_MCA_116S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA116S-3xxx-I',
    imageName: 'FU_MCA_116S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA116S-3xxx-U',
    imageName: 'FU_MCA_116S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA116S-3xxx-D',
    imageName: 'FU_MCA_116S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCE2.7S-2xxx-F',
    imageName: 'MB_2_7S_DS_F_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2.7S-2xxx-M',
    imageName: 'MB_2_7S_DS_M_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3.6P-2xxx-F',
    imageName: 'MB_3_6P_DS_F_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3.6P-2xxx-M',
    imageName: 'MB_3_6P_DS_M_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE6.6S-2xxx-F',
    imageName: 'MB_6_6S_DS_F_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE6.6S-2xxx-M',
    imageName: 'MB_6_6S_DS_M_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE10.6S-2xxx-F',
    imageName: 'MB_10_6S_DS_M_MCA-1',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE10.6S-2xxx-M',
    imageName: 'MB_10_6S_DS_M_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE125P-2xxx-U',
    imageName: 'FU_MCE_125P_90mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE125P-2xxx-I',
    imageName: 'FU_MCE_125P_90mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE125P-2xxx-A',
    imageName: 'FU_MCE_125P_90mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE125P-3xxx-D',
    imageName: 'FU_MCE_125P_90mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE124S-2xxx-U',
    imageName: 'AB_124S_DS_U_MCE_NO_KEYS',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE124S-2xxx-I',
    imageName: 'AB_124S_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE124S-2xxx-A',
    imageName: 'AB_124S_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE224S-2xxx-U',
    imageName: 'AB_224S_DS_U_MCE_NO_KEYS',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE224S-2xxx-I',
    imageName: 'AB_224S_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE224S-2xxx-A',
    imageName: 'AB_224S_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE324S-2xxx-U',
    imageName: 'AB_324_S_P_DS_U_MCE_NO_KEYS',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE324S-2xxx-I',
    imageName: 'AB_324_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE324S-2xxx-A',
    imageName: 'AB_324_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE324P-2xxx-U',
    imageName: 'AB_324_S_P_DS_U_MCE_NO_KEYS',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE324P-2xxx-I',
    imageName: 'AB_324_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE324P-2xxx-A',
    imageName: 'AB_324_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE524S-2xxx-U',
    imageName: 'AB_524_S_P_DS_U_MCE_NO_KEYS',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE524S-2xxx-I',
    imageName: 'AB_524_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE524S-2xxx-A',
    imageName: 'AB_524_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE524P-2xxx-U',
    imageName: 'AB_524_S_P_DS_U_MCE_NO_KEYS',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE524P-2xxx-I',
    imageName: 'AB_524_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE524P-2xxx-A',
    imageName: 'AB_524_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE323S-2xxx-A',
    imageName: 'PB_323S_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE323S-2xxx-I',
    imageName: 'PB_323S_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE323S-2xxx-U',
    imageName: 'PB_323S_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE323S-2xxx-E',
    imageName: 'PB_323S_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE323S-2xxx-R',
    imageName: 'PB_323S_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623S-2xxx-A',
    imageName: 'PB_623_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623S-2xxx-I',
    imageName: 'PB_623_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623S-2xxx-U',
    imageName: 'PB_623_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623S-2xxx-E',
    imageName: 'PB_623_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623S-2xxx-R',
    imageName: 'PB_623_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623P-2xxx-A',
    imageName: 'PB_623_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623P-2xxx-I',
    imageName: 'PB_623_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623P-2xxx-U',
    imageName: 'PB_623_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623P-2xxx-E',
    imageName: 'PB_623_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE623P-2xxx-R',
    imageName: 'PB_623_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE1203S-2xxx-A',
    imageName: 'PB_1203S_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE1203S-2xxx-I',
    imageName: 'PB_1203S_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE1203S-2xxx-U',
    imageName: 'PB_1203S_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE1203S-2xxx-E',
    imageName: 'PB_1203S_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE1203S-2xxx-R',
    imageName: 'PB_1203S_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203S-2xxx-A',
    imageName: 'PB_2203_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203S-2xxx-I',
    imageName: 'PB_2203_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203S-2xxx-U',
    imageName: 'PB_2203_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203S-2xxx-E',
    imageName: 'PB_2203_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203S-2xxx-R',
    imageName: 'PB_2203_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203P-2xxx-A',
    imageName: 'PB_2203_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203P-2xxx-I',
    imageName: 'PB_2203_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203P-2xxx-U',
    imageName: 'PB_2203_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203P-2xxx-E',
    imageName: 'PB_2203_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2203P-2xxx-R',
    imageName: 'PB_2203_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3203S-2xxx-A',
    imageName: 'PB_3203_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3203S-2xxx-I',
    imageName: 'PB_3203_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3203S-2xxx-U',
    imageName: 'PB_3203_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3203S-2xxx-E',
    imageName: 'PB_3203_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE3203S-2xxx-R',
    imageName: 'PB_3203_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203S-2xxx-A',
    imageName: 'PB_5203_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203S-2xxx-I',
    imageName: 'PB_5203_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203S-2xxx-U',
    imageName: 'PB_5203_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203S-2xxx-E',
    imageName: 'PB_5203_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203S-2xxx-R',
    imageName: 'PB_5203_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203P-2xxx-A',
    imageName: 'PB_5203_S_P_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203P-2xxx-I',
    imageName: 'PB_5203_S_P_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203P-2xxx-U',
    imageName: 'PB_5203_S_P_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203P-2xxx-E',
    imageName: 'PB_5203_S_P_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5203P-2xxx-R',
    imageName: 'PB_5203_S_P_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5202S-2xxx-A',
    imageName: 'PB_5202S_DS_A_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5202S-2xxx-I',
    imageName: 'PB_5202S_DS_I_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5202S-2xxx-U',
    imageName: 'PB_5202S_DS_U_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5202S-2xxx-E',
    imageName: 'PB_5202S_DS_E_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5202S-2xxx-R',
    imageName: 'PB_5202S_DS_R_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE2202S-2xxx-0',
    imageName: 'PB_large_2202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE4202S-2xxx-0',
    imageName: 'PB_large_4202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE5201S-2xxx-0',
    imageName: 'PB_large_5201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE6202S-2xxx-0',
    imageName: 'PB_large_6202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE6202P-2xxx-0',
    imageName: 'PB_large_6202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE8201S-2xxx-0',
    imageName: 'PB_large_8201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE8202S-2xxx-0',
    imageName: 'PB_large_8202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE10202S-2xxx-0',
    imageName: 'PB_large_10202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE12201S-2xxx-0',
    imageName: 'PB_large_12201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE14202S-2xxx-0',
    imageName: 'PB_large_14202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE14202P-2xxx-0',
    imageName: 'PB_large_14202_DS_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE11201S-2xxx-0',
    imageName: 'HCB_14201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE20201S-2xxx-0',
    imageName: 'HCB_20201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36200S-2xxx-0',
    imageName: 'HCB_36200S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36201S-2xxx-0',
    imageName: 'HCB_36201_S_P_MCA',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36201P-2xxx-0',
    imageName: 'HCB_36201_S_P_MCA',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE50201S-2xxx-0',
    imageName: 'HCB_50201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE70200S-2xxx-0',
    imageName: 'HCB_70200S_MCE-1',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE70201S-2xxx-0',
    imageName: 'HCB_70201S_MCE',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE32202P-2xxx-0',
    imageName: 'HCB_round_32202P_MCA',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36S-3xxx-A',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36S-3xxx-I',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36S-3xxx-U',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36S-3xxx-D',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36P-3xxx-A',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36P-3xxx-I',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36P-3xxx-U',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE36P-3xxx-D',
    imageName: 'FU_MCE_36S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66S-3xxx-A',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66S-3xxx-I',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66S-3xxx-U',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66S-3xxx-D',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66P-3xxx-A',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66P-3xxx-I',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66P-3xxx-U',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE66P-3xxx-D',
    imageName: 'FU_MCE_66S_50mm',
    userInterface: 'MCE',
  },
  // TODO: Remove MCE116S - PLATFORM-8569
  {
    materialNumber: 'MCE116S-3xxx-A',
    imageName: 'FU_MCE_116S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE116S-3xxx-I',
    imageName: 'FU_MCE_116S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE116S-3xxx-U',
    imageName: 'FU_MCE_116S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCE116S-3xxx-D',
    imageName: 'FU_MCE_116S_50mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCA226S-3xxx-D',
    imageName: 'FU_MCA_66S_50mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCA225S-3xxx-D',
    imageName: 'FU_MCA_225S_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCE225S-3xxx-D',
    imageName: 'FU_MCE_225S_90mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCA225P-3xxx-D',
    imageName: 'FU_MCA_225S_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCE225P-3xxx-D',
    imageName: 'FU_MCE_225S_90mm',
    userInterface: 'MCE',
  },
  {
    materialNumber: 'MCA125S-3xxx-D',
    imageName: 'FU_MCA_225S_90mm',
    userInterface: 'MCA',
  },
  {
    materialNumber: 'MCE125S-3xxx-D',
    imageName: 'FU_MCE_225S_90mm',
    userInterface: 'MCE',
  },
  //
];

export default products;
