/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RatingDTO
 */
export interface RatingDTO {
    /**
     * The rating value of the customer.
     * @type {number}
     * @memberof RatingDTO
     */
    ratingValue: RatingDTORatingValueEnum;
}


/**
 * @export
 */
export const RatingDTORatingValueEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type RatingDTORatingValueEnum = typeof RatingDTORatingValueEnum[keyof typeof RatingDTORatingValueEnum];


/**
 * Check if a given object implements the RatingDTO interface.
 */
export function instanceOfRatingDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ratingValue" in value;

    return isInstance;
}

export function RatingDTOFromJSON(json: any): RatingDTO {
    return RatingDTOFromJSONTyped(json, false);
}

export function RatingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ratingValue': json['ratingValue'],
    };
}

export function RatingDTOToJSON(value?: RatingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ratingValue': value.ratingValue,
    };
}

