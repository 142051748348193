import React, { useMemo, useState } from 'react';
import { WeighingModule } from '../../../data/types';
import { availableScaleIntervalGroup, availableMaxCapacity } from './utils';
import { maxCapacityGroup, scaleIntervalGroup, UseModuleSelection, UseModuleSelectionProps } from '../types';

export const useModuleSelectionItem = ({
  category,
  selectedModule,
  onDropdownChange,
  selectedDropdownValues,
  onModuleSelection,
  onUndoModuleSelection,
}: UseModuleSelectionProps): UseModuleSelection => {
  const maxCapacityGroups = useMemo(() => availableMaxCapacity(category.modules), [category]);
  const scaleIntervalGroups = useMemo(() => availableScaleIntervalGroup(category.modules), [category]);

  const [maxCapacity, setMaxCapacity] = useState<maxCapacityGroup | undefined>(
    selectedModule || selectedDropdownValues?.maxCapacity
      ? maxCapacityGroups.find(
          (group) =>
            group.name === selectedDropdownValues?.maxCapacity ||
            group.name === selectedModule?.maxCapacity.join(' | '),
        )
      : undefined,
  );

  const [scaleInterval, setScaleInterval] = useState<scaleIntervalGroup | undefined>(
    selectedModule || selectedDropdownValues?.scaleInterval
      ? scaleIntervalGroups.find(
          (group) => selectedDropdownValues?.scaleInterval || group.name === selectedModule?.scaleInterval.join(' | '),
        )
      : undefined,
  );

  const handleMaxCapacitySelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedMaxCapacityGroup = maxCapacityGroups.find((group) => group.name === e.target.value);
    if (selectedModule && selectedMaxCapacityGroup !== maxCapacity) {
      onUndoModuleSelection();
    }

    setMaxCapacity(selectedMaxCapacityGroup);
    onDropdownChange('maxCapacity', selectedMaxCapacityGroup?.name);
  };

  const handleScaleIntervalSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedScaleInterval = scaleIntervalGroups.find((group) => group.name === e.target.value);

    setScaleInterval(selectedScaleInterval);
    onDropdownChange('scaleInterval', selectedScaleInterval?.name);

    if (selectedModule && selectedScaleInterval !== scaleInterval) {
      onUndoModuleSelection();
    }
  };

  const handleModuleSelection = (module: WeighingModule) => {
    onModuleSelection(module);
  };

  // filter the modules according to the selections
  const modulesFilteredByMaxCapacity = maxCapacity ? maxCapacity.modules : category.modules;
  const modulesFilteredByScaleInterval = scaleInterval ? scaleInterval.modules : category.modules;

  // filter compatible loads and scale divisions
  const compMaxCapacityGroups = maxCapacityGroups;
  const compScaleIntervalGroups = maxCapacity
    ? availableScaleIntervalGroup(modulesFilteredByMaxCapacity)
    : scaleIntervalGroups;

  const filteredModules = modulesFilteredByMaxCapacity.filter((item) => modulesFilteredByScaleInterval.includes(item));

  return {
    compMaxCapacityGroups,
    compScaleIntervalGroups,
    filteredModules,
    handleMaxCapacitySelection,
    handleScaleIntervalSelection,
    maxCapacity,
    scaleInterval,
    handleModuleSelection,
  };
};
