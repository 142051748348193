import { Category, WeighingModule } from '../../types';
import module_125p from './125p';
import module_125s from './125s';
import module_225p from './225p';
import module_225s from './225s';
import module_226s from './226s';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const modules: WeighingModule[] = [module_225s, module_226s, module_225p, module_125s, module_125p];
const t = i18next.getFixedT(null, 'common');

export const semimicro: Category = {
  get name() {
    return t(`category.${this.id}.name`);
  },
  get info() {
    return t(`category.${this.id}.info`);
  },
  modules,
  image: `${env('IMAGE_PREFIX')}/FU_MCA_225S_90mm.png`,
  id: 'semiMicro',
  newProduct: true,
};

export default semimicro;
