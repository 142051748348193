import { Certificate, Country } from '../data/types';
import { NavigationStepsEnum, NavigationStepStatus, NavigationStepStatusState } from '../types/navigation';
import { computeStatus } from './requestQuote';

const STEP_REQUEST_QUOTE = 5;

export function computeStep4Status(
  country: Country | undefined,
  certificate: Certificate | undefined,
): NavigationStepStatus {
  if (country && certificate) {
    return 'complete';
  }
  if (!country && !certificate) {
    return 'not_set';
  }

  return 'incomplete';
}

export function getStepHeader(step: number, navigationStatus: NavigationStepStatusState): string {
  if (step === STEP_REQUEST_QUOTE) {
    const status: NavigationStepStatus = computeStatus(navigationStatus);
    return status;
  }

  const stepKey = `step${step}` as NavigationStepsEnum;

  return stepKey;
}

export function getFirstNotCompleteStep(navigationStatus: NavigationStepStatusState): number {
  const step = Object.values(navigationStatus).findIndex((status) => status !== 'complete');
  return step + 1;
}
