import { CCol, CContainer, CHeader, CHeaderBrand, CImg, CLink, CNav, CRow, CSubheader } from '@coreui/react';
import React from 'react';
import sartoriusLogo from '../sartorius-logo.svg';
import sartoriusWhiteLogo from '../sartorius-white-logo.svg';
import vwrLogo from '../vwr-logo.svg';
import NavigationSteps from './Navigation/NavigationSteps';
import NavigationTitle from './Navigation/NavigationTitle';
import { Trans, useTranslation } from 'react-i18next';
import LanguageDropdown from './LanguageDropdown';
import styles from './Header.module.scss';
import PlatformContext from '../context/PlatformContext';
import { QuoteRequestDTOContextEnum } from '../context/QuoteRequestDTOContextEnum';

interface HeaderProps {
  showNavigation: boolean;
}

const Header: React.FC<HeaderProps> = ({ showNavigation }) => {
  const { t } = useTranslation('common');
  const platform = React.useContext(PlatformContext);

  return (
    <CHeader
      data-testid="header"
      className={platform === QuoteRequestDTOContextEnum.Vwr ? styles.vwrHeaderColor : ''}
      withSubheader={true}
    >
      <CContainer className={styles.headerContainer}>
        {platform === QuoteRequestDTOContextEnum.Sartorius || platform === QuoteRequestDTOContextEnum.Tf ? (
          <>
            <CHeaderBrand className="mr-auto">
              <CLink href="https://www.sartorius.com/en/products/weighing/laboratory-balances/cubis-ii" target="_blank">
                <CImg data-testid="logo" src={sartoriusLogo} alt="Sartorius" width={150} height={35} />
              </CLink>
            </CHeaderBrand>
            <CNav className={styles.navContainer}>
              <LanguageDropdown />
            </CNav>
          </>
        ) : (
          <>
            <CHeaderBrand className={`${styles.vwrHeaderContainer} mr-auto`}>
              {/* <CLink href="https://www.sartorius.com/en/products/weighing/laboratory-balances/cubis-ii" target="_blank"> */}
              {/*  <CImg data-testid="logo" className={styles.vwrImage} src={vwrLogo} alt="VWR" width={150} height={35} /> */}
              {/* </CLink> */}
              <CImg data-testid="logo" className={styles.vwrImage} src={vwrLogo} alt="VWR" width={150} height={35} />
            </CHeaderBrand>
            <CHeaderBrand className={styles.vwrHeaderContainer}>
              <CLink href="https://www.sartorius.com/en/products/weighing/laboratory-balances/cubis-ii" target="_blank">
                <CImg className={styles.vwrImage} src={sartoriusWhiteLogo} alt="Sartorius" width={150} height={35} />
              </CLink>
            </CHeaderBrand>
            <CNav className={styles.navContainer}>
              <LanguageDropdown />
            </CNav>
          </>
        )}
      </CContainer>

      <CSubheader className="bg-light border-0 text-black" data-testid="subheader">
        <CContainer>
          <CRow>
            <CCol lg="12">
              <h2 className="mt-3">
                <Trans i18nKey="header.configure_your_balance" t={t}>
                  Configure Your Cubis
                  <sup>®&nbsp;</sup>
                  II Lab Balance
                </Trans>
              </h2>
            </CCol>
          </CRow>
        </CContainer>
      </CSubheader>
      {showNavigation && (
        <>
          <CSubheader className="bg-light border-0">
            <CContainer className="my-1">
              <NavigationSteps />
            </CContainer>
          </CSubheader>
          <CSubheader className="border-0 pb-2">
            <CContainer>
              <NavigationTitle />
            </CContainer>
          </CSubheader>
        </>
      )}
    </CHeader>
  );
};

export default Header;
