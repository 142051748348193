/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Information about the customer that is requesting a quote
 * @export
 * @interface VWRCustomerContactData
 */
export interface VWRCustomerContactData {
    /**
     * First name of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    firstName: string;
    /**
     * Last name of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    lastName: string;
    /**
     * Email of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    email: string;
    /**
     * Telephone number of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    phone?: string;
    /**
     * Company of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    company: string;
    /**
     * Country of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    country: string;
    /**
     * Industry of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    industry: string;
    /**
     * Street of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    street?: string;
    /**
     * Address number of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    addressNumber?: string;
    /**
     * City of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    city?: string;
    /**
     * Zip Code/Postal code of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    zipcode?: string;
    /**
     * How many units would you like to buy?
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    units?: string;
    /**
     * Comments of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    comments?: string;
    /**
     * Customer wants to get a newsletter
     * @type {boolean}
     * @memberof VWRCustomerContactData
     */
    newsletter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    utmTrackingUrl?: string;
    /**
     * Industry Focus of the customer
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    industryFocus: string;
    /**
     * 
     * @type {string}
     * @memberof VWRCustomerContactData
     */
    timelineOfPurchase?: string;
}

/**
 * Check if a given object implements the VWRCustomerContactData interface.
 */
export function instanceOfVWRCustomerContactData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "industryFocus" in value;

    return isInstance;
}

export function VWRCustomerContactDataFromJSON(json: any): VWRCustomerContactData {
    return VWRCustomerContactDataFromJSONTyped(json, false);
}

export function VWRCustomerContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VWRCustomerContactData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'company': json['company'],
        'country': json['country'],
        'industry': json['industry'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'addressNumber': !exists(json, 'addressNumber') ? undefined : json['addressNumber'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'units': !exists(json, 'units') ? undefined : json['units'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'newsletter': !exists(json, 'newsletter') ? undefined : json['newsletter'],
        'utmTrackingUrl': !exists(json, 'utmTrackingUrl') ? undefined : json['utmTrackingUrl'],
        'industryFocus': json['industry_focus'],
        'timelineOfPurchase': !exists(json, 'timeline_of_purchase') ? undefined : json['timeline_of_purchase'],
    };
}

export function VWRCustomerContactDataToJSON(value?: VWRCustomerContactData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'company': value.company,
        'country': value.country,
        'industry': value.industry,
        'street': value.street,
        'addressNumber': value.addressNumber,
        'city': value.city,
        'zipcode': value.zipcode,
        'units': value.units,
        'comments': value.comments,
        'newsletter': value.newsletter,
        'utmTrackingUrl': value.utmTrackingUrl,
        'industry_focus': value.industryFocus,
        'timeline_of_purchase': value.timelineOfPurchase,
    };
}

