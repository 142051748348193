import { CLabel, CSelect } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCertificateSelection } from './hooks/useCertificateSelection';
import { CeritficateSelectionProps } from './types';

const CertificateSelection: React.FC<CeritficateSelectionProps> = (props): JSX.Element => {
  const { options, enabled, currentValue, onSelect } = props;
  const { isPristine, handleSelect, selectRef } = useCertificateSelection({ enabled, onSelect, currentValue });
  const { t } = useTranslation('common');
  return (
    <>
      <CLabel htmlFor="approval">{t('certificateSelection.label')}*</CLabel>
      <CSelect
        data-testid="approval-select"
        className={`${isPristine ? 'border border-dark' : ''} data-hj-allow`}
        id="approval"
        innerRef={selectRef}
        value={currentValue}
        disabled={!enabled}
        onChange={handleSelect}
      >
        {options.length > 0 ? (
          <option value="">{t('certificateSelection.option')}</option>
        ) : (
          <option value="">{t('certificateSelection.disabled')}</option>
        )}
        {options.map((certificate) => (
          <option key={certificate.name} value={certificate.name}>
            {certificate.label}
          </option>
        ))}
      </CSelect>
    </>
  );
};

export default CertificateSelection;
