/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeighingModule
 */
export interface WeighingModule {
    /**
     * 
     * @type {string}
     * @memberof WeighingModule
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WeighingModule
     */
    maxCapacity?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WeighingModule
     */
    scaleInterval?: Array<number>;
}

/**
 * Check if a given object implements the WeighingModule interface.
 */
export function instanceOfWeighingModule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WeighingModuleFromJSON(json: any): WeighingModule {
    return WeighingModuleFromJSONTyped(json, false);
}

export function WeighingModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeighingModule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'maxCapacity': !exists(json, 'maxCapacity') ? undefined : json['maxCapacity'],
        'scaleInterval': !exists(json, 'scaleInterval') ? undefined : json['scaleInterval'],
    };
}

export function WeighingModuleToJSON(value?: WeighingModule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'maxCapacity': value.maxCapacity,
        'scaleInterval': value.scaleInterval,
    };
}

