import certificates from '../certificates';
import images from './images';
import env from '@beam-australia/react-env';
import accessories from '../accessories';
import isEmpty from 'lodash/isEmpty';

export const getProductImageName = (productNumber: string): string | undefined => {
  let imageName = undefined;
  const certificateNames = certificates.map((certificate) => certificate.name);
  const certificateRegExp = new RegExp(certificateNames.join('|'), 'gi');

  // array of the product aliases for accessories that have their own specific image
  const productAccessoryAliases = accessories
    .filter((accessory) => productNumber.includes(accessory.name) && accessory.hasOwnImage)
    .map((accessory) => accessory.alias);

  const materialNumberRegExp = new RegExp(/^(MCA|MCE)([0-9.SP]+)-[2-3]xxx-([A-Z0])/, 'g');

  const cleanedMaterialNumber = productNumber.replace(certificateRegExp, 'xxx');
  const trimmedMaterialNumber = materialNumberRegExp.exec(cleanedMaterialNumber);

  if (trimmedMaterialNumber !== null && !isEmpty(trimmedMaterialNumber)) {
    imageName = images[trimmedMaterialNumber[0]];
  } else {
    const specialCases125P = ['MCA125P-I-MTS', 'MCA125P-U-MTS', 'MCE125P-U-MTS', 'MCA125PU-S01',
     'MCA125PI-S01', 'MCE125PU-S01', 'MCA125PU-CCN', 'MCA125PI-CCN', 'MCE125PU-CCN'];

    if(specialCases125P.some(x=> x === cleanedMaterialNumber)) {
      imageName = images[cleanedMaterialNumber];
    }
  }

  // update the imageName with the accessory aliases separated by a dash in the end for custom images
  if (trimmedMaterialNumber && !isEmpty(trimmedMaterialNumber) && !isEmpty(productAccessoryAliases)) {
    imageName = images[`${trimmedMaterialNumber[0]}-${productAccessoryAliases[0]}`];
  }

  return imageName;
};

export const getProductImageUrl = (productNumber: string): string | null => {
  const imageName = getProductImageName(productNumber);
  let imageUrl = null;

  if (imageName) {
    imageUrl = `${env('IMAGE_PREFIX')}/${imageName}`;
  }

  return imageUrl;
};
