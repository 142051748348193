import React from 'react';
import ImageWithModal from '../ImageWithModal';
import { getProductImageUrl } from '../../data/images/utils';

export const ProductImage = ({ productNumber }: { productNumber: string }): React.ReactElement | null => {
  const imageUrl = getProductImageUrl(productNumber);

  if (!imageUrl) return null;

  return <ImageWithModal src={imageUrl} srcFullsize={imageUrl} fluidGrow />;
};
