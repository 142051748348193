import React from 'react';
import { useAccessorySelectionList } from './hooks/useAccessorySelectionList';
import IncompleteStepsNotifier from './IncompleteStepsNotifier';
import AccessoryList from './AccessoryList';

const ACCESSORIES_IN_ROW = 3;

const AccessorySelectionList: React.FC<Record<string, never>> = () => {
  const {
    config,
    isOtherStepsCompleted,
    moduleHasAccessories,
    productMaterialNumber,
    allAccessoriesGrouped,
    onAccessorySelect,
    onUndoSelection,
  } = useAccessorySelectionList(ACCESSORIES_IN_ROW);

  return (
    <>
      {!isOtherStepsCompleted && <IncompleteStepsNotifier />}
      {moduleHasAccessories && isOtherStepsCompleted && (
        <AccessoryList
          allAccessoriesGrouped={allAccessoriesGrouped}
          productMaterialNumber={productMaterialNumber}
          onAccessorySelect={onAccessorySelect}
          onUndoSelection={onUndoSelection}
          accessoriesInRow={ACCESSORIES_IN_ROW}
          config={config}
        />
      )}
    </>
  );
};

export default AccessorySelectionList;
