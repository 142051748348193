import { WeighingModule } from '../../types';
import { shields, accessories, defaultSelectedAccessories } from './defaults';
import { certificates } from '../../certificates';

const module_225s: WeighingModule = {
  name: '225S',
  scaleInterval: [0.01],
  maxCapacity: [220],
  compatibleShields: shields,
  possibleCertificates: certificates,
  compatibleAccessories: accessories,
  defaultSelectedAccessories
};

export default module_225s;
