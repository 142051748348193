import { Category, WeighingModule } from '../../types';
import module_11201s from './11201s';
import module_20201s from './20201s';
import module_36200s from './36200s';
import module_36201p from './36201p';
import module_36201s from './36201s';
import module_50201s from './50201s';
import module_5201s from './5201s';
import module_70200s from './70200s';
import module_70201s from './70201s';
import module_8201s from './8201s';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const modules: WeighingModule[] = [
  module_5201s,
  module_8201s,
  module_11201s,
  module_20201s,
  module_36200s,
  module_36201p,
  module_36201s,
  module_50201s,
  module_70200s,
  module_70201s,
];

const t = i18next.getFixedT(null, 'common');

export const highCapacity: Category = {
  get name() {
    return t(`category.${this.id}.name`);
  },
  get info() {
    return t(`category.${this.id}.info`);
  },
  modules,
  image: `${env('IMAGE_PREFIX')}/HCB_14201S_MCA.png`,
  id: 'highCapacity',
};

export default highCapacity;
