import { CCol, CRow } from '@coreui/react';
import React, { useEffect } from 'react';
import { mca, mcaSemiMicro125P, mce, mceSemiMicro } from '../../data';

import UserInterfaceItem from './UserInterfaceItem';
import { useUserInterfaceSelection } from './hooks/useUserInterfaceSelection';
import UserInterfaceHelpText from './UserInterfaceHelpText';
import { useTranslation } from 'react-i18next';
import HelpTextButton from '../HelpTextButton';
import { getCategoryByModule } from '../../services/modules';
import { useRecoilState } from 'recoil';
import { currentStepState } from '../../states';

const UserInterfaceSelection: React.FC = (): JSX.Element => {
  const { config, onDisplayModuleSelect, onUndoSelection, compatibleDisplayNames } = useUserInterfaceSelection();
  const { t } = useTranslation('common');
  const is125P = config.module && config.module.name === '125P';
  const is125pWithDraftShieldI = is125P && config.draftShield?.name === 'I';

  const isMceCompatible =
    !is125pWithDraftShieldI && (compatibleDisplayNames ? compatibleDisplayNames.includes(mce.name) : true);

  if (!isMceCompatible && config.displayModule && config.displayModule.name === 'MCE') {
    onUndoSelection();
  }

  const isMcaCompatible = compatibleDisplayNames ? compatibleDisplayNames.includes(mca.name) : true;
  const configModuleId = config.module ? getCategoryByModule(config.module)?.id : undefined;
  const isHardwareCompatible =
    !!configModuleId && (configModuleId === 'highCapacityMicro' || configModuleId === 'semiMicro') && !is125P;

  const [, setCurrentStep] = useRecoilState(currentStepState);

  useEffect(
    () => {
      setCurrentStep(3);
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      <HelpTextButton title={t('helpText.step3.heading')} content={<UserInterfaceHelpText />} />
      <CRow className="mb-4">
        <CCol lg="6">
          <UserInterfaceItem
            displayModule={is125P ? mcaSemiMicro125P : mca}
            compatible={isMcaCompatible}
            selected={mca.name === config.displayModule?.name}
            onSelect={onDisplayModuleSelect(mca)}
            onUndoSelection={onUndoSelection}
            isHardwareCompatible={isHardwareCompatible}
          />
        </CCol>
        <CCol lg="6">
          <UserInterfaceItem
            displayModule={configModuleId === 'semiMicro' && !is125P ? mceSemiMicro : mce}
            compatible={isMceCompatible}
            selected={mce.name === config.displayModule?.name}
            onSelect={onDisplayModuleSelect(mce)}
            onUndoSelection={onUndoSelection}
            isHardwareCompatible={isHardwareCompatible}
          />
        </CCol>
      </CRow>
    </>
  );
};

export default UserInterfaceSelection;
