import { CCard, CCardGroup, CCol, CRow } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import AccessorySelectionItem, { AccessorySelectionItemDTO } from './AccessorySelectionItem';
import { Accessory, Configuration } from '../../data/types';
import styles from './AccessorySelection.module.scss';

export interface AccessoryListProps {
  allAccessoriesGrouped: Accessory[][];
  productMaterialNumber: string | undefined;
  accessoriesInRow: number;
  onUndoSelection: (accessory: Accessory) => () => void;
  onAccessorySelect: (accessory: Accessory) => () => void;
  config: Configuration;
}

const AccessoryList: React.FC<AccessoryListProps> = (props): JSX.Element => {
  const { t } = useTranslation('common');
  const { allAccessoriesGrouped, productMaterialNumber, accessoriesInRow, onAccessorySelect, onUndoSelection, config } =
    props;

  function createSelectionItemData(accessory: Accessory, index: number) {
    const selectionItemData: AccessorySelectionItemDTO = {
      name: accessory.name,
      label: accessory.label,
      description: accessory.description,
      imageURL: accessory.image,
      selected: config.accessories?.map((accessory) => accessory.name).includes(accessory.name) || false,
      lastInRow: index === accessoriesInRow - 1,
    };

    return selectionItemData;
  }

  return (
    <>
      <CRow className="mb-4" data-testid="accessory-list">
        <CCol lg="12">{t('accessorySelection.header', { productMaterialNumber })}</CCol>
        {allAccessoriesGrouped.map((accessoryGroup: Accessory[], index) => {
          return (
            <CCol lg="12" key={`accessory-col-${index}`}>
              <CCardGroup key={`group-${index}`}>
                {accessoryGroup.map((accessory: Accessory, index) => {
                  return (
                    <AccessorySelectionItem
                      key={accessory.name}
                      itemData={createSelectionItemData(accessory, index)}
                      onAccessorySelect={onAccessorySelect(accessory)}
                      onUndoSelection={onUndoSelection(accessory)}
                    />
                  );
                })}
                {accessoryGroup.length < accessoriesInRow &&
                  _.times(accessoriesInRow - accessoryGroup.length, (i) => (
                    <CCard
                      className={`${styles.hiddenCard} mb-5 ${i === accessoryGroup.length - 1 ? 'mr-5' : ''}`}
                      data-testId={`card-placeholder-${i}`}
                    />
                  ))}
              </CCardGroup>
            </CCol>
          );
        })}
      </CRow>
    </>
  );
};

export default AccessoryList;
