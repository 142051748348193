import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { accessories, shields, documentationsAndServices, defaultSelectedAccessories } from './defaults';
import { mca } from '../../displays';

const module_116s: WeighingModule = {
  name: '116S',
  scaleInterval: [0.002],
  maxCapacity: [111],
  compatibleShields: shields,
  possibleCertificates: certificates,
  compatibleAccessories: accessories,
  compatibleDisplays: [mca],
  documentationsAndServices,
  defaultSelectedAccessories,
};

export default module_116s;
