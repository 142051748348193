import { IndustryFocus } from '../types';

export const if0A: IndustryFocus = {
  key: '0A - Classical Pharma & Small Molecules',
  id: '0A',
};

export const if0B: IndustryFocus = {
  key: '0B - CRO, CDMO, CMO, CTO Classical Pharma',
  id: '0B',
};

export const if1A: IndustryFocus = {
  key: '1A - Automotive & Transportation',
  id: '1A',
};

export const if1B: IndustryFocus = {
  key: '1B - Chemical',
  id: '1B',
};

export const if1C: IndustryFocus = {
  key: '1C - Personal Care Products',
  id: '1C',
};

export const if1D: IndustryFocus = {
  key: '1D - Machinery, Facility Engineering & Consultancy',
  id: '1D',
};

export const if1E: IndustryFocus = {
  key: '1E - Classical Food & Beverage',
  id: '1E',
};

export const if1H: IndustryFocus = {
  key: '1H - Semiconductor & Electronics',
  id: '1H',
};

export const if1I: IndustryFocus = {
  key: '1I - Pulp and Paper',
  id: '1I',
};

export const if1J: IndustryFocus = {
  key: '1J - Synthetic Bio',
  id: '1J',
};

export const if1K: IndustryFocus = {
  key: '1K - Medical Devices, Medical Technology & Diagnostics',
  id: '1K',
};

export const if1Z: IndustryFocus = {
  key: '1Z - Other Industries (Tobacco, Textile, Mining, etc.)',
  id: '1Z',
};

export const if3A: IndustryFocus = {
  key: '3A - Testing Labs',
  id: '3A',
};

export const if4A: IndustryFocus = {
  key: '4A - Life Science Universities, Research Institutes & Top University Hospitals',
  id: '4A',
};

export const if4C: IndustryFocus = {
  key: '4C - Non-Life Science Universities, Research Institutes & Teaching Colleges',
  id: '4C',
};

export const if4E: IndustryFocus = {
  key: '4E - General Hospitals & Pharmacies',
  id: '4E',
};

export const if5A: IndustryFocus = {
  key: '5A - Key Strategic Distributors',
  id: '5A',
};

export const if5B: IndustryFocus = {
  key: '5B - Standard Distributors',
  id: '5B',
};

export const if5F: IndustryFocus = {
  key: '5F - Sales Partner Organizations',
  id: '5F',
};

export const if7A: IndustryFocus = {
  key: '7A - Proteins',
  id: '7A',
};

export const if7B: IndustryFocus = {
  key: '7B - CRO, CDMO, CMO, CTO Proteins',
  id: '7B',
};

export const if7C: IndustryFocus = {
  key: '7C - Cell Therapy',
  id: '7C',
};

export const if7D: IndustryFocus = {
  key: '7D - CRO, CDMO, CMO, CTO Cell Therapy',
  id: '7D',
};

export const if7E: IndustryFocus = {
  key: '7E - Gene Therapy',
  id: '7E',
};

export const if7F: IndustryFocus = {
  key: '7F - CRO, CDMO, CMO, CTO Gene Therapy',
  id: '7F',
};

export const if7G: IndustryFocus = {
  key: '7G - Vaccines, Virology & mRNA',
  id: '7G',
};

export const if7H: IndustryFocus = {
  key: '7H - CRO, CDMO, CMO, CTO Vaccines',
  id: '7H',
};

export const if7I: IndustryFocus = {
  key: '7I - Gene Modified Cell Therapy',
  id: '7I',
};

export const if7J: IndustryFocus = {
  key: '7J - CRO, CDMO, CMO, CTO Gene Modified Cell Therapy',
  id: '7J',
};

export const if7K: IndustryFocus = {
  key: '7K - Classical Plasma & Blood',
  id: '7K',
};
