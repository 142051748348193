import React from 'react';
import { Country } from '../../../data/types';
import { UseCountrySelectParams } from '../types';

interface iUseCountrySelect {
  handleSelect: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useCountrySelect = ({ onSelect }: UseCountrySelectParams): iUseCountrySelect => {
  const handleSelect = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value === '' ? undefined : target.value;
    onSelect(value as Country);
  };

  return {
    handleSelect,
  };
};
