/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationError,
  QuoteRequestDTO,
  TFQuoteRequestDTO,
  VWRQuoteRequestDTO,
} from '../models/index';
import {
    ApplicationErrorFromJSON,
    ApplicationErrorToJSON,
    QuoteRequestDTOFromJSON,
    QuoteRequestDTOToJSON,
    TFQuoteRequestDTOFromJSON,
    TFQuoteRequestDTOToJSON,
    VWRQuoteRequestDTOFromJSON,
    VWRQuoteRequestDTOToJSON,
} from '../models/index';

export interface RequestQuoteRequest {
    quoteRequestDTO: QuoteRequestDTO;
}

export interface RequestTfQuoteRequest {
    tFQuoteRequestDTO: TFQuoteRequestDTO;
}

export interface RequestVWRQuoteRequest {
    vWRQuoteRequestDTO: VWRQuoteRequestDTO;
}

/**
 * 
 */
export class QuoteApi extends runtime.BaseAPI {

    /**
     * Request a new Quote. Creates a PDF File and sends it via mail.
     */
    async requestQuoteRaw(requestParameters: RequestQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.quoteRequestDTO === null || requestParameters.quoteRequestDTO === undefined) {
            throw new runtime.RequiredError('quoteRequestDTO','Required parameter requestParameters.quoteRequestDTO was null or undefined when calling requestQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/quoterequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuoteRequestDTOToJSON(requestParameters.quoteRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a new Quote. Creates a PDF File and sends it via mail.
     */
    async requestQuote(requestParameters: RequestQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * Request a new Quote for Thermo Fisher. Creates a PDF File and sends it via mail.
     */
    async requestTfQuoteRaw(requestParameters: RequestTfQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tFQuoteRequestDTO === null || requestParameters.tFQuoteRequestDTO === undefined) {
            throw new runtime.RequiredError('tFQuoteRequestDTO','Required parameter requestParameters.tFQuoteRequestDTO was null or undefined when calling requestTfQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tf/quoterequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TFQuoteRequestDTOToJSON(requestParameters.tFQuoteRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a new Quote for Thermo Fisher. Creates a PDF File and sends it via mail.
     */
    async requestTfQuote(requestParameters: RequestTfQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestTfQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * Request a new Quote VWR. Creates a PDF File and sends it via mail.
     */
    async requestVWRQuoteRaw(requestParameters: RequestVWRQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vWRQuoteRequestDTO === null || requestParameters.vWRQuoteRequestDTO === undefined) {
            throw new runtime.RequiredError('vWRQuoteRequestDTO','Required parameter requestParameters.vWRQuoteRequestDTO was null or undefined when calling requestVWRQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/vwr/quoterequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VWRQuoteRequestDTOToJSON(requestParameters.vWRQuoteRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a new Quote VWR. Creates a PDF File and sends it via mail.
     */
    async requestVWRQuote(requestParameters: RequestVWRQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestVWRQuoteRaw(requestParameters, initOverrides);
    }

}
