/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Information about the customer that is requesting a quote
 * @export
 * @interface TFCustomerContactData
 */
export interface TFCustomerContactData {
    /**
     * First name of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    firstName: string;
    /**
     * Last name of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    lastName: string;
    /**
     * Email of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    email: string;
    /**
     * Telephone number of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    phone: string;
    /**
     * Company of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    company: string;
    /**
     * Country of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    country: string;
    /**
     * Street of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    street: string;
    /**
     * Address number of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    addressNumber?: string;
    /**
     * City of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    city: string;
    /**
     * Zip Code/Postal code of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    zipcode: string;
    /**
     * How many units would you like to buy?
     * @type {string}
     * @memberof TFCustomerContactData
     */
    units?: string;
    /**
     * Comments of the customer
     * @type {string}
     * @memberof TFCustomerContactData
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof TFCustomerContactData
     */
    utmTrackingUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TFCustomerContactData
     */
    timelineOfPurchase?: string;
}

/**
 * Check if a given object implements the TFCustomerContactData interface.
 */
export function instanceOfTFCustomerContactData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "zipcode" in value;

    return isInstance;
}

export function TFCustomerContactDataFromJSON(json: any): TFCustomerContactData {
    return TFCustomerContactDataFromJSONTyped(json, false);
}

export function TFCustomerContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TFCustomerContactData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phone': json['phone'],
        'company': json['company'],
        'country': json['country'],
        'street': json['street'],
        'addressNumber': !exists(json, 'addressNumber') ? undefined : json['addressNumber'],
        'city': json['city'],
        'zipcode': json['zipcode'],
        'units': !exists(json, 'units') ? undefined : json['units'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'utmTrackingUrl': !exists(json, 'utmTrackingUrl') ? undefined : json['utmTrackingUrl'],
        'timelineOfPurchase': !exists(json, 'timeline_of_purchase') ? undefined : json['timeline_of_purchase'],
    };
}

export function TFCustomerContactDataToJSON(value?: TFCustomerContactData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'company': value.company,
        'country': value.country,
        'street': value.street,
        'addressNumber': value.addressNumber,
        'city': value.city,
        'zipcode': value.zipcode,
        'units': value.units,
        'comments': value.comments,
        'utmTrackingUrl': value.utmTrackingUrl,
        'timeline_of_purchase': value.timelineOfPurchase,
    };
}

