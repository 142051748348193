import { useRecoilState } from 'recoil';
import { configurationState, navigationStepStatusState } from '../../../states';
import { useEffect, useState } from 'react';
import { Certificate, Configuration, Country } from '../../../data/types';
import { Location } from 'history';
import { useHistory } from 'react-router-dom';
import { getCertificatesForCountry } from '../../../services/countryApproval';
import { computeStep4Status } from '../../../services/navigation';
import { UseCountryApprovalSelection } from '../types';
import { CountryApprovalAccessoryEvent } from '../../../types/tagManagerEvents';
import { NavigationSlugs } from '../../../types/navigation';
import { sendGoogleTagManagerEvent, trackPageImpression } from '../../../services/googleTagManager';

export const useCountryApprovalSelection = (): UseCountryApprovalSelection => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [navigationStepStatus, setNavigationStepStatus] = useRecoilState(navigationStepStatusState);
  const [certificatesForCountry, setCertificatesForCountry] = useState<Certificate[]>([]);
  const [confirmWarning, setConfirmWarning] = useState<boolean>(false);
  const [targetLocation, setTargetLocation] = useState<Location | undefined>();
  const history = useHistory();

  useEffect(() => {
    trackPageImpression(4);
  }, []);

  useEffect(() => {
    return () => {
      trackCountryApprovalSelection();
    };
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    if (config.country) {
      const certificates = getCertificatesForCountry(config.country, config.module);
      setCertificatesForCountry(certificates);
    }
    // eslint-disable-next-line
  }, [config.country]);

  function updateNavigation({ country, certificate }: Configuration) {
    setNavigationStepStatus({ ...navigationStepStatus, step4: computeStep4Status(country, certificate) });
  }

  function onCountrySelect(country: Country | undefined) {
    const newConfig: Configuration = {
      ...config,
      country,
    };

    if (country !== undefined) {
      const certificates = getCertificatesForCountry(country, config.module);
      setCertificatesForCountry(certificates);

      if (config.certificate && !certificates.map(({ name }) => name).includes(config.certificate.name)) {
        newConfig.certificate = undefined;
      }
    } else {
      setCertificatesForCountry([]);
      newConfig.certificate = undefined;
    }

    setConfig(newConfig);
    updateNavigation(newConfig);
  }

  function onCertificateSelect(certificate: Certificate | undefined) {
    setConfig({ ...config, certificate });
    updateNavigation({ ...config, certificate });
  }

  function goToClickedRoute() {
    const targetRoute = targetLocation?.pathname || '/step5';
    setTargetLocation(undefined);
    setConfirmWarning(false);
    history.push(targetRoute);
  }

  function handleRouteExit(location: Location) {
    const exactlyOneFieldIsEmpty = (config.country && !config.certificate) || (!config.country && config.certificate);
    if (!targetLocation && exactlyOneFieldIsEmpty) {
      setConfirmWarning(true);
      setTargetLocation(location);
      return false;
    }
    return true;
  }

  const trackCountryApprovalSelection = () => {
    if (history.location.pathname.includes('/step5') && config.country && config.certificate) {
      const trackingEvent: CountryApprovalAccessoryEvent = {
        event: 'cubisCalculatorSelection',
        'cubis-config-slug': NavigationSlugs[4],
        'cubis-config-step': 4,
        'cubis-config-approval': config.certificate.name,
        'cubis-config-country': config.country,
        'cubis-config-accessories': config.accessories?.map((accessory) => accessory.name).toString(),
      };
      sendGoogleTagManagerEvent(trackingEvent);
    }
  };

  return {
    config,
    certificatesForCountry,
    confirmWarning,
    setConfirmWarning,
    handleRouteExit,
    goToClickedRoute,
    onCertificateSelect,
    onCountrySelect,
    targetLocation,
  };
};
