import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_5203p: WeighingModule = {
  name: '5203P',
  scaleInterval: [1, 2, 5],
  maxCapacity: [1200, 2400, 5200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_5203p;
