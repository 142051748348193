import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_124s: WeighingModule = {
  name: '124S',
  scaleInterval: [0.1],
  maxCapacity: [120],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_124s;
