import { WeighingModule } from '../../types';
import { none } from '../../shields';
import { certificates } from '../../certificates';

const module_10202s: WeighingModule = {
  name: '10202S',
  scaleInterval: [10],
  maxCapacity: [10200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_10202s;
