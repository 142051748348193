import { WeighingModule } from '../../types';
import { shields, accessories, defaultSelectedAccessories } from './defaults';
import { certificates } from '../../certificates';

const module_225p: WeighingModule = {
  name: '225P',
  scaleInterval: [0.01, 0.1],
  maxCapacity: [120, 220],
  compatibleShields: shields,
  possibleCertificates: certificates,
  compatibleAccessories: accessories,
  defaultSelectedAccessories
};

export default module_225p;
