/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BalanceConfiguration } from './BalanceConfiguration';
import {
    BalanceConfigurationFromJSON,
    BalanceConfigurationFromJSONTyped,
    BalanceConfigurationToJSON,
} from './BalanceConfiguration';
import type { CustomerContactData } from './CustomerContactData';
import {
    CustomerContactDataFromJSON,
    CustomerContactDataFromJSONTyped,
    CustomerContactDataToJSON,
} from './CustomerContactData';

/**
 * 
 * @export
 * @interface QuoteRequestDTO
 */
export interface QuoteRequestDTO {
    /**
     * 
     * @type {CustomerContactData}
     * @memberof QuoteRequestDTO
     */
    customerContactData: CustomerContactData;
    /**
     * 
     * @type {BalanceConfiguration}
     * @memberof QuoteRequestDTO
     */
    balanceConfiguration?: BalanceConfiguration;
    /**
     * 
     * @type {BalanceConfiguration}
     * @memberof QuoteRequestDTO
     */
    recommendedBalanceConfiguration?: BalanceConfiguration;
}

/**
 * Check if a given object implements the QuoteRequestDTO interface.
 */
export function instanceOfQuoteRequestDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerContactData" in value;

    return isInstance;
}

export function QuoteRequestDTOFromJSON(json: any): QuoteRequestDTO {
    return QuoteRequestDTOFromJSONTyped(json, false);
}

export function QuoteRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerContactData': CustomerContactDataFromJSON(json['customerContactData']),
        'balanceConfiguration': !exists(json, 'balanceConfiguration') ? undefined : BalanceConfigurationFromJSON(json['balanceConfiguration']),
        'recommendedBalanceConfiguration': !exists(json, 'recommendedBalanceConfiguration') ? undefined : BalanceConfigurationFromJSON(json['recommendedBalanceConfiguration']),
    };
}

export function QuoteRequestDTOToJSON(value?: QuoteRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerContactData': CustomerContactDataToJSON(value.customerContactData),
        'balanceConfiguration': BalanceConfigurationToJSON(value.balanceConfiguration),
        'recommendedBalanceConfiguration': BalanceConfigurationToJSON(value.recommendedBalanceConfiguration),
    };
}

