import { CRow, CCol } from '@coreui/react';
import React from 'react';
import useNavigationSteps from '../../hooks/useNavigationSteps';
import NavigationStep from './NavigationStep';
import styles from './NavigationSteps.module.scss';
import stepStyles from './NavigationStep.module.scss';
import { useTranslation } from 'react-i18next';

type NavigationProps = Record<string, never>;

const NavigationSteps: React.FC<NavigationProps> = (): JSX.Element | null => {
  const [{ navigationStepStatus, currentlyEditedStep }, { onStepSelect }] = useNavigationSteps();
  const { t } = useTranslation('common');
  return (
    <>
      <div className={`${styles.stepsBox} bg-light text-center small pt-1 pb-3`}>
        <CRow>
          <CCol lg="12">
            <div className={styles.navigation}>
              <NavigationStep
                testid="navigation-step1"
                status={navigationStepStatus.step1}
                currentlyEdited={currentlyEditedStep === 1}
                onClick={onStepSelect(1)}
              >
                <div>{t('navigation.title.step1.line1')}</div>
                <div>{t('navigation.title.step1.line2')}</div>
              </NavigationStep>

              <NavigationStep
                testid="navigation-step2"
                status={navigationStepStatus.step2}
                currentlyEdited={currentlyEditedStep === 2}
                onClick={onStepSelect(2)}
              >
                <div>{t('navigation.title.step2.line1')}</div>
                <div>{t('navigation.title.step2.line2')}</div>
              </NavigationStep>

              <NavigationStep
                testid="navigation-step3"
                status={navigationStepStatus.step3}
                currentlyEdited={currentlyEditedStep === 3}
                onClick={onStepSelect(3)}
              >
                <div>{t('navigation.title.step3.line1')}</div>
                <div>{t('navigation.title.step3.line2')}</div>
              </NavigationStep>

              <NavigationStep
                testid="navigation-step4"
                status={navigationStepStatus.step4}
                currentlyEdited={currentlyEditedStep === 4}
                onClick={onStepSelect(4)}
                disabled={navigationStepStatus.step3 !== 'complete'}
              >
                <div>{t('navigation.title.step4.line1')}</div>
                <div>{t('navigation.title.step4.line2')}</div>
              </NavigationStep>
              <div
                data-testid="navigation-step5"
                className={`${navigationStepStatus.step3 !== 'complete' && stepStyles.disableNextSteps} ${currentlyEditedStep === 5 ? stepStyles.activeRequestStep : stepStyles.requestStep}  `}
                onClick={onStepSelect(5)}
              >
                <div className={stepStyles.navigationContentWrapper}>
                  <div className={stepStyles.textContainer}>{t('navigation.title.step5.line1')}</div>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default NavigationSteps;
