const UTM_WHITELIST = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export type UtmParams = { [key: string]: string | number };

export function interceptUtmParameters(location: Location): void {
  const queryParams = new URLSearchParams(location.search);

  const utmToSave: UtmParams = {};

  queryParams.forEach((value, key) => {
    if (UTM_WHITELIST.includes(key)) {
      utmToSave[key] = value;
    }
  });

  if (Object.keys(utmToSave).length === 0) {
    return;
  }
  const url = location.href.split('?')[0];
  const utmParamString = Object.keys(utmToSave)
    .map((key) => key + '=' + utmToSave[key])
    .join('&');
  const utmUrl = `${url}?${utmParamString}`;

  localStorage.setItem('utm', utmUrl);
}

export function getUtmParametersURL(): string {
  return localStorage.getItem('utm') || '';
}
