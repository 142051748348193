import { CButton, CCol, CRow } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UndoIcon from '../assets/icons/undo.svg';

const iconStyles = {
  width: 15,
  verticalAlign: -2,
  transform: 'scale(-1, 1)',
};

interface UndoSelectionProps {
  onUndoSelection: () => void;
}

const UndoSelection: React.FC<UndoSelectionProps> = ({ onUndoSelection }) => {
  const { t } = useTranslation('common');

  return (
    <CRow className="pt-4">
      <CCol className="col-5">{t('general.selected')}</CCol>
      <CCol className="col-7 text-right">
        <CButton color="link" className="p-0" onClick={onUndoSelection} data-testid="module-unselect">
          {t('general.undoSelection')}
          <small>
            <img src={UndoIcon} alt="Undo" title="Undo" style={iconStyles} />
          </small>
        </CButton>
      </CCol>
    </CRow>
  );
};

export default UndoSelection;
