import { DraftShield } from '../types';
import env from '@beam-australia/react-env';
import i18next from 'i18next';

const t = i18next.getFixedT(null, 'common');

const createDraftShield = (name: string, image: string, id: string): DraftShield => {
  return {
    name,
    image,
    id,
    get label() {
      return t(`draftShield.${id}.label`);
    },
    get description() {
      return t(`draftShield.${id}.description`);
    },
  };
};

export const A = createDraftShield('A', `${env('IMAGE_PREFIX')}/wl0893d1-WH.png`, 'automaticGlass');
export const D = createDraftShield(
  'D',
  `${env('IMAGE_PREFIX')}/Fusion-Draft-Shield-D_sRGB_reduced.png`,
  'innerDraftShield',
);
export const E = createDraftShield('E', `${env('IMAGE_PREFIX')}/wl0894d1-WH.png`, 'manualGlassE');
export const F = createDraftShield('F', `${env('IMAGE_PREFIX')}/wl-11252-e_--.png`, 'manualStainlessSteel');
export const I = createDraftShield('I', `${env('IMAGE_PREFIX')}/wl0891d1-WH.png`, 'automaticWithIonizer');
export const M = createDraftShield(
  'M',
  `${env('IMAGE_PREFIX')}/Cubis-II-Microbalance-L-0000240-Sartorius.png`,
  'automaticRoundGlass',
);
export const R = createDraftShield('R', `${env('IMAGE_PREFIX')}/wl0892d1-WH.png`, 'stainlessSteel');
export const U = createDraftShield('U', `${env('IMAGE_PREFIX')}/wl0889d1-WH.png`, 'manualGlassU');
export const none = createDraftShield('0', `${env('IMAGE_PREFIX')}/HCB_14201S_MCA.png`, 'none');

export const shields: DraftShield[] = [A, D, E, F, I, M, R, U, none];

export default shields;
