import React from 'react';
import { NavigationStepStatus } from '../../types/navigation';
import NavigationIcon from './NavigationIcon';
import styles from './NavigationStep.module.scss';

export type IconKey = '' | 'pencil' | 'warning' | 'check-alt';

const iconKeys: { [key in NavigationStepStatus]: IconKey } = {
  incomplete: 'warning',
  complete: 'check-alt',
  not_set: '',
};

interface NavigationStepProps {
  status: NavigationStepStatus;
  currentlyEdited: boolean;
  onClick: () => void;
  testid: string;
  disabled?: boolean;
}

const NavigationStep: React.FC<NavigationStepProps> = (props): JSX.Element => {
  const { status, currentlyEdited, onClick, children, testid, disabled = false } = props;
  const iconKey: IconKey = currentlyEdited ? 'pencil' : iconKeys[status];

  return (
    <div
      className={`${styles.step} ${currentlyEdited ? styles.activeStep : styles.inactiveStep} ${disabled && styles.disableNextSteps} mr-1 py-0`}
      onClick={onClick}
      data-testid={testid}
    >
      <div className={styles.navigationContentWrapper}>
        <div className={`${styles.iconContainer}`}>
          <NavigationIcon iconKey={iconKey} />
        </div>
        <div className={`${styles.textContainer}`}>{children}</div>
      </div>
    </div>
  );
};

export default NavigationStep;
