interface Images {
  [key: string]: string;
}

const images: Images = {
  'MCA2.7S-2xxx-F': 'MB_2_7S_DS_F_MCA_NO_KEYS.png',
  'MCA2.7S-2xxx-M': 'MB_2_7S_DS_M_MCA.png',
  'MCA3.6P-2xxx-F': 'MB_3_6P_DS_F_MCA_NO_KEYS.png',
  'MCA3.6P-2xxx-M': 'MB_3_6P_DS_M_MCA.png',
  'MCA6.6S-2xxx-F': 'MB_6_6S_DS_F_MCA_NO_KEYS.png',
  'MCA6.6S-2xxx-M': 'MB_6_6S_DS_M_MCA.png',
  'MCA10.6S-2xxx-F': 'MB_10_6S_DS_F_MCA_NO_KEYS.png',
  'MCA10.6S-2xxx-M': 'MB_10_6S_DS_M_MCA.png',
  'MCA125P-2xxx-U': 'MCA_225SP_U.jpg',
  'MCA125P-2xxx-I': 'MCA_225SP_I.jpg',
  'MCA125P-2xxx-A': 'MCA_225SP_A.jpg',
  'MCA124S-2xxx-U': 'AB_124S_DS_U_MCA_NO_KEYS.png',
  'MCA124S-2xxx-I': 'AB_124S_DS_I_MCA.png',
  'MCA124S-2xxx-A': 'AB_124S_DS_A_MCA.png',
  'MCA224S-2xxx-U': 'AB_224S_DS_U_MCA_NO_KEYS.png',
  'MCA224S-2xxx-I': 'AB_224S_DS_I_MCA.png',
  'MCA224S-2xxx-A': 'AB_224S_DS_A_MCA.png',
  'MCA324S-2xxx-U': 'AB_324_S_P_DS_U_MCA_NO_KEYS.png',
  'MCA324S-2xxx-I': 'AB_324_S_P_DS_I_MCA.png',
  'MCA324S-2xxx-A': 'AB_324_S_P_DS_A_MCA.png',
  'MCA324P-2xxx-U': 'AB_324_S_P_DS_U_MCA_NO_KEYS.png',
  'MCA324P-2xxx-I': 'AB_324_S_P_DS_I_MCA.png',
  'MCA324P-2xxx-A': 'AB_324_S_P_DS_A_MCA.png',
  'MCA524S-2xxx-U': 'AB_524_S_P_DS_U_MCA_NO_KEYS.png',
  'MCA524S-2xxx-I': 'AB_524_S_P_DS_I_MCA.png',
  'MCA524S-2xxx-A': 'AB_524_S_P_DS_A_MCA.png',
  'MCA524P-2xxx-U': 'AB_524_S_P_DS_U_MCA_NO_KEYS.png',
  'MCA524P-2xxx-I': 'AB_524_S_P_DS_I_MCA.png',
  'MCA524P-2xxx-A': 'AB_524_S_P_DS_A_MCA.png',
  'MCA323S-2xxx-A': 'PB_323S_DS_A_MCA.png',
  'MCA323S-2xxx-I': 'PB_323S_DS_I_MCA.png',
  'MCA323S-2xxx-U': 'PB_323S_DS_U_MCA.png',
  'MCA323S-2xxx-E': 'PB_323S_DS_E_MCA.png',
  'MCA323S-2xxx-R': 'PB_323S_DS_R_MCA.png',
  'MCA36S-3xxx-A': 'FU_MCA_36S_50mm.png',
  'MCA36S-3xxx-I': 'FU_MCA_36S_50mm.png',
  'MCA36S-3xxx-U': 'FU_MCA_36S_50mm.png',
  'MCA36S-3xxx-D': 'FU_MCA_36S_50mm.png',
  'MCA36P-3xxx-A': 'FU_MCA_36S_50mm.png',
  'MCA36P-3xxx-I': 'FU_MCA_36S_50mm.png',
  'MCA36P-3xxx-U': 'FU_MCA_36S_50mm.png',
  'MCA36P-3xxx-D': 'FU_MCA_36S_50mm.png',
  'MCA66S-3xxx-A': 'FU_MCA_66S_50mm.png',
  'MCA66S-3xxx-I': 'FU_MCA_66S_50mm.png',
  'MCA66S-3xxx-U': 'FU_MCA_66S_50mm.png',
  'MCA66S-3xxx-D': 'FU_MCA_66S_50mm.png',
  'MCA66P-3xxx-A': 'FU_MCA_66S_50mm.png',
  'MCA66P-3xxx-I': 'FU_MCA_66S_50mm.png',
  'MCA66P-3xxx-U': 'FU_MCA_66S_50mm.png',
  'MCA66P-3xxx-D': 'FU_MCA_66S_50mm.png',
  'MCA116S-3xxx-A': 'FU_MCA_116S_50mm.png',
  'MCA116S-3xxx-I': 'FU_MCA_116S_50mm.png',
  'MCA116S-3xxx-U': 'FU_MCA_116S_50mm.png',
  'MCA116S-3xxx-D': 'FU_MCA_116S_50mm.png',
  'MCA36S-3xxx-A-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-I-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-U-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-D-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-A-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-I-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-U-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-D-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-A-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-I-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-U-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-D-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-A-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-I-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-U-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36P-3xxx-D-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA36S-3xxx-A-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36S-3xxx-I-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36S-3xxx-U-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36S-3xxx-D-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36P-3xxx-A-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36P-3xxx-I-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36P-3xxx-U-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA36P-3xxx-D-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66S-3xxx-A-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-I-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-U-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-D-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-A-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-I-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-U-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-D-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-A-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-I-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-U-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-D-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-A-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-I-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-U-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66P-3xxx-D-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA66S-3xxx-A-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66S-3xxx-I-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66S-3xxx-U-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66S-3xxx-D-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66P-3xxx-A-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66P-3xxx-I-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66P-3xxx-U-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA66P-3xxx-D-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA116S-3xxx-A-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-I-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-U-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-D-1': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-A-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-I-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-U-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-D-2': 'FU_MCA_66S_50mm_YDS125A.jpg',
  'MCA116S-3xxx-A-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA116S-3xxx-I-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA116S-3xxx-U-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA116S-3xxx-D-3': 'FU-MCA_116S_90mm_YDSHR.jpg',
  'MCA623S-2xxx-A': 'PB_623_S_P_DS_A_MCA.png',
  'MCA623S-2xxx-I': 'PB_623_S_P_DS_I_MCA.png',
  'MCA623S-2xxx-U': 'PB_623_S_P_DS_U_MCA.png',
  'MCA623S-2xxx-E': 'PB_623_S_P_DS_E_MCA.png',
  'MCA623S-2xxx-R': 'PB_623_S_P_DS_R_MCA.png',
  'MCA623P-2xxx-A': 'PB_623_S_P_DS_A_MCA.png',
  'MCA623P-2xxx-I': 'PB_623_S_P_DS_I_MCA.png',
  'MCA623P-2xxx-U': 'PB_623_S_P_DS_U_MCA.png',
  'MCA623P-2xxx-E': 'PB_623_S_P_DS_E_MCA.png',
  'MCA623P-2xxx-R': 'PB_623_S_P_DS_R_MCA.png',
  'MCA1203S-2xxx-A': 'PB_1203S_DS_A_MCA.png',
  'MCA1203S-2xxx-I': 'PB_1203S_DS_I_MCA.png',
  'MCA1203S-2xxx-U': 'PB_1203S_DS_U_MCA.png',
  'MCA1203S-2xxx-E': 'PB_1203S_DS_E_MCA.png',
  'MCA1203S-2xxx-R': 'PB_1203S_DS_R_MCA.png',
  'MCA2203S-2xxx-A': 'PB_2203_S_P_DS_A_MCA.png',
  'MCA2203S-2xxx-I': 'PB_2203_S_P_DS_I_MCA.png',
  'MCA2203S-2xxx-U': 'PB_2203_S_P_DS_U_MCA.png',
  'MCA2203S-2xxx-E': 'PB_2203_S_P_DS_E_MCA.png',
  'MCA2203S-2xxx-R': 'PB_2203_S_P_DS_R_MCA.png',
  'MCA2203P-2xxx-A': 'PB_2203_S_P_DS_A_MCA.png',
  'MCA2203P-2xxx-I': 'PB_2203_S_P_DS_I_MCA.png',
  'MCA2203P-2xxx-U': 'PB_2203_S_P_DS_U_MCA.png',
  'MCA2203P-2xxx-E': 'PB_2203_S_P_DS_E_MCA.png',
  'MCA2203P-2xxx-R': 'PB_2203_S_P_DS_R_MCA.png',
  'MCA3203S-2xxx-A': 'PB_3203_S_P_DS_A_MCA.png',
  'MCA3203S-2xxx-I': 'PB_3203_S_P_DS_I_MCA.png',
  'MCA3203S-2xxx-U': 'PB_3203_S_P_DS_U_MCA.png',
  'MCA3203S-2xxx-E': 'PB_3203_S_P_DS_E_MCA.png',
  'MCA3203S-2xxx-R': 'PB_3203_S_P_DS_R_MCA.png',
  'MCA5203S-2xxx-A': 'PB_5203_S_P_DS_A_MCA.png',
  'MCA5203S-2xxx-I': 'PB_5203_S_P_DS_I_MCA.png',
  'MCA5203S-2xxx-U': 'PB_5203_S_P_DS_U_MCA.png',
  'MCA5203S-2xxx-E': 'PB_5203_S_P_DS_E_MCA.png',
  'MCA5203S-2xxx-R': 'PB_5203_S_P_DS_R_MCA.png',
  'MCA5203P-2xxx-A': 'PB_5203_S_P_DS_A_MCA.png',
  'MCA5203P-2xxx-I': 'PB_5203_S_P_DS_I_MCA.png',
  'MCA5203P-2xxx-U': 'PB_5203_S_P_DS_U_MCA.png',
  'MCA5203P-2xxx-E': 'PB_5203_S_P_DS_E_MCA.png',
  'MCA5203P-2xxx-R': 'PB_5203_S_P_DS_R_MCA.png',
  'MCA5202S-2xxx-A': 'PB_5202S_DS_A_MCA.png',
  'MCA5202S-2xxx-I': 'PB_5202S_DS_I_MCA.png',
  'MCA5202S-2xxx-U': 'PB_5202S_DS_U_MCA.png',
  'MCA5202S-2xxx-E': 'PB_5202S_DS_E_MCA.png',
  'MCA5202S-2xxx-R': 'PB_5202S_DS_R_MCA.png',
  'MCA2202S-2xxx-0': 'PB_large_2202_S_P_DS_MCA_NO_KEYS.png',
  'MCA4202S-2xxx-0': 'PB_large_4202_S_P_DS_MCA_NO_KEYS.png',
  'MCA5201S-2xxx-0': 'PB_large_5201S_MCA_NO_KEYS.png',
  'MCA6202S-2xxx-0': 'PB_large_6202_S_P_DS_MCA_NO_KEYS.png',
  'MCA6202P-2xxx-0': 'PB_large_6202_S_P_DS_MCA_NO_KEYS.png',
  'MCA8201S-2xxx-0': 'PB_large_8201S_MCA_NO_KEYS.png',
  'MCA8202S-2xxx-0': 'PB_large_8202_S_P_DS_MCA_NO_KEYS.png',
  'MCA10202S-2xxx-0': 'PB_large_10202_S_P_DS_MCA_NO_KEYS.png',
  'MCA12201S-2xxx-0': 'PB_large_12201S_MCA_NO_KEYS.png',
  'MCA14202S-2xxx-0': 'PB_large_14202_S_P_DS_MCA_NO_KEYS.png',
  'MCA14202P-2xxx-0': 'PB_large_14202_S_P_DS_MCA_NO_KEYS.png',
  'MCA11201S-2xxx-0': 'HCB_14201S_MCA.png',
  'MCA20201S-2xxx-0': 'HCB_20201S_MCA.png',
  'MCA36200S-2xxx-0': 'HCB_36200S_MCA.png',
  'MCA36201S-2xxx-0': 'HCB_36201_S_P_MCA.png',
  'MCA36201P-2xxx-0': 'HCB_36201_S_P_MCA.png',
  'MCA50201S-2xxx-0': 'HCB_50201S_MCA.png',
  'MCA70200S-2xxx-0': 'HCB_70200S_MCA.png',
  'MCA70201S-2xxx-0': 'HCB_70201S_MCA.png',
  'MCA32202P-2xxx-0': 'HCB_round_32202P_MCA.png',
  'MCE2.7S-2xxx-F': 'MB_2_7S_DS_F_MCE.png',
  'MCE2.7S-2xxx-M': 'MB_2_7S_DS_M_MCE.png',
  'MCE3.6P-2xxx-F': 'MB_3_6P_DS_F_MCE.png',
  'MCE3.6P-2xxx-M': 'MB_3_6P_DS_M_MCE.png',
  'MCE6.6S-2xxx-F': 'MB_6_6S_DS_F_MCE.png',
  'MCE6.6S-2xxx-M': 'MB_6_6S_DS_M_MCE.png',
  'MCE10.6S-2xxx-F': 'MB_10_6S_DS_M_MCE.png',
  'MCE10.6S-2xxx-M': 'MB_10_6S_DS_M_MCE.png',
  'MCE125P-2xxx-U': 'MCE_225SP_U.jpg',
  'MCE125P-2xxx-I': 'MCE_225SP_I.jpg',
  'MCE125P-2xxx-A': 'MCE_225SP_A.jpg',
  'MCE124S-2xxx-U': 'AB_124S_DS_U_MCE_NO_KEYS.png',
  'MCE124S-2xxx-I': 'AB_124S_DS_I_MCE.png',
  'MCE124S-2xxx-A': 'AB_124S_DS_A_MCE.png',
  'MCE224S-2xxx-U': 'AB_224S_DS_U_MCE_NO_KEYS.png',
  'MCE224S-2xxx-I': 'AB_224S_DS_I_MCE.png',
  'MCE224S-2xxx-A': 'AB_224S_DS_A_MCE.png',
  'MCE324S-2xxx-U': 'AB_324_S_P_DS_U_MCE_NO_KEYS.png',
  'MCE324S-2xxx-I': 'AB_324_S_P_DS_I_MCE.png',
  'MCE324S-2xxx-A': 'AB_324_S_P_DS_A_MCE.png',
  'MCE324P-2xxx-U': 'AB_324_S_P_DS_U_MCE.png',
  'MCE324P-2xxx-I': 'AB_324_S_P_DS_I_MCE.png',
  'MCE324P-2xxx-A': 'AB_324_S_P_DS_A_MCE.png',
  'MCE524S-2xxx-U': 'AB_524_S_P_DS_U_MCE.png',
  'MCE524S-2xxx-I': 'AB_524_S_P_DS_I_MCE.png',
  'MCE524S-2xxx-A': 'AB_524_S_P_DS_A_MCE.png',
  'MCE524P-2xxx-U': 'AB_524_S_P_DS_U_MCE.png',
  'MCE524P-2xxx-I': 'AB_524_S_P_DS_I_MCE.png',
  'MCE524P-2xxx-A': 'AB_524_S_P_DS_A_MCE.png',
  'MCE323S-2xxx-A': 'PB_323S_DS_A_MCE.png',
  'MCE323S-2xxx-I': 'PB_323S_DS_I_MCE.png',
  'MCE323S-2xxx-U': 'PB_323S_DS_U_MCE.png',
  'MCE323S-2xxx-E': 'PB_323S_DS_E_MCE.png',
  'MCE323S-2xxx-R': 'PB_323S_DS_R_MCE.png',
  'MCE623S-2xxx-A': 'PB_623S_DS_A_MCE.png',
  'MCE623S-2xxx-I': 'PB_623S_DS_I_MCE.png',
  'MCE623S-2xxx-U': 'PB_623S_DS_U_MCE.png',
  'MCE623S-2xxx-E': 'PB_623S_DS_E_MCE.png',
  'MCE623S-2xxx-R': 'PB_623S_DS_R_MCE.png',
  'MCE623P-2xxx-A': 'PB_623S_DS_A_MCE.png',
  'MCE623P-2xxx-I': 'PB_623S_DS_I_MCE.png',
  'MCE623P-2xxx-U': 'PB_623S_DS_U_MCE.png',
  'MCE623P-2xxx-E': 'PB_623S_DS_E_MCE.png',
  'MCE623P-2xxx-R': 'PB_623S_DS_R_MCE.png',
  'MCE1203S-2xxx-A': 'PB_1203S_DS_A_MCE.png',
  'MCE1203S-2xxx-I': 'PB_1203S_DS_I_MCE.png',
  'MCE1203S-2xxx-U': 'PB_1203S_DS_U_MCE.png',
  'MCE1203S-2xxx-E': 'PB_1203S_DS_E_MCE.png',
  'MCE1203S-2xxx-R': 'PB_1203S_DS_R_MCE.png',
  'MCE2203S-2xxx-A': 'PB_2203_S_P_DS_A_MCE.png',
  'MCE2203S-2xxx-I': 'PB_2203_S_P_DS_I_MCE.png',
  'MCE2203S-2xxx-U': 'PB_2203_S_P_DS_U_MCE.png',
  'MCE2203S-2xxx-E': 'PB_2203_S_P_DS_E_MCE.png',
  'MCE2203S-2xxx-R': 'PB_2203_S_P_DS_R_MCE.png',
  'MCE2203P-2xxx-A': 'PB_2203_S_P_DS_A_MCE.png',
  'MCE2203P-2xxx-I': 'PB_2203_S_P_DS_I_MCE.png',
  'MCE2203P-2xxx-U': 'PB_2203_S_P_DS_U_MCE.png',
  'MCE2203P-2xxx-E': 'PB_2203_S_P_DS_E_MCE.png',
  'MCE2203P-2xxx-R': 'PB_2203_S_P_DS_R_MCE.png',
  'MCE3203S-2xxx-A': 'PB_3203_S_P_DS_A_MCE.png',
  'MCE3203S-2xxx-I': 'PB_3203_S_P_DS_I_MCE.png',
  'MCE3203S-2xxx-U': 'PB_3203_S_P_DS_U_MCE.png',
  'MCE3203S-2xxx-E': 'PB_3203_S_P_DS_E_MCE.png',
  'MCE3203S-2xxx-R': 'PB_3203_S_P_DS_R_MCE.png',
  'MCE5203S-2xxx-A': 'PB_5203_S_P_DS_A_MCE.png',
  'MCE5203S-2xxx-I': 'PB_5203_S_P_DS_I_MCE.png',
  'MCE5203S-2xxx-U': 'PB_5203_S_P_DS_U_MCE.png',
  'MCE5203S-2xxx-E': 'PB_5203_S_P_DS_E_MCE.png',
  'MCE5203S-2xxx-R': 'PB_5203_S_P_DS_R_MCE.png',
  'MCE5203P-2xxx-A': 'PB_5203_S_P_DS_A_MCE.png',
  'MCE5203P-2xxx-I': 'PB_5203_S_P_DS_I_MCE.png',
  'MCE5203P-2xxx-U': 'PB_5203_S_P_DS_U_MCE.png',
  'MCE5203P-2xxx-E': 'PB_5203_S_P_DS_E_MCE.png',
  'MCE5203P-2xxx-R': 'PB_5203_S_P_DS_R_MCE.png',
  'MCE5202S-2xxx-A': 'PB_5202S_DS_A_MCE.png',
  'MCE5202S-2xxx-I': 'PB_5202S_DS_I_MCE.png',
  'MCE5202S-2xxx-U': 'PB_5202S_DS_U_MCE.png',
  'MCE5202S-2xxx-E': 'PB_5202S_DS_E_MCE.png',
  'MCE5202S-2xxx-R': 'PB_5202S_DS_R_MCE.png',
  'MCE2202S-2xxx-0': 'PB_large_2202_DS_MCE.png',
  'MCE4202S-2xxx-0': 'PB_large_4202_DS_MCE.png',
  'MCE5201S-2xxx-0': 'PB_large_5201S_MCE.png',
  'MCE6202S-2xxx-0': 'PB_large_6202_DS_MCE.png',
  'MCE6202P-2xxx-0': 'PB_large_6202_DS_MCE.png',
  'MCE8201S-2xxx-0': 'PB_large_8201S_MCE.png',
  'MCE8202S-2xxx-0': 'PB_large_8202_DS_MCE.png',
  'MCE10202S-2xxx-0': 'PB_large_10202_DS_MCE.png',
  'MCE12201S-2xxx-0': 'PB_large_12201S_MCE.png',
  'MCE14202S-2xxx-0': 'PB_large_14202_DS_MCE.png',
  'MCE14202P-2xxx-0': 'PB_large_14202_DS_MCE.png',
  'MCE11201S-2xxx-0': 'HCB_14201S_MCE.png',
  'MCE20201S-2xxx-0': 'HCB_20201S_MCE.png',
  'MCE36200S-2xxx-0': 'HCB_36200S_MCE.png',
  'MCE36201S-2xxx-0': 'HCB_36201_S_P_MCA.png',
  'MCE36201P-2xxx-0': 'HCB_36201_S_P_MCA.png',
  'MCE50201S-2xxx-0': 'HCB_50201S_MCE.png',
  'MCE70200S-2xxx-0': 'HCB_70200S_MCE.png',
  'MCE70201S-2xxx-0': 'HCB_70201S_MCE.png',
  'MCE32202P-2xxx-0': 'HCB_round_32202P_MCE.png',
  'MCE36S-3xxx-A': 'FU_MCE_36S_50mm.png',
  'MCE36S-3xxx-I': 'FU_MCE_36S_50mm.png',
  'MCE36S-3xxx-U': 'FU_MCE_36S_50mm.png',
  'MCE36S-3xxx-D': 'FU_MCE_36S_50mm.png',
  'MCE36P-3xxx-A': 'FU_MCE_36S_50mm.png',
  'MCE36P-3xxx-I': 'FU_MCE_36S_50mm.png',
  'MCE36P-3xxx-U': 'FU_MCE_36S_50mm.png',
  'MCE36P-3xxx-D': 'FU_MCE_36S_50mm.png',
  'MCE66S-3xxx-A': 'FU_MCE_66S_50mm.png',
  'MCE66S-3xxx-I': 'FU_MCE_66S_50mm.png',
  'MCE66S-3xxx-U': 'FU_MCE_66S_50mm.png',
  'MCE66S-3xxx-D': 'FU_MCE_66S_50mm.png',
  'MCE66P-3xxx-A': 'FU_MCE_66S_50mm.png',
  'MCE66P-3xxx-I': 'FU_MCE_66S_50mm.png',
  'MCE66P-3xxx-U': 'FU_MCE_66S_50mm.png',
  'MCE66P-3xxx-D': 'FU_MCE_66S_50mm.png',
  'MCE36S-3xxx-A-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36S-3xxx-I-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36S-3xxx-U-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36S-3xxx-D-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36P-3xxx-A-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36P-3xxx-I-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36P-3xxx-U-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36P-3xxx-D-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE36S-3xxx-A-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36S-3xxx-I-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36S-3xxx-U-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36S-3xxx-D-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36P-3xxx-A-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36P-3xxx-I-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36P-3xxx-U-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36P-3xxx-D-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE36S-3xxx-A-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36S-3xxx-I-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36S-3xxx-U-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36S-3xxx-D-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36P-3xxx-A-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36P-3xxx-I-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36P-3xxx-U-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE36P-3xxx-D-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66S-3xxx-A-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66S-3xxx-I-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66S-3xxx-U-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66S-3xxx-D-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66P-3xxx-A-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66P-3xxx-I-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66P-3xxx-U-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66P-3xxx-D-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE66S-3xxx-A-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66S-3xxx-I-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66S-3xxx-U-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66S-3xxx-D-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66P-3xxx-A-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66P-3xxx-I-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66P-3xxx-U-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66P-3xxx-D-2': 'FU_MCE_36S_50mm_YDS125U.jpg',
  'MCE66S-3xxx-A-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66S-3xxx-I-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66S-3xxx-U-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66S-3xxx-D-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66P-3xxx-A-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66P-3xxx-I-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66P-3xxx-U-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE66P-3xxx-D-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE116S-3xxx-A-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE116S-3xxx-I-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE116S-3xxx-U-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE116S-3xxx-D-1': 'FU_MCE_36S_50mm_YDS125A.png',
  'MCE116S-3xxx-A-2': 'FU_MCE_36S_50mm_YDS125U.JPG',
  'MCE116S-3xxx-I-2': 'FU_MCE_36S_50mm_YDS125U.JPG',
  'MCE116S-3xxx-U-2': 'FU_MCE_36S_50mm_YDS125U.JPG',
  'MCE116S-3xxx-D-2': 'FU_MCE_36S_50mm_YDS125U.JPG',
  'MCE116S-3xxx-A-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE116S-3xxx-I-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE116S-3xxx-U-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCE116S-3xxx-D-3': 'FU_MCE_36S_50mm_YDSHR.png',
  'MCA125P-I-MTS': 'MCA_225SP_I.jpg',
  'MCA125P-U-MTS': 'MCA_225SP_U.jpg',
  'MCE125P-U-MTS': 'MCE_225SP_U.jpg',
  'MCA125PU-S01': 'MCA_225SP_U.jpg',
  'MCA125PI-S01': 'MCA_225SP_I.jpg',
  'MCE125PU-S01': 'MCE_225SP_U.jpg',
  'MCA125PU-CCN': 'MCA_225SP_U.jpg',
  'MCA125PI-CCN': 'MCA_225SP_I.jpg',
  'MCE125PU-CCN': 'MCE_225SP_U.jpg',
  'MCA226S-3xxx-D': 'FU_MCA_66S_50mm.png',
  'MCA226S-3xxx-D-1': 'FU_MCA_125P_50mm_YDS125A.jpg',
  'MCA225S-3xxx-D': 'FU_MCA_225S_90mm.jpg',
  'MCA225S-3xxx-D-1': 'FU_MCA_125P_50mm_YDS125A.jpg',
  'MCE225S-3xxx-D': 'FU_MCE_225S_50mm_YDS125A.png',
  'MCE225S-3xxx-D-1': 'FU_MCE_225S_50mm_YDS125A.png',
  'MCA225P-3xxx-D': 'FU_MCA_225S_90mm.jpg',
  'MCE225P-3xxx-D': 'FU_MCE_225S_90mm.jpg',
  'MCA225P-3xxx-D-1': 'FU_MCA_125P_50mm_YDS125A.jpg',
  'MCE225P-3xxx-D-1': 'FU_MCE_225S_50mm_YDS125A.png',
  'MCA125S-3xxx-D': 'FU_MCA_225S_90mm.jpg',
  'MCE125S-3xxx-D': 'FU_MCE_225S_90mm.jpg',
  'MCA125S-3xxx-D-1': 'FU_MCA_125P_50mm_YDS125A.jpg',
  'MCE125S-3xxx-D-1': 'FU_MCE_225S_50mm_YDS125A.png',
};

export default images;
