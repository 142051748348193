import { useEffect } from 'react';
import { disableHotjar, enableHotjar } from './hooks';
import { WindowHotjarSettings } from './types';

export interface HotjarProps {
  consentStatus: boolean;
}

const HOTJAR_WINDOW_SETTINGS: WindowHotjarSettings = { hjid: 5188521, hjsv: 6 };
const HOJAR_SCRIPT_ID = 'my-platform-hotjar';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Hotjar = (props: HotjarProps) => {
  const { consentStatus } = props;

  useEffect(() => {
    if (
      consentStatus === true &&
      window.location.href.startsWith('https://balance.sartorius.com/Cubis-II-Configurator')
    ) {
      enableHotjar(HOTJAR_WINDOW_SETTINGS, HOJAR_SCRIPT_ID);
    } else {
      disableHotjar(HOJAR_SCRIPT_ID);
    }
  }, [consentStatus]);

  return null;
};

export default Hotjar;
