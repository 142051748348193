import hardwares from '../hardwares';
import { noSoftware, optionsForIndividualSoftwarePackage, qp99 } from '../softwares';
import { DisplayModule, HardwarePackage, SoftwareOption, SoftwarePackage } from '../types';
import env from '@beam-australia/react-env';
import i18next from 'i18next';
const t = i18next.getFixedT(null, 'common');

const createDisplayModule = (
  name: string,
  image: string,
  softwarePackages: SoftwarePackage[] | undefined,
  hardwarePackages: HardwarePackage[] | undefined,
): DisplayModule => {
  return {
    name,
    get label() {
      return t(`displayModule.${this.name}.label`);
    },
    get description() {
      return t(`displayModule.${this.name}.description`);
    },
    get optionsDescription() {
      return t(`displayModule.${this.name}.optionsDescription`);
    },
    softwarePackages: softwarePackages,
    image: image,
    get selectHardwareOptions() {
      return t(`displayModule.${this.name}.selectHardwareOptions`);
    },
    get display() {
      return t(`displayModule.${this.name}.display`);
    },
    hardwarePackages: hardwarePackages,
  };
};

export const createSoftwarePackage = (
  name: string,
  options: SoftwareOption[],
): {
  name: string;
  label: string;
  options: SoftwareOption[];
} => {
  return {
    name: name,
    get label(): string {
      return t(`displayModule.${mca.name}.softwarePackages.${this.name}`);
    },
    options: options,
  };
};

const individualSoftwarePackage = createSoftwarePackage('individual', optionsForIndividualSoftwarePackage);
const qp99SoftwarePackage = createSoftwarePackage(qp99.name, []);
const qp99SemiMicroSoftwarePackage = createSoftwarePackage(qp99.name, []);
const noSoftwareActivation = createSoftwarePackage(noSoftware.name, []);

export const mca = createDisplayModule(
  'MCA',
  `${env('IMAGE_PREFIX')}/wl-11213-e_wb.png`,
  [individualSoftwarePackage, qp99SoftwarePackage, noSoftwareActivation],
  hardwares,
);
export const mcaSemiMicro125P = createDisplayModule(
  'MCA',
  `${env('IMAGE_PREFIX')}/wl-11213-e_wb.png`,
  [qp99SemiMicroSoftwarePackage],
  hardwares,
);
export const mce = createDisplayModule('MCE', `${env('IMAGE_PREFIX')}/wl-11251-e_wb.png`, undefined, undefined);
export const mceSemiMicro = createDisplayModule(
  'MCE',
  `${env('IMAGE_PREFIX')}/wl-11251-e_wb.png`,
  undefined,
  hardwares,
);
export const displayModules: DisplayModule[] = [mca, mce];

export default displayModules;
