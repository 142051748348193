import { WeighingModule } from '../../types';
import { none } from '../../shields';
import { certificates } from '../../certificates';

const module_6202p: WeighingModule = {
  name: '6202P',
  scaleInterval: [10, 20, 50],
  maxCapacity: [1500, 3000, 6200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_6202p;
