import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_5203s: WeighingModule = {
  name: '5203S',
  scaleInterval: [1],
  maxCapacity: [5200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_5203s;
