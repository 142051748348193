import { CButton } from '@coreui/react';
import React, { useState } from 'react';
import styles from './ExperienceRate.module.scss';
import Star from '../assets/stars/star.svg';
import StarFilled from '../assets/stars/star-filled.svg';
import { RatingApi, Configuration as ApiConfiguration, RatingDTORatingValueEnum } from '../api';
import env from '@beam-australia/react-env';
import { Trans, useTranslation } from 'react-i18next';

const selectedIcon = <img src={StarFilled} title="filled Star" />;
const deselectedIcon = <img src={Star} title="Empty star" />;

const ExperienceRate = (): JSX.Element => {
  const [rating, setRating] = useState<RatingDTORatingValueEnum>(4);
  const [hoveredStar, setHoveredStar] = useState<number>(0);
  const [ratingSent, setRatingSent] = useState<boolean>(false);
  const { t } = useTranslation('common');

  function onHover(star: number) {
    return function () {
      setHoveredStar(star);
    };
  }

  function onOut() {
    setHoveredStar(0);
  }

  function onSelect(rate: RatingDTORatingValueEnum) {
    return function () {
      setRating(rate);
    };
  }

  async function onSubmit() {
    setRatingSent(false);

    const api = new RatingApi(
      new ApiConfiguration({
        basePath: env('BACKEND_URL'),
        headers: { 'Ocp-Apim-Subscription-Key': env('APIM_SUBSCRIPTION') },
      }),
    );
    await api.sendRating({ ratingDTO: { ratingValue: rating } });

    setRatingSent(true);
  }

  return (
    <div className={`${styles.experienceRateContainer} bg-primary text-dark`}>
      {!ratingSent && (
        <>
          <h2 className={styles.ratingHeadline}>
            <Trans i18nKey="success.experienceRate.header" t={t}>
              Rate your experience with the Cubis<sup>®</sup> II Configurator
            </Trans>
          </h2>
          <p className={styles.ratingText}>
            <Trans i18nKey="success.experienceRate.subheader" t={t}>
              How well was the Cubis<sup>®</sup> II Configurator supporting you when configuring your new lab balance?
            </Trans>
          </p>
          <div className={`${styles.stars} pt-5 pb-2`}>
            {Object.values(RatingDTORatingValueEnum).map((star) => (
              <span
                data-testid={`star`}
                className="star"
                key={star}
                onMouseOver={onHover(star)}
                onMouseOut={onOut}
                onClick={onSelect(star)}
              >
                {rating < star ? (hoveredStar < star ? deselectedIcon : selectedIcon) : selectedIcon}
              </span>
            ))}

            <div className={styles.buttonWrapper}>
              <CButton color="secondary" className={`${styles.btnSubmit} mt-1`} onClick={onSubmit}>
                {t('general.submit')}
              </CButton>
            </div>
          </div>
        </>
      )}
      {ratingSent && (
        <h2 className="text-center">
          <i className="icon-paper-plane" /> {t('success.experienceRate.thankYou')}
        </h2>
      )}
    </div>
  );
};

export default ExperienceRate;
