import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { none } from '../../shields';

const module_12201s: WeighingModule = {
  name: '12201S',
  scaleInterval: [100],
  maxCapacity: [12200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_12201s;
