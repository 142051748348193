import { useRecoilState } from 'recoil';
import CountryApprovalAccessorySelection from '../components/CountryApprovalAccessorySelection/CountryApprovalAccessorySelection';
import { currentStepState, navigationStepStatusState } from '../states';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const Step4 = (): JSX.Element => {
  const [navigationStatus] = useRecoilState(navigationStepStatusState);
  const [, setCurrentStep] = useRecoilState(currentStepState);

  useEffect(
    () => {
      setCurrentStep(4);
    },
    // eslint-disable-next-line
    [],
  );
  const history = useHistory();

  if (navigationStatus.step3 !== 'complete') {
    history.push('/step3');
  }

  return <CountryApprovalAccessorySelection />;
};

export default Step4;
