import classNames from 'classnames';
import React from 'react';
import { useRecoilState } from 'recoil';
import useNavigationSteps from '../../hooks/useNavigationSteps';
import { getStepHeader } from '../../services/navigation';
import { isRecommendationAvailableState, navigationStepStatusState } from '../../states';
import styles from './NavigationTitle.module.scss';
import { NavigationButtonNext, NavigationButtonPrevious } from './NavigationButtons';
import { useTranslation } from 'react-i18next';

const NavigationTitle: React.FC = () => {
  const [{ currentlyEditedStep, currentlyEditedStepStatus }, { onStepSelect }] = useNavigationSteps();
  const [navigationStatus] = useRecoilState(navigationStepStatusState);
  const [isRecommendationAvailable, setIsRecommendationAvailable] =
    useRecoilState<boolean>(isRecommendationAvailableState);
  const { t } = useTranslation('common');

  if (currentlyEditedStep !== 5 && isRecommendationAvailable) {
    setIsRecommendationAvailable(false);
  }

  return (
    <div className={styles.navigationButtons}>
      <div className={'d-flex flex-row'}>
        <div>
          <h3
            data-testid="step-header"
            className={`pt-1 ${
              isRecommendationAvailable ? styles.navigationTitleRecommendation : styles.navigationTitle
            }`}
          >
            {isRecommendationAvailable
              ? t(`navigation.description.completeWithRecommendation`)
              : t(`navigation.description.${getStepHeader(currentlyEditedStep, navigationStatus)}`)}
          </h3>
        </div>
        <div className="pl-8 ml-auto text-nowrap">
          {currentlyEditedStep !== 1 && (
            <NavigationButtonPrevious data-testid="prev-button" onClick={onStepSelect(currentlyEditedStep - 1)} />
          )}

          {currentlyEditedStep < 5 && (
            <NavigationButtonNext
              label={currentlyEditedStep === 4 ? t('requestQuote.form.reviewSelection') : t('general.next')}
              currentlyEditedStepStatus={currentlyEditedStepStatus}
              buttonNextClasses={classNames({
                'border-dark': currentlyEditedStepStatus !== 'complete',
                'border-primary': currentlyEditedStepStatus === 'complete',
              })}
              disabled={currentlyEditedStep === 3 && currentlyEditedStepStatus === 'not_set'}
              onClick={onStepSelect(currentlyEditedStep + 1)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationTitle;
