import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import {accessories, shields, documentationsAndServices, defaultSelectedAccessories} from './defaults';

const module_36s: WeighingModule = {
  name: '36S',
  scaleInterval: [0.001],
  maxCapacity: [32],
  compatibleShields: shields,
  possibleCertificates: certificates,
  compatibleAccessories: accessories,
  documentationsAndServices,
  defaultSelectedAccessories,
};

export default module_36s;
