import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_524p: WeighingModule = {
  name: '524P',
  scaleInterval: [0.1, 0.2, 0.5],
  maxCapacity: [120, 240, 520],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_524p;
