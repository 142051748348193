import React from 'react';
import { IconKey } from './NavigationStep';
import styles from './NavigationStep.module.scss';
import ExclamationMarkIcon from '../../assets/icons/exclamation-mark.svg';
import PencilMarkIcon from '../../assets/icons/pencil.svg';
import CheckmarkMarkIcon from '../../assets/icons/checkmark.svg';
import EmptyIcon from '../../assets/icons/empty.svg';

interface NavigationIconProps {
  iconKey: IconKey;
  borderColor?: 'white' | 'dark';
}

const NavigationIcon = ({ iconKey }: NavigationIconProps): JSX.Element => {
  let Icon = undefined;
  let title = undefined;
  switch (iconKey) {
    case 'warning':
      Icon = ExclamationMarkIcon;
      title = 'Incomplete';
      break;
    case 'check-alt':
      Icon = CheckmarkMarkIcon;
      title = 'Completed';
      break;
    case 'pencil':
      Icon = PencilMarkIcon;
      title = 'Active';
      break;
    case '':
      Icon = EmptyIcon;
      title = '';
  }

  return (
    <span className={`${styles.iconWrapper}`}>
      <img src={Icon} className={styles.icon} alt={title} title={title} />
    </span>
  );
};

export default NavigationIcon;
