import React, { useEffect, useState } from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '../utils/LanguageUtils';
import styles from './LanguageDropdown.module.scss';
import PlatformContext from '../context/PlatformContext';
import { QuoteRequestDTOContextEnum } from '../context/QuoteRequestDTOContextEnum';
interface ILanguageDropdownProps {
  onToggle?: (open: boolean) => void;
  onSave?: () => void;
}

const LanguageDropdown: React.FC<ILanguageDropdownProps> = (props: ILanguageDropdownProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { onToggle, onSave = () => {} } = props;
  const { t, i18n } = useTranslation('common');
  const location = useLocation();
  const history = useHistory();

  const platform = React.useContext(PlatformContext);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(i18n.language);

  function toggle(): void {
    const result = !isExpanded;
    setIsExpanded(result);
    if (onToggle) onToggle(result);
  }

  function changeLanguage(lang: string, save: boolean): void {
    LanguageUtils.setLanguage(lang, save).then((value) => {
      const path = location.pathname.split('/').slice(2).join('/');
      const redirect = `/${lang}/${path}${location.search}`;
      if (value && save && onSave) {
        onSave();
      }
      history.replace(redirect);
    });

    setIsExpanded(false);
    if (onToggle) onToggle(false);
  }

  function getLangs(): string[] {
    if (i18n.services) {
      return Object.keys(i18n.services.resourceStore.data);
    } else {
      return ['en'];
    }
  }

  function renderNavDropdown(): JSX.Element {
    return (
      <CDropdown inNav={true}>
        <CDropdownToggle
          caret={false}
          onClick={toggle}
          data-testid="lang-dropdown-toggle"
          className={platform === QuoteRequestDTOContextEnum.Vwr ? styles.whiteColor : ''}
        >
          <div className="d-flex align-items-center">
            <div>
              <i className="text-icon icon-globe" />
            </div>
            <div role="currentlanguage" lang={language} data-testid="lang-dropdown-current-language">
              {t(`language.${language}`)}
            </div>
          </div>
        </CDropdownToggle>
        <CDropdownMenu className={styles.languageDropdown} placement="bottom-end" show={isExpanded}>
          {getLangs().map((lang) => {
            return (
              <CDropdownItem
                className="dropdown-item"
                onClick={() => changeLanguage(lang, true)}
                key={`language.${lang}`}
                lang={lang}
                data-testid="lang-dropdown-item"
              >
                <i className={`${styles.langDropdownIcon} prepend-icon ${language == lang ? 'icon-check' : ''}`} />
                {t(`language.${lang}`)}
              </CDropdownItem>
            );
          })}
        </CDropdownMenu>
      </CDropdown>
    );
  }

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return renderNavDropdown();
};

export default LanguageDropdown;
