import React from 'react';
import { useTranslation } from 'react-i18next';

const DraftShieldHelpText: React.FC = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div>
      <p>{t('helpText.step2.description')}</p>
      <br />
      <ul>
        <li>{t('helpText.step2.item1')}</li>
        <li>{t('helpText.step2.item2')}</li>
        <li>{t('helpText.step2.item3')}</li>
        <li>{t('helpText.step2.item4')}</li>
      </ul>
    </div>
  );
};

export default DraftShieldHelpText;
