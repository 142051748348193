import { HardwarePackage } from '../types';
import i18next from 'i18next';

const t = i18next.getFixedT(null, 'common');

export const createHardwareOption = (name: string, showOnProductNumber: boolean): HardwarePackage => {
  return {
    name,
    get label() {
      return t(`hardwareOption.${this.name}.label`);
    },
    get code() {
      return t(`hardwareOption.${this.name}.code`);
    },
    showOnProductNumber,
  };
};

export const AutomatedDraft = createHardwareOption('AutomatedDraft', true);
export const AutomatedOuterDraft = createHardwareOption('AutomatedOuterDraft', true);
export const ActivatedIonizer = createHardwareOption('ActivatedIonizer', true);
export const NoHardware = createHardwareOption('none', false);

export const hardwares: HardwarePackage[] = [AutomatedDraft, AutomatedOuterDraft, ActivatedIonizer, NoHardware];

export default hardwares;
