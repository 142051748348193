import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CTooltip } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IIcon from '../../assets/icons/i.svg';
import ImageWithModal from '../ImageWithModal';
import UndoSelection from '../UndoSelection';
import styles from './DraftShieldSelection.module.scss';

export interface DraftShieldSelectionItemDTO {
  name: string;
  label: string;
  description: string;
  imageURL: string;
  enabled: boolean;
  compatible: boolean;
  selected: boolean;
  lastInRow: boolean;
}

interface DraftShieldSelectionItemProps {
  itemData: DraftShieldSelectionItemDTO;
  selectedCategory: string | undefined;
  onDraftShieldSelect: () => void;
  onUndoSelection: () => void;
}

const DraftShieldSelectionItem: React.FC<DraftShieldSelectionItemProps> = (
  props: DraftShieldSelectionItemProps,
): JSX.Element => {
  const {
    itemData: { name, label, description, imageURL, enabled, compatible, selected, lastInRow },
    selectedCategory,
    onDraftShieldSelect,
    onUndoSelection,
  } = props;

  const backgroundColor = selected ? 'primary' : 'light';
  const canBeSelected = enabled && compatible && !selected;
  const { t } = useTranslation('common');

  return (
    <CCard
      data-testid={`${name}-card`}
      className={`bg-${backgroundColor} text-dark mb-5 ${!lastInRow ? 'mr-5' : ''} ${
        enabled && !compatible ? styles.muted : ''
      }`}
    >
      <CCardHeader
        className={`bg-${backgroundColor} text-${!enabled || compatible ? 'dark' : 'muted'} ${
          enabled && !compatible ? styles.muted : ''
        }`}
      >
        <strong className="mr-1">
          {label} ({name})
        </strong>
        <CTooltip content={<div className="text-left">{description}</div>}>
          <img src={IIcon} width={20} height={20} />
        </CTooltip>
      </CCardHeader>
      <CCardBody className="text-center pb-2">
        <ImageWithModal src={imageURL} srcFullsize={imageURL} fluidGrow={true} muted={enabled && !compatible} />
      </CCardBody>
      <CCardFooter className={`bg-${backgroundColor} text-dark ${enabled && !compatible ? styles.muted : ''}`}>
        {canBeSelected && (
          <CButton
            data-testid={`${name}-button`}
            variant="outline"
            color="dark"
            shape="square"
            block={true}
            className="border border-dark"
            onClick={onDraftShieldSelect}
          >
            {t('general.select')}
          </CButton>
        )}
        {selected && <UndoSelection onUndoSelection={onUndoSelection} />}
        {enabled && !compatible && (
          <div className="text-muted" data-testid="not-available">
            {t('draftShieldSelection.notAvailable', { selectedCategory })}
          </div>
        )}
      </CCardFooter>
    </CCard>
  );
};

export default DraftShieldSelectionItem;
