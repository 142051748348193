import { DisplayModule, WeighingModule } from '../data/types';

export function getCompatibleDisplayNames(module: WeighingModule): string[] | null {
  return module.compatibleDisplays ? module.compatibleDisplays.map(({ name }) => name) : null;
}

export function displayIsCompatibleWithModule(displayModule: DisplayModule, module: WeighingModule): boolean {
  const found = module.compatibleDisplays
    ? module.compatibleDisplays.find((display) => display.name === displayModule.name)
    : true;
  return !!found;
}
