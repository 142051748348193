import { WeighingModule } from '../../types';
import { none } from '../../shields';
import { certificates } from '../../certificates';

const module_8202s: WeighingModule = {
  name: '8202S',
  scaleInterval: [10],
  maxCapacity: [8200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_8202s;
