import { WeighingModule } from '../../types';
import { certificates } from '../../certificates';
import { A, E, I, R, U } from '../../shields';

const module_5202s: WeighingModule = {
  name: '5202S',
  scaleInterval: [10],
  maxCapacity: [5200],
  compatibleShields: [A, E, I, R, U],
  possibleCertificates: certificates,
};

export default module_5202s;
