import { WindowHotjarSettings } from './types';

export const enableHotjar = (hotjarSettings: WindowHotjarSettings, scriptId: string): void => {
  window._hjSettings = hotjarSettings;
  const head = document.getElementsByTagName('head')[0];
  const hotjarScript = document.createElement('script');
  hotjarScript.setAttribute('async', '1');
  hotjarScript.setAttribute('id', scriptId);
  hotjarScript.setAttribute(
    'src',
    `https://static.hotjar.com/c/hotjar-${hotjarSettings.hjid}.js?sv=${hotjarSettings.hjsv}`,
  );

  head.appendChild(hotjarScript);
};

export const disableHotjar = (scriptId: string): void => {
  const hotjarScript = document.getElementById(scriptId);
  if (hotjarScript) {
    hotjarScript?.parentNode?.removeChild(hotjarScript);
  }
};
