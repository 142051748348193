import i18next from 'i18next';
import lang_en from '../i18n/en.json';
import lang_de from '../i18n/de.json';
import lang_fr from '../i18n/fr.json';
import lang_zh from '../i18n/zh.json';
import lang_jp from '../i18n/jp.json';
import lang_ko from '../i18n/ko.json';
import lang_es from '../i18n/es.json';
import lang_pt from '../i18n/pt.json';
import lang_it from '../i18n/it.json';

import vwr_lang_cs from '../i18n/vwr/cs.json';
import vwr_lang_da from '../i18n/vwr/da.json';
import vwr_lang_de from '../i18n/vwr/de.json';
import vwr_lang_en from '../i18n/vwr/en.json';
import vwr_lang_es from '../i18n/vwr/es.json';
import vwr_lang_fi from '../i18n/vwr/fi.json';
import vwr_lang_fr from '../i18n/vwr/fr.json';
import vwr_lang_it from '../i18n/vwr/it.json';
import vwr_lang_ja from '../i18n/vwr/ja.json';
import vwr_lang_ko from '../i18n/vwr/kо.json';
import vwr_lang_nl from '../i18n/vwr/nl.json';
import vwr_lang_no from '../i18n/vwr/no.json';
import vwr_lang_pl from '../i18n/vwr/pl.json';
import vwr_lang_pt from '../i18n/vwr/pt.json';
import vwr_lang_sk from '../i18n/vwr/sk.json';
import vwr_lang_sl from '../i18n/vwr/sl.json';
import vwr_lang_sv from '../i18n/vwr/sv.json';
import vwr_lang_zh from '../i18n/vwr/zh.json';

import tf_lang_cs from '../i18n/tf/cs.json';
import tf_lang_da from '../i18n/tf/da.json';
import tf_lang_fi from '../i18n/tf/fi.json';
import tf_lang_nl from '../i18n/tf/nl.json';
import tf_lang_no from '../i18n/tf/no.json';
import tf_lang_pl from '../i18n/tf/pl.json';
import tf_lang_sk from '../i18n/tf/sk.json';
import tf_lang_sl from '../i18n/tf/sl.json';
import tf_lang_sv from '../i18n/tf/sv.json';

import { PlatformContextProvider } from '../context/PlatformContext';
import { QuoteRequestDTOContextEnum } from '../context/QuoteRequestDTOContextEnum';

const localStorageKey = 'sartorius.cubis.lang';

declare global {
  interface Window {
    setLanguage: (lang: string, save: boolean) => void;
  }
}

class LanguageUtils {
  /**
   * Init language files, load language setting from language param/local storage/browser language. Defaults to "en"
   */
  public static initI18n(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let resources: any;
    const context = PlatformContextProvider(Object.create(null))?.props.value;

    if (context === QuoteRequestDTOContextEnum.Vwr) {
      resources = {
        en: {
          common: vwr_lang_en,
        },
        cs: {
          common: vwr_lang_cs,
        },
        da: {
          common: vwr_lang_da,
        },
        de: {
          common: vwr_lang_de,
        },
        es: {
          common: vwr_lang_es,
        },
        fi: {
          common: vwr_lang_fi,
        },
        fr: {
          common: vwr_lang_fr,
        },
        it: {
          common: vwr_lang_it,
        },
        ja: {
          common: vwr_lang_ja,
        },
        ko: {
          common: vwr_lang_ko,
        },
        nl: {
          common: vwr_lang_nl,
        },
        no: {
          common: vwr_lang_no,
        },
        pl: {
          common: vwr_lang_pl,
        },
        pt: {
          common: vwr_lang_pt,
        },
        sk: {
          common: vwr_lang_sk,
        },
        sl: {
          common: vwr_lang_sl,
        },
        sv: {
          common: vwr_lang_sv,
        },
        zh: {
          common: vwr_lang_zh,
        },
      };
    } else if (context === QuoteRequestDTOContextEnum.Tf) {
      resources = {
        cs: {
          common: tf_lang_cs,
        },
        da: {
          common: tf_lang_da,
        },
        nl: {
          common: tf_lang_nl,
        },
        en: {
          common: lang_en,
        },
        fi: {
          common: tf_lang_fi,
        },
        fr: {
          common: lang_fr,
        },
        de: {
          common: lang_de,
        },
        it: {
          common: lang_it,
        },
        ja: {
          common: lang_jp,
        },
        no: {
          common: tf_lang_no,
        },
        pl: {
          common: tf_lang_pl,
        },
        pt: {
          common: lang_pt,
        },
        sk: {
          common: tf_lang_sk,
        },
        sl: {
          common: tf_lang_sl,
        },
        es: {
          common: lang_es,
        },
        sv: {
          common: tf_lang_sv,
        },
        kr: {
          common: lang_ko,
        },
        cn: {
          common: lang_zh,
        },
      };
    } else {
      resources = {
        en: {
          common: lang_en,
        },
        de: {
          common: lang_de,
        },
        es: {
          common: lang_es,
        },
        fr: {
          common: lang_fr,
        },
        pt: {
          common: lang_pt,
        },
        it: {
          common: lang_it,
        },
        zh: {
          common: lang_zh,
        },
        jp: {
          common: lang_jp,
        },
        ko: {
          common: lang_ko,
        },
      };
    }

    i18next.init({
      interpolation: { escapeValue: false },
      lng: 'en',
      fallbackLng: 'en',
      resources,
      react: {
        useSuspense: false,
      },
    });
    this.initLanguage();

    // expose setLanguage, so cypress can use it
    window.setLanguage = (lang: string, save: boolean) =>
      this.setLanguage(lang, save).then((success) => {
        if (success) {
          window.console.log(`language set to ${lang}`);
        } else {
          window.console.error(`failed to change language`);
        }
      });
  }

  /**
   * Set language and save it to localStorage (default)
   * @param lang language ID (e.g. "de")
   * @param save save to localStorage (default: true)
   */
  public static setLanguage(lang: string, save = true): Promise<boolean> {
    return i18next
      .changeLanguage(lang)
      .then(() => {
        if (save) {
          return this.saveLanguage(lang);
        }

        return Promise.resolve(false);
      })
      .catch((e) => Promise.reject(e));
  }

  /**
   * Initializes the language
   * set language by url parameter e.g /Cubis-II-Configurator/de/step1, if not present
   * set language from local storage, if not present
   * set language by browser language
   * @private
   */
  private static initLanguage() {
    const languageParam = window.location.pathname.split('/')[2];
    const languageStored = localStorage.getItem(localStorageKey);
    const browserLanguage = navigator.language;

    if (this.isInitializedLanguage(languageParam)) {
      this.setLanguage(languageParam);
    } else if (languageStored) {
      this.setLanguage(languageStored);
    } else if (this.isInitializedLanguage(browserLanguage)) {
      this.setLanguage(browserLanguage);
    }
  }

  /**
   * Checks if given language has been initialized / is available
   * @private
   */
  private static isInitializedLanguage(lang: string) {
    return Object.keys(i18next.services.resourceStore.data).includes(lang);
  }

  /**
   * Store language locally, to persist between page loads/refreshs
   * @param lang language ID (e.g. "de")
   * @private
   */
  private static async saveLanguage(lang: string): Promise<boolean> {
    // store locally
    localStorage.setItem(localStorageKey, lang);

    return Promise.resolve(false);
  }
}

export default LanguageUtils;
