import { SoftwareOption } from '../types';
import i18next from 'i18next';

const t = i18next.getFixedT(null, 'common');

export const createSoftwareOption = (name: string, showOnProductNumber: boolean): SoftwareOption => {
  return {
    name,
    get label() {
      return t(`softwareOption.${this.name}.label`);
    },
    get description() {
      return t(`softwareOption.${this.name}.description`);
    },
    showOnProductNumber,
  };
};

export const qp1 = createSoftwareOption('QP1', true);
export const qp2 = createSoftwareOption('QP2', true);
export const qp3 = createSoftwareOption('QP3', true);
export const qp4 = createSoftwareOption('QP4', true);
export const qp99 = createSoftwareOption('QP99', true);
export const noSoftware = createSoftwareOption('none', false);

export const softwares: SoftwareOption[] = [qp1, qp2, qp3, qp4, qp99];

export const optionsForIndividualSoftwarePackage: SoftwareOption[] = [qp1, qp2, qp3, qp4];

export default softwares;
