import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_224s: WeighingModule = {
  name: '224S',
  scaleInterval: [0.1],
  maxCapacity: [220],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_224s;
