export const UC_WINDOW_EVENT_NAME = 'ucConsentStatus';

export const AZURE_APP_INSIGHTS_DPS = 'Azure Application Insights';
export const HOTJAR_DPS = 'Hotjar';

export const UC_UI_INITIALIZATION_EVENT_NAME = 'UC_UI_INITIALIZED';
export const UC_UI_INITIALIZATION_MAX_PERIOD = 5000;

export const UC_UI_CMP_EVENT = 'UC_UI_CMP_EVENT';
export const UC_UI_VIEW_CHANGED = 'UC_UI_VIEW_CHANGED';

export type UCServiceCustomEvent = {
  [HOTJAR_DPS]: boolean;
};

export type UCServices = (keyof UCServiceCustomEvent)[];

export interface UCServiceBaseInfo {
  name: string;
  consent: UCServiceBaseInfoConsent;
}

export interface UCServiceBaseInfoConsent {
  status: boolean;
}

export interface WindowHotjarSettings {
  hjid: string | number;
  hjsv: string | number;
  scriptSource?: string;
  contentHost?: string;
  wsHost?: string;
}

declare global {
  interface Window {
    UC_UI: {
      showSecondLayer: () => void;
      isInitialized: () => boolean;
      getServicesBaseInfo: () => UCServiceBaseInfo[];
    };
    _hjSettings: WindowHotjarSettings;
  }

  interface GlobalEventHandlersEventMap {
    [UC_WINDOW_EVENT_NAME]: CustomEvent<UCServiceCustomEvent>;
  }
}
