import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_106s: WeighingModule = {
  name: '10.6S',
  scaleInterval: [0.001],
  maxCapacity: [10.1],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_106s;
