import { CButton, CLabel, CTooltip } from '@coreui/react';
import React, { useState } from 'react';
import SelectionBox from './SelectionBox';
import IIcon from '../../assets/icons/i.svg';
import { ProductImage } from './ProductImage';
import { useTranslation } from 'react-i18next';
import ProductNumberButton from './ProductNumberButton';
import { Configuration, Product } from '../../data/types';
import { ModuleDetails } from '../../services/modules';
import { overrideMaterialNumber } from '../../data/products/utils';
import RecommendedIcon from '../../assets/icons/recommended.svg';
import FastDeliveryIcon from '../../assets/icons/fast-delivery.svg';
import { getProductImageUrl } from '../../data/images/utils';
import styles from './RecommendationSummary.module.scss';
import ArrowUpIcon from '../../assets/icons/arrow-up.svg';

interface RecommendationSummaryProps {
  recommendedConfiguration: Configuration | undefined;
  recommendedProduct: Product | undefined;
  recommendedModuleDetails: ModuleDetails | undefined;
  configuration: Configuration;
  moduleDetails: ModuleDetails | undefined;
}

const RecommendationSummary: React.FC<RecommendationSummaryProps> = ({
  recommendedConfiguration,
  recommendedModuleDetails,
  recommendedProduct,
  configuration,
  moduleDetails,
}): JSX.Element => {
  const [areDetailsVisible, setAreDetailsVisible] = useState(true);
  const { t } = useTranslation('common');
  const isMaxCapacityDifferent = moduleDetails?.maxCapacity !== recommendedModuleDetails?.maxCapacity;
  const isScaleIntervalDifferent = moduleDetails?.scaleInterval !== recommendedModuleDetails?.scaleInterval;
  const isDraftShieldDifferent = configuration.draftShield?.id !== recommendedConfiguration?.draftShield?.id;

  const productImageUrl = recommendedProduct?.materialNumber && getProductImageUrl(recommendedProduct.materialNumber);
  const imagePadding = productImageUrl ? undefined : '4.4svh';

  const renderAccessories = React.useCallback(() => {
    // the selected module has compatible accessories
    if (recommendedModuleDetails && recommendedModuleDetails.compatibleAccessories) {
      // additional accessories have been selected
      if (
        recommendedConfiguration &&
        recommendedConfiguration.accessories &&
        recommendedConfiguration.accessories.length > 0
      ) {
        return recommendedConfiguration.accessories.map((accessory) => (
          <div key={accessory.name} data-testid={`accessory-${accessory.name}`}>
            {accessory.label}
          </div>
        ));
      }
      // additional accessories have not been selected
      return (
        <div data-testid="accessory-notSelected" className="mb-2">
          {t(`selectionSummary.notSelected`)}
        </div>
      );
    }
    // the selected module does not support additional accessories
    return (
      <div data-testid="accessory-notAvailable" className="mb-2">
        {t(`selectionSummary.notAvailable`)}
      </div>
    );
  }, [recommendedConfiguration, recommendedModuleDetails, t]);

  return (
    <div className="bg-light text-dark px-3 py-4 mb-4">
      <h4 className="mb-2">
        <strong>{t(`selectionSummary.ourRecommendation`)}</strong>
        <div style={{ display: 'inline', float: 'right' }} className="mb-2">
          <CTooltip
            placement="top"
            content={`<div class="text-left">${t(`selectionSummary.recommendationTooltip`)}</div>`}
          >
            <img data-testid="recommendationTooltipTest" src={IIcon} width={20} height={20} alt="Info" />
          </CTooltip>
        </div>
      </h4>

      <div className="mb-4 font-2xl">
        <div style={{ zIndex: 1 }} className="px-1 py-1 text-light position-absolute bg-dark" data-testid="info">
          <img src={RecommendedIcon} width={24} height={24} alt="TODO:" />
          <strong>{t('selectionSummary.recommendationReason.advancedProduct')}</strong>
        </div>

        <div
          className="px-1 py-1 text-light position-absolute bg-dark"
          data-testid="info"
          style={{ marginTop: '4.4svh', zIndex: 1 }}
        >
          <img src={FastDeliveryIcon} width={24} height={24} alt="TODO:" />
          <strong>{t('selectionSummary.recommendationReason.fastDelivery')}</strong>
        </div>
        {recommendedProduct && <ProductImage productNumber={recommendedProduct.materialNumber} />}
      </div>
      {areDetailsVisible && (
        <>
          <SelectionBox title={t(`selectionSummary.step1.header`)}>
            <CLabel style={{ paddingTop: imagePadding }}>{t(`selectionSummary.step1.balance`)}</CLabel>
            <div data-testid="balance" className="mb-2">
              {recommendedModuleDetails ? recommendedModuleDetails.categoryName : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step1.maximumCapacity`)}</CLabel>
            <div data-testid="max-capacity" className="mb-2">
              {recommendedModuleDetails
                ? `${recommendedModuleDetails.maxCapacity} g`
                : t(`selectionSummary.notSelected`)}
              {isMaxCapacityDifferent && (
                <CTooltip placement="bottom" content={`<div class="text-left">Recommended option for you</div>`}>
                  <img
                    data-testid="maxCapTooltipTest"
                    src={IIcon}
                    style={{ marginLeft: '0.5rem' }}
                    width={20}
                    height={20}
                    alt="Info"
                  />
                </CTooltip>
              )}
            </div>
            <CLabel>{t(`selectionSummary.step1.scaleInterval`)}</CLabel>
            <div data-testid="scale-interval">
              {recommendedModuleDetails
                ? `${recommendedModuleDetails.scaleInterval} mg`
                : t(`selectionSummary.notSelected`)}
              {isScaleIntervalDifferent && (
                <CTooltip placement="bottom" content={`<div class="text-left">Recommended option for you</div>`}>
                  <img
                    data-testid="scaleTooltipTest"
                    src={IIcon}
                    style={{ marginLeft: '0.5rem' }}
                    width={20}
                    height={20}
                    alt="Info"
                  />
                </CTooltip>
              )}
            </div>
          </SelectionBox>

          <SelectionBox title={t(`selectionSummary.step2.header`)}>
            <CLabel>{t(`selectionSummary.step2.draftShield`)}</CLabel>
            <div data-testid="draft-shield" className="mb-2">
              {recommendedConfiguration?.draftShield
                ? recommendedConfiguration.draftShield.label
                : t(`selectionSummary.notSelected`)}
              {isDraftShieldDifferent && (
                <CTooltip placement="bottom" content={`<div class="text-left">Recommended option for you</div>`}>
                  <img
                    data-testid="draftShieldTooltipTest"
                    style={{ marginLeft: '0.5rem' }}
                    src={IIcon}
                    width={20}
                    height={20}
                    alt="Info"
                  />
                </CTooltip>
              )}
            </div>
          </SelectionBox>

          <SelectionBox title={t(`selectionSummary.step3.header`)}>
            <CLabel>{t(`selectionSummary.step3.userInterface`)}</CLabel>
            <div data-testid="user-interface" className="mb-2">
              {recommendedConfiguration?.displayModule
                ? recommendedConfiguration.displayModule.label
                : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step3.softwarePackages`)}</CLabel>
            <div data-testid="software-package" className="mb-2">
              {recommendedConfiguration?.displayModule && recommendedConfiguration.displayModule.name === 'MCE' && (
                <span data-testid={`software-package-standard`}>
                  {t(`displayModule.MCE.softwarePackages.standard`)}
                </span>
              )}
              {recommendedConfiguration?.displayModule && recommendedConfiguration.displayModule.name === 'MCA' && (
                <span>
                  {recommendedConfiguration.softwarePackage
                    ? `${recommendedConfiguration.softwarePackage.label} ${
                        recommendedConfiguration.softwarePackage.name !== 'none'
                          ? `(${recommendedConfiguration.softwarePackage.name})`
                          : ''
                      }`
                    : t(`selectionSummary.notSelected`)}
                </span>
              )}
            </div>
            {recommendedConfiguration?.hardwarePackage && (
              <>
                <CLabel>{t(`selectionSummary.step3.hardwareOptions`)}</CLabel>
                <span>
                  {recommendedConfiguration.hardwarePackage ? (
                    <div
                      key={recommendedConfiguration.hardwarePackage.name}
                      data-testid={`software-package-${recommendedConfiguration.hardwarePackage.name}`}
                    >
                      {recommendedConfiguration.hardwarePackage.label}
                    </div>
                  ) : (
                    <div>{t(`selectionSummary.notSelected`)}</div>
                  )}
                </span>
              </>
            )}
          </SelectionBox>

          <SelectionBox title={t(`selectionSummary.step4.header`)} borderBottom={false}>
            <CLabel>{t(`selectionSummary.step4.country`)}</CLabel>
            <div data-testid="country" className="mb-2">
              {recommendedConfiguration?.country
                ? t(`country.${recommendedConfiguration.country}`)
                : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step4.approval`)}</CLabel>
            <div data-testid="approval" className="mb-2">
              {recommendedConfiguration?.certificate
                ? recommendedConfiguration.certificate.label
                : t(`selectionSummary.notSelected`)}
            </div>
            <CLabel>{t(`selectionSummary.step4.accessories`)}</CLabel>
            {renderAccessories()}
            <CLabel>{t(`selectionSummary.step4.documentationsAndServices`)}</CLabel>
            <div>
              {recommendedModuleDetails && recommendedModuleDetails.documentationsAndServices
                ? t(`selectionSummary.notSelected`)
                : t(`selectionSummary.notAvailable`)}
            </div>
          </SelectionBox>

          <div style={{ marginTop: '4svh' }}>
            {recommendedProduct && (
              <ProductNumberButton productNumber={overrideMaterialNumber(recommendedProduct.materialNumber)} />
            )}
          </div>
        </>
      )}
      {!areDetailsVisible && <span className={styles.hiddenDetailsText}>{t('general.hiddenDetailsText')}</span>}

      <div className={styles.detailsButton}>
        <CButton
          variant="outline"
          color="dark"
          shape="square"
          block={true}
          className="border border-dark"
          onClick={() => {
            setAreDetailsVisible(!areDetailsVisible);
          }}
        >
          {t(areDetailsVisible ? 'general.hideDetails' : 'general.showDetails')}
          <img
            className={`${styles.arrow} ${areDetailsVisible ? '' : styles.arrowRotated}`}
            src={ArrowUpIcon}
            width={30}
            height={30}
            alt=""
            title=""
          />
        </CButton>
      </div>
    </div>
  );
};

export default RecommendationSummary;
