import { Industry } from '../types';
import {
  if0A,
  if0B,
  if1A,
  if1B,
  if1C,
  if1D,
  if1E,
  if1H,
  if1I,
  if1J,
  if1K,
  if1Z,
  if3A,
  if4A,
  if4C,
  if4E,
  if5A,
  if5B,
  if5F,
  if7A,
  if7B,
  if7C,
  if7D,
  if7E,
  if7F,
  if7G,
  if7H,
  if7I,
  if7J,
  if7K,
} from './industryFocus';

export const industries = new Map<string, Industry>([
  ['0 - Classical Pharma', { id: '0', industryFocus: [if0A, if0B] }],
  [
    '1 - Industrial Manufacturing & Services',
    { id: '1', industryFocus: [if1A, if1B, if1C, if1D, if1E, if1H, if1I, if1J, if1K, if1Z] },
  ],
  ['3 - Testing Labs', { id: '3', industryFocus: [if3A] }],
  ['4 - University & Academia & Research Centers', { id: '4', industryFocus: [if4A, if4C, if4E] }],
  ['5 - Distributors & Agencies', { id: '5', industryFocus: [if5A, if5B, if5F] }],
  [
    '7 - BioPharma & Biotech',
    { id: '7', industryFocus: [if7A, if7B, if7C, if7D, if7E, if7F, if7G, if7H, if7I, if7J, if7K] },
  ],
]);

export const vwrIndustries = new Map<string, string>([
  ['01', '01 BIOPHARM'],
  ['02', '02 HEALTH CARE'],
  ['03', '03 EDUCATION'],
  ['04', '04 GOVERNMENT'],
  ['05', '05 INDUSTRIAL'],
  ['06', '06 MICRO ELECTRONICS'],
  ['07', '07 RESELLER'],
  ['09', '09 MISCELLANEOUS'],
  ['10', '10 SERVICES'],
]);

export const vwrInterests = new Map<string, string>([
  ['1', '1 Research & Development'],
  ['2', '2 Production'],
  ['3', '3 Quality Control'],
  ['4', '4 Life Sciences'],
  ['5', '5 Food & Beverage Testing'],
  ['6', '6 Healthcare/Clinical'],
  ['8', '8 Controlled Environment / Cleanroom'],
  ['10', '10 Chromatography'],
  ['11', '11 Microbiology'],
  ['12', '12 Environment'],
  ['13', '13 Teaching'],
  ['14', '14 Service & Quality'],
]);
