import { CButton } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import { NavigationStepStatus } from '../../types/navigation';

interface NavigationButtonProps {
  onClick: () => void;
}

interface NavigationButtonNextProps extends NavigationButtonProps {
  label: string;
  currentlyEditedStepStatus: NavigationStepStatus;
  buttonNextClasses: string;
  disabled?: boolean;
}

export const NavigationButtonNext: React.FC<NavigationButtonNextProps> = ({
  label,
  onClick,
  currentlyEditedStepStatus,
  buttonNextClasses,
  disabled = false,
}): JSX.Element => {
  return (
    <CButton
      data-testid="next-button"
      shape="square"
      color={currentlyEditedStepStatus !== 'complete' ? 'dark' : 'primary'}
      variant={currentlyEditedStepStatus !== 'complete' ? 'outline' : ''}
      className={`${buttonNextClasses} ml-2`}
      disabled={disabled}
      onClick={(e: MouseEvent) => {
        onClick();
        (e.currentTarget as HTMLElement).blur();
      }}
    >
      {label} <img src={ArrowRightIcon} width={25} height={25} alt={label} title={label} />
    </CButton>
  );
};

export const NavigationButtonPrevious: React.FC<NavigationButtonProps> = ({ onClick }): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <CButton
      shape="square"
      color="dark"
      variant="outline"
      className="border border-dark"
      onClick={onClick}
      data-testid="back-button"
    >
      <img src={ArrowLeftIcon} width={25} height={25} alt="Back" title="Back" />
      {t('general.back')}
    </CButton>
  );
};
