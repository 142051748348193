import React, { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { configurationState, navigationStepStatusState } from '../../../states';
import { computeStatus, sendRequest } from '../../../services/requestQuote';
import { useHistory } from 'react-router-dom';
import { getProduct } from '../../../services/products';
import { FormDTO } from '../types';
import { sendGoogleTagManagerEvent } from '../../../services/googleTagManager';
import { QuoteRequestEvent } from '../../../types/tagManagerEvents';
import { Configuration, Product } from '../../../data/types';
import { NavigationStepStatus } from '../../../types/navigation';
import PlatformContext from '../../../context/PlatformContext';
import { QuoteApi, Configuration as ApiConfiguration } from '../../../api';
import env from '@beam-australia/react-env';
import { useTranslation } from 'react-i18next';

interface iUseRequestForm {
  formElement: React.MutableRefObject<HTMLFormElement | null>;
  product: Product | undefined;
  onSubmit: (data: FormDTO) => void;
  status: NavigationStepStatus;
}

export const useRequestForm = (
  setSubmitError: (value: boolean) => void,
  recommendedConfiguration?: Configuration,
  recommendedProduct?: Product,
): iUseRequestForm => {
  const formElement = useRef<HTMLFormElement | null>(null);
  const [navigation] = useRecoilState(navigationStepStatusState);
  const [configuration] = useRecoilState(configurationState);
  const context = React.useContext(PlatformContext);
  const status = computeStatus(navigation);
  const history = useHistory();
  const { i18n } = useTranslation('common');

  let product: Product | undefined;
  if (status === 'complete') {
    product = getProduct(configuration);
  }

  async function onSubmit(data: FormDTO) {

    try {
      const api = new QuoteApi(
        new ApiConfiguration({
          basePath: env('BACKEND_URL'),
          headers: { 'Ocp-Apim-Subscription-Key': env('APIM_SUBSCRIPTION') },
        }),
      );

      await sendRequest(
        data,
        configuration,
        product,
        context,
        api,
        i18n.language,
        recommendedConfiguration,
        recommendedProduct,
      );
    } catch {
      setSubmitError(true);
      return;
    }

    trackFormSuccess(data);
    history.push('/success');
  }

  const trackFormSuccess = (data: FormDTO) => {
    const sku = getProduct(configuration)?.materialNumber || '';
    const event: QuoteRequestEvent = {
      event: 'cubisCalculatorSubmit',
      'cubis-config-step': 5,
      'cubis-config-slug': 'Request a quote',
      'cubis-config-sku': sku,
      'cubis-config-quantity': data.units,
      'cubis-config-userTimelineOfPurchase': data.timelineOfPurchase,
      userInfo: {
        company: data.company,
        country: data.country,
        industry: data.industry,
        industryFocus: data.industryFocus,
        postalCode: data.zipcode,
        marketingOptIn: data.newsletter,
      },
    };

    sendGoogleTagManagerEvent(event);
  };

  return {
    formElement,
    product,
    onSubmit,
    status,
  };
};
