import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_623p: WeighingModule = {
  name: '623P',
  scaleInterval: [1, 2, 5],
  maxCapacity: [150, 300, 620],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_623p;
