import { CCol, CRow } from '@coreui/react';
import React, { useEffect } from 'react';
import RequestForm from './RequestForm';
import SelectionSummary from './SelectionSummary';
import { useRequestQuote } from './hooks/useRequestQuote';
import { trackPageImpression } from '../../services/googleTagManager';
import { useTranslation } from 'react-i18next';
import RecommendationSummary from './RecommendationSummary';
import { useSelectionSummary } from './hooks/useSelectionSummary';
import { useRecommendationSummary } from './hooks/useRecommendationSummary';
import { useRecoilState } from 'recoil';
import { isRecommendationAvailableState } from '../../states';
import PlatformContext from '../../context/PlatformContext';
import { QuoteRequestDTOContextEnum } from '../../context/QuoteRequestDTOContextEnum';
import styles from './RequestQuote.module.scss';

const RequestQuote: React.FC<Record<string, never>> = (): JSX.Element => {
  const platform = React.useContext(PlatformContext);
  const isSartorius = platform === QuoteRequestDTOContextEnum.Sartorius;

  const { goToStep, moduleDetails, configuration, product, onCompleteConfigurationClick } = useSelectionSummary();
  const { status } = useRequestQuote();
  const { recommendedConfiguration, recommendedProduct, recommendedModuleDetails } = useRecommendationSummary(
    configuration,
    status,
    isSartorius,
  );
  const { t } = useTranslation('common');
  const [isRecommendationAvailable, setIsRecommendationAvailable] =
    useRecoilState<boolean>(isRecommendationAvailableState);

  useEffect(() => {
    trackPageImpression(5);
  }, []);

  setIsRecommendationAvailable(
    (product && recommendedProduct && product.materialNumber !== recommendedProduct.materialNumber) ?? false,
  );

  return (
    <>
      {status !== 'complete' && (
        <p data-testid="subtitle" className="mb-5">
          {t(`requestQuote.notComplete`)}
        </p>
      )}
      <CRow>
        {!isRecommendationAvailable && (
          <CCol className={`${styles.rowStyle} ${styles.mobileBottom}`} lg={status === 'not_set' ? 12 : 8}>
            <RequestForm />
          </CCol>
        )}
        {status !== 'not_set' && (
          <>
            <CCol lg="4">
              <SelectionSummary
                configuration={configuration}
                goToStep={goToStep}
                moduleDetails={moduleDetails}
                onCompleteConfigurationClick={onCompleteConfigurationClick}
                product={product}
                isRecomended={isRecommendationAvailable}
              />
            </CCol>
            {isRecommendationAvailable && (
              <CCol lg="4">
                <RecommendationSummary
                  recommendedConfiguration={recommendedConfiguration}
                  recommendedModuleDetails={recommendedModuleDetails}
                  recommendedProduct={recommendedProduct}
                  configuration={configuration}
                  moduleDetails={moduleDetails}
                />
              </CCol>
            )}
          </>
        )}
        {isRecommendationAvailable && (
          <CCol lg="4">
            <RequestForm recommendedConfiguration={recommendedConfiguration} recommendedProduct={recommendedProduct} />
          </CCol>
        )}
      </CRow>
    </>
  );
};

export default RequestQuote;
