import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_36p: WeighingModule = {
  name: '3.6P',
  scaleInterval: [0.001, 0.002, 0.005],
  maxCapacity: [1.1, 2.1, 3.1],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_36p;
