import { CLabel, CSelect } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import countries from '../../data/countries';
import { useCountrySelect } from './hooks/useCountrySelection';
import { CountrySelectionProps } from './types';

const CountrySelection: React.FC<CountrySelectionProps> = ({ currentValue, onSelect }): JSX.Element => {
  const { handleSelect } = useCountrySelect({ onSelect });
  const { t } = useTranslation('common');

  return (
    <>
      <CLabel htmlFor="country">{t('countrySelection.label')}*</CLabel>
      <CSelect
        id="country"
        onChange={handleSelect}
        value={currentValue == undefined ? '' : currentValue}
        data-testid="country-select"
        className="data-hj-allow"
      >
        <option value="">{t('countrySelection.option')}</option>
        {countries.map((value) => (
          <option value={value} key={value}>
            {t(`country.${value}`)}
          </option>
        ))}
      </CSelect>
    </>
  );
};

export default CountrySelection;
