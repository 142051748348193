import { WeighingModule } from '../../types';
import { none } from '../../shields';
import { certificates } from '../../certificates';

const module_14202s: WeighingModule = {
  name: '14202S',
  scaleInterval: [10],
  maxCapacity: [14200],
  compatibleShields: [none],
  possibleCertificates: certificates,
};

export default module_14202s;
