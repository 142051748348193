import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { shields } from './defaults';

const module_36201s: WeighingModule = {
  name: '36201S',
  scaleInterval: [100],
  maxCapacity: [36200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_36201s;
