import { NavigationStepStatus, NavigationStepStatusState } from '../types/navigation';
import { getUtmParametersURL } from './utm';
import { FormDTO } from '../components/RequestQuote/types';
import { BalanceConfiguration, CustomerContactData, VWRCustomerContactData, QuoteApi } from '../api';
import { Configuration, Product } from '../data/types';
import { getCategoryByModule } from './modules';
import { getProductImageName } from '../data/images/utils';
import { QuoteRequestDTOContextEnum } from '../context/QuoteRequestDTOContextEnum';

export interface RequestQuoteResponse {
  status: 'OK' | 'ERROR';
  msg: string;
}

export function computeStatus(navigationStatus: NavigationStepStatusState): NavigationStepStatus {
  let status: NavigationStepStatus = 'incomplete';

  const allAreComplete = Object.values(navigationStatus).every((item) => item === 'complete');
  if (allAreComplete) {
    status = 'complete';
  }
  const allAreNotSet = Object.values(navigationStatus).every((item) => item === 'not_set');
  if (allAreNotSet) {
    status = 'not_set';
  }

  return status;
}

export const getBalanceConfiguration = (
  configuration: Configuration,
  product: Product | undefined,
): BalanceConfiguration => {
  return {
    country: configuration.country,
    // eslint-disable-next-line
    category: getCategoryByModule(configuration.module!),
    draftShield: configuration.draftShield,
    softwarePackage: configuration.softwarePackage,
    displayModule: configuration.displayModule,
    certificate: configuration.certificate,
    weighingModule: configuration.module,
    product: {
      materialNumber: product ? product.materialNumber : undefined,
      imageName: product ? getProductImageName(product.materialNumber) : undefined,
    },
    promoCode: configuration.promoCode,
    accessories: configuration.accessories,
    documentationAndServices: configuration.documentationsAndServices,
    hardwarePackage: configuration.hardwarePackage,
  };
};

export async function sendRequest(
  contactData: FormDTO,
  configuration: Configuration,
  product: Product | undefined,
  context: QuoteRequestDTOContextEnum,
  api: QuoteApi,
  selectedLanguage: string,
  recommendedConfiguration?: Configuration,
  recommendedProduct?: Product,
): Promise<void> {
  const balanceConfiguration: BalanceConfiguration = getBalanceConfiguration(configuration, product);
  let recommendedBalanceConfiguration: BalanceConfiguration | undefined = undefined;

  if (recommendedConfiguration && recommendedProduct) {
    recommendedBalanceConfiguration = getBalanceConfiguration(recommendedConfiguration, recommendedProduct);
  }

  if (context === QuoteRequestDTOContextEnum.Sartorius) {
    const customerContactData: CustomerContactData = {
      ...contactData,
      utmTrackingUrl: getUtmParametersURL(),
    };

    if (recommendedBalanceConfiguration) {
      return api.requestQuote({
        quoteRequestDTO: { customerContactData, balanceConfiguration, recommendedBalanceConfiguration },
      });
    } else {
      return api.requestQuote({ quoteRequestDTO: { customerContactData, balanceConfiguration } });
    }
  } else if (context === QuoteRequestDTOContextEnum.Vwr) {
    const customerContactData: VWRCustomerContactData = {
      ...contactData,
      utmTrackingUrl: getUtmParametersURL(),
    };

    return api.requestVWRQuote({ vWRQuoteRequestDTO: { customerContactData, balanceConfiguration, selectedLanguage } });
  } else if (context === QuoteRequestDTOContextEnum.Tf) {
    const customerContactData: CustomerContactData = {
      ...contactData,
      utmTrackingUrl: getUtmParametersURL(),
    };

    return api.requestTfQuote({ tFQuoteRequestDTO: { customerContactData, balanceConfiguration, selectedLanguage } });
  }
}
