import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { shields } from './defaults';

const module_36201p: WeighingModule = {
  name: '36201P',
  scaleInterval: [100, 1000],
  maxCapacity: [10200, 36200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_36201p;
