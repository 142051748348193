import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CTooltip } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IIcon from '../../assets/icons/i.svg';
import ImageWithModal from '../ImageWithModal';
import UndoSelection from '../UndoSelection';

export interface AccessorySelectionItemDTO {
  name: string;
  label: string;
  description: string;
  imageURL: string;
  selected: boolean;
  lastInRow: boolean;
}

interface AccessorySelectionItemProps {
  itemData: AccessorySelectionItemDTO;
  onAccessorySelect: () => void;
  onUndoSelection: () => void;
}

const AccessorySelectionItem: React.FC<AccessorySelectionItemProps> = (
  props: AccessorySelectionItemProps,
): JSX.Element => {
  const {
    itemData: { name, label, description, imageURL, selected, lastInRow },
    onAccessorySelect,
    onUndoSelection,
  } = props;

  const backgroundColor = selected ? 'primary' : 'light';
  const canBeSelected = !selected;
  const { t } = useTranslation('common');

  return (
    <CCard data-testid={`${name}-card`} className={`bg-${backgroundColor} text-dark mb-5 ${!lastInRow ? 'mr-5' : ''}`}>
      <CCardHeader className={`bg-${backgroundColor} text-dark`}>
        <strong className="mr-1">{label}</strong>
        <CTooltip content={<div className="text-left">{description}</div>}>
          <img src={IIcon} width={20} height={20} />
        </CTooltip>
      </CCardHeader>
      <CCardBody className="text-center pb-2">
        <ImageWithModal src={imageURL} srcFullsize={imageURL} fluidGrow={true} muted={false} />
      </CCardBody>
      <CCardFooter className={`bg-${backgroundColor} text-dark`}>
        {canBeSelected && (
          <CButton
            data-testid={`${name}-button`}
            variant="outline"
            color="dark"
            shape="square"
            block={true}
            className="border border-dark"
            onClick={onAccessorySelect}
          >
            {t('general.select')}
          </CButton>
        )}
        {selected && <UndoSelection onUndoSelection={onUndoSelection} />}
      </CCardFooter>
    </CCard>
  );
};

export default AccessorySelectionItem;
