import { CButton, CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';
import React, { ReactElement, useState } from 'react';
import './HelpTextButton.scss';

interface props {
  title: string;
  content: ReactElement;
}

const HelpTextButton: React.FC<props> = ({ title, content }): JSX.Element => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="position-relative">
      <CButton
        color={show ? 'secondary-active' : 'secondary'}
        onClick={toggleShow}
        className="help-button"
        style={{ right: 0, top: 0, padding: 0, zIndex: 2 }}
        data-testid="help-button"
      >
        <i className="icon-up" />
        <span className="help-text">Need help?</span>
      </CButton>
      <CModal show={show} onClose={toggleShow} centered={true} size="lg" scrollable>
        <CModalHeader data-testid="help-modal-header" closeButton>
          <CModalTitle data-testid="help-modal-title">{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{content}</CModalBody>
      </CModal>
    </div>
  );
};

export default HelpTextButton;
