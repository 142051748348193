/* istanbul ignore file */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import { interceptUtmParameters } from './services/utm';
import { NavigationEvent } from './types/tagManagerEvents';
import TagManager from 'react-gtm-module';
import env from '@beam-australia/react-env';
import LanguageUtils from './utils/LanguageUtils';

declare global {
  interface Window {
    dataLayer: (Gtag.EventParams | NavigationEvent)[];
  }
}

const tagManagerArgs = {
  gtmId: env('GOOGLE_TAG_MANAGER_ID'),
};

LanguageUtils.initI18n();
TagManager.initialize(tagManagerArgs);

interceptUtmParameters(window.location);

ReactDOM.render(
  <React.StrictMode>
    <Router basename="/Cubis-II-Configurator">
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
