import { CFormGroup, CLabel, CSelect, CTooltip } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import IIcon from '../../assets/icons/i.svg';
import { SoftwareOption, SoftwarePackage } from '../../data/types';
import { configurationState } from '../../states';

interface SoftwarePackageSelectionProps {
  packages: SoftwarePackage[];
  displayModule: string;
  disabled: boolean;
}

const SoftwarePackageSelection: React.FC<SoftwarePackageSelectionProps> = ({ packages, disabled }) => {
  const [config, setConfig] = useRecoilState(configurationState);
  const selectedPackage = config.softwarePackage;
  const selectedSoftwareOptions = config.softwareOptions?.map(({ name }) => name) ?? [];
  const { t } = useTranslation('common');

  function onPackageSelect({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    let newConfig = {};

    // if the selected package is different than the last selected one we reset the software choices to avoid keeping data from multiple packages in the config object
    if (selectedPackage && value !== selectedPackage.name) {
      newConfig = {
        ...config,
        softwareOptions: value === 'QP99' ? undefined : [],
        softwarePackage: packages.find((item) => item.name === value),
      };
    } else {
      newConfig = {
        ...config,
        softwareOptions: value === 'QP99' ? undefined : config.softwareOptions,
        softwarePackage: packages.find((item) => item.name === value),
      };
    }
    setConfig(newConfig);
  }

  function onSoftwareOptionSelect({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (!selectedPackage) {
      return;
    }
    const selectedOption = selectedPackage.options.find(({ name }) => name === target.id);
    if (!selectedOption) {
      return;
    }

    const currentlySelectedOptions = config.softwareOptions ?? [];
    let updatedSoftwareOptions: SoftwareOption[] = [];

    if (target.checked) {
      updatedSoftwareOptions = [...currentlySelectedOptions, selectedOption];
    } else {
      updatedSoftwareOptions = currentlySelectedOptions.filter(({ name }) => name !== target.id);
    }

    setConfig({
      ...config,
      softwareOptions: updatedSoftwareOptions,
    });
  }

  return (
    <CFormGroup className="mt-4">
      <CLabel className="font-3xl font-weight-bold text-dark" htmlFor="software-package">
        {t('softwarePackageSelection.label')}
      </CLabel>
      <CSelect
        id="software-package"
        data-testid="software-package"
        onChange={onPackageSelect}
        value={selectedPackage?.name}
        disabled={disabled}
        className="data-hj-allow"
      >
        <option value="0">{t('softwarePackageSelection.selectSoftwarePackage')}</option>
        {packages.map(({ name, label }) => (
          <option value={name} key={name} data-testid={`software-package-${name}`}>
            {label}
          </option>
        ))}
      </CSelect>
      {selectedPackage && selectedPackage.options.length > 0 && (
        <>
          <CLabel htmlFor="software-package" className="pt-4 pb-1">
            {t('softwarePackageSelection.selectAppPackages')}
          </CLabel>
          {selectedPackage.options.map((option) => (
            <div className="form-check custom-checkbox software-selection-checkbox" key={option.name}>
              <input
                className="custom-control-input"
                type="checkbox"
                value={option.name}
                id={option.name}
                name={option.name}
                checked={selectedSoftwareOptions.includes(option.name)}
                onChange={onSoftwareOptionSelect}
                data-testid={`software-option.${option.name}`}
              />
              <label className="form-check-label custom-control-label ml-2 text-dark mb-1" htmlFor={option.name}>
                <span className="mr-1">
                  {option.label} ({option.name}){' '}
                </span>
                <CTooltip content={option.description}>
                  <img src={IIcon} width={20} height={20} />
                </CTooltip>
              </label>
            </div>
          ))}
        </>
      )}
    </CFormGroup>
  );
};

export default SoftwarePackageSelection;
