import { CCol, CRow } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AttentionIcon from '../../assets/icons/attention.svg';
import { NOTIFIER_TYPE_SERVICE } from './DocumentationAndServiceSelectionList';

interface IncompleteStepsNotifierProps {
  type?: string;
}

const IncompleteStepsNotifier: React.FC<IncompleteStepsNotifierProps> = (props: IncompleteStepsNotifierProps) => {
  const { type } = props;
  const { t } = useTranslation('common');
  return (
    <>
      <CRow className="mb-4" data-testid="complete-other-steps-first">
        <CCol lg="12">
          <img src={AttentionIcon} className="mr-2" alt="attention"/>
          {type && type === NOTIFIER_TYPE_SERVICE
            ? t('documentationAndServiceSelection.incompleteSteps')
            : t('accessorySelection.incompleteSteps')}
        </CCol>
      </CRow>
    </>
  );
};

export default IncompleteStepsNotifier;
