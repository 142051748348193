/* eslint-disable */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { HOTJAR_DPS, UCServiceCustomEvent, UCServices, UC_WINDOW_EVENT_NAME } from './types';
import Hotjar from './Hotjar';

const UserCentricsManager = (): JSX.Element => {
  const supportedServices: UCServices = useMemo(() => [HOTJAR_DPS], []);

  const [UCInitialCheckDone, setUCInitialCheckDone] = useState<boolean>(false);
  const [UCInitialized, setUCInitialized] = useState<boolean>(false);
  const [servicesConsent, setServicesConsent] = useState<UCServiceCustomEvent>({
    [HOTJAR_DPS]: false,
  });

  const isUCInitialized = useCallback((): boolean => {
    const isInitialized = window.UC_UI !== undefined && window.UC_UI.isInitialized();
    setUCInitialized(isInitialized);
    return isInitialized;
  }, []);

  const addUCWindowEventListenerForUserConsentStatusUpdates = useCallback((): void => {
    window.addEventListener(UC_WINDOW_EVENT_NAME, (e) => {
      const { detail } = e;

      const consentsCopy = { ...servicesConsent };

      supportedServices.forEach((serv) => {
        if (!(detail === undefined || detail[serv] === undefined)) {
          consentsCopy[serv] = detail[serv];
        }
      });

      setServicesConsent(consentsCopy);
    });
  }, [servicesConsent, supportedServices]);

  const removeWindowEventListenerForUserConsentStatusUpdates = useCallback((): void => {
    window.removeEventListener(UC_WINDOW_EVENT_NAME, () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    });
  }, []);

  useEffect(() => {
    addUCWindowEventListenerForUserConsentStatusUpdates();

    return removeWindowEventListenerForUserConsentStatusUpdates();
  }, [addUCWindowEventListenerForUserConsentStatusUpdates, removeWindowEventListenerForUserConsentStatusUpdates]);

  const checkCurrentUserUCConsentStatusOnLoad = useCallback(() => {
    if (!UCInitialCheckDone && isUCInitialized()) {
      const services = window.UC_UI.getServicesBaseInfo();

      const consentsCopy = { ...servicesConsent };

      supportedServices.forEach((serv) => {
        const serviceDPS = services.find((s) => s.name === serv);

        if (serviceDPS !== undefined) {
          consentsCopy[serv] = serviceDPS.consent?.status;
        }
      });

      setServicesConsent(consentsCopy);
      setUCInitialCheckDone(true);
    }
  }, [UCInitialCheckDone, isUCInitialized, servicesConsent, supportedServices]);

  useEffect(() => {
    checkCurrentUserUCConsentStatusOnLoad();
  }, [checkCurrentUserUCConsentStatusOnLoad, UCInitialized]);

  return (
    <>
      <Hotjar key={HOTJAR_DPS} consentStatus={servicesConsent[HOTJAR_DPS]} />
    </>
  );
};

export default UserCentricsManager;
