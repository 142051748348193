import React from 'react';
import { useTranslation } from 'react-i18next';

const CountryApprovalHelpText: React.FC = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div>
      <p>{t('helpText.step4.description')}</p>
      <br />
      <p>{t('helpText.step4.p1')}</p>
      <p>{t('helpText.step4.p2')}</p>
      <p>{t('helpText.step4.p3')}</p>
      <p>{t('helpText.step4.p4')}</p>
      <p>{t('helpText.step4.p5')}</p>
      <p>{t('helpText.step4.p6')}</p>
      <p>{t('helpText.step4.p7')}</p>
      <p>{t('helpText.step4.p8')}</p>
      <p>{t('helpText.step4.p9')}</p>
      <p>{t('helpText.step4.p10')}</p>
      <p>{t('helpText.step4.p11')}</p>
      <p>{t('helpText.step4.p12')}</p>
    </div>
  );
};

export default CountryApprovalHelpText;
