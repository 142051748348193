import { useRecoilState } from 'recoil';
import { navigationStepStatusState } from '../../../states';
import { computeStatus } from '../../../services/requestQuote';
import { useEffect } from 'react';
import { NavigationStepStatusState } from '../../../types/navigation';
import { UseRequestQuote } from '../types';

export const useRequestQuote = (): UseRequestQuote => {
  const [navigation, setNavigation] = useRecoilState(navigationStepStatusState);
  const status = computeStatus(navigation);

  useEffect(() => {
    if (status !== 'not_set') {
      // when the user opens the summary page all not set steps become incomplete
      const updatedNavigationStepsStatus: NavigationStepStatusState = {
        ...navigation,
        step1: navigation.step1 === 'not_set' ? 'incomplete' : navigation.step1,
        step2: navigation.step2 === 'not_set' ? 'incomplete' : navigation.step2,
        step3: navigation.step3 === 'not_set' ? 'incomplete' : navigation.step3,
        step4: navigation.step4 === 'not_set' ? 'incomplete' : navigation.step4,
      };

      setNavigation(updatedNavigationStepsStatus);
    }
    // eslint-disable-next-line
  }, []);

  return {
    status,
    navigation,
  };
};
