import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

const LanguageRedirect = (): JSX.Element => {
  const location = useLocation();
  const { i18n } = useTranslation('common');

  const url = location.pathname + location.search ?? '/';
  return <Redirect to={i18n.language + url} />;
};

export default LanguageRedirect;
