import React from 'react';
import { useTranslation } from 'react-i18next';

const ModuleSelectionHelpText: React.FC = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div>
      <p>{t('helpText.step1.description')}</p>
      <br />
      <p className="help-text-paragraph-title">{t('helpText.step1.p1.title')}:</p>
      <ul>
        <li>{t('helpText.step1.p1.item1')}</li>
        <li>{t('helpText.step1.p1.item2')}</li>
        <li>{t('helpText.step1.p1.item3')}</li>
      </ul>
      <p className="help-text-paragraph-title">{t('helpText.step1.p2.title')}:</p>
      <ul>
        <li>{t('helpText.step1.p2.item1')}</li>
        <li>{t('helpText.step1.p2.item2')}</li>
        <li>{t('helpText.step1.p2.item3')}</li>
      </ul>
      <p className="help-text-paragraph-title">{t('helpText.step1.p3.title')}:</p>
      <ul>
        <li>{t('helpText.step1.p3.item1')}</li>
        <li>{t('helpText.step1.p3.item2')}</li>
        <li>{t('helpText.step1.p3.item3')}</li>
        <li>{t('helpText.step1.p3.item4')}</li>
      </ul>
      <p className="help-text-paragraph-title">{t('helpText.step1.p4.title')}:</p>
      <ul>
        <li>{t('helpText.step1.p4.item1')}</li>
        <li>{t('helpText.step1.p4.item2')}</li>
        <li>{t('helpText.step1.p4.item3')}</li>
        <li>{t('helpText.step1.p4.item4')}</li>
      </ul>
      <p className="help-text-paragraph-title">{t('helpText.step1.p5.title')}:</p>
      <ul>
        <li>{t('helpText.step1.p5.item1')}</li>
        <li>{t('helpText.step1.p5.item2')}</li>
      </ul>
    </div>
  );
};

export default ModuleSelectionHelpText;
