/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationError,
  RatingDTO,
} from '../models/index';
import {
    ApplicationErrorFromJSON,
    ApplicationErrorToJSON,
    RatingDTOFromJSON,
    RatingDTOToJSON,
} from '../models/index';

export interface SendRatingRequest {
    ratingDTO: RatingDTO;
}

/**
 * 
 */
export class RatingApi extends runtime.BaseAPI {

    /**
     * Sends a user rating as a mail
     */
    async sendRatingRaw(requestParameters: SendRatingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ratingDTO === null || requestParameters.ratingDTO === undefined) {
            throw new runtime.RequiredError('ratingDTO','Required parameter requestParameters.ratingDTO was null or undefined when calling sendRating.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ratings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RatingDTOToJSON(requestParameters.ratingDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends a user rating as a mail
     */
    async sendRating(requestParameters: SendRatingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendRatingRaw(requestParameters, initOverrides);
    }

}
