/* tslint:disable */
/* eslint-disable */
/**
 * Quote Request API
 * API for requesting quotes via Cubis Configurator
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BalanceConfiguration } from './BalanceConfiguration';
import {
    BalanceConfigurationFromJSON,
    BalanceConfigurationFromJSONTyped,
    BalanceConfigurationToJSON,
} from './BalanceConfiguration';
import type { VWRCustomerContactData } from './VWRCustomerContactData';
import {
    VWRCustomerContactDataFromJSON,
    VWRCustomerContactDataFromJSONTyped,
    VWRCustomerContactDataToJSON,
} from './VWRCustomerContactData';

/**
 * 
 * @export
 * @interface VWRQuoteRequestDTO
 */
export interface VWRQuoteRequestDTO {
    /**
     * 
     * @type {VWRCustomerContactData}
     * @memberof VWRQuoteRequestDTO
     */
    customerContactData: VWRCustomerContactData;
    /**
     * 
     * @type {BalanceConfiguration}
     * @memberof VWRQuoteRequestDTO
     */
    balanceConfiguration?: BalanceConfiguration;
    /**
     * The selected language for the UI by the user
     * @type {string}
     * @memberof VWRQuoteRequestDTO
     */
    selectedLanguage: string;
}

/**
 * Check if a given object implements the VWRQuoteRequestDTO interface.
 */
export function instanceOfVWRQuoteRequestDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerContactData" in value;
    isInstance = isInstance && "selectedLanguage" in value;

    return isInstance;
}

export function VWRQuoteRequestDTOFromJSON(json: any): VWRQuoteRequestDTO {
    return VWRQuoteRequestDTOFromJSONTyped(json, false);
}

export function VWRQuoteRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VWRQuoteRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerContactData': VWRCustomerContactDataFromJSON(json['customerContactData']),
        'balanceConfiguration': !exists(json, 'balanceConfiguration') ? undefined : BalanceConfigurationFromJSON(json['balanceConfiguration']),
        'selectedLanguage': json['selectedLanguage'],
    };
}

export function VWRQuoteRequestDTOToJSON(value?: VWRQuoteRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerContactData': VWRCustomerContactDataToJSON(value.customerContactData),
        'balanceConfiguration': BalanceConfigurationToJSON(value.balanceConfiguration),
        'selectedLanguage': value.selectedLanguage,
    };
}

