import { CButton, CCard, CCardBody, CCol, CFormGroup, CRow } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import ConfigurationWarning, { computeProceedToStepTitle } from '../ConfigurationWarning';
import AccessorySelectionList from './AccessorySelectionList';
import HelpTextButton from '../HelpTextButton';
import CertificateSelection from './CertificateSelection';
import CountryApprovalHelpText from './CountryApprovalHelpText';
import CountrySelection from './CountrySelection';
import { useCountryApprovalSelection } from './hooks/useCountryApprovalSelection';
import DocumentationAndServiceSelectionList from './DocumentationAndServiceSelectionList';

const CountryApprovalAccessorySelection: React.FC<Record<string, never>> = (): JSX.Element => {
  const { t } = useTranslation('common');
  const {
    certificatesForCountry,
    config,
    confirmWarning,
    goToClickedRoute,
    handleRouteExit,
    onCountrySelect,
    onCertificateSelect,
    setConfirmWarning,
    targetLocation,
  } = useCountryApprovalSelection();

  const configurationWarningKey = computeProceedToStepTitle(targetLocation?.pathname ?? '');
  const configurationWarningLabel = t(configurationWarningKey);
  return (
    <>
      <HelpTextButton title={t('helpText.step4.heading')} content={<CountryApprovalHelpText />} />
      <CRow>
        <CCol lg="12">
          <CCard data-testid="configuration-card" className="bg-light text-dark p-0">
            <CCardBody>
              <CFormGroup className="mt-2">
                <CRow className="mt-4">
                  <CCol>
                    <CountrySelection currentValue={config.country} onSelect={onCountrySelect} />
                  </CCol>
                  <CCol>
                    <CertificateSelection
                      options={certificatesForCountry}
                      enabled={!!config.country && certificatesForCountry.length > 0}
                      currentValue={config.certificate?.name}
                      onSelect={onCertificateSelect}
                    />
                  </CCol>
                </CRow>
              </CFormGroup>
            </CCardBody>
          </CCard>
          <AccessorySelectionList />
          <DocumentationAndServiceSelectionList />
        </CCol>
      </CRow>
      <Prompt message={handleRouteExit} />
      <ConfigurationWarning
        title={t('configurationWarning.incompleteConfiguration')}
        show={confirmWarning}
        buttons={
          <>
            <CButton
              color="primary"
              block={true}
              className="mb-3"
              onClick={() => {
                setConfirmWarning(false);
              }}
              data-testid="complete-configuration"
            >
              {t('configurationWarning.completeConfiguration')}{' '}
            </CButton>
            <CButton
              shape="square"
              color="dark"
              variant="outline"
              className="border border-dark"
              block={true}
              onClick={goToClickedRoute}
              data-testid="configuration-warning-proceed"
            >
              {configurationWarningLabel}
              <img
                src={ArrowRightIcon}
                width={20}
                height={20}
                alt={configurationWarningLabel}
                title={configurationWarningLabel}
              />
            </CButton>
          </>
        }
        onClose={() => {
          setConfirmWarning(false);
        }}
      >
        <p>
          <small>{t('configurationWarning.incompleteCountryApprovalConfiguration')}</small>
        </p>
      </ConfigurationWarning>
    </>
  );
};

export default CountryApprovalAccessorySelection;
