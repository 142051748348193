interface CountryStateCodes {
  [key: string]: { name: string; code: string }[];
}

export const countryStateCodes: CountryStateCodes = {
  AD: [
    {
      name: 'Canillo',
      code: 'AD-02',
    },
    {
      name: 'Encamp',
      code: 'AD-03',
    },
    {
      name: 'La Massana',
      code: 'AD-04',
    },
    {
      name: 'Ordino',
      code: 'AD-05',
    },
    {
      name: 'Sant Julià de Lòria',
      code: 'AD-06',
    },
    {
      name: 'Andorra la Vella',
      code: 'AD-07',
    },
    {
      name: 'Escaldes-Engordany',
      code: 'AD-08',
    },
  ],
  AE: [
    {
      name: '‘Ajmān',
      code: 'AE-AJ',
    },
    {
      name: 'Abū Z̧aby',
      code: 'AE-AZ',
    },
    {
      name: 'Dubayy',
      code: 'AE-DU',
    },
    {
      name: 'Al Fujayrah',
      code: 'AE-FU',
    },
    {
      name: 'Ra’s al Khaymah',
      code: 'AE-RK',
    },
    {
      name: 'Ash Shāriqah',
      code: 'AE-SH',
    },
    {
      name: 'Umm al Qaywayn',
      code: 'AE-UQ',
    },
  ],
  AF: [
    {
      name: 'Balkh',
      code: 'AF-BAL',
    },
    {
      name: 'Bāmyān',
      code: 'AF-BAM',
    },
    {
      name: 'Bādghīs',
      code: 'AF-BDG',
    },
    {
      name: 'Badakhshān',
      code: 'AF-BDS',
    },
    {
      name: 'Baghlān',
      code: 'AF-BGL',
    },
    {
      name: 'Dāykundī',
      code: 'AF-DAY',
    },
    {
      name: 'Farāh',
      code: 'AF-FRA',
    },
    {
      name: 'Fāryāb',
      code: 'AF-FYB',
    },
    {
      name: 'Ghaznī',
      code: 'AF-GHA',
    },
    {
      name: 'Ghōr',
      code: 'AF-GHO',
    },
    {
      name: 'Helmand',
      code: 'AF-HEL',
    },
    {
      name: 'Herāt',
      code: 'AF-HER',
    },
    {
      name: 'Jowzjān',
      code: 'AF-JOW',
    },
    {
      name: 'Kābul',
      code: 'AF-KAB',
    },
    {
      name: 'Kandahār',
      code: 'AF-KAN',
    },
    {
      name: 'Kāpīsā',
      code: 'AF-KAP',
    },
    {
      name: 'Kunduz',
      code: 'AF-KDZ',
    },
    {
      name: 'Khōst',
      code: 'AF-KHO',
    },
    {
      name: 'Kunaṟ',
      code: 'AF-KNR',
    },
    {
      name: 'Laghmān',
      code: 'AF-LAG',
    },
    {
      name: 'Lōgar',
      code: 'AF-LOG',
    },
    {
      name: 'Nangarhār',
      code: 'AF-NAN',
    },
    {
      name: 'Nīmrōz',
      code: 'AF-NIM',
    },
    {
      name: 'Nūristān',
      code: 'AF-NUR',
    },
    {
      name: 'Panjshayr',
      code: 'AF-PAN',
    },
    {
      name: 'Parwān',
      code: 'AF-PAR',
    },
    {
      name: 'Paktiyā',
      code: 'AF-PIA',
    },
    {
      name: 'Paktīkā',
      code: 'AF-PKA',
    },
    {
      name: 'Samangān',
      code: 'AF-SAM',
    },
    {
      name: 'Sar-e Pul',
      code: 'AF-SAR',
    },
    {
      name: 'Takhār',
      code: 'AF-TAK',
    },
    {
      name: 'Uruzgān',
      code: 'AF-URU',
    },
    {
      name: 'Wardak',
      code: 'AF-WAR',
    },
    {
      name: 'Zābul',
      code: 'AF-ZAB',
    },
  ],
  AL: [
    {
      name: 'Berat',
      code: 'AL-01',
    },
    {
      name: 'Durrës',
      code: 'AL-02',
    },
    {
      name: 'Elbasan',
      code: 'AL-03',
    },
    {
      name: 'Fier',
      code: 'AL-04',
    },
    {
      name: 'Gjirokastër',
      code: 'AL-05',
    },
    {
      name: 'Korçë',
      code: 'AL-06',
    },
    {
      name: 'Kukës',
      code: 'AL-07',
    },
    {
      name: 'Lezhë',
      code: 'AL-08',
    },
    {
      name: 'Dibër',
      code: 'AL-09',
    },
    {
      name: 'Shkodër',
      code: 'AL-10',
    },
    {
      name: 'Tiranë',
      code: 'AL-11',
    },
    {
      name: 'Vlorë',
      code: 'AL-12',
    },
  ],
  AM: [
    {
      name: 'Armagh',
      code: 'AM',
    },
  ],
  AO: [
    {
      name: 'Bengo',
      code: 'AO-BGO',
    },
    {
      name: 'Benguela',
      code: 'AO-BGU',
    },
    {
      name: 'Bié',
      code: 'AO-BIE',
    },
    {
      name: 'Cabinda',
      code: 'AO-CAB',
    },
    {
      name: 'Kuando Kubango',
      code: 'AO-CCU',
    },
    {
      name: 'Cunene',
      code: 'AO-CNN',
    },
    {
      name: 'Kwanza Norte',
      code: 'AO-CNO',
    },
    {
      name: 'Kwanza Sul',
      code: 'AO-CUS',
    },
    {
      name: 'Huambo',
      code: 'AO-HUA',
    },
    {
      name: 'Huíla',
      code: 'AO-HUI',
    },
    {
      name: 'Lunda Norte',
      code: 'AO-LNO',
    },
    {
      name: 'Lunda Sul',
      code: 'AO-LSU',
    },
    {
      name: 'Luanda',
      code: 'AO-LUA',
    },
    {
      name: 'Malange',
      code: 'AO-MAL',
    },
    {
      name: 'Moxico',
      code: 'AO-MOX',
    },
    {
      name: 'Namibe',
      code: 'AO-NAM',
    },
    {
      name: 'Uíge',
      code: 'AO-UIG',
    },
    {
      name: 'Zaire',
      code: 'AO-ZAI',
    },
  ],
  AR: [
    {
      name: 'Salta',
      code: 'AR-A',
    },
    {
      name: 'Buenos Aires',
      code: 'AR-B',
    },
    {
      name: 'San Luis',
      code: 'AR-D',
    },
    {
      name: 'Entre Ríos',
      code: 'AR-E',
    },
    {
      name: 'La Rioja',
      code: 'AR-F',
    },
    {
      name: 'Santiago del Estero',
      code: 'AR-G',
    },
    {
      name: 'Chaco',
      code: 'AR-H',
    },
    {
      name: 'San Juan',
      code: 'AR-J',
    },
    {
      name: 'Catamarca',
      code: 'AR-K',
    },
    {
      name: 'La Pampa',
      code: 'AR-L',
    },
    {
      name: 'Mendoza',
      code: 'AR-M',
    },
    {
      name: 'Misiones',
      code: 'AR-N',
    },
    {
      name: 'Formosa',
      code: 'AR-P',
    },
    {
      name: 'Neuquén',
      code: 'AR-Q',
    },
    {
      name: 'Río Negro',
      code: 'AR-R',
    },
    {
      name: 'Santa Fe',
      code: 'AR-S',
    },
    {
      name: 'Tucumán',
      code: 'AR-T',
    },
    {
      name: 'Chubut',
      code: 'AR-U',
    },
    {
      name: 'Tierra del Fuego',
      code: 'AR-V',
    },
    {
      name: 'Corrientes',
      code: 'AR-W',
    },
    {
      name: 'Córdoba',
      code: 'AR-X',
    },
    {
      name: 'Jujuy',
      code: 'AR-Y',
    },
    {
      name: 'Santa Cruz',
      code: 'AR-Z',
    },
  ],
  AT: [
    {
      name: 'Burgenland',
      code: 'AT-1',
    },
    {
      name: 'Kärnten',
      code: 'AT-2',
    },
    {
      name: 'Niederösterreich',
      code: 'AT-3',
    },
    {
      name: 'Oberösterreich',
      code: 'AT-4',
    },
    {
      name: 'Salzburg',
      code: 'AT-5',
    },
    {
      name: 'Steiermark',
      code: 'AT-6',
    },
    {
      name: 'Tirol',
      code: 'AT-7',
    },
    {
      name: 'Vorarlberg',
      code: 'AT-8',
    },
    {
      name: 'Wien',
      code: 'AT-9',
    },
  ],
  AU: [
    {
      name: 'Australian Capital Territory',
      code: 'AU-ACT',
    },
    {
      name: 'New South Wales',
      code: 'AU-NSW',
    },
    {
      name: 'Northern Territory',
      code: 'AU-NT',
    },
    {
      name: 'Queensland',
      code: 'AU-QLD',
    },
    {
      name: 'South Australia',
      code: 'AU-SA',
    },
    {
      name: 'Tasmania',
      code: 'AU-TAS',
    },
    {
      name: 'Victoria',
      code: 'AU-VIC',
    },
    {
      name: 'Western Australia',
      code: 'AU-WA',
    },
  ],
  AZ: [
    {
      name: 'Abşeron',
      code: 'AZ-ABS',
    },
    {
      name: 'Ağstafa',
      code: 'AZ-AGA',
    },
    {
      name: 'Ağcabədi',
      code: 'AZ-AGC',
    },
    {
      name: 'Ağdam',
      code: 'AZ-AGM',
    },
    {
      name: 'Ağdaş',
      code: 'AZ-AGS',
    },
    {
      name: 'Ağsu',
      code: 'AZ-AGU',
    },
    {
      name: 'Astara',
      code: 'AZ-AST',
    },
    {
      name: 'Bakı',
      code: 'AZ-BA',
    },
    {
      name: 'Babək',
      code: 'AZ-BAB',
    },
    {
      name: 'Balakən',
      code: 'AZ-BAL',
    },
    {
      name: 'Bərdə',
      code: 'AZ-BAR',
    },
    {
      name: 'Beyləqan',
      code: 'AZ-BEY',
    },
    {
      name: 'Biləsuvar',
      code: 'AZ-BIL',
    },
    {
      name: 'Cəbrayıl',
      code: 'AZ-CAB',
    },
    {
      name: 'Cəlilabad',
      code: 'AZ-CAL',
    },
    {
      name: 'Culfa',
      code: 'AZ-CUL',
    },
    {
      name: 'Daşkəsən',
      code: 'AZ-DAS',
    },
    {
      name: 'Füzuli',
      code: 'AZ-FUZ',
    },
    {
      name: 'Gədəbəy',
      code: 'AZ-GAD',
    },
    {
      name: 'Goranboy',
      code: 'AZ-GOR',
    },
    {
      name: 'Göyçay',
      code: 'AZ-GOY',
    },
    {
      name: 'Göygöl',
      code: 'AZ-GYG',
    },
    {
      name: 'Hacıqabul',
      code: 'AZ-HAC',
    },
    {
      name: 'İmişli',
      code: 'AZ-IMI',
    },
    {
      name: 'İsmayıllı',
      code: 'AZ-ISM',
    },
    {
      name: 'Kəlbəcər',
      code: 'AZ-KAL',
    },
    {
      name: 'Kǝngǝrli',
      code: 'AZ-KAN',
    },
    {
      name: 'Kürdəmir',
      code: 'AZ-KUR',
    },
    {
      name: 'Laçın',
      code: 'AZ-LAC',
    },
    {
      name: 'Lənkəran',
      code: 'AZ-LAN',
    },
    {
      name: 'Lerik',
      code: 'AZ-LER',
    },
    {
      name: 'Masallı',
      code: 'AZ-MAS',
    },
    {
      name: 'Mingecevir',
      code: 'AZ-MI',
    },
    {
      name: 'Naftalan',
      code: 'AZ-NA',
    },
    {
      name: 'Neftçala',
      code: 'AZ-NEF',
    },
    {
      name: 'Oğuz',
      code: 'AZ-OGU',
    },
    {
      name: 'Ordubad',
      code: 'AZ-ORD',
    },
    {
      name: 'Qəbələ',
      code: 'AZ-QAB',
    },
    {
      name: 'Qax',
      code: 'AZ-QAX',
    },
    {
      name: 'Qazax',
      code: 'AZ-QAZ',
    },
    {
      name: 'Quba',
      code: 'AZ-QBA',
    },
    {
      name: 'Qubadlı',
      code: 'AZ-QBI',
    },
    {
      name: 'Qobustan',
      code: 'AZ-QOB',
    },
    {
      name: 'Qusar',
      code: 'AZ-QUS',
    },
    {
      name: 'Sabirabad',
      code: 'AZ-SAB',
    },
    {
      name: 'Sədərək',
      code: 'AZ-SAD',
    },
    {
      name: 'Şahbuz',
      code: 'AZ-SAH',
    },
    {
      name: 'Şəki',
      code: 'AZ-SAK',
    },
    {
      name: 'Salyan',
      code: 'AZ-SAL',
    },
    {
      name: 'Şərur',
      code: 'AZ-SAR',
    },
    {
      name: 'Saatlı',
      code: 'AZ-SAT',
    },
    {
      name: 'Siyəzən',
      code: 'AZ-SIY',
    },
    {
      name: 'Şəmkir',
      code: 'AZ-SKR',
    },
    {
      name: 'Samux',
      code: 'AZ-SMX',
    },
    {
      name: 'Şuşa',
      code: 'AZ-SUS',
    },
    {
      name: 'Tərtər',
      code: 'AZ-TAR',
    },
    {
      name: 'Tovuz',
      code: 'AZ-TOV',
    },
    {
      name: 'Ucar',
      code: 'AZ-UCA',
    },
    {
      name: 'Xaçmaz',
      code: 'AZ-XAC',
    },
    {
      name: 'Xocalı',
      code: 'AZ-XCI',
    },
    {
      name: 'Xızı',
      code: 'AZ-XIZ',
    },
    {
      name: 'Xocavənd',
      code: 'AZ-XVD',
    },
    {
      name: 'Yardımlı',
      code: 'AZ-YAR',
    },
    {
      name: 'Yevlax',
      code: 'AZ-YEV',
    },
    {
      name: 'Zəngilan',
      code: 'AZ-ZAN',
    },
    {
      name: 'Zaqatala',
      code: 'AZ-ZAQ',
    },
    {
      name: 'Zərdab',
      code: 'AZ-ZAR',
    },
  ],
  BA: [
    {
      name: 'Federacija Bosne i Hercegovine',
      code: 'BA-BIH',
    },
    {
      name: 'Republika Srpska',
      code: 'BA-SRP',
    },
  ],
  BB: [
    {
      name: 'Christ Church',
      code: 'BB-01',
    },
    {
      name: 'Saint Andrew',
      code: 'BB-02',
    },
    {
      name: 'Saint George',
      code: 'BB-03',
    },
    {
      name: 'Saint James',
      code: 'BB-04',
    },
    {
      name: 'Saint John',
      code: 'BB-05',
    },
    {
      name: 'Saint Joseph',
      code: 'BB-06',
    },
    {
      name: 'Saint Lucy',
      code: 'BB-07',
    },
    {
      name: 'Saint Michael',
      code: 'BB-08',
    },
    {
      name: 'Saint Peter',
      code: 'BB-09',
    },
    {
      name: 'Saint Philip',
      code: 'BB-10',
    },
    {
      name: 'Saint Thomas',
      code: 'BB-11',
    },
  ],
  BD: [
    {
      name: 'Bandarban',
      code: 'BD-01',
    },
    {
      name: 'Barguna',
      code: 'BD-02',
    },
    {
      name: 'Bogra',
      code: 'BD-03',
    },
    {
      name: 'Brahmanbaria',
      code: 'BD-04',
    },
    {
      name: 'Bagerhat',
      code: 'BD-05',
    },
    {
      name: 'Barisal',
      code: 'BD-06',
    },
    {
      name: 'Bhola',
      code: 'BD-07',
    },
    {
      name: 'Comilla',
      code: 'BD-08',
    },
    {
      name: 'Chandpur',
      code: 'BD-09',
    },
    {
      name: 'Chittagong',
      code: 'BD-10',
    },
    {
      name: "Cox's Bazar",
      code: 'BD-11',
    },
    {
      name: 'Chuadanga',
      code: 'BD-12',
    },
    {
      name: 'Dhaka',
      code: 'BD-13',
    },
    {
      name: 'Dinajpur',
      code: 'BD-14',
    },
    {
      name: 'Faridpur',
      code: 'BD-15',
    },
    {
      name: 'Feni',
      code: 'BD-16',
    },
    {
      name: 'Gopalganj',
      code: 'BD-17',
    },
    {
      name: 'Gazipur',
      code: 'BD-18',
    },
    {
      name: 'Gaibandha',
      code: 'BD-19',
    },
    {
      name: 'Habiganj',
      code: 'BD-20',
    },
    {
      name: 'Jamalpur',
      code: 'BD-21',
    },
    {
      name: 'Jessore',
      code: 'BD-22',
    },
    {
      name: 'Jhenaidah',
      code: 'BD-23',
    },
    {
      name: 'Joypurhat',
      code: 'BD-24',
    },
    {
      name: 'Jhalakathi',
      code: 'BD-25',
    },
    {
      name: 'Kishoreganj',
      code: 'BD-26',
    },
    {
      name: 'Khulna',
      code: 'BD-27',
    },
    {
      name: 'Kurigram',
      code: 'BD-28',
    },
    {
      name: 'Khagrachhari',
      code: 'BD-29',
    },
    {
      name: 'Kushtia',
      code: 'BD-30',
    },
    {
      name: 'Lakshmipur',
      code: 'BD-31',
    },
    {
      name: 'Lalmonirhat',
      code: 'BD-32',
    },
    {
      name: 'Manikganj',
      code: 'BD-33',
    },
    {
      name: 'Mymensingh',
      code: 'BD-34',
    },
    {
      name: 'Munshiganj',
      code: 'BD-35',
    },
    {
      name: 'Madaripur',
      code: 'BD-36',
    },
    {
      name: 'Magura',
      code: 'BD-37',
    },
    {
      name: 'Moulvibazar',
      code: 'BD-38',
    },
    {
      name: 'Meherpur',
      code: 'BD-39',
    },
    {
      name: 'Narayanganj',
      code: 'BD-40',
    },
    {
      name: 'Netrakona',
      code: 'BD-41',
    },
    {
      name: 'Narsingdi',
      code: 'BD-42',
    },
    {
      name: 'Narail',
      code: 'BD-43',
    },
    {
      name: 'Natore',
      code: 'BD-44',
    },
    {
      name: 'Chapai Nawabganj',
      code: 'BD-45',
    },
    {
      name: 'Nilphamari',
      code: 'BD-46',
    },
    {
      name: 'Noakhali',
      code: 'BD-47',
    },
    {
      name: 'Naogaon',
      code: 'BD-48',
    },
    {
      name: 'Pabna',
      code: 'BD-49',
    },
    {
      name: 'Pirojpur',
      code: 'BD-50',
    },
    {
      name: 'Patuakhali',
      code: 'BD-51',
    },
    {
      name: 'Panchagarh',
      code: 'BD-52',
    },
    {
      name: 'Rajbari',
      code: 'BD-53',
    },
    {
      name: 'Rajshahi',
      code: 'BD-54',
    },
    {
      name: 'Rangpur',
      code: 'BD-55',
    },
    {
      name: 'Rangamati',
      code: 'BD-56',
    },
    {
      name: 'Sherpur',
      code: 'BD-57',
    },
    {
      name: 'Satkhira',
      code: 'BD-58',
    },
    {
      name: 'Sirajganj',
      code: 'BD-59',
    },
    {
      name: 'Sylhet',
      code: 'BD-60',
    },
    {
      name: 'Sunamganj',
      code: 'BD-61',
    },
    {
      name: 'Shariatpur',
      code: 'BD-62',
    },
    {
      name: 'Tangail',
      code: 'BD-63',
    },
    {
      name: 'Thakurgaon',
      code: 'BD-64',
    },
  ],
  BE: [
    {
      name: 'Bruxelles-Capitale, Région de',
      code: 'BE-BRU',
    },
    {
      name: 'Antwerpen',
      code: 'BE-VAN',
    },
    {
      name: 'Vlaams-Brabant',
      code: 'BE-VBR',
    },
    {
      name: 'Limburg',
      code: 'BE-VLI',
    },
    {
      name: 'Oost-Vlaanderen',
      code: 'BE-VOV',
    },
    {
      name: 'West-Vlaanderen',
      code: 'BE-VWV',
    },
    {
      name: 'wallonne, Région',
      code: 'BE-WAL',
    },
    {
      name: 'Brabant wallon',
      code: 'BE-WBR',
    },
    {
      name: 'Hainaut',
      code: 'BE-WHT',
    },
    {
      name: 'Liège',
      code: 'BE-WLG',
    },
    {
      name: 'Luxembourg',
      code: 'BE-WLX',
    },
    {
      name: 'Namur',
      code: 'BE-WNA',
    },
  ],
  BF: [
    {
      name: 'Balé',
      code: 'BF-BAL',
    },
    {
      name: 'Bam',
      code: 'BF-BAM',
    },
    {
      name: 'Banwa',
      code: 'BF-BAN',
    },
    {
      name: 'Bazèga',
      code: 'BF-BAZ',
    },
    {
      name: 'Bougouriba',
      code: 'BF-BGR',
    },
    {
      name: 'Boulgou',
      code: 'BF-BLG',
    },
    {
      name: 'Boulkiemdé',
      code: 'BF-BLK',
    },
    {
      name: 'Comoé',
      code: 'BF-COM',
    },
    {
      name: 'Ganzourgou',
      code: 'BF-GAN',
    },
    {
      name: 'Gnagna',
      code: 'BF-GNA',
    },
    {
      name: 'Gourma',
      code: 'BF-GOU',
    },
    {
      name: 'Houet',
      code: 'BF-HOU',
    },
    {
      name: 'Ioba',
      code: 'BF-IOB',
    },
    {
      name: 'Kadiogo',
      code: 'BF-KAD',
    },
    {
      name: 'Kénédougou',
      code: 'BF-KEN',
    },
    {
      name: 'Komondjari',
      code: 'BF-KMD',
    },
    {
      name: 'Kompienga',
      code: 'BF-KMP',
    },
    {
      name: 'Koulpélogo',
      code: 'BF-KOP',
    },
    {
      name: 'Kossi',
      code: 'BF-KOS',
    },
    {
      name: 'Kouritenga',
      code: 'BF-KOT',
    },
    {
      name: 'Kourwéogo',
      code: 'BF-KOW',
    },
    {
      name: 'Léraba',
      code: 'BF-LER',
    },
    {
      name: 'Loroum',
      code: 'BF-LOR',
    },
    {
      name: 'Mouhoun',
      code: 'BF-MOU',
    },
    {
      name: 'Namentenga',
      code: 'BF-NAM',
    },
    {
      name: 'Nahouri',
      code: 'BF-NAO',
    },
    {
      name: 'Nayala',
      code: 'BF-NAY',
    },
    {
      name: 'Noumbiel',
      code: 'BF-NOU',
    },
    {
      name: 'Oubritenga',
      code: 'BF-OUB',
    },
    {
      name: 'Oudalan',
      code: 'BF-OUD',
    },
    {
      name: 'Passoré',
      code: 'BF-PAS',
    },
    {
      name: 'Poni',
      code: 'BF-PON',
    },
    {
      name: 'Séno',
      code: 'BF-SEN',
    },
    {
      name: 'Sissili',
      code: 'BF-SIS',
    },
    {
      name: 'Sanmatenga',
      code: 'BF-SMT',
    },
    {
      name: 'Sanguié',
      code: 'BF-SNG',
    },
    {
      name: 'Soum',
      code: 'BF-SOM',
    },
    {
      name: 'Sourou',
      code: 'BF-SOR',
    },
    {
      name: 'Tapoa',
      code: 'BF-TAP',
    },
    {
      name: 'Tuy',
      code: 'BF-TUI',
    },
    {
      name: 'Yagha',
      code: 'BF-YAG',
    },
    {
      name: 'Yatenga',
      code: 'BF-YAT',
    },
    {
      name: 'Ziro',
      code: 'BF-ZIR',
    },
    {
      name: 'Zondoma',
      code: 'BF-ZON',
    },
    {
      name: 'Zoundwéogo',
      code: 'BF-ZOU',
    },
  ],
  BG: [
    {
      name: 'Blagoevgrad',
      code: 'BG-01',
    },
    {
      name: 'Burgas',
      code: 'BG-02',
    },
    {
      name: 'Varna',
      code: 'BG-03',
    },
    {
      name: 'Veliko Tarnovo',
      code: 'BG-04',
    },
    {
      name: 'Vidin',
      code: 'BG-05',
    },
    {
      name: 'Vratsa',
      code: 'BG-06',
    },
    {
      name: 'Gabrovo',
      code: 'BG-07',
    },
    {
      name: 'Dobrich',
      code: 'BG-08',
    },
    {
      name: 'Kardzhali',
      code: 'BG-09',
    },
    {
      name: 'Kyustendil',
      code: 'BG-10',
    },
    {
      name: 'Lovech',
      code: 'BG-11',
    },
    {
      name: 'Montana',
      code: 'BG-12',
    },
    {
      name: 'Pazardzhik',
      code: 'BG-13',
    },
    {
      name: 'Pernik',
      code: 'BG-14',
    },
    {
      name: 'Pleven',
      code: 'BG-15',
    },
    {
      name: 'Plovdiv',
      code: 'BG-16',
    },
    {
      name: 'Razgrad',
      code: 'BG-17',
    },
    {
      name: 'Ruse',
      code: 'BG-18',
    },
    {
      name: 'Silistra',
      code: 'BG-19',
    },
    {
      name: 'Sliven',
      code: 'BG-20',
    },
    {
      name: 'Smolyan',
      code: 'BG-21',
    },
    {
      name: 'Sofia',
      code: 'BG-22',
    },
    {
      name: 'Sofia',
      code: 'BG-23',
    },
    {
      name: 'Stara Zagora',
      code: 'BG-24',
    },
    {
      name: 'Targovishte',
      code: 'BG-25',
    },
    {
      name: 'Haskovo',
      code: 'BG-26',
    },
    {
      name: 'Shumen',
      code: 'BG-27',
    },
    {
      name: 'Yambol',
      code: 'BG-28',
    },
  ],
  BH: [
    {
      name: 'Al ‘Āşimah',
      code: 'BH-13',
    },
    {
      name: 'Al Janūbīyah',
      code: 'BH-14',
    },
    {
      name: 'Al Muḩarraq',
      code: 'BH-15',
    },
    {
      name: 'Ash Shamālīyah',
      code: 'BH-17',
    },
  ],
  BI: [
    {
      name: 'Bubanza',
      code: 'BI-BB',
    },
    {
      name: 'Bujumbura Rural',
      code: 'BI-BL',
    },
    {
      name: 'Bujumbura Mairie',
      code: 'BI-BM',
    },
    {
      name: 'Bururi',
      code: 'BI-BR',
    },
    {
      name: 'Cankuzo',
      code: 'BI-CA',
    },
    {
      name: 'Cibitoke',
      code: 'BI-CI',
    },
    {
      name: 'Gitega',
      code: 'BI-GI',
    },
    {
      name: 'Kirundo',
      code: 'BI-KI',
    },
    {
      name: 'Karuzi',
      code: 'BI-KR',
    },
    {
      name: 'Kayanza',
      code: 'BI-KY',
    },
    {
      name: 'Makamba',
      code: 'BI-MA',
    },
    {
      name: 'Muramvya',
      code: 'BI-MU',
    },
    {
      name: 'Mwaro',
      code: 'BI-MW',
    },
    {
      name: 'Muyinga',
      code: 'BI-MY',
    },
    {
      name: 'Ngozi',
      code: 'BI-NG',
    },
    {
      name: 'Rumonge',
      code: 'BI-RM',
    },
    {
      name: 'Rutana',
      code: 'BI-RT',
    },
    {
      name: 'Ruyigi',
      code: 'BI-RY',
    },
  ],
  BJ: [
    {
      name: 'Atacora',
      code: 'BJ-AK',
    },
    {
      name: 'Alibori',
      code: 'BJ-AL',
    },
    {
      name: 'Atlantique',
      code: 'BJ-AQ',
    },
    {
      name: 'Borgou',
      code: 'BJ-BO',
    },
    {
      name: 'Collines',
      code: 'BJ-CO',
    },
    {
      name: 'Donga',
      code: 'BJ-DO',
    },
    {
      name: 'Couffo',
      code: 'BJ-KO',
    },
    {
      name: 'Littoral',
      code: 'BJ-LI',
    },
    {
      name: 'Mono',
      code: 'BJ-MO',
    },
    {
      name: 'Ouémé',
      code: 'BJ-OU',
    },
    {
      name: 'Plateau',
      code: 'BJ-PL',
    },
    {
      name: 'Zou',
      code: 'BJ-ZO',
    },
  ],
  BN: [
    {
      name: 'Belait',
      code: 'BN-BE',
    },
    {
      name: 'Brunei-Muara',
      code: 'BN-BM',
    },
    {
      name: 'Temburong',
      code: 'BN-TE',
    },
    {
      name: 'Tutong',
      code: 'BN-TU',
    },
  ],
  BO: [
    {
      name: 'El Beni',
      code: 'BO-B',
    },
    {
      name: 'Cochabamba',
      code: 'BO-C',
    },
    {
      name: 'Chuquisaca',
      code: 'BO-H',
    },
    {
      name: 'La Paz',
      code: 'BO-L',
    },
    {
      name: 'Pando',
      code: 'BO-N',
    },
    {
      name: 'Oruro',
      code: 'BO-O',
    },
    {
      name: 'Potosí',
      code: 'BO-P',
    },
    {
      name: 'Santa Cruz',
      code: 'BO-S',
    },
    {
      name: 'Tarija',
      code: 'BO-T',
    },
  ],
  BQ: [
    {
      name: 'Bonaire',
      code: 'BQ-BO',
    },
    {
      name: 'Saba',
      code: 'BQ-SA',
    },
    {
      name: 'Sint Eustatius',
      code: 'BQ-SE',
    },
  ],
  BR: [
    {
      name: 'Acre',
      code: 'BR-AC',
    },
    {
      name: 'Alagoas',
      code: 'BR-AL',
    },
    {
      name: 'Amazonas',
      code: 'BR-AM',
    },
    {
      name: 'Amapá',
      code: 'BR-AP',
    },
    {
      name: 'Bahia',
      code: 'BR-BA',
    },
    {
      name: 'Ceará',
      code: 'BR-CE',
    },
    {
      name: 'Distrito Federal',
      code: 'BR-DF',
    },
    {
      name: 'Espírito Santo',
      code: 'BR-ES',
    },
    {
      name: 'Goiás',
      code: 'BR-GO',
    },
    {
      name: 'Maranhão',
      code: 'BR-MA',
    },
    {
      name: 'Minas Gerais',
      code: 'BR-MG',
    },
    {
      name: 'Mato Grosso do Sul',
      code: 'BR-MS',
    },
    {
      name: 'Mato Grosso',
      code: 'BR-MT',
    },
    {
      name: 'Pará',
      code: 'BR-PA',
    },
    {
      name: 'Paraíba',
      code: 'BR-PB',
    },
    {
      name: 'Pernambuco',
      code: 'BR-PE',
    },
    {
      name: 'Piauí',
      code: 'BR-PI',
    },
    {
      name: 'Paraná',
      code: 'BR-PR',
    },
    {
      name: 'Rio de Janeiro',
      code: 'BR-RJ',
    },
    {
      name: 'Rio Grande do Norte',
      code: 'BR-RN',
    },
    {
      name: 'Rondônia',
      code: 'BR-RO',
    },
    {
      name: 'Roraima',
      code: 'BR-RR',
    },
    {
      name: 'Rio Grande do Sul',
      code: 'BR-RS',
    },
    {
      name: 'Santa Catarina',
      code: 'BR-SC',
    },
    {
      name: 'Sergipe',
      code: 'BR-SE',
    },
    {
      name: 'São Paulo',
      code: 'BR-SP',
    },
    {
      name: 'Tocantins',
      code: 'BR-TO',
    },
  ],
  BS: [
    {
      name: 'Acklins',
      code: 'BS-AK',
    },
    {
      name: 'Bimini',
      code: 'BS-BI',
    },
    {
      name: 'Black Point',
      code: 'BS-BP',
    },
    {
      name: 'Berry Islands',
      code: 'BS-BY',
    },
    {
      name: 'Central Eleuthera',
      code: 'BS-CE',
    },
    {
      name: 'Cat Island',
      code: 'BS-CI',
    },
    {
      name: 'Crooked Island and Long Cay',
      code: 'BS-CK',
    },
    {
      name: 'Central Abaco',
      code: 'BS-CO',
    },
    {
      name: 'Central Andros',
      code: 'BS-CS',
    },
    {
      name: 'East Grand Bahama',
      code: 'BS-EG',
    },
    {
      name: 'Exuma',
      code: 'BS-EX',
    },
    {
      name: 'City of Freeport',
      code: 'BS-FP',
    },
    {
      name: 'Grand Cay',
      code: 'BS-GC',
    },
    {
      name: 'Harbour Island',
      code: 'BS-HI',
    },
    {
      name: 'Hope Town',
      code: 'BS-HT',
    },
    {
      name: 'Inagua',
      code: 'BS-IN',
    },
    {
      name: 'Long Island',
      code: 'BS-LI',
    },
    {
      name: 'Mangrove Cay',
      code: 'BS-MC',
    },
    {
      name: 'Mayaguana',
      code: 'BS-MG',
    },
    {
      name: "Moore's Island",
      code: 'BS-MI',
    },
    {
      name: 'North Eleuthera',
      code: 'BS-NE',
    },
    {
      name: 'North Abaco',
      code: 'BS-NO',
    },
    {
      name: 'North Andros',
      code: 'BS-NS',
    },
    {
      name: 'Rum Cay',
      code: 'BS-RC',
    },
    {
      name: 'Ragged Island',
      code: 'BS-RI',
    },
    {
      name: 'South Andros',
      code: 'BS-SA',
    },
    {
      name: 'South Eleuthera',
      code: 'BS-SE',
    },
    {
      name: 'South Abaco',
      code: 'BS-SO',
    },
    {
      name: 'San Salvador',
      code: 'BS-SS',
    },
    {
      name: 'Spanish Wells',
      code: 'BS-SW',
    },
    {
      name: 'West Grand Bahama',
      code: 'BS-WG',
    },
  ],
  BT: [
    {
      name: 'Paro',
      code: 'BT-11',
    },
    {
      name: 'Chhukha',
      code: 'BT-12',
    },
    {
      name: 'Haa',
      code: 'BT-13',
    },
    {
      name: 'Samtse',
      code: 'BT-14',
    },
    {
      name: 'Thimphu',
      code: 'BT-15',
    },
    {
      name: 'Tsirang',
      code: 'BT-21',
    },
    {
      name: 'Dagana',
      code: 'BT-22',
    },
    {
      name: 'Punakha',
      code: 'BT-23',
    },
    {
      name: 'Wangdue Phodrang',
      code: 'BT-24',
    },
    {
      name: 'Sarpang',
      code: 'BT-31',
    },
    {
      name: 'Trongsa',
      code: 'BT-32',
    },
    {
      name: 'Bumthang',
      code: 'BT-33',
    },
    {
      name: 'Zhemgang',
      code: 'BT-34',
    },
    {
      name: 'Trashigang',
      code: 'BT-41',
    },
    {
      name: 'Monggar',
      code: 'BT-42',
    },
    {
      name: 'Pemagatshel',
      code: 'BT-43',
    },
    {
      name: 'Lhuentse',
      code: 'BT-44',
    },
    {
      name: 'Samdrup Jongkhar',
      code: 'BT-45',
    },
    {
      name: 'Gasa',
      code: 'BT-GA',
    },
    {
      name: 'Trashi Yangtse',
      code: 'BT-TY',
    },
  ],
  BW: [
    {
      name: 'Central',
      code: 'BW-CE',
    },
    {
      name: 'Chobe',
      code: 'BW-CH',
    },
    {
      name: 'Francistown',
      code: 'BW-FR',
    },
    {
      name: 'Gaborone',
      code: 'BW-GA',
    },
    {
      name: 'Ghanzi',
      code: 'BW-GH',
    },
    {
      name: 'Jwaneng',
      code: 'BW-JW',
    },
    {
      name: 'Kgalagadi',
      code: 'BW-KG',
    },
    {
      name: 'Kgatleng',
      code: 'BW-KL',
    },
    {
      name: 'Kweneng',
      code: 'BW-KW',
    },
    {
      name: 'Lobatse',
      code: 'BW-LO',
    },
    {
      name: 'North East',
      code: 'BW-NE',
    },
    {
      name: 'North West',
      code: 'BW-NW',
    },
    {
      name: 'South East',
      code: 'BW-SE',
    },
    {
      name: 'Southern',
      code: 'BW-SO',
    },
    {
      name: 'Selibe Phikwe',
      code: 'BW-SP',
    },
  ],
  BY: [
    {
      name: "Brestskaya voblasts'",
      code: 'BY-BR',
    },
    {
      name: 'Horad Minsk',
      code: 'BY-HM',
    },
    {
      name: "Homyel'skaya voblasts'",
      code: 'BY-HO',
    },
    {
      name: 'Hrodzienskaja voblasć',
      code: 'BY-HR',
    },
    {
      name: "Mahilyowskaya voblasts'",
      code: 'BY-MA',
    },
    {
      name: "Minskaya voblasts'",
      code: 'BY-MI',
    },
    {
      name: "Vitsyebskaya voblasts'",
      code: 'BY-VI',
    },
  ],
  BZ: [
    {
      name: 'Belize',
      code: 'BZ-BZ',
    },
    {
      name: 'Cayo',
      code: 'BZ-CY',
    },
    {
      name: 'Corozal',
      code: 'BZ-CZL',
    },
    {
      name: 'Orange Walk',
      code: 'BZ-OW',
    },
    {
      name: 'Stann Creek',
      code: 'BZ-SC',
    },
    {
      name: 'Toledo',
      code: 'BZ-TOL',
    },
  ],
  CA: [
    {
      name: 'Alberta',
      code: 'CA-AB',
    },
    {
      name: 'British Columbia',
      code: 'CA-BC',
    },
    {
      name: 'Manitoba',
      code: 'CA-MB',
    },
    {
      name: 'New Brunswick',
      code: 'CA-NB',
    },
    {
      name: 'Newfoundland and Labrador',
      code: 'CA-NL',
    },
    {
      name: 'Nova Scotia',
      code: 'CA-NS',
    },
    {
      name: 'Northwest Territories',
      code: 'CA-NT',
    },
    {
      name: 'Nunavut',
      code: 'CA-NU',
    },
    {
      name: 'Ontario',
      code: 'CA-ON',
    },
    {
      name: 'Prince Edward Island',
      code: 'CA-PE',
    },
    {
      name: 'Quebec',
      code: 'CA-QC',
    },
    {
      name: 'Saskatchewan',
      code: 'CA-SK',
    },
    {
      name: 'Yukon',
      code: 'CA-YT',
    },
  ],
  CD: [
    {
      name: 'Kongo Central',
      code: 'CD-CD',
    },
    {
      name: 'Bas-Uélé',
      code: 'CD-BU',
    },
    {
      name: 'Équateur',
      code: 'CD-EQ',
    },
    {
      name: 'Haut-Katanga',
      code: 'CD-HK',
    },
    {
      name: 'Haut-Lomami',
      code: 'CD-HL',
    },
    {
      name: 'Haut-Uélé',
      code: 'CD-HU',
    },
    {
      name: 'Ituri',
      code: 'CD-IT',
    },
    {
      name: 'Kasaï Central',
      code: 'CD-KC',
    },
    {
      name: 'Kasaï Oriental',
      code: 'CD-KE',
    },
    {
      name: 'Kwango',
      code: 'CD-KG',
    },
    {
      name: 'Kwilu',
      code: 'CD-KL',
    },
    {
      name: 'Kinshasa',
      code: 'CD-KN',
    },
    {
      name: 'Kasaï',
      code: 'CD-KS',
    },
    {
      name: 'Lomami',
      code: 'CD-LO',
    },
    {
      name: 'Lualaba',
      code: 'CD-LU',
    },
    {
      name: 'Maniema',
      code: 'CD-MA',
    },
    {
      name: 'Mai-Ndombe',
      code: 'CD-MN',
    },
    {
      name: 'Mongala',
      code: 'CD-MO',
    },
    {
      name: 'Nord-Kivu',
      code: 'CD-NK',
    },
    {
      name: 'Nord-Ubangi',
      code: 'CD-NU',
    },
    {
      name: 'Sankuru',
      code: 'CD-SA',
    },
    {
      name: 'Sud-Kivu',
      code: 'CD-SK',
    },
    {
      name: 'Sud-Ubangi',
      code: 'CD-SU',
    },
    {
      name: 'Tanganyika',
      code: 'CD-TA',
    },
    {
      name: 'Tshopo',
      code: 'CD-TO',
    },
    {
      name: 'Tshuapa',
      code: 'CD-TU',
    },
  ],
  CF: [
    {
      name: 'Ouham',
      code: 'CF-AC',
    },
    {
      name: 'Bamingui-Bangoran',
      code: 'CF-BB',
    },
    {
      name: 'Bangui',
      code: 'CF-BGF',
    },
    {
      name: 'Basse-Kotto',
      code: 'CF-BK',
    },
    {
      name: 'Haute-Kotto',
      code: 'CF-HK',
    },
    {
      name: 'Haut-Mbomou',
      code: 'CF-HM',
    },
    {
      name: 'Haute-Sangha / Mambéré-Kadéï',
      code: 'CF-HS',
    },
    {
      name: 'Gribingui',
      code: 'CF-KB',
    },
    {
      name: 'Kémo-Gribingui',
      code: 'CF-KG',
    },
    {
      name: 'Lobaye',
      code: 'CF-LB',
    },
    {
      name: 'Mbomou',
      code: 'CF-MB',
    },
    {
      name: 'Ombella-Mpoko',
      code: 'CF-MP',
    },
    {
      name: 'Nana-Mambéré',
      code: 'CF-NM',
    },
    {
      name: 'Ouham-Pendé',
      code: 'CF-OP',
    },
    {
      name: 'Sangha',
      code: 'CF-SE',
    },
    {
      name: 'Ouaka',
      code: 'CF-UK',
    },
    {
      name: 'Vakaga',
      code: 'CF-VK',
    },
  ],
  CG: [
    {
      name: 'Bouenza',
      code: 'CG-11',
    },
    {
      name: 'Pool',
      code: 'CG-12',
    },
    {
      name: 'Sangha',
      code: 'CG-13',
    },
    {
      name: 'Plateaux',
      code: 'CG-14',
    },
    {
      name: 'Cuvette-Ouest',
      code: 'CG-15',
    },
    {
      name: 'Pointe-Noire',
      code: 'CG-16',
    },
    {
      name: 'Lékoumou',
      code: 'CG-2',
    },
    {
      name: 'Kouilou',
      code: 'CG-5',
    },
    {
      name: 'Likouala',
      code: 'CG-7',
    },
    {
      name: 'Cuvette',
      code: 'CG-8',
    },
    {
      name: 'Niari',
      code: 'CG-9',
    },
    {
      name: 'Brazzaville',
      code: 'CG-BZV',
    },
  ],
  CH: [
    {
      name: 'Aargau',
      code: 'CH-AG',
    },
    {
      name: 'Appenzell Innerrhoden',
      code: 'CH-AI',
    },
    {
      name: 'Appenzell Ausserrhoden',
      code: 'CH-AR',
    },
    {
      name: 'Bern',
      code: 'CH-BE',
    },
    {
      name: 'Basel-Landschaft',
      code: 'CH-BL',
    },
    {
      name: 'Basel-Stadt',
      code: 'CH-BS',
    },
    {
      name: 'Freiburg',
      code: 'CH-FR',
    },
    {
      name: 'Genève',
      code: 'CH-GE',
    },
    {
      name: 'Glarus',
      code: 'CH-GL',
    },
    {
      name: 'Graubünden',
      code: 'CH-GR',
    },
    {
      name: 'Jura',
      code: 'CH-JU',
    },
    {
      name: 'Luzern',
      code: 'CH-LU',
    },
    {
      name: 'Neuchâtel',
      code: 'CH-NE',
    },
    {
      name: 'Nidwalden',
      code: 'CH-NW',
    },
    {
      name: 'Obwalden',
      code: 'CH-OW',
    },
    {
      name: 'Sankt Gallen',
      code: 'CH-SG',
    },
    {
      name: 'Schaffhausen',
      code: 'CH-SH',
    },
    {
      name: 'Solothurn',
      code: 'CH-SO',
    },
    {
      name: 'Schwyz',
      code: 'CH-SZ',
    },
    {
      name: 'Thurgau',
      code: 'CH-TG',
    },
    {
      name: 'Ticino',
      code: 'CH-TI',
    },
    {
      name: 'Uri',
      code: 'CH-UR',
    },
    {
      name: 'Vaud',
      code: 'CH-VD',
    },
    {
      name: 'Wallis',
      code: 'CH-VS',
    },
    {
      name: 'Zug',
      code: 'CH-ZG',
    },
    {
      name: 'Zürich',
      code: 'CH-ZH',
    },
  ],
  CI: [
    {
      name: 'Abidjan',
      code: 'CI-AB',
    },
    {
      name: 'Bas-Sassandra',
      code: 'CI-BS',
    },
    {
      name: 'Comoé',
      code: 'CI-CM',
    },
    {
      name: 'Denguélé',
      code: 'CI-DN',
    },
    {
      name: 'Gôh-Djiboua',
      code: 'CI-GD',
    },
    {
      name: 'Lacs',
      code: 'CI-LC',
    },
    {
      name: 'Lagunes',
      code: 'CI-LG',
    },
    {
      name: 'Montagnes',
      code: 'CI-MG',
    },
    {
      name: 'Sassandra-Marahoué',
      code: 'CI-SM',
    },
    {
      name: 'Savanes',
      code: 'CI-SV',
    },
    {
      name: 'Vallée du Bandama',
      code: 'CI-VB',
    },
    {
      name: 'Woroba',
      code: 'CI-WR',
    },
    {
      name: 'Yamoussoukro',
      code: 'CI-YM',
    },
    {
      name: 'Zanzan',
      code: 'CI-ZZ',
    },
  ],
  CL: [
    {
      name: 'Aisén del General Carlos Ibañez del Campo',
      code: 'CL-AI',
    },
    {
      name: 'Antofagasta',
      code: 'CL-AN',
    },
    {
      name: 'Arica y Parinacota',
      code: 'CL-AP',
    },
    {
      name: 'La Araucanía',
      code: 'CL-AR',
    },
    {
      name: 'Atacama',
      code: 'CL-AT',
    },
    {
      name: 'Biobío',
      code: 'CL-BI',
    },
    {
      name: 'Coquimbo',
      code: 'CL-CO',
    },
    {
      name: "Libertador General Bernardo O'Higgins",
      code: 'CL-LI',
    },
    {
      name: 'Los Lagos',
      code: 'CL-LL',
    },
    {
      name: 'Los Ríos',
      code: 'CL-LR',
    },
    {
      name: 'Magallanes',
      code: 'CL-MA',
    },
    {
      name: 'Maule',
      code: 'CL-ML',
    },
    {
      name: 'Ñuble',
      code: 'CL-NB',
    },
    {
      name: 'Región Metropolitana de Santiago',
      code: 'CL-RM',
    },
    {
      name: 'Tarapacá',
      code: 'CL-TA',
    },
    {
      name: 'Valparaíso',
      code: 'CL-VS',
    },
  ],
  CM: [
    {
      name: 'Adamaoua',
      code: 'CM-AD',
    },
    {
      name: 'Centre',
      code: 'CM-CE',
    },
    {
      name: 'Far North',
      code: 'CM-EN',
    },
    {
      name: 'East',
      code: 'CM-ES',
    },
    {
      name: 'Littoral',
      code: 'CM-LT',
    },
    {
      name: 'North',
      code: 'CM-NO',
    },
    {
      name: 'North-West',
      code: 'CM-NW',
    },
    {
      name: 'West',
      code: 'CM-OU',
    },
    {
      name: 'South',
      code: 'CM-SU',
    },
    {
      name: 'South-West',
      code: 'CM-SW',
    },
  ],
  CN: [
    {
      name: 'Anhui Sheng',
      code: 'CN-AH',
    },
    {
      name: 'Beijing Shi',
      code: 'CN-BJ',
    },
    {
      name: 'Chongqing Shi',
      code: 'CN-CQ',
    },
    {
      name: 'Fujian Sheng',
      code: 'CN-FJ',
    },
    {
      name: 'Guangdong Sheng',
      code: 'CN-GD',
    },
    {
      name: 'Gansu Sheng',
      code: 'CN-GS',
    },
    {
      name: 'Guangxi Zhuangzu Zizhiqu',
      code: 'CN-GX',
    },
    {
      name: 'Guizhou Sheng',
      code: 'CN-GZ',
    },
    {
      name: 'Henan Sheng',
      code: 'CN-HA',
    },
    {
      name: 'Hubei Sheng',
      code: 'CN-HB',
    },
    {
      name: 'Hebei Sheng',
      code: 'CN-HE',
    },
    {
      name: 'Hainan Sheng',
      code: 'CN-HI',
    },
    {
      name: 'Hong Kong SAR',
      code: 'CN-HK',
    },
    {
      name: 'Heilongjiang Sheng',
      code: 'CN-HL',
    },
    {
      name: 'Hunan Sheng',
      code: 'CN-HN',
    },
    {
      name: 'Jilin Sheng',
      code: 'CN-JL',
    },
    {
      name: 'Jiangsu Sheng',
      code: 'CN-JS',
    },
    {
      name: 'Jiangxi Sheng',
      code: 'CN-JX',
    },
    {
      name: 'Liaoning Sheng',
      code: 'CN-LN',
    },
    {
      name: 'Macao SAR',
      code: 'CN-MO',
    },
    {
      name: 'Nei Mongol Zizhiqu',
      code: 'CN-NM',
    },
    {
      name: 'Ningxia Huizi Zizhiqu',
      code: 'CN-NX',
    },
    {
      name: 'Qinghai Sheng',
      code: 'CN-QH',
    },
    {
      name: 'Sichuan Sheng',
      code: 'CN-SC',
    },
    {
      name: 'Shandong Sheng',
      code: 'CN-SD',
    },
    {
      name: 'Shanghai Shi',
      code: 'CN-SH',
    },
    {
      name: 'Shaanxi Sheng',
      code: 'CN-SN',
    },
    {
      name: 'Shanxi Sheng',
      code: 'CN-SX',
    },
    {
      name: 'Tianjin Shi',
      code: 'CN-TJ',
    },
    {
      name: 'Taiwan Sheng',
      code: 'CN-TW',
    },
    {
      name: 'Xinjiang Uygur Zizhiqu',
      code: 'CN-XJ',
    },
    {
      name: 'Xizang Zizhiqu',
      code: 'CN-XZ',
    },
    {
      name: 'Yunnan Sheng',
      code: 'CN-YN',
    },
    {
      name: 'Zhejiang Sheng',
      code: 'CN-ZJ',
    },
  ],
  CO: [
    {
      name: 'Amazonas',
      code: 'CO-AMA',
    },
    {
      name: 'Antioquia',
      code: 'CO-ANT',
    },
    {
      name: 'Arauca',
      code: 'CO-ARA',
    },
    {
      name: 'Atlántico',
      code: 'CO-ATL',
    },
    {
      name: 'Bolívar',
      code: 'CO-BOL',
    },
    {
      name: 'Boyacá',
      code: 'CO-BOY',
    },
    {
      name: 'Caldas',
      code: 'CO-CAL',
    },
    {
      name: 'Caquetá',
      code: 'CO-CAQ',
    },
    {
      name: 'Casanare',
      code: 'CO-CAS',
    },
    {
      name: 'Cauca',
      code: 'CO-CAU',
    },
    {
      name: 'Cesar',
      code: 'CO-CES',
    },
    {
      name: 'Chocó',
      code: 'CO-CHO',
    },
    {
      name: 'Córdoba',
      code: 'CO-COR',
    },
    {
      name: 'Cundinamarca',
      code: 'CO-CUN',
    },
    {
      name: 'Distrito Capital de Bogotá',
      code: 'CO-DC',
    },
    {
      name: 'Guainía',
      code: 'CO-GUA',
    },
    {
      name: 'Guaviare',
      code: 'CO-GUV',
    },
    {
      name: 'Huila',
      code: 'CO-HUI',
    },
    {
      name: 'La Guajira',
      code: 'CO-LAG',
    },
    {
      name: 'Magdalena',
      code: 'CO-MAG',
    },
    {
      name: 'Meta',
      code: 'CO-MET',
    },
    {
      name: 'Nariño',
      code: 'CO-NAR',
    },
    {
      name: 'Norte de Santander',
      code: 'CO-NSA',
    },
    {
      name: 'Putumayo',
      code: 'CO-PUT',
    },
    {
      name: 'Quindío',
      code: 'CO-QUI',
    },
    {
      name: 'Risaralda',
      code: 'CO-RIS',
    },
    {
      name: 'Santander',
      code: 'CO-SAN',
    },
    {
      name: 'San Andrés, Providencia y Santa Catalina',
      code: 'CO-SAP',
    },
    {
      name: 'Sucre',
      code: 'CO-SUC',
    },
    {
      name: 'Tolima',
      code: 'CO-TOL',
    },
    {
      name: 'Valle del Cauca',
      code: 'CO-VAC',
    },
    {
      name: 'Vaupés',
      code: 'CO-VAU',
    },
    {
      name: 'Vichada',
      code: 'CO-VID',
    },
  ],
  CR: [
    {
      name: 'Alajuela',
      code: 'CR-A',
    },
    {
      name: 'Cartago',
      code: 'CR-C',
    },
    {
      name: 'Guanacaste',
      code: 'CR-G',
    },
    {
      name: 'Heredia',
      code: 'CR-H',
    },
    {
      name: 'Limón',
      code: 'CR-L',
    },
    {
      name: 'Puntarenas',
      code: 'CR-P',
    },
    {
      name: 'San José',
      code: 'CR-SJ',
    },
  ],
  CU: [
    {
      name: 'Pinar del Río',
      code: 'CU-01',
    },
    {
      name: 'La Habana',
      code: 'CU-03',
    },
    {
      name: 'Matanzas',
      code: 'CU-04',
    },
    {
      name: 'Villa Clara',
      code: 'CU-05',
    },
    {
      name: 'Cienfuegos',
      code: 'CU-06',
    },
    {
      name: 'Sancti Spíritus',
      code: 'CU-07',
    },
    {
      name: 'Ciego de Ávila',
      code: 'CU-08',
    },
    {
      name: 'Camagüey',
      code: 'CU-09',
    },
    {
      name: 'Las Tunas',
      code: 'CU-10',
    },
    {
      name: 'Holguín',
      code: 'CU-11',
    },
    {
      name: 'Granma',
      code: 'CU-12',
    },
    {
      name: 'Santiago de Cuba',
      code: 'CU-13',
    },
    {
      name: 'Guantánamo',
      code: 'CU-14',
    },
    {
      name: 'Artemisa',
      code: 'CU-15',
    },
    {
      name: 'Mayabeque',
      code: 'CU-16',
    },
    {
      name: 'Isla de la Juventud',
      code: 'CU-99',
    },
  ],
  CV: [
    {
      name: 'Brava',
      code: 'CV-BR',
    },
    {
      name: 'Boa Vista',
      code: 'CV-BV',
    },
    {
      name: 'Santa Catarina',
      code: 'CV-CA',
    },
    {
      name: 'Santa Catarina do Fogo',
      code: 'CV-CF',
    },
    {
      name: 'Santa Cruz',
      code: 'CV-CR',
    },
    {
      name: 'Maio',
      code: 'CV-MA',
    },
    {
      name: 'Mosteiros',
      code: 'CV-MO',
    },
    {
      name: 'Paul',
      code: 'CV-PA',
    },
    {
      name: 'Porto Novo',
      code: 'CV-PN',
    },
    {
      name: 'Praia',
      code: 'CV-PR',
    },
    {
      name: 'Ribeira Brava',
      code: 'CV-RB',
    },
    {
      name: 'Ribeira Grande',
      code: 'CV-RG',
    },
    {
      name: 'Ribeira Grande de Santiago',
      code: 'CV-RS',
    },
    {
      name: 'São Domingos',
      code: 'CV-SD',
    },
    {
      name: 'São Filipe',
      code: 'CV-SF',
    },
    {
      name: 'Sal',
      code: 'CV-SL',
    },
    {
      name: 'São Miguel',
      code: 'CV-SM',
    },
    {
      name: 'São Lourenço dos Órgãos',
      code: 'CV-SO',
    },
    {
      name: 'São Salvador do Mundo',
      code: 'CV-SS',
    },
    {
      name: 'São Vicente',
      code: 'CV-SV',
    },
    {
      name: 'Tarrafal',
      code: 'CV-TA',
    },
    {
      name: 'Tarrafal de São Nicolau',
      code: 'CV-TS',
    },
  ],
  CY: [
    {
      name: 'Lefkosia',
      code: 'CY-01',
    },
    {
      name: 'Lemesos',
      code: 'CY-02',
    },
    {
      name: 'Larnaka',
      code: 'CY-03',
    },
    {
      name: 'Ammochostos',
      code: 'CY-04',
    },
    {
      name: 'Pafos',
      code: 'CY-05',
    },
    {
      name: 'Keryneia',
      code: 'CY-06',
    },
  ],
  CZ: [
    {
      name: 'Praha, Hlavní město',
      code: 'CZ-10',
    },
    {
      name: 'Středočeský kraj',
      code: 'CZ-20',
    },
    {
      name: 'Jihočeský kraj',
      code: 'CZ-31',
    },
    {
      name: 'Plzeňský kraj',
      code: 'CZ-32',
    },
    {
      name: 'Karlovarský kraj',
      code: 'CZ-41',
    },
    {
      name: 'Ústecký kraj',
      code: 'CZ-42',
    },
    {
      name: 'Liberecký kraj',
      code: 'CZ-51',
    },
    {
      name: 'Královéhradecký kraj',
      code: 'CZ-52',
    },
    {
      name: 'Pardubický kraj',
      code: 'CZ-53',
    },
    {
      name: 'Kraj Vysočina',
      code: 'CZ-63',
    },
    {
      name: 'Jihomoravský kraj',
      code: 'CZ-64',
    },
    {
      name: 'Olomoucký kraj',
      code: 'CZ-71',
    },
    {
      name: 'Zlínský kraj',
      code: 'CZ-72',
    },
    {
      name: 'Moravskoslezský kraj',
      code: 'CZ-80',
    },
  ],
  DE: [
    {
      name: 'Brandenburg',
      code: 'DE-BB',
    },
    {
      name: 'Berlin',
      code: 'DE-BE',
    },
    {
      name: 'Baden-Württemberg',
      code: 'DE-BW',
    },
    {
      name: 'Bayern',
      code: 'DE-BY',
    },
    {
      name: 'Bremen',
      code: 'DE-HB',
    },
    {
      name: 'Hessen',
      code: 'DE-HE',
    },
    {
      name: 'Hamburg',
      code: 'DE-HH',
    },
    {
      name: 'Mecklenburg-Vorpommern',
      code: 'DE-MV',
    },
    {
      name: 'Niedersachsen',
      code: 'DE-NI',
    },
    {
      name: 'Nordrhein-Westfalen',
      code: 'DE-NW',
    },
    {
      name: 'Rheinland-Pfalz',
      code: 'DE-RP',
    },
    {
      name: 'Schleswig-Holstein',
      code: 'DE-SH',
    },
    {
      name: 'Saarland',
      code: 'DE-SL',
    },
    {
      name: 'Sachsen',
      code: 'DE-SN',
    },
    {
      name: 'Sachsen-Anhalt',
      code: 'DE-ST',
    },
    {
      name: 'Thüringen',
      code: 'DE-TH',
    },
  ],
  DJ: [
    {
      name: 'Arta',
      code: 'DJ-AR',
    },
    {
      name: 'Ali Sabieh',
      code: 'DJ-AS',
    },
    {
      name: 'Dikhil',
      code: 'DJ-DI',
    },
    {
      name: 'Djibouti',
      code: 'DJ-DJ',
    },
    {
      name: 'Obock',
      code: 'DJ-OB',
    },
    {
      name: 'Tadjourah',
      code: 'DJ-TA',
    },
  ],
  DK: [
    {
      name: 'Nordjylland',
      code: 'DK-81',
    },
    {
      name: 'Midtjylland',
      code: 'DK-82',
    },
    {
      name: 'Syddanmark',
      code: 'DK-83',
    },
    {
      name: 'Hovedstaden',
      code: 'DK-84',
    },
    {
      name: 'Sjælland',
      code: 'DK-85',
    },
  ],
  DM: [
    {
      name: 'Saint Andrew',
      code: 'DM-02',
    },
    {
      name: 'Saint David',
      code: 'DM-03',
    },
    {
      name: 'Saint George',
      code: 'DM-04',
    },
    {
      name: 'Saint John',
      code: 'DM-05',
    },
    {
      name: 'Saint Joseph',
      code: 'DM-06',
    },
    {
      name: 'Saint Luke',
      code: 'DM-07',
    },
    {
      name: 'Saint Mark',
      code: 'DM-08',
    },
    {
      name: 'Saint Patrick',
      code: 'DM-09',
    },
    {
      name: 'Saint Paul',
      code: 'DM-10',
    },
    {
      name: 'Saint Peter',
      code: 'DM-11',
    },
  ],
  DO: [
    {
      name: 'Distrito Nacional',
      code: 'DO-01',
    },
    {
      name: 'Azua',
      code: 'DO-02',
    },
    {
      name: 'Baoruco',
      code: 'DO-03',
    },
    {
      name: 'Barahona',
      code: 'DO-04',
    },
    {
      name: 'Dajabón',
      code: 'DO-05',
    },
    {
      name: 'Duarte',
      code: 'DO-06',
    },
    {
      name: 'Elías Piña',
      code: 'DO-07',
    },
    {
      name: 'El Seibo',
      code: 'DO-08',
    },
    {
      name: 'Espaillat',
      code: 'DO-09',
    },
    {
      name: 'Independencia',
      code: 'DO-10',
    },
    {
      name: 'La Altagracia',
      code: 'DO-11',
    },
    {
      name: 'La Romana',
      code: 'DO-12',
    },
    {
      name: 'La Vega',
      code: 'DO-13',
    },
    {
      name: 'María Trinidad Sánchez',
      code: 'DO-14',
    },
    {
      name: 'Monte Cristi',
      code: 'DO-15',
    },
    {
      name: 'Pedernales',
      code: 'DO-16',
    },
    {
      name: 'Peravia',
      code: 'DO-17',
    },
    {
      name: 'Puerto Plata',
      code: 'DO-18',
    },
    {
      name: 'Hermanas Mirabal',
      code: 'DO-19',
    },
    {
      name: 'Samaná',
      code: 'DO-20',
    },
    {
      name: 'San Cristóbal',
      code: 'DO-21',
    },
    {
      name: 'San Juan',
      code: 'DO-22',
    },
    {
      name: 'San Pedro de Macorís',
      code: 'DO-23',
    },
    {
      name: 'Sánchez Ramírez',
      code: 'DO-24',
    },
    {
      name: 'Santiago',
      code: 'DO-25',
    },
    {
      name: 'Santiago Rodríguez',
      code: 'DO-26',
    },
    {
      name: 'Valverde',
      code: 'DO-27',
    },
    {
      name: 'Monseñor Nouel',
      code: 'DO-28',
    },
    {
      name: 'Monte Plata',
      code: 'DO-29',
    },
    {
      name: 'Hato Mayor',
      code: 'DO-30',
    },
    {
      name: 'San José de Ocoa',
      code: 'DO-31',
    },
    {
      name: 'Santo Domingo',
      code: 'DO-32',
    },
  ],
  DZ: [
    {
      name: 'Adrar',
      code: 'DZ-01',
    },
    {
      name: 'Chlef',
      code: 'DZ-02',
    },
    {
      name: 'Laghouat',
      code: 'DZ-03',
    },
    {
      name: 'Oum el Bouaghi',
      code: 'DZ-04',
    },
    {
      name: 'Batna',
      code: 'DZ-05',
    },
    {
      name: 'Béjaïa',
      code: 'DZ-06',
    },
    {
      name: 'Biskra',
      code: 'DZ-07',
    },
    {
      name: 'Béchar',
      code: 'DZ-08',
    },
    {
      name: 'Blida',
      code: 'DZ-09',
    },
    {
      name: 'Bouira',
      code: 'DZ-10',
    },
    {
      name: 'Tamanrasset',
      code: 'DZ-11',
    },
    {
      name: 'Tébessa',
      code: 'DZ-12',
    },
    {
      name: 'Tlemcen',
      code: 'DZ-13',
    },
    {
      name: 'Tiaret',
      code: 'DZ-14',
    },
    {
      name: 'Tizi Ouzou',
      code: 'DZ-15',
    },
    {
      name: 'Alger',
      code: 'DZ-16',
    },
    {
      name: 'Djelfa',
      code: 'DZ-17',
    },
    {
      name: 'Jijel',
      code: 'DZ-18',
    },
    {
      name: 'Sétif',
      code: 'DZ-19',
    },
    {
      name: 'Saïda',
      code: 'DZ-20',
    },
    {
      name: 'Skikda',
      code: 'DZ-21',
    },
    {
      name: 'Sidi Bel Abbès',
      code: 'DZ-22',
    },
    {
      name: 'Annaba',
      code: 'DZ-23',
    },
    {
      name: 'Guelma',
      code: 'DZ-24',
    },
    {
      name: 'Constantine',
      code: 'DZ-25',
    },
    {
      name: 'Médéa',
      code: 'DZ-26',
    },
    {
      name: 'Mostaganem',
      code: 'DZ-27',
    },
    {
      name: "M'sila",
      code: 'DZ-28',
    },
    {
      name: 'Mascara',
      code: 'DZ-29',
    },
    {
      name: 'Ouargla',
      code: 'DZ-30',
    },
    {
      name: 'Oran',
      code: 'DZ-31',
    },
    {
      name: 'El Bayadh',
      code: 'DZ-32',
    },
    {
      name: 'Illizi',
      code: 'DZ-33',
    },
    {
      name: 'Bordj Bou Arréridj',
      code: 'DZ-34',
    },
    {
      name: 'Boumerdès',
      code: 'DZ-35',
    },
    {
      name: 'El Tarf',
      code: 'DZ-36',
    },
    {
      name: 'Tindouf',
      code: 'DZ-37',
    },
    {
      name: 'Tissemsilt',
      code: 'DZ-38',
    },
    {
      name: 'El Oued',
      code: 'DZ-39',
    },
    {
      name: 'Khenchela',
      code: 'DZ-40',
    },
    {
      name: 'Souk Ahras',
      code: 'DZ-41',
    },
    {
      name: 'Tipaza',
      code: 'DZ-42',
    },
    {
      name: 'Mila',
      code: 'DZ-43',
    },
    {
      name: 'Aïn Defla',
      code: 'DZ-44',
    },
    {
      name: 'Naama',
      code: 'DZ-45',
    },
    {
      name: 'Aïn Témouchent',
      code: 'DZ-46',
    },
    {
      name: 'Ghardaïa',
      code: 'DZ-47',
    },
    {
      name: 'Relizane',
      code: 'DZ-48',
    },
  ],
  EC: [
    {
      name: 'Azuay',
      code: 'EC-A',
    },
    {
      name: 'Bolívar',
      code: 'EC-B',
    },
    {
      name: 'Carchi',
      code: 'EC-C',
    },
    {
      name: 'Orellana',
      code: 'EC-D',
    },
    {
      name: 'Esmeraldas',
      code: 'EC-E',
    },
    {
      name: 'Cañar',
      code: 'EC-F',
    },
    {
      name: 'Guayas',
      code: 'EC-G',
    },
    {
      name: 'Chimborazo',
      code: 'EC-H',
    },
    {
      name: 'Imbabura',
      code: 'EC-I',
    },
    {
      name: 'Loja',
      code: 'EC-L',
    },
    {
      name: 'Manabí',
      code: 'EC-M',
    },
    {
      name: 'Napo',
      code: 'EC-N',
    },
    {
      name: 'El Oro',
      code: 'EC-O',
    },
    {
      name: 'Pichincha',
      code: 'EC-P',
    },
    {
      name: 'Los Ríos',
      code: 'EC-R',
    },
    {
      name: 'Morona Santiago',
      code: 'EC-S',
    },
    {
      name: 'Santo Domingo de los Tsáchilas',
      code: 'EC-SD',
    },
    {
      name: 'Santa Elena',
      code: 'EC-SE',
    },
    {
      name: 'Tungurahua',
      code: 'EC-T',
    },
    {
      name: 'Sucumbíos',
      code: 'EC-U',
    },
    {
      name: 'Galápagos',
      code: 'EC-W',
    },
    {
      name: 'Cotopaxi',
      code: 'EC-X',
    },
    {
      name: 'Pastaza',
      code: 'EC-Y',
    },
    {
      name: 'Zamora Chinchipe',
      code: 'EC-Z',
    },
  ],
  EE: [
    {
      name: 'Harjumaa',
      code: 'EE-37',
    },
    {
      name: 'Hiiumaa',
      code: 'EE-39',
    },
    {
      name: 'Ida-Virumaa',
      code: 'EE-44',
    },
    {
      name: 'Jõgevamaa',
      code: 'EE-49',
    },
    {
      name: 'Järvamaa',
      code: 'EE-51',
    },
    {
      name: 'Läänemaa',
      code: 'EE-57',
    },
    {
      name: 'Lääne-Virumaa',
      code: 'EE-59',
    },
    {
      name: 'Põlvamaa',
      code: 'EE-65',
    },
    {
      name: 'Pärnumaa',
      code: 'EE-67',
    },
    {
      name: 'Raplamaa',
      code: 'EE-70',
    },
    {
      name: 'Saaremaa',
      code: 'EE-74',
    },
    {
      name: 'Tartumaa',
      code: 'EE-78',
    },
    {
      name: 'Valgamaa',
      code: 'EE-82',
    },
    {
      name: 'Viljandimaa',
      code: 'EE-84',
    },
    {
      name: 'Võrumaa',
      code: 'EE-86',
    },
  ],
  EG: [
    {
      name: 'Al Iskandarīyah',
      code: 'EG-ALX',
    },
    {
      name: 'Aswān',
      code: 'EG-ASN',
    },
    {
      name: 'Asyūţ',
      code: 'EG-AST',
    },
    {
      name: 'Al Baḩr al Aḩmar',
      code: 'EG-BA',
    },
    {
      name: 'Al Buḩayrah',
      code: 'EG-BH',
    },
    {
      name: 'Banī Suwayf',
      code: 'EG-BNS',
    },
    {
      name: 'Al Qāhirah',
      code: 'EG-C',
    },
    {
      name: 'Ad Daqahlīyah',
      code: 'EG-DK',
    },
    {
      name: 'Dumyāţ',
      code: 'EG-DT',
    },
    {
      name: 'Al Fayyūm',
      code: 'EG-FYM',
    },
    {
      name: 'Al Gharbīyah',
      code: 'EG-GH',
    },
    {
      name: 'Al Jīzah',
      code: 'EG-GZ',
    },
    {
      name: "Al Ismā'īlīyah",
      code: 'EG-IS',
    },
    {
      name: "Janūb Sīnā'",
      code: 'EG-JS',
    },
    {
      name: 'Al Qalyūbīyah',
      code: 'EG-KB',
    },
    {
      name: 'Kafr ash Shaykh',
      code: 'EG-KFS',
    },
    {
      name: 'Qinā',
      code: 'EG-KN',
    },
    {
      name: 'Al Uqşur',
      code: 'EG-LX',
    },
    {
      name: 'Al Minyā',
      code: 'EG-MN',
    },
    {
      name: 'Al Minūfīyah',
      code: 'EG-MNF',
    },
    {
      name: 'Maţrūḩ',
      code: 'EG-MT',
    },
    {
      name: 'Būr Sa‘īd',
      code: 'EG-PTS',
    },
    {
      name: 'Sūhāj',
      code: 'EG-SHG',
    },
    {
      name: 'Ash Sharqīyah',
      code: 'EG-SHR',
    },
    {
      name: "Shamāl Sīnā'",
      code: 'EG-SIN',
    },
    {
      name: 'As Suways',
      code: 'EG-SUZ',
    },
    {
      name: 'Al Wādī al Jadīd',
      code: 'EG-WAD',
    },
  ],
  ER: [
    {
      name: '‘Anseba',
      code: 'ER-AN',
    },
    {
      name: 'Debubawi K’eyyĭḥ Baḥri',
      code: 'ER-DK',
    },
    {
      name: 'Debub',
      code: 'ER-DU',
    },
    {
      name: 'Gash-Barka',
      code: 'ER-GB',
    },
    {
      name: 'Ma’ĭkel',
      code: 'ER-MA',
    },
    {
      name: 'Semienawi K’eyyĭḥ Baḥri',
      code: 'ER-SK',
    },
  ],
  ES: [
    {
      name: 'Alicante',
      code: 'ES-A',
    },
    {
      name: 'Albacete',
      code: 'ES-AB',
    },
    {
      name: 'Almería',
      code: 'ES-AL',
    },
    {
      name: 'Andalucía',
      code: 'ES-AN',
    },
    {
      name: 'Aragón',
      code: 'ES-AR',
    },
    {
      name: 'Asturias, Principado de',
      code: 'ES-AS',
    },
    {
      name: 'Ávila',
      code: 'ES-AV',
    },
    {
      name: 'Barcelona [Barcelona]',
      code: 'ES-B',
    },
    {
      name: 'Badajoz',
      code: 'ES-BA',
    },
    {
      name: 'Bizkaia',
      code: 'ES-BI',
    },
    {
      name: 'Burgos',
      code: 'ES-BU',
    },
    {
      name: 'A Coruña [La Coruña]',
      code: 'ES-C',
    },
    {
      name: 'Cádiz',
      code: 'ES-CA',
    },
    {
      name: 'Cantabria',
      code: 'ES-CB',
    },
    {
      name: 'Cáceres',
      code: 'ES-CC',
    },
    {
      name: 'Ceuta',
      code: 'ES-CE',
    },
    {
      name: 'Castilla y León',
      code: 'ES-CL',
    },
    {
      name: 'Castilla-La Mancha',
      code: 'ES-CM',
    },
    {
      name: 'Canarias',
      code: 'ES-CN',
    },
    {
      name: 'Córdoba',
      code: 'ES-CO',
    },
    {
      name: 'Ciudad Real',
      code: 'ES-CR',
    },
    {
      name: 'Castellón',
      code: 'ES-CS',
    },
    {
      name: 'Cuenca',
      code: 'ES-CU',
    },
    {
      name: 'Extremadura',
      code: 'ES-EX',
    },
    {
      name: 'Las Palmas',
      code: 'ES-GC',
    },
    {
      name: 'Girona [Gerona]',
      code: 'ES-GI',
    },
    {
      name: 'Granada',
      code: 'ES-GR',
    },
    {
      name: 'Guadalajara',
      code: 'ES-GU',
    },
    {
      name: 'Huelva',
      code: 'ES-H',
    },
    {
      name: 'Huesca',
      code: 'ES-HU',
    },
    {
      name: 'Jaén',
      code: 'ES-J',
    },
    {
      name: 'Lleida [Lérida]',
      code: 'ES-L',
    },
    {
      name: 'León',
      code: 'ES-LE',
    },
    {
      name: 'La Rioja',
      code: 'ES-LO',
    },
    {
      name: 'Lugo [Lugo]',
      code: 'ES-LU',
    },
    {
      name: 'Madrid',
      code: 'ES-M',
    },
    {
      name: 'Málaga',
      code: 'ES-MA',
    },
    {
      name: 'Murcia, Región de',
      code: 'ES-MC',
    },
    {
      name: 'Madrid, Comunidad de',
      code: 'ES-MD',
    },
    {
      name: 'Melilla',
      code: 'ES-ML',
    },
    {
      name: 'Murcia',
      code: 'ES-MU',
    },
    {
      name: 'Navarra',
      code: 'ES-NA',
    },
    {
      name: 'Navarra, Comunidad Foral de',
      code: 'ES-NC',
    },
    {
      name: 'Asturias',
      code: 'ES-O',
    },
    {
      name: 'Ourense [Orense]',
      code: 'ES-OR',
    },
    {
      name: 'Palencia',
      code: 'ES-P',
    },
    {
      name: 'Balears [Baleares]',
      code: 'ES-PM',
    },
    {
      name: 'Pontevedra [Pontevedra]',
      code: 'ES-PO',
    },
    {
      name: 'País Vasco',
      code: 'ES-PV',
    },
    {
      name: 'La Rioja',
      code: 'ES-RI',
    },
    {
      name: 'Cantabria',
      code: 'ES-S',
    },
    {
      name: 'Salamanca',
      code: 'ES-SA',
    },
    {
      name: 'Sevilla',
      code: 'ES-SE',
    },
    {
      name: 'Segovia',
      code: 'ES-SG',
    },
    {
      name: 'Soria',
      code: 'ES-SO',
    },
    {
      name: 'Gipuzkoa',
      code: 'ES-SS',
    },
    {
      name: 'Tarragona [Tarragona]',
      code: 'ES-T',
    },
    {
      name: 'Teruel',
      code: 'ES-TE',
    },
    {
      name: 'Santa Cruz de Tenerife',
      code: 'ES-TF',
    },
    {
      name: 'Toledo',
      code: 'ES-TO',
    },
    {
      name: 'Valencia',
      code: 'ES-V',
    },
    {
      name: 'Valladolid',
      code: 'ES-VA',
    },
    {
      name: 'Valenciana, Comunidad',
      code: 'ES-VC',
    },
    {
      name: 'Álava',
      code: 'ES-VI',
    },
    {
      name: 'Zaragoza',
      code: 'ES-Z',
    },
    {
      name: 'Zamora',
      code: 'ES-ZA',
    },
  ],
  ET: [
    {
      name: 'Addis Ababa',
      code: 'ET-AA',
    },
    {
      name: 'Afar',
      code: 'ET-AF',
    },
    {
      name: 'Amara',
      code: 'ET-AM',
    },
    {
      name: 'Benshangul-Gumaz',
      code: 'ET-BE',
    },
    {
      name: 'Dire Dawa',
      code: 'ET-DD',
    },
    {
      name: 'Gambela Peoples',
      code: 'ET-GA',
    },
    {
      name: 'Harari People',
      code: 'ET-HA',
    },
    {
      name: 'Oromia',
      code: 'ET-OR',
    },
    {
      name: 'Southern Nations, Nationalities and Peoples',
      code: 'ET-SN',
    },
    {
      name: 'Somali',
      code: 'ET-SO',
    },
    {
      name: 'Tigrai',
      code: 'ET-TI',
    },
  ],
  FI: [
    {
      name: 'Landskapet Åland',
      code: 'FI-01',
    },
    {
      name: 'Södra Karelen',
      code: 'FI-02',
    },
    {
      name: 'Södra Österbotten',
      code: 'FI-03',
    },
    {
      name: 'Södra Savolax',
      code: 'FI-04',
    },
    {
      name: 'Kajanaland',
      code: 'FI-05',
    },
    {
      name: 'Egentliga Tavastland',
      code: 'FI-06',
    },
    {
      name: 'Mellersta Österbotten',
      code: 'FI-07',
    },
    {
      name: 'Mellersta Finland',
      code: 'FI-08',
    },
    {
      name: 'Kymmenedalen',
      code: 'FI-09',
    },
    {
      name: 'Lappland',
      code: 'FI-10',
    },
    {
      name: 'Birkaland',
      code: 'FI-11',
    },
    {
      name: 'Österbotten',
      code: 'FI-12',
    },
    {
      name: 'Norra Karelen',
      code: 'FI-13',
    },
    {
      name: 'Norra Österbotten',
      code: 'FI-14',
    },
    {
      name: 'Norra Savolax',
      code: 'FI-15',
    },
    {
      name: 'Päijänne-Tavastland',
      code: 'FI-16',
    },
    {
      name: 'Satakunda',
      code: 'FI-17',
    },
    {
      name: 'Nyland',
      code: 'FI-18',
    },
    {
      name: 'Egentliga Finland',
      code: 'FI-19',
    },
  ],
  FJ: [
    {
      name: 'Central',
      code: 'FJ-C',
    },
    {
      name: 'Eastern',
      code: 'FJ-E',
    },
    {
      name: 'Northern',
      code: 'FJ-N',
    },
    {
      name: 'Rotuma',
      code: 'FJ-R',
    },
    {
      name: 'Western',
      code: 'FJ-W',
    },
  ],
  FM: [
    {
      name: 'Kosrae',
      code: 'FM-KSA',
    },
    {
      name: 'Pohnpei',
      code: 'FM-PNI',
    },
    {
      name: 'Chuuk',
      code: 'FM-TRK',
    },
    {
      name: 'Yap',
      code: 'FM-YAP',
    },
  ],
  FR: [
    {
      name: 'Ain',
      code: 'FR-01',
    },
    {
      name: 'Aisne',
      code: 'FR-02',
    },
    {
      name: 'Allier',
      code: 'FR-03',
    },
    {
      name: 'Alpes-de-Haute-Provence',
      code: 'FR-04',
    },
    {
      name: 'Hautes-Alpes',
      code: 'FR-05',
    },
    {
      name: 'Alpes-Maritimes',
      code: 'FR-06',
    },
    {
      name: 'Ardèche',
      code: 'FR-07',
    },
    {
      name: 'Ardennes',
      code: 'FR-08',
    },
    {
      name: 'Ariège',
      code: 'FR-09',
    },
    {
      name: 'Aube',
      code: 'FR-10',
    },
    {
      name: 'Aude',
      code: 'FR-11',
    },
    {
      name: 'Aveyron',
      code: 'FR-12',
    },
    {
      name: 'Bouches-du-Rhône',
      code: 'FR-13',
    },
    {
      name: 'Calvados',
      code: 'FR-14',
    },
    {
      name: 'Cantal',
      code: 'FR-15',
    },
    {
      name: 'Charente',
      code: 'FR-16',
    },
    {
      name: 'Charente-Maritime',
      code: 'FR-17',
    },
    {
      name: 'Cher',
      code: 'FR-18',
    },
    {
      name: 'Corrèze',
      code: 'FR-19',
    },
    {
      name: "Côte-d'Or",
      code: 'FR-21',
    },
    {
      name: "Côtes-d'Armor",
      code: 'FR-22',
    },
    {
      name: 'Creuse',
      code: 'FR-23',
    },
    {
      name: 'Dordogne',
      code: 'FR-24',
    },
    {
      name: 'Doubs',
      code: 'FR-25',
    },
    {
      name: 'Drôme',
      code: 'FR-26',
    },
    {
      name: 'Eure',
      code: 'FR-27',
    },
    {
      name: 'Eure-et-Loir',
      code: 'FR-28',
    },
    {
      name: 'Finistère',
      code: 'FR-29',
    },
    {
      name: 'Corse-du-Sud',
      code: 'FR-2A',
    },
    {
      name: 'Haute-Corse',
      code: 'FR-2B',
    },
    {
      name: 'Gard',
      code: 'FR-30',
    },
    {
      name: 'Haute-Garonne',
      code: 'FR-31',
    },
    {
      name: 'Gers',
      code: 'FR-32',
    },
    {
      name: 'Gironde',
      code: 'FR-33',
    },
    {
      name: 'Hérault',
      code: 'FR-34',
    },
    {
      name: 'Ille-et-Vilaine',
      code: 'FR-35',
    },
    {
      name: 'Indre',
      code: 'FR-36',
    },
    {
      name: 'Indre-et-Loire',
      code: 'FR-37',
    },
    {
      name: 'Isère',
      code: 'FR-38',
    },
    {
      name: 'Jura',
      code: 'FR-39',
    },
    {
      name: 'Landes',
      code: 'FR-40',
    },
    {
      name: 'Loir-et-Cher',
      code: 'FR-41',
    },
    {
      name: 'Loire',
      code: 'FR-42',
    },
    {
      name: 'Haute-Loire',
      code: 'FR-43',
    },
    {
      name: 'Loire-Atlantique',
      code: 'FR-44',
    },
    {
      name: 'Loiret',
      code: 'FR-45',
    },
    {
      name: 'Lot',
      code: 'FR-46',
    },
    {
      name: 'Lot-et-Garonne',
      code: 'FR-47',
    },
    {
      name: 'Lozère',
      code: 'FR-48',
    },
    {
      name: 'Maine-et-Loire',
      code: 'FR-49',
    },
    {
      name: 'Manche',
      code: 'FR-50',
    },
    {
      name: 'Marne',
      code: 'FR-51',
    },
    {
      name: 'Haute-Marne',
      code: 'FR-52',
    },
    {
      name: 'Mayenne',
      code: 'FR-53',
    },
    {
      name: 'Meurthe-et-Moselle',
      code: 'FR-54',
    },
    {
      name: 'Meuse',
      code: 'FR-55',
    },
    {
      name: 'Morbihan',
      code: 'FR-56',
    },
    {
      name: 'Moselle',
      code: 'FR-57',
    },
    {
      name: 'Nièvre',
      code: 'FR-58',
    },
    {
      name: 'Nord',
      code: 'FR-59',
    },
    {
      name: 'Oise',
      code: 'FR-60',
    },
    {
      name: 'Orne',
      code: 'FR-61',
    },
    {
      name: 'Pas-de-Calais',
      code: 'FR-62',
    },
    {
      name: 'Puy-de-Dôme',
      code: 'FR-63',
    },
    {
      name: 'Pyrénées-Atlantiques',
      code: 'FR-64',
    },
    {
      name: 'Hautes-Pyrénées',
      code: 'FR-65',
    },
    {
      name: 'Pyrénées-Orientales',
      code: 'FR-66',
    },
    {
      name: 'Bas-Rhin',
      code: 'FR-67',
    },
    {
      name: 'Haut-Rhin',
      code: 'FR-68',
    },
    {
      name: 'Rhône',
      code: 'FR-69',
    },
    {
      name: 'Haute-Saône',
      code: 'FR-70',
    },
    {
      name: 'Saône-et-Loire',
      code: 'FR-71',
    },
    {
      name: 'Sarthe',
      code: 'FR-72',
    },
    {
      name: 'Savoie',
      code: 'FR-73',
    },
    {
      name: 'Haute-Savoie',
      code: 'FR-74',
    },
    {
      name: 'Paris',
      code: 'FR-75',
    },
    {
      name: 'Seine-Maritime',
      code: 'FR-76',
    },
    {
      name: 'Seine-et-Marne',
      code: 'FR-77',
    },
    {
      name: 'Yvelines',
      code: 'FR-78',
    },
    {
      name: 'Deux-Sèvres',
      code: 'FR-79',
    },
    {
      name: 'Somme',
      code: 'FR-80',
    },
    {
      name: 'Tarn',
      code: 'FR-81',
    },
    {
      name: 'Tarn-et-Garonne',
      code: 'FR-82',
    },
    {
      name: 'Var',
      code: 'FR-83',
    },
    {
      name: 'Vaucluse',
      code: 'FR-84',
    },
    {
      name: 'Vendée',
      code: 'FR-85',
    },
    {
      name: 'Vienne',
      code: 'FR-86',
    },
    {
      name: 'Haute-Vienne',
      code: 'FR-87',
    },
    {
      name: 'Vosges',
      code: 'FR-88',
    },
    {
      name: 'Yonne',
      code: 'FR-89',
    },
    {
      name: 'Territoire de Belfort',
      code: 'FR-90',
    },
    {
      name: 'Essonne',
      code: 'FR-91',
    },
    {
      name: 'Hauts-de-Seine',
      code: 'FR-92',
    },
    {
      name: 'Seine-Saint-Denis',
      code: 'FR-93',
    },
    {
      name: 'Val-de-Marne',
      code: 'FR-94',
    },
    {
      name: "Val-d'Oise",
      code: 'FR-95',
    },
    {
      name: 'Saint-Barthélemy',
      code: 'FR-BL',
    },
    {
      name: 'Clipperton',
      code: 'FR-CP',
    },
    {
      name: 'Guyane',
      code: 'FR-GF',
    },
    {
      name: 'Guadeloupe',
      code: 'FR-GUA',
    },
    {
      name: 'La Réunion',
      code: 'FR-LRE',
    },
    {
      name: 'Mayotte',
      code: 'FR-MAY',
    },
    {
      name: 'Saint-Martin',
      code: 'FR-MF',
    },
    {
      name: 'Martinique',
      code: 'FR-MQ',
    },
    {
      name: 'Nouvelle-Calédonie',
      code: 'FR-NC',
    },
    {
      name: 'Polynésie française',
      code: 'FR-PF',
    },
    {
      name: 'Saint-Pierre-et-Miquelon',
      code: 'FR-PM',
    },
    {
      name: 'Terres australes françaises',
      code: 'FR-TF',
    },
    {
      name: 'Wallis-et-Futuna',
      code: 'FR-WF',
    },
  ],
  GA: [
    {
      name: 'Estuaire',
      code: 'GA-01',
    },
    {
      name: 'Haut-Ogooué',
      code: 'GA-02',
    },
    {
      name: 'Moyen-Ogooué',
      code: 'GA-03',
    },
    {
      name: 'Ngounié',
      code: 'GA-04',
    },
    {
      name: 'Nyanga',
      code: 'GA-05',
    },
    {
      name: 'Ogooué-Ivindo',
      code: 'GA-06',
    },
    {
      name: 'Ogooué-Lolo',
      code: 'GA-07',
    },
    {
      name: 'Ogooué-Maritime',
      code: 'GA-08',
    },
    {
      name: 'Woleu-Ntem',
      code: 'GA-09',
    },
  ],
  GB: [
    {
      name: 'Armagh City, Banbridge and Craigavon',
      code: 'GB-ABC',
    },
    {
      name: 'Aberdeenshire',
      code: 'GB-ABD',
    },
    {
      name: 'Aberdeen City',
      code: 'GB-ABE',
    },
    {
      name: 'Argyll and Bute',
      code: 'GB-AGB',
    },
    {
      name: 'Isle of Anglesey [Sir Ynys Môn GB-YNM]',
      code: 'GB-AGY',
    },
    {
      name: 'Ards and North Down',
      code: 'GB-AND',
    },
    {
      name: 'Antrim and Newtownabbey',
      code: 'GB-ANN',
    },
    {
      name: 'Angus',
      code: 'GB-ANS',
    },
    {
      name: 'Bath and North East Somerset',
      code: 'GB-BAS',
    },
    {
      name: 'Blackburn with Darwen',
      code: 'GB-BBD',
    },
    {
      name: 'Bedford',
      code: 'GB-BDF',
    },
    {
      name: 'Barking and Dagenham',
      code: 'GB-BDG',
    },
    {
      name: 'Brent',
      code: 'GB-BEN',
    },
    {
      name: 'Bexley',
      code: 'GB-BEX',
    },
    {
      name: 'Belfast',
      code: 'GB-BFS',
    },
    {
      name: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]',
      code: 'GB-BGE',
    },
    {
      name: 'Blaenau Gwent',
      code: 'GB-BGW',
    },
    {
      name: 'Birmingham',
      code: 'GB-BIR',
    },
    {
      name: 'Buckinghamshire',
      code: 'GB-BKM',
    },
    {
      name: 'Bournemouth',
      code: 'GB-BMH',
    },
    {
      name: 'Barnet',
      code: 'GB-BNE',
    },
    {
      name: 'Brighton and Hove',
      code: 'GB-BNH',
    },
    {
      name: 'Barnsley',
      code: 'GB-BNS',
    },
    {
      name: 'Bolton',
      code: 'GB-BOL',
    },
    {
      name: 'Blackpool',
      code: 'GB-BPL',
    },
    {
      name: 'Bracknell Forest',
      code: 'GB-BRC',
    },
    {
      name: 'Bradford',
      code: 'GB-BRD',
    },
    {
      name: 'Bromley',
      code: 'GB-BRY',
    },
    {
      name: 'Bristol, City of',
      code: 'GB-BST',
    },
    {
      name: 'Bury',
      code: 'GB-BUR',
    },
    {
      name: 'Cambridgeshire',
      code: 'GB-CAM',
    },
    {
      name: 'Caerphilly [Caerffili GB-CAF]',
      code: 'GB-CAY',
    },
    {
      name: 'Central Bedfordshire',
      code: 'GB-CBF',
    },
    {
      name: 'Causeway Coast and Glens',
      code: 'GB-CCG',
    },
    {
      name: 'Ceredigion [Sir Ceredigion]',
      code: 'GB-CGN',
    },
    {
      name: 'Cheshire East',
      code: 'GB-CHE',
    },
    {
      name: 'Cheshire West and Chester',
      code: 'GB-CHW',
    },
    {
      name: 'Calderdale',
      code: 'GB-CLD',
    },
    {
      name: 'Clackmannanshire',
      code: 'GB-CLK',
    },
    {
      name: 'Cumbria',
      code: 'GB-CMA',
    },
    {
      name: 'Camden',
      code: 'GB-CMD',
    },
    {
      name: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]',
      code: 'GB-CMN',
    },
    {
      name: 'Cornwall',
      code: 'GB-CON',
    },
    {
      name: 'Coventry',
      code: 'GB-COV',
    },
    {
      name: 'Cardiff [Caerdydd GB-CRD]',
      code: 'GB-CRF',
    },
    {
      name: 'Croydon',
      code: 'GB-CRY',
    },
    {
      name: 'Conwy',
      code: 'GB-CWY',
    },
    {
      name: 'Darlington',
      code: 'GB-DAL',
    },
    {
      name: 'Derbyshire',
      code: 'GB-DBY',
    },
    {
      name: 'Denbighshire [Sir Ddinbych GB-DDB]',
      code: 'GB-DEN',
    },
    {
      name: 'Derby',
      code: 'GB-DER',
    },
    {
      name: 'Devon',
      code: 'GB-DEV',
    },
    {
      name: 'Dumfries and Galloway',
      code: 'GB-DGY',
    },
    {
      name: 'Doncaster',
      code: 'GB-DNC',
    },
    {
      name: 'Dundee City',
      code: 'GB-DND',
    },
    {
      name: 'Dorset',
      code: 'GB-DOR',
    },
    {
      name: 'Derry City and Strabane',
      code: 'GB-DRS',
    },
    {
      name: 'Dudley',
      code: 'GB-DUD',
    },
    {
      name: 'Durham County',
      code: 'GB-DUR',
    },
    {
      name: 'Ealing',
      code: 'GB-EAL',
    },
    {
      name: 'East Ayrshire',
      code: 'GB-EAY',
    },
    {
      name: 'Edinburgh, City of',
      code: 'GB-EDH',
    },
    {
      name: 'East Dunbartonshire',
      code: 'GB-EDU',
    },
    {
      name: 'East Lothian',
      code: 'GB-ELN',
    },
    {
      name: 'Eilean Siar',
      code: 'GB-ELS',
    },
    {
      name: 'Enfield',
      code: 'GB-ENF',
    },
    {
      name: 'East Renfrewshire',
      code: 'GB-ERW',
    },
    {
      name: 'East Riding of Yorkshire',
      code: 'GB-ERY',
    },
    {
      name: 'Essex',
      code: 'GB-ESS',
    },
    {
      name: 'East Sussex',
      code: 'GB-ESX',
    },
    {
      name: 'Falkirk',
      code: 'GB-FAL',
    },
    {
      name: 'Fife',
      code: 'GB-FIF',
    },
    {
      name: 'Flintshire [Sir y Fflint GB-FFL]',
      code: 'GB-FLN',
    },
    {
      name: 'Fermanagh and Omagh',
      code: 'GB-FMO',
    },
    {
      name: 'Gateshead',
      code: 'GB-GAT',
    },
    {
      name: 'Glasgow City',
      code: 'GB-GLG',
    },
    {
      name: 'Gloucestershire',
      code: 'GB-GLS',
    },
    {
      name: 'Greenwich',
      code: 'GB-GRE',
    },
    {
      name: 'Gwynedd',
      code: 'GB-GWN',
    },
    {
      name: 'Halton',
      code: 'GB-HAL',
    },
    {
      name: 'Hampshire',
      code: 'GB-HAM',
    },
    {
      name: 'Havering',
      code: 'GB-HAV',
    },
    {
      name: 'Hackney',
      code: 'GB-HCK',
    },
    {
      name: 'Herefordshire',
      code: 'GB-HEF',
    },
    {
      name: 'Hillingdon',
      code: 'GB-HIL',
    },
    {
      name: 'Highland',
      code: 'GB-HLD',
    },
    {
      name: 'Hammersmith and Fulham',
      code: 'GB-HMF',
    },
    {
      name: 'Hounslow',
      code: 'GB-HNS',
    },
    {
      name: 'Hartlepool',
      code: 'GB-HPL',
    },
    {
      name: 'Hertfordshire',
      code: 'GB-HRT',
    },
    {
      name: 'Harrow',
      code: 'GB-HRW',
    },
    {
      name: 'Haringey',
      code: 'GB-HRY',
    },
    {
      name: 'Isles of Scilly',
      code: 'GB-IOS',
    },
    {
      name: 'Isle of Wight',
      code: 'GB-IOW',
    },
    {
      name: 'Islington',
      code: 'GB-ISL',
    },
    {
      name: 'Inverclyde',
      code: 'GB-IVC',
    },
    {
      name: 'Kensington and Chelsea',
      code: 'GB-KEC',
    },
    {
      name: 'Kent',
      code: 'GB-KEN',
    },
    {
      name: 'Kingston upon Hull',
      code: 'GB-KHL',
    },
    {
      name: 'Kirklees',
      code: 'GB-KIR',
    },
    {
      name: 'Kingston upon Thames',
      code: 'GB-KTT',
    },
    {
      name: 'Knowsley',
      code: 'GB-KWL',
    },
    {
      name: 'Lancashire',
      code: 'GB-LAN',
    },
    {
      name: 'Lisburn and Castlereagh',
      code: 'GB-LBC',
    },
    {
      name: 'Lambeth',
      code: 'GB-LBH',
    },
    {
      name: 'Leicester',
      code: 'GB-LCE',
    },
    {
      name: 'Leeds',
      code: 'GB-LDS',
    },
    {
      name: 'Leicestershire',
      code: 'GB-LEC',
    },
    {
      name: 'Lewisham',
      code: 'GB-LEW',
    },
    {
      name: 'Lincolnshire',
      code: 'GB-LIN',
    },
    {
      name: 'Liverpool',
      code: 'GB-LIV',
    },
    {
      name: 'London, City of',
      code: 'GB-LND',
    },
    {
      name: 'Luton',
      code: 'GB-LUT',
    },
    {
      name: 'Manchester',
      code: 'GB-MAN',
    },
    {
      name: 'Middlesbrough',
      code: 'GB-MDB',
    },
    {
      name: 'Medway',
      code: 'GB-MDW',
    },
    {
      name: 'Mid and East Antrim',
      code: 'GB-MEA',
    },
    {
      name: 'Milton Keynes',
      code: 'GB-MIK',
    },
    {
      name: 'Midlothian',
      code: 'GB-MLN',
    },
    {
      name: 'Monmouthshire [Sir Fynwy GB-FYN]',
      code: 'GB-MON',
    },
    {
      name: 'Merton',
      code: 'GB-MRT',
    },
    {
      name: 'Moray',
      code: 'GB-MRY',
    },
    {
      name: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]',
      code: 'GB-MTY',
    },
    {
      name: 'Mid Ulster',
      code: 'GB-MUL',
    },
    {
      name: 'North Ayrshire',
      code: 'GB-NAY',
    },
    {
      name: 'Northumberland',
      code: 'GB-NBL',
    },
    {
      name: 'North East Lincolnshire',
      code: 'GB-NEL',
    },
    {
      name: 'Newcastle upon Tyne',
      code: 'GB-NET',
    },
    {
      name: 'Norfolk',
      code: 'GB-NFK',
    },
    {
      name: 'Nottingham',
      code: 'GB-NGM',
    },
    {
      name: 'North Lanarkshire',
      code: 'GB-NLK',
    },
    {
      name: 'North Lincolnshire',
      code: 'GB-NLN',
    },
    {
      name: 'Newry, Mourne and Down',
      code: 'GB-NMD',
    },
    {
      name: 'North Somerset',
      code: 'GB-NSM',
    },
    {
      name: 'Northamptonshire',
      code: 'GB-NTH',
    },
    {
      name: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]',
      code: 'GB-NTL',
    },
    {
      name: 'Nottinghamshire',
      code: 'GB-NTT',
    },
    {
      name: 'North Tyneside',
      code: 'GB-NTY',
    },
    {
      name: 'Newham',
      code: 'GB-NWM',
    },
    {
      name: 'Newport [Casnewydd GB-CNW]',
      code: 'GB-NWP',
    },
    {
      name: 'North Yorkshire',
      code: 'GB-NYK',
    },
    {
      name: 'Oldham',
      code: 'GB-OLD',
    },
    {
      name: 'Orkney Islands',
      code: 'GB-ORK',
    },
    {
      name: 'Oxfordshire',
      code: 'GB-OXF',
    },
    {
      name: 'Pembrokeshire [Sir Benfro GB-BNF]',
      code: 'GB-PEM',
    },
    {
      name: 'Perth and Kinross',
      code: 'GB-PKN',
    },
    {
      name: 'Plymouth',
      code: 'GB-PLY',
    },
    {
      name: 'Poole',
      code: 'GB-POL',
    },
    {
      name: 'Portsmouth',
      code: 'GB-POR',
    },
    {
      name: 'Powys',
      code: 'GB-POW',
    },
    {
      name: 'Peterborough',
      code: 'GB-PTE',
    },
    {
      name: 'Redcar and Cleveland',
      code: 'GB-RCC',
    },
    {
      name: 'Rochdale',
      code: 'GB-RCH',
    },
    {
      name: 'Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]',
      code: 'GB-RCT',
    },
    {
      name: 'Redbridge',
      code: 'GB-RDB',
    },
    {
      name: 'Reading',
      code: 'GB-RDG',
    },
    {
      name: 'Renfrewshire',
      code: 'GB-RFW',
    },
    {
      name: 'Richmond upon Thames',
      code: 'GB-RIC',
    },
    {
      name: 'Rotherham',
      code: 'GB-ROT',
    },
    {
      name: 'Rutland',
      code: 'GB-RUT',
    },
    {
      name: 'Sandwell',
      code: 'GB-SAW',
    },
    {
      name: 'South Ayrshire',
      code: 'GB-SAY',
    },
    {
      name: 'Scottish Borders, The',
      code: 'GB-SCB',
    },
    {
      name: 'Suffolk',
      code: 'GB-SFK',
    },
    {
      name: 'Sefton',
      code: 'GB-SFT',
    },
    {
      name: 'South Gloucestershire',
      code: 'GB-SGC',
    },
    {
      name: 'Sheffield',
      code: 'GB-SHF',
    },
    {
      name: 'St. Helens',
      code: 'GB-SHN',
    },
    {
      name: 'Shropshire',
      code: 'GB-SHR',
    },
    {
      name: 'Stockport',
      code: 'GB-SKP',
    },
    {
      name: 'Salford',
      code: 'GB-SLF',
    },
    {
      name: 'Slough',
      code: 'GB-SLG',
    },
    {
      name: 'South Lanarkshire',
      code: 'GB-SLK',
    },
    {
      name: 'Sunderland',
      code: 'GB-SND',
    },
    {
      name: 'Solihull',
      code: 'GB-SOL',
    },
    {
      name: 'Somerset',
      code: 'GB-SOM',
    },
    {
      name: 'Southend-on-Sea',
      code: 'GB-SOS',
    },
    {
      name: 'Surrey',
      code: 'GB-SRY',
    },
    {
      name: 'Stoke-on-Trent',
      code: 'GB-STE',
    },
    {
      name: 'Stirling',
      code: 'GB-STG',
    },
    {
      name: 'Southampton',
      code: 'GB-STH',
    },
    {
      name: 'Sutton',
      code: 'GB-STN',
    },
    {
      name: 'Staffordshire',
      code: 'GB-STS',
    },
    {
      name: 'Stockton-on-Tees',
      code: 'GB-STT',
    },
    {
      name: 'South Tyneside',
      code: 'GB-STY',
    },
    {
      name: 'Swansea [Abertawe GB-ATA]',
      code: 'GB-SWA',
    },
    {
      name: 'Swindon',
      code: 'GB-SWD',
    },
    {
      name: 'Southwark',
      code: 'GB-SWK',
    },
    {
      name: 'Tameside',
      code: 'GB-TAM',
    },
    {
      name: 'Telford and Wrekin',
      code: 'GB-TFW',
    },
    {
      name: 'Thurrock',
      code: 'GB-THR',
    },
    {
      name: 'Torbay',
      code: 'GB-TOB',
    },
    {
      name: 'Torfaen [Tor-faen]',
      code: 'GB-TOF',
    },
    {
      name: 'Trafford',
      code: 'GB-TRF',
    },
    {
      name: 'Tower Hamlets',
      code: 'GB-TWH',
    },
    {
      name: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]',
      code: 'GB-VGL',
    },
    {
      name: 'Warwickshire',
      code: 'GB-WAR',
    },
    {
      name: 'West Berkshire',
      code: 'GB-WBK',
    },
    {
      name: 'West Dunbartonshire',
      code: 'GB-WDU',
    },
    {
      name: 'Waltham Forest',
      code: 'GB-WFT',
    },
    {
      name: 'Wigan',
      code: 'GB-WGN',
    },
    {
      name: 'Wiltshire',
      code: 'GB-WIL',
    },
    {
      name: 'Wakefield',
      code: 'GB-WKF',
    },
    {
      name: 'Walsall',
      code: 'GB-WLL',
    },
    {
      name: 'West Lothian',
      code: 'GB-WLN',
    },
    {
      name: 'Wolverhampton',
      code: 'GB-WLV',
    },
    {
      name: 'Wandsworth',
      code: 'GB-WND',
    },
    {
      name: 'Windsor and Maidenhead',
      code: 'GB-WNM',
    },
    {
      name: 'Wokingham',
      code: 'GB-WOK',
    },
    {
      name: 'Worcestershire',
      code: 'GB-WOR',
    },
    {
      name: 'Wirral',
      code: 'GB-WRL',
    },
    {
      name: 'Warrington',
      code: 'GB-WRT',
    },
    {
      name: 'Wrexham [Wrecsam GB-WRC]',
      code: 'GB-WRX',
    },
    {
      name: 'Westminster',
      code: 'GB-WSM',
    },
    {
      name: 'West Sussex',
      code: 'GB-WSX',
    },
    {
      name: 'York',
      code: 'GB-YOR',
    },
    {
      name: 'Shetland Islands',
      code: 'GB-ZET',
    },
  ],
  GD: [
    {
      name: 'Saint Andrew',
      code: 'GD-01',
    },
    {
      name: 'Saint David',
      code: 'GD-02',
    },
    {
      name: 'Saint George',
      code: 'GD-03',
    },
    {
      name: 'Saint John',
      code: 'GD-04',
    },
    {
      name: 'Saint Mark',
      code: 'GD-05',
    },
    {
      name: 'Saint Patrick',
      code: 'GD-06',
    },
    {
      name: 'Southern Grenadine Islands',
      code: 'GD-10',
    },
  ],
  GE: [
    {
      name: 'Abkhazia',
      code: 'GE-AB',
    },
    {
      name: 'Ajaria',
      code: 'GE-AJ',
    },
    {
      name: 'Guria',
      code: 'GE-GU',
    },
    {
      name: 'Imereti',
      code: 'GE-IM',
    },
    {
      name: "K'akheti",
      code: 'GE-KA',
    },
    {
      name: 'Kvemo Kartli',
      code: 'GE-KK',
    },
    {
      name: 'Mtskheta-Mtianeti',
      code: 'GE-MM',
    },
    {
      name: "Rach'a-Lechkhumi-Kvemo Svaneti",
      code: 'GE-RL',
    },
    {
      name: 'Samtskhe-Javakheti',
      code: 'GE-SJ',
    },
    {
      name: 'Shida Kartli',
      code: 'GE-SK',
    },
    {
      name: 'Samegrelo-Zemo Svaneti',
      code: 'GE-SZ',
    },
    {
      name: 'Tbilisi',
      code: 'GE-TB',
    },
  ],
  GH: [
    {
      name: 'Greater Accra',
      code: 'GH-AA',
    },
    {
      name: 'Ahafo',
      code: 'GH-AF',
    },
    {
      name: 'Ashanti',
      code: 'GH-AH',
    },
    {
      name: 'Bono East',
      code: 'GH-BE',
    },
    {
      name: 'Bono',
      code: 'GH-BO',
    },
    {
      name: 'Central',
      code: 'GH-CP',
    },
    {
      name: 'Eastern',
      code: 'GH-EP',
    },
    {
      name: 'North East',
      code: 'GH-NE',
    },
    {
      name: 'Northern',
      code: 'GH-NP',
    },
    {
      name: 'Oti',
      code: 'GH-OT',
    },
    {
      name: 'Savannah',
      code: 'GH-SV',
    },
    {
      name: 'Volta',
      code: 'GH-TV',
    },
    {
      name: 'Upper East',
      code: 'GH-UE',
    },
    {
      name: 'Upper West',
      code: 'GH-UW',
    },
    {
      name: 'Western North',
      code: 'GH-WN',
    },
    {
      name: 'Western',
      code: 'GH-WP',
    },
  ],
  GL: [
    {
      name: 'Avannaata Kommunia',
      code: 'GL-AV',
    },
    {
      name: 'Kommune Kujalleq',
      code: 'GL-KU',
    },
    {
      name: 'Qeqqata Kommunia',
      code: 'GL-QE',
    },
    {
      name: 'Kommune Qeqertalik',
      code: 'GL-QT',
    },
    {
      name: 'Kommuneqarfik Sermersooq',
      code: 'GL-SM',
    },
  ],
  GM: [
    {
      name: 'Banjul',
      code: 'GM-B',
    },
    {
      name: 'Lower River',
      code: 'GM-L',
    },
    {
      name: 'Central River',
      code: 'GM-M',
    },
    {
      name: 'North Bank',
      code: 'GM-N',
    },
    {
      name: 'Upper River',
      code: 'GM-U',
    },
    {
      name: 'Western',
      code: 'GM-W',
    },
  ],
  GN: [
    {
      name: 'Boké',
      code: 'GN-B',
    },
    {
      name: 'Conakry',
      code: 'GN-C',
    },
    {
      name: 'Kindia',
      code: 'GN-D',
    },
    {
      name: 'Faranah',
      code: 'GN-F',
    },
    {
      name: 'Kankan',
      code: 'GN-K',
    },
    {
      name: 'Labé',
      code: 'GN-L',
    },
    {
      name: 'Mamou',
      code: 'GN-M',
    },
    {
      name: 'Nzérékoré',
      code: 'GN-N',
    },
  ],
  GP: [
    {
      name: 'Annobon',
      code: 'GP-AN',
    },
    {
      name: 'Bioko Nord',
      code: 'GP-BN',
    },
    {
      name: 'Bioko Sud',
      code: 'GP-BS',
    },
    {
      name: 'Centro Sud',
      code: 'GP-CS',
    },
    {
      name: 'Kié-Ntem',
      code: 'GP-KN',
    },
    {
      name: 'Littoral',
      code: 'GP-LI',
    },
    {
      name: 'Wele-Nzas',
      code: 'GP-WN',
    },
  ],
  GQ: [
    {
      name: 'Annobon',
      code: 'GQ-AN',
    },
    {
      name: 'Bioko Nord',
      code: 'GQ-BN',
    },
    {
      name: 'Bioko Sud',
      code: 'GQ-BS',
    },
    {
      name: 'Centro Sud',
      code: 'GQ-CS',
    },
    {
      name: 'Kié-Ntem',
      code: 'GQ-KN',
    },
    {
      name: 'Littoral',
      code: 'GQ-LI',
    },
    {
      name: 'Wele-Nzas',
      code: 'GQ-WN',
    },
  ],
  GR: [
    {
      name: 'Anatolikí Makedonía kai Thráki',
      code: 'GR-A',
    },
    {
      name: 'Kentrikí Makedonía',
      code: 'GR-B',
    },
    {
      name: 'Dytikí Makedonía',
      code: 'GR-C',
    },
    {
      name: 'Ípeiros',
      code: 'GR-D',
    },
    {
      name: 'Thessalía',
      code: 'GR-E',
    },
    {
      name: 'Ionía Nísia',
      code: 'GR-F',
    },
    {
      name: 'Dytikí Elláda',
      code: 'GR-G',
    },
    {
      name: 'Stereá Elláda',
      code: 'GR-H',
    },
    {
      name: 'Attikí',
      code: 'GR-I',
    },
    {
      name: 'Pelopónnisos',
      code: 'GR-J',
    },
    {
      name: 'Vóreio Aigaío',
      code: 'GR-K',
    },
    {
      name: 'Nótio Aigaío',
      code: 'GR-L',
    },
    {
      name: 'Kríti',
      code: 'GR-M',
    },
  ],
  GT: [
    {
      name: 'Alta Verapaz',
      code: 'GT-AV',
    },
    {
      name: 'Baja Verapaz',
      code: 'GT-BV',
    },
    {
      name: 'Chimaltenango',
      code: 'GT-CM',
    },
    {
      name: 'Chiquimula',
      code: 'GT-CQ',
    },
    {
      name: 'Escuintla',
      code: 'GT-ES',
    },
    {
      name: 'Guatemala',
      code: 'GT-GU',
    },
    {
      name: 'Huehuetenango',
      code: 'GT-HU',
    },
    {
      name: 'Izabal',
      code: 'GT-IZ',
    },
    {
      name: 'Jalapa',
      code: 'GT-JA',
    },
    {
      name: 'Jutiapa',
      code: 'GT-JU',
    },
    {
      name: 'Petén',
      code: 'GT-PE',
    },
    {
      name: 'El Progreso',
      code: 'GT-PR',
    },
    {
      name: 'Quiché',
      code: 'GT-QC',
    },
    {
      name: 'Quetzaltenango',
      code: 'GT-QZ',
    },
    {
      name: 'Retalhuleu',
      code: 'GT-RE',
    },
    {
      name: 'Sacatepéquez',
      code: 'GT-SA',
    },
    {
      name: 'San Marcos',
      code: 'GT-SM',
    },
    {
      name: 'Sololá',
      code: 'GT-SO',
    },
    {
      name: 'Santa Rosa',
      code: 'GT-SR',
    },
    {
      name: 'Suchitepéquez',
      code: 'GT-SU',
    },
    {
      name: 'Totonicapán',
      code: 'GT-TO',
    },
    {
      name: 'Zacapa',
      code: 'GT-ZA',
    },
  ],
  GW: [
    {
      name: 'Bafatá',
      code: 'GW-BA',
    },
    {
      name: 'Bolama',
      code: 'GW-BL',
    },
    {
      name: 'Biombo',
      code: 'GW-BM',
    },
    {
      name: 'Bissau',
      code: 'GW-BS',
    },
    {
      name: 'Cacheu',
      code: 'GW-CA',
    },
    {
      name: 'Gabú',
      code: 'GW-GA',
    },
    {
      name: 'Oio',
      code: 'GW-OI',
    },
    {
      name: 'Quinara',
      code: 'GW-QU',
    },
    {
      name: 'Tombali',
      code: 'GW-TO',
    },
  ],
  GY: [
    {
      name: 'Barima-Waini',
      code: 'GY-BA',
    },
    {
      name: 'Cuyuni-Mazaruni',
      code: 'GY-CU',
    },
    {
      name: 'Demerara-Mahaica',
      code: 'GY-DE',
    },
    {
      name: 'East Berbice-Corentyne',
      code: 'GY-EB',
    },
    {
      name: 'Essequibo Islands-West Demerara',
      code: 'GY-ES',
    },
    {
      name: 'Mahaica-Berbice',
      code: 'GY-MA',
    },
    {
      name: 'Pomeroon-Supenaam',
      code: 'GY-PM',
    },
    {
      name: 'Potaro-Siparuni',
      code: 'GY-PT',
    },
    {
      name: 'Upper Demerara-Berbice',
      code: 'GY-UD',
    },
    {
      name: 'Upper Takutu-Upper Essequibo',
      code: 'GY-UT',
    },
  ],
  HN: [
    {
      name: 'Atlántida',
      code: 'HN-AT',
    },
    {
      name: 'Choluteca',
      code: 'HN-CH',
    },
    {
      name: 'Colón',
      code: 'HN-CL',
    },
    {
      name: 'Comayagua',
      code: 'HN-CM',
    },
    {
      name: 'Copán',
      code: 'HN-CP',
    },
    {
      name: 'Cortés',
      code: 'HN-CR',
    },
    {
      name: 'El Paraíso',
      code: 'HN-EP',
    },
    {
      name: 'Francisco Morazán',
      code: 'HN-FM',
    },
    {
      name: 'Gracias a Dios',
      code: 'HN-GD',
    },
    {
      name: 'Islas de la Bahía',
      code: 'HN-IB',
    },
    {
      name: 'Intibucá',
      code: 'HN-IN',
    },
    {
      name: 'Lempira',
      code: 'HN-LE',
    },
    {
      name: 'La Paz',
      code: 'HN-LP',
    },
    {
      name: 'Ocotepeque',
      code: 'HN-OC',
    },
    {
      name: 'Olancho',
      code: 'HN-OL',
    },
    {
      name: 'Santa Bárbara',
      code: 'HN-SB',
    },
    {
      name: 'Valle',
      code: 'HN-VA',
    },
    {
      name: 'Yoro',
      code: 'HN-YO',
    },
  ],
  HR: [
    {
      name: 'Zagrebačka županija',
      code: 'HR-01',
    },
    {
      name: 'Krapinsko-zagorska županija',
      code: 'HR-02',
    },
    {
      name: 'Sisačko-moslavačka županija',
      code: 'HR-03',
    },
    {
      name: 'Karlovačka županija',
      code: 'HR-04',
    },
    {
      name: 'Varaždinska županija',
      code: 'HR-05',
    },
    {
      name: 'Koprivničko-križevačka županija',
      code: 'HR-06',
    },
    {
      name: 'Bjelovarsko-bilogorska županija',
      code: 'HR-07',
    },
    {
      name: 'Primorsko-goranska županija',
      code: 'HR-08',
    },
    {
      name: 'Ličko-senjska županija',
      code: 'HR-09',
    },
    {
      name: 'Virovitičko-podravska županija',
      code: 'HR-10',
    },
    {
      name: 'Požeško-slavonska županija',
      code: 'HR-11',
    },
    {
      name: 'Brodsko-posavska županija',
      code: 'HR-12',
    },
    {
      name: 'Zadarska županija',
      code: 'HR-13',
    },
    {
      name: 'Osječko-baranjska županija',
      code: 'HR-14',
    },
    {
      name: 'Šibensko-kninska županija',
      code: 'HR-15',
    },
    {
      name: 'Vukovarsko-srijemska županija',
      code: 'HR-16',
    },
    {
      name: 'Splitsko-dalmatinska županija',
      code: 'HR-17',
    },
    {
      name: 'Istarska županija',
      code: 'HR-18',
    },
    {
      name: 'Dubrovačko-neretvanska županija',
      code: 'HR-19',
    },
    {
      name: 'Međimurska županija',
      code: 'HR-20',
    },
    {
      name: 'Grad Zagreb',
      code: 'HR-21',
    },
  ],
  HT: [
    {
      name: 'Artibonite',
      code: 'HT-AR',
    },
    {
      name: 'Centre',
      code: 'HT-CE',
    },
    {
      name: 'Grande’Anse',
      code: 'HT-GA',
    },
    {
      name: 'Nord',
      code: 'HT-ND',
    },
    {
      name: 'Nord-Est',
      code: 'HT-NE',
    },
    {
      name: 'Nippes',
      code: 'HT-NI',
    },
    {
      name: 'Nord-Ouest',
      code: 'HT-NO',
    },
    {
      name: 'Ouest',
      code: 'HT-OU',
    },
    {
      name: 'Sud',
      code: 'HT-SD',
    },
    {
      name: 'Sud-Est',
      code: 'HT-SE',
    },
  ],
  HU: [
    {
      name: 'Baranya',
      code: 'HU-BA',
    },
    {
      name: 'Békéscsaba',
      code: 'HU-BC',
    },
    {
      name: 'Békés',
      code: 'HU-BE',
    },
    {
      name: 'Bács-Kiskun',
      code: 'HU-BK',
    },
    {
      name: 'Budapest',
      code: 'HU-BU',
    },
    {
      name: 'Borsod-Abaúj-Zemplén',
      code: 'HU-BZ',
    },
    {
      name: 'Csongrád',
      code: 'HU-CS',
    },
    {
      name: 'Debrecen',
      code: 'HU-DE',
    },
    {
      name: 'Dunaújváros',
      code: 'HU-DU',
    },
    {
      name: 'Eger',
      code: 'HU-EG',
    },
    {
      name: 'Érd',
      code: 'HU-ER',
    },
    {
      name: 'Fejér',
      code: 'HU-FE',
    },
    {
      name: 'Győr-Moson-Sopron',
      code: 'HU-GS',
    },
    {
      name: 'Győr',
      code: 'HU-GY',
    },
    {
      name: 'Hajdú-Bihar',
      code: 'HU-HB',
    },
    {
      name: 'Heves',
      code: 'HU-HE',
    },
    {
      name: 'Hódmezővásárhely',
      code: 'HU-HV',
    },
    {
      name: 'Jász-Nagykun-Szolnok',
      code: 'HU-JN',
    },
    {
      name: 'Komárom-Esztergom',
      code: 'HU-KE',
    },
    {
      name: 'Kecskemét',
      code: 'HU-KM',
    },
    {
      name: 'Kaposvár',
      code: 'HU-KV',
    },
    {
      name: 'Miskolc',
      code: 'HU-MI',
    },
    {
      name: 'Nagykanizsa',
      code: 'HU-NK',
    },
    {
      name: 'Nógrád',
      code: 'HU-NO',
    },
    {
      name: 'Nyíregyháza',
      code: 'HU-NY',
    },
    {
      name: 'Pest',
      code: 'HU-PE',
    },
    {
      name: 'Pécs',
      code: 'HU-PS',
    },
    {
      name: 'Szeged',
      code: 'HU-SD',
    },
    {
      name: 'Székesfehérvár',
      code: 'HU-SF',
    },
    {
      name: 'Szombathely',
      code: 'HU-SH',
    },
    {
      name: 'Szolnok',
      code: 'HU-SK',
    },
    {
      name: 'Sopron',
      code: 'HU-SN',
    },
    {
      name: 'Somogy',
      code: 'HU-SO',
    },
    {
      name: 'Szekszárd',
      code: 'HU-SS',
    },
    {
      name: 'Salgótarján',
      code: 'HU-ST',
    },
    {
      name: 'Szabolcs-Szatmár-Bereg',
      code: 'HU-SZ',
    },
    {
      name: 'Tatabánya',
      code: 'HU-TB',
    },
    {
      name: 'Tolna',
      code: 'HU-TO',
    },
    {
      name: 'Vas',
      code: 'HU-VA',
    },
    {
      name: 'Veszprém',
      code: 'HU-VE',
    },
    {
      name: 'Veszprém',
      code: 'HU-VM',
    },
    {
      name: 'Zala',
      code: 'HU-ZA',
    },
    {
      name: 'Zalaegerszeg',
      code: 'HU-ZE',
    },
  ],
  ID: [
    {
      name: 'Aceh',
      code: 'ID-AC',
    },
    {
      name: 'Bali',
      code: 'ID-BA',
    },
    {
      name: 'Kepulauan Bangka Belitung',
      code: 'ID-BB',
    },
    {
      name: 'Bengkulu',
      code: 'ID-BE',
    },
    {
      name: 'Banten',
      code: 'ID-BT',
    },
    {
      name: 'Gorontalo',
      code: 'ID-GO',
    },
    {
      name: 'Jambi',
      code: 'ID-JA',
    },
    {
      name: 'Jawa Barat',
      code: 'ID-JB',
    },
    {
      name: 'Jawa Timur',
      code: 'ID-JI',
    },
    {
      name: 'Jakarta Raya',
      code: 'ID-JK',
    },
    {
      name: 'Jawa Tengah',
      code: 'ID-JT',
    },
    {
      name: 'Jawa',
      code: 'ID-JW',
    },
    {
      name: 'Kalimantan',
      code: 'ID-KA',
    },
    {
      name: 'Kalimantan Barat',
      code: 'ID-KB',
    },
    {
      name: 'Kalimantan Timur',
      code: 'ID-KI',
    },
    {
      name: 'Kepulauan Riau',
      code: 'ID-KR',
    },
    {
      name: 'Kalimantan Selatan',
      code: 'ID-KS',
    },
    {
      name: 'Kalimantan Tengah',
      code: 'ID-KT',
    },
    {
      name: 'Kalimantan Utara',
      code: 'ID-KU',
    },
    {
      name: 'Lampung',
      code: 'ID-LA',
    },
    {
      name: 'Maluku',
      code: 'ID-MA',
    },
    {
      name: 'Maluku',
      code: 'ID-ML',
    },
    {
      name: 'Maluku Utara',
      code: 'ID-MU',
    },
    {
      name: 'Nusa Tenggara Barat',
      code: 'ID-NB',
    },
    {
      name: 'Nusa Tenggara Timur',
      code: 'ID-NT',
    },
    {
      name: 'Nusa Tenggara',
      code: 'ID-NU',
    },
    {
      name: 'Papua',
      code: 'ID-PA',
    },
    {
      name: 'Papua Barat',
      code: 'ID-PB',
    },
    {
      name: 'Papua',
      code: 'ID-PP',
    },
    {
      name: 'Riau',
      code: 'ID-RI',
    },
    {
      name: 'Sulawesi Utara',
      code: 'ID-SA',
    },
    {
      name: 'Sumatera Barat',
      code: 'ID-SB',
    },
    {
      name: 'Sulawesi Tenggara',
      code: 'ID-SG',
    },
    {
      name: 'Sulawesi',
      code: 'ID-SL',
    },
    {
      name: 'Sumatera',
      code: 'ID-SM',
    },
    {
      name: 'Sulawesi Selatan',
      code: 'ID-SN',
    },
    {
      name: 'Sulawesi Barat',
      code: 'ID-SR',
    },
    {
      name: 'Sumatera Selatan',
      code: 'ID-SS',
    },
    {
      name: 'Sulawesi Tengah',
      code: 'ID-ST',
    },
    {
      name: 'Sumatera Utara',
      code: 'ID-SU',
    },
    {
      name: 'Yogyakarta',
      code: 'ID-YO',
    },
  ],
  IE: [
    {
      name: 'Clare',
      code: 'IE-CE',
    },
    {
      name: 'Cavan',
      code: 'IE-CN',
    },
    {
      name: 'Cork',
      code: 'IE-CO',
    },
    {
      name: 'Carlow',
      code: 'IE-CW',
    },
    {
      name: 'Dublin',
      code: 'IE-D',
    },
    {
      name: 'Donegal',
      code: 'IE-DL',
    },
    {
      name: 'Galway',
      code: 'IE-G',
    },
    {
      name: 'Kildare',
      code: 'IE-KE',
    },
    {
      name: 'Kilkenny',
      code: 'IE-KK',
    },
    {
      name: 'Kerry',
      code: 'IE-KY',
    },
    {
      name: 'Longford',
      code: 'IE-LD',
    },
    {
      name: 'Louth',
      code: 'IE-LH',
    },
    {
      name: 'Limerick',
      code: 'IE-LK',
    },
    {
      name: 'Leitrim',
      code: 'IE-LM',
    },
    {
      name: 'Laois',
      code: 'IE-LS',
    },
    {
      name: 'Meath',
      code: 'IE-MH',
    },
    {
      name: 'Monaghan',
      code: 'IE-MN',
    },
    {
      name: 'Mayo',
      code: 'IE-MO',
    },
    {
      name: 'Offaly',
      code: 'IE-OY',
    },
    {
      name: 'Roscommon',
      code: 'IE-RN',
    },
    {
      name: 'Sligo',
      code: 'IE-SO',
    },
    {
      name: 'Tipperary',
      code: 'IE-TA',
    },
    {
      name: 'Waterford',
      code: 'IE-WD',
    },
    {
      name: 'Westmeath',
      code: 'IE-WH',
    },
    {
      name: 'Wicklow',
      code: 'IE-WW',
    },
    {
      name: 'Wexford',
      code: 'IE-WX',
    },
  ],
  IL: [
    {
      name: 'HaDarom',
      code: 'IL-D',
    },
    {
      name: 'H̱efa',
      code: 'IL-HA',
    },
    {
      name: 'Yerushalayim',
      code: 'IL-JM',
    },
    {
      name: 'HaMerkaz',
      code: 'IL-M',
    },
    {
      name: 'Tel Aviv',
      code: 'IL-TA',
    },
    {
      name: 'HaTsafon',
      code: 'IL-Z',
    },
  ],
  IN: [
    {
      name: 'Andaman and Nicobar Islands',
      code: 'IN-AN',
    },
    {
      name: 'Andhra Pradesh',
      code: 'IN-AP',
    },
    {
      name: 'Arunachal Pradesh',
      code: 'IN-AR',
    },
    {
      name: 'Assam',
      code: 'IN-AS',
    },
    {
      name: 'Bihar',
      code: 'IN-BR',
    },
    {
      name: 'Chandigarh',
      code: 'IN-CH',
    },
    {
      name: 'Chhattisgarh',
      code: 'IN-CT',
    },
    {
      name: 'Daman and Diu',
      code: 'IN-DD',
    },
    {
      name: 'Delhi',
      code: 'IN-DL',
    },
    {
      name: 'Dadra and Nagar Haveli',
      code: 'IN-DN',
    },
    {
      name: 'Goa',
      code: 'IN-GA',
    },
    {
      name: 'Gujarat',
      code: 'IN-GJ',
    },
    {
      name: 'Himachal Pradesh',
      code: 'IN-HP',
    },
    {
      name: 'Haryana',
      code: 'IN-HR',
    },
    {
      name: 'Jharkhand',
      code: 'IN-JH',
    },
    {
      name: 'Jammu and Kashmir',
      code: 'IN-JK',
    },
    {
      name: 'Karnataka',
      code: 'IN-KA',
    },
    {
      name: 'Kerala',
      code: 'IN-KL',
    },
    {
      name: 'Ladakh',
      code: 'IN-LA',
    },
    {
      name: 'Lakshadweep',
      code: 'IN-LD',
    },
    {
      name: 'Maharashtra',
      code: 'IN-MH',
    },
    {
      name: 'Meghalaya',
      code: 'IN-ML',
    },
    {
      name: 'Manipur',
      code: 'IN-MN',
    },
    {
      name: 'Madhya Pradesh',
      code: 'IN-MP',
    },
    {
      name: 'Mizoram',
      code: 'IN-MZ',
    },
    {
      name: 'Nagaland',
      code: 'IN-NL',
    },
    {
      name: 'Odisha',
      code: 'IN-OR',
    },
    {
      name: 'Punjab',
      code: 'IN-PB',
    },
    {
      name: 'Puducherry',
      code: 'IN-PY',
    },
    {
      name: 'Rajasthan',
      code: 'IN-RJ',
    },
    {
      name: 'Sikkim',
      code: 'IN-SK',
    },
    {
      name: 'Telangana',
      code: 'IN-TG',
    },
    {
      name: 'Tamil Nadu',
      code: 'IN-TN',
    },
    {
      name: 'Tripura',
      code: 'IN-TR',
    },
    {
      name: 'Uttar Pradesh',
      code: 'IN-UP',
    },
    {
      name: 'Uttarakhand',
      code: 'IN-UT',
    },
    {
      name: 'West Bengal',
      code: 'IN-WB',
    },
  ],
  IQ: [
    {
      name: 'Al Anbār',
      code: 'IQ-AN',
    },
    {
      name: 'Arbīl',
      code: 'IQ-AR',
    },
    {
      name: 'Al Başrah',
      code: 'IQ-BA',
    },
    {
      name: 'Bābil',
      code: 'IQ-BB',
    },
    {
      name: 'Baghdād',
      code: 'IQ-BG',
    },
    {
      name: 'Dahūk',
      code: 'IQ-DA',
    },
    {
      name: 'Diyālá',
      code: 'IQ-DI',
    },
    {
      name: 'Dhī Qār',
      code: 'IQ-DQ',
    },
    {
      name: 'Karbalā’',
      code: 'IQ-KA',
    },
    {
      name: 'Kirkūk',
      code: 'IQ-KI',
    },
    {
      name: 'Maysān',
      code: 'IQ-MA',
    },
    {
      name: 'Al Muthanná',
      code: 'IQ-MU',
    },
    {
      name: 'An Najaf',
      code: 'IQ-NA',
    },
    {
      name: 'Nīnawá',
      code: 'IQ-NI',
    },
    {
      name: 'Al Qādisīyah',
      code: 'IQ-QA',
    },
    {
      name: 'Şalāḩ ad Dīn',
      code: 'IQ-SD',
    },
    {
      name: 'As Sulaymānīyah',
      code: 'IQ-SU',
    },
    {
      name: 'Wāsiţ',
      code: 'IQ-WA',
    },
  ],
  IR: [
    {
      name: 'Āz̄ārbāyjān-e Shārqī',
      code: 'IR-01',
    },
    {
      name: 'Āz̄ārbāyjān-e Ghārbī',
      code: 'IR-02',
    },
    {
      name: 'Ardabīl',
      code: 'IR-03',
    },
    {
      name: 'Eşfahān',
      code: 'IR-04',
    },
    {
      name: 'Īlām',
      code: 'IR-05',
    },
    {
      name: 'Būshehr',
      code: 'IR-06',
    },
    {
      name: 'Tehrān',
      code: 'IR-07',
    },
    {
      name: 'Chahār Maḩāl va Bakhtīārī',
      code: 'IR-08',
    },
    {
      name: 'Khūzestān',
      code: 'IR-10',
    },
    {
      name: 'Zanjān',
      code: 'IR-11',
    },
    {
      name: 'Semnān',
      code: 'IR-12',
    },
    {
      name: 'Sīstān va Balūchestān',
      code: 'IR-13',
    },
    {
      name: 'Fārs',
      code: 'IR-14',
    },
    {
      name: 'Kermān',
      code: 'IR-15',
    },
    {
      name: 'Kordestān',
      code: 'IR-16',
    },
    {
      name: 'Kermānshāh',
      code: 'IR-17',
    },
    {
      name: 'Kohgīlūyeh va Bowyer Aḩmad',
      code: 'IR-18',
    },
    {
      name: 'Gīlān',
      code: 'IR-19',
    },
    {
      name: 'Lorestān',
      code: 'IR-20',
    },
    {
      name: 'Māzandarān',
      code: 'IR-21',
    },
    {
      name: 'Markazī',
      code: 'IR-22',
    },
    {
      name: 'Hormozgān',
      code: 'IR-23',
    },
    {
      name: 'Hamadān',
      code: 'IR-24',
    },
    {
      name: 'Yazd',
      code: 'IR-25',
    },
    {
      name: 'Qom',
      code: 'IR-26',
    },
    {
      name: 'Golestān',
      code: 'IR-27',
    },
    {
      name: 'Qazvīn',
      code: 'IR-28',
    },
    {
      name: 'Khorāsān-e Jonūbī',
      code: 'IR-29',
    },
    {
      name: 'Khorāsān-e Raẕavī',
      code: 'IR-30',
    },
    {
      name: 'Khorāsān-e Shomālī',
      code: 'IR-31',
    },
    {
      name: 'Alborz',
      code: 'IR-32',
    },
  ],
  IS: [
    {
      name: 'Höfuðborgarsvæði',
      code: 'IS-01',
    },
    {
      name: 'Suðurnes',
      code: 'IS-02',
    },
    {
      name: 'Vesturland',
      code: 'IS-03',
    },
    {
      name: 'Vestfirðir',
      code: 'IS-04',
    },
    {
      name: 'Norðurland vestra',
      code: 'IS-05',
    },
    {
      name: 'Norðurland eystra',
      code: 'IS-06',
    },
    {
      name: 'Austurland',
      code: 'IS-07',
    },
    {
      name: 'Suðurland',
      code: 'IS-08',
    },
  ],
  IT: [
    {
      name: 'Piemonte',
      code: 'IT-21',
    },
    {
      name: "Valle d'Aosta",
      code: 'IT-23',
    },
    {
      name: 'Lombardia',
      code: 'IT-25',
    },
    {
      name: 'Trentino-Alto Adige',
      code: 'IT-32',
    },
    {
      name: 'Veneto',
      code: 'IT-34',
    },
    {
      name: 'Friuli Venezia Giulia',
      code: 'IT-36',
    },
    {
      name: 'Liguria',
      code: 'IT-42',
    },
    {
      name: 'Emilia-Romagna',
      code: 'IT-45',
    },
    {
      name: 'Toscana',
      code: 'IT-52',
    },
    {
      name: 'Umbria',
      code: 'IT-55',
    },
    {
      name: 'Marche',
      code: 'IT-57',
    },
    {
      name: 'Lazio',
      code: 'IT-62',
    },
    {
      name: 'Abruzzo',
      code: 'IT-65',
    },
    {
      name: 'Molise',
      code: 'IT-67',
    },
    {
      name: 'Campania',
      code: 'IT-72',
    },
    {
      name: 'Puglia',
      code: 'IT-75',
    },
    {
      name: 'Basilicata',
      code: 'IT-77',
    },
    {
      name: 'Calabria',
      code: 'IT-78',
    },
    {
      name: 'Sicilia',
      code: 'IT-82',
    },
    {
      name: 'Sardegna',
      code: 'IT-88',
    },
    {
      name: 'Agrigento',
      code: 'IT-AG',
    },
    {
      name: 'Alessandria',
      code: 'IT-AL',
    },
    {
      name: 'Ancona',
      code: 'IT-AN',
    },
    {
      name: 'Aosta',
      code: 'IT-AO',
    },
    {
      name: 'Ascoli Piceno',
      code: 'IT-AP',
    },
    {
      name: "L'Aquila",
      code: 'IT-AQ',
    },
    {
      name: 'Arezzo',
      code: 'IT-AR',
    },
    {
      name: 'Asti',
      code: 'IT-AT',
    },
    {
      name: 'Avellino',
      code: 'IT-AV',
    },
    {
      name: 'Bari',
      code: 'IT-BA',
    },
    {
      name: 'Bergamo',
      code: 'IT-BG',
    },
    {
      name: 'Biella',
      code: 'IT-BI',
    },
    {
      name: 'Belluno',
      code: 'IT-BL',
    },
    {
      name: 'Benevento',
      code: 'IT-BN',
    },
    {
      name: 'Bologna',
      code: 'IT-BO',
    },
    {
      name: 'Brindisi',
      code: 'IT-BR',
    },
    {
      name: 'Brescia',
      code: 'IT-BS',
    },
    {
      name: 'Barletta-Andria-Trani',
      code: 'IT-BT',
    },
    {
      name: 'Bolzano',
      code: 'IT-BZ',
    },
    {
      name: 'Cagliari',
      code: 'IT-CA',
    },
    {
      name: 'Campobasso',
      code: 'IT-CB',
    },
    {
      name: 'Caserta',
      code: 'IT-CE',
    },
    {
      name: 'Chieti',
      code: 'IT-CH',
    },
    {
      name: 'Carbonia-Iglesias',
      code: 'IT-CI',
    },
    {
      name: 'Caltanissetta',
      code: 'IT-CL',
    },
    {
      name: 'Cuneo',
      code: 'IT-CN',
    },
    {
      name: 'Como',
      code: 'IT-CO',
    },
    {
      name: 'Cremona',
      code: 'IT-CR',
    },
    {
      name: 'Cosenza',
      code: 'IT-CS',
    },
    {
      name: 'Catania',
      code: 'IT-CT',
    },
    {
      name: 'Catanzaro',
      code: 'IT-CZ',
    },
    {
      name: 'Enna',
      code: 'IT-EN',
    },
    {
      name: 'Forlì-Cesena',
      code: 'IT-FC',
    },
    {
      name: 'Ferrara',
      code: 'IT-FE',
    },
    {
      name: 'Foggia',
      code: 'IT-FG',
    },
    {
      name: 'Firenze',
      code: 'IT-FI',
    },
    {
      name: 'Fermo',
      code: 'IT-FM',
    },
    {
      name: 'Frosinone',
      code: 'IT-FR',
    },
    {
      name: 'Genova',
      code: 'IT-GE',
    },
    {
      name: 'Gorizia',
      code: 'IT-GO',
    },
    {
      name: 'Grosseto',
      code: 'IT-GR',
    },
    {
      name: 'Imperia',
      code: 'IT-IM',
    },
    {
      name: 'Isernia',
      code: 'IT-IS',
    },
    {
      name: 'Crotone',
      code: 'IT-KR',
    },
    {
      name: 'Lecco',
      code: 'IT-LC',
    },
    {
      name: 'Lecce',
      code: 'IT-LE',
    },
    {
      name: 'Livorno',
      code: 'IT-LI',
    },
    {
      name: 'Lodi',
      code: 'IT-LO',
    },
    {
      name: 'Latina',
      code: 'IT-LT',
    },
    {
      name: 'Lucca',
      code: 'IT-LU',
    },
    {
      name: 'Monza e Brianza',
      code: 'IT-MB',
    },
    {
      name: 'Macerata',
      code: 'IT-MC',
    },
    {
      name: 'Messina',
      code: 'IT-ME',
    },
    {
      name: 'Milano',
      code: 'IT-MI',
    },
    {
      name: 'Mantova',
      code: 'IT-MN',
    },
    {
      name: 'Modena',
      code: 'IT-MO',
    },
    {
      name: 'Massa-Carrara',
      code: 'IT-MS',
    },
    {
      name: 'Matera',
      code: 'IT-MT',
    },
    {
      name: 'Napoli',
      code: 'IT-NA',
    },
    {
      name: 'Novara',
      code: 'IT-NO',
    },
    {
      name: 'Nuoro',
      code: 'IT-NU',
    },
    {
      name: 'Ogliastra',
      code: 'IT-OG',
    },
    {
      name: 'Oristano',
      code: 'IT-OR',
    },
    {
      name: 'Olbia-Tempio',
      code: 'IT-OT',
    },
    {
      name: 'Palermo',
      code: 'IT-PA',
    },
    {
      name: 'Piacenza',
      code: 'IT-PC',
    },
    {
      name: 'Padova',
      code: 'IT-PD',
    },
    {
      name: 'Pescara',
      code: 'IT-PE',
    },
    {
      name: 'Perugia',
      code: 'IT-PG',
    },
    {
      name: 'Pisa',
      code: 'IT-PI',
    },
    {
      name: 'Pordenone',
      code: 'IT-PN',
    },
    {
      name: 'Prato',
      code: 'IT-PO',
    },
    {
      name: 'Parma',
      code: 'IT-PR',
    },
    {
      name: 'Pistoia',
      code: 'IT-PT',
    },
    {
      name: 'Pesaro e Urbino',
      code: 'IT-PU',
    },
    {
      name: 'Pavia',
      code: 'IT-PV',
    },
    {
      name: 'Potenza',
      code: 'IT-PZ',
    },
    {
      name: 'Ravenna',
      code: 'IT-RA',
    },
    {
      name: 'Reggio Calabria',
      code: 'IT-RC',
    },
    {
      name: 'Reggio Emilia',
      code: 'IT-RE',
    },
    {
      name: 'Ragusa',
      code: 'IT-RG',
    },
    {
      name: 'Rieti',
      code: 'IT-RI',
    },
    {
      name: 'Roma',
      code: 'IT-RM',
    },
    {
      name: 'Rimini',
      code: 'IT-RN',
    },
    {
      name: 'Rovigo',
      code: 'IT-RO',
    },
    {
      name: 'Salerno',
      code: 'IT-SA',
    },
    {
      name: 'Sud Sardegna',
      code: 'IT-SD',
    },
    {
      name: 'Siena',
      code: 'IT-SI',
    },
    {
      name: 'Sondrio',
      code: 'IT-SO',
    },
    {
      name: 'La Spezia',
      code: 'IT-SP',
    },
    {
      name: 'Siracusa',
      code: 'IT-SR',
    },
    {
      name: 'Sassari',
      code: 'IT-SS',
    },
    {
      name: 'Savona',
      code: 'IT-SV',
    },
    {
      name: 'Taranto',
      code: 'IT-TA',
    },
    {
      name: 'Teramo',
      code: 'IT-TE',
    },
    {
      name: 'Trento',
      code: 'IT-TN',
    },
    {
      name: 'Torino',
      code: 'IT-TO',
    },
    {
      name: 'Trapani',
      code: 'IT-TP',
    },
    {
      name: 'Terni',
      code: 'IT-TR',
    },
    {
      name: 'Trieste',
      code: 'IT-TS',
    },
    {
      name: 'Treviso',
      code: 'IT-TV',
    },
    {
      name: 'Udine',
      code: 'IT-UD',
    },
    {
      name: 'Varese',
      code: 'IT-VA',
    },
    {
      name: 'Verbano-Cusio-Ossola',
      code: 'IT-VB',
    },
    {
      name: 'Vercelli',
      code: 'IT-VC',
    },
    {
      name: 'Venezia',
      code: 'IT-VE',
    },
    {
      name: 'Vicenza',
      code: 'IT-VI',
    },
    {
      name: 'Verona',
      code: 'IT-VR',
    },
    {
      name: 'Medio Campidano',
      code: 'IT-VS',
    },
    {
      name: 'Viterbo',
      code: 'IT-VT',
    },
    {
      name: 'Vibo Valentia',
      code: 'IT-VV',
    },
  ],
  JM: [
    {
      name: 'Kingston',
      code: 'JM-01',
    },
    {
      name: 'Saint Andrew',
      code: 'JM-02',
    },
    {
      name: 'Saint Thomas',
      code: 'JM-03',
    },
    {
      name: 'Portland',
      code: 'JM-04',
    },
    {
      name: 'Saint Mary',
      code: 'JM-05',
    },
    {
      name: 'Saint Ann',
      code: 'JM-06',
    },
    {
      name: 'Trelawny',
      code: 'JM-07',
    },
    {
      name: 'Saint James',
      code: 'JM-08',
    },
    {
      name: 'Hanover',
      code: 'JM-09',
    },
    {
      name: 'Westmoreland',
      code: 'JM-10',
    },
    {
      name: 'Saint Elizabeth',
      code: 'JM-11',
    },
    {
      name: 'Manchester',
      code: 'JM-12',
    },
    {
      name: 'Clarendon',
      code: 'JM-13',
    },
    {
      name: 'Saint Catherine',
      code: 'JM-14',
    },
  ],
  JO: [
    {
      name: '‘Ajlūn',
      code: 'JO-AJ',
    },
    {
      name: 'Al ‘A̅şimah',
      code: 'JO-AM',
    },
    {
      name: 'Al ‘Aqabah',
      code: 'JO-AQ',
    },
    {
      name: 'Aţ Ţafīlah',
      code: 'JO-AT',
    },
    {
      name: 'Az Zarqā’',
      code: 'JO-AZ',
    },
    {
      name: 'Al Balqā’',
      code: 'JO-BA',
    },
    {
      name: 'Irbid',
      code: 'JO-IR',
    },
    {
      name: 'Jarash',
      code: 'JO-JA',
    },
    {
      name: 'Al Karak',
      code: 'JO-KA',
    },
    {
      name: 'Al Mafraq',
      code: 'JO-MA',
    },
    {
      name: 'Mādabā',
      code: 'JO-MD',
    },
    {
      name: 'Ma‘ān',
      code: 'JO-MN',
    },
  ],
  JP: [
    {
      name: 'Hokkaido',
      code: 'JP-01',
    },
    {
      name: 'Aomori',
      code: 'JP-02',
    },
    {
      name: 'Iwate',
      code: 'JP-03',
    },
    {
      name: 'Miyagi',
      code: 'JP-04',
    },
    {
      name: 'Akita',
      code: 'JP-05',
    },
    {
      name: 'Yamagata',
      code: 'JP-06',
    },
    {
      name: 'Fukushima',
      code: 'JP-07',
    },
    {
      name: 'Ibaraki',
      code: 'JP-08',
    },
    {
      name: 'Tochigi',
      code: 'JP-09',
    },
    {
      name: 'Gunma',
      code: 'JP-10',
    },
    {
      name: 'Saitama',
      code: 'JP-11',
    },
    {
      name: 'Chiba',
      code: 'JP-12',
    },
    {
      name: 'Tokyo',
      code: 'JP-13',
    },
    {
      name: 'Kanagawa',
      code: 'JP-14',
    },
    {
      name: 'Niigata',
      code: 'JP-15',
    },
    {
      name: 'Toyama',
      code: 'JP-16',
    },
    {
      name: 'Ishikawa',
      code: 'JP-17',
    },
    {
      name: 'Fukui',
      code: 'JP-18',
    },
    {
      name: 'Yamanashi',
      code: 'JP-19',
    },
    {
      name: 'Nagano',
      code: 'JP-20',
    },
    {
      name: 'Gifu',
      code: 'JP-21',
    },
    {
      name: 'Shizuoka',
      code: 'JP-22',
    },
    {
      name: 'Aichi',
      code: 'JP-23',
    },
    {
      name: 'Mie',
      code: 'JP-24',
    },
    {
      name: 'Shiga',
      code: 'JP-25',
    },
    {
      name: 'Kyoto',
      code: 'JP-26',
    },
    {
      name: 'Osaka',
      code: 'JP-27',
    },
    {
      name: 'Hyogo',
      code: 'JP-28',
    },
    {
      name: 'Nara',
      code: 'JP-29',
    },
    {
      name: 'Wakayama',
      code: 'JP-30',
    },
    {
      name: 'Tottori',
      code: 'JP-31',
    },
    {
      name: 'Shimane',
      code: 'JP-32',
    },
    {
      name: 'Okayama',
      code: 'JP-33',
    },
    {
      name: 'Hiroshima',
      code: 'JP-34',
    },
    {
      name: 'Yamaguchi',
      code: 'JP-35',
    },
    {
      name: 'Tokushima',
      code: 'JP-36',
    },
    {
      name: 'Kagawa',
      code: 'JP-37',
    },
    {
      name: 'Ehime',
      code: 'JP-38',
    },
    {
      name: 'Kochi',
      code: 'JP-39',
    },
    {
      name: 'Fukuoka',
      code: 'JP-40',
    },
    {
      name: 'Saga',
      code: 'JP-41',
    },
    {
      name: 'Nagasaki',
      code: 'JP-42',
    },
    {
      name: 'Kumamoto',
      code: 'JP-43',
    },
    {
      name: 'Oita',
      code: 'JP-44',
    },
    {
      name: 'Miyazaki',
      code: 'JP-45',
    },
    {
      name: 'Kagoshima',
      code: 'JP-46',
    },
    {
      name: 'Okinawa',
      code: 'JP-47',
    },
  ],
  KE: [
    {
      name: 'Baringo',
      code: 'KE-01',
    },
    {
      name: 'Bomet',
      code: 'KE-02',
    },
    {
      name: 'Bungoma',
      code: 'KE-03',
    },
    {
      name: 'Busia',
      code: 'KE-04',
    },
    {
      name: 'Elgeyo/Marakwet',
      code: 'KE-05',
    },
    {
      name: 'Embu',
      code: 'KE-06',
    },
    {
      name: 'Garissa',
      code: 'KE-07',
    },
    {
      name: 'Homa Bay',
      code: 'KE-08',
    },
    {
      name: 'Isiolo',
      code: 'KE-09',
    },
    {
      name: 'Kajiado',
      code: 'KE-10',
    },
    {
      name: 'Kakamega',
      code: 'KE-11',
    },
    {
      name: 'Kericho',
      code: 'KE-12',
    },
    {
      name: 'Kiambu',
      code: 'KE-13',
    },
    {
      name: 'Kilifi',
      code: 'KE-14',
    },
    {
      name: 'Kirinyaga',
      code: 'KE-15',
    },
    {
      name: 'Kisii',
      code: 'KE-16',
    },
    {
      name: 'Kisumu',
      code: 'KE-17',
    },
    {
      name: 'Kitui',
      code: 'KE-18',
    },
    {
      name: 'Kwale',
      code: 'KE-19',
    },
    {
      name: 'Laikipia',
      code: 'KE-20',
    },
    {
      name: 'Lamu',
      code: 'KE-21',
    },
    {
      name: 'Machakos',
      code: 'KE-22',
    },
    {
      name: 'Makueni',
      code: 'KE-23',
    },
    {
      name: 'Mandera',
      code: 'KE-24',
    },
    {
      name: 'Marsabit',
      code: 'KE-25',
    },
    {
      name: 'Meru',
      code: 'KE-26',
    },
    {
      name: 'Migori',
      code: 'KE-27',
    },
    {
      name: 'Mombasa',
      code: 'KE-28',
    },
    {
      name: "Murang'a",
      code: 'KE-29',
    },
    {
      name: 'Nairobi City',
      code: 'KE-30',
    },
    {
      name: 'Nakuru',
      code: 'KE-31',
    },
    {
      name: 'Nandi',
      code: 'KE-32',
    },
    {
      name: 'Narok',
      code: 'KE-33',
    },
    {
      name: 'Nyamira',
      code: 'KE-34',
    },
    {
      name: 'Nyandarua',
      code: 'KE-35',
    },
    {
      name: 'Nyeri',
      code: 'KE-36',
    },
    {
      name: 'Samburu',
      code: 'KE-37',
    },
    {
      name: 'Siaya',
      code: 'KE-38',
    },
    {
      name: 'Taita/Taveta',
      code: 'KE-39',
    },
    {
      name: 'Tana River',
      code: 'KE-40',
    },
    {
      name: 'Tharaka-Nithi',
      code: 'KE-41',
    },
    {
      name: 'Trans Nzoia',
      code: 'KE-42',
    },
    {
      name: 'Turkana',
      code: 'KE-43',
    },
    {
      name: 'Uasin Gishu',
      code: 'KE-44',
    },
    {
      name: 'Vihiga',
      code: 'KE-45',
    },
    {
      name: 'Wajir',
      code: 'KE-46',
    },
    {
      name: 'West Pokot',
      code: 'KE-47',
    },
  ],
  KG: [
    {
      name: 'Batken',
      code: 'KG-B',
    },
    {
      name: 'Chüy',
      code: 'KG-C',
    },
    {
      name: 'Bishkek',
      code: 'KG-GB',
    },
    {
      name: 'Osh',
      code: 'KG-GO',
    },
    {
      name: 'Jalal-Abad',
      code: 'KG-J',
    },
    {
      name: 'Naryn',
      code: 'KG-N',
    },
    {
      name: 'Osh',
      code: 'KG-O',
    },
    {
      name: 'Talas',
      code: 'KG-T',
    },
    {
      name: 'Ysyk-Köl',
      code: 'KG-Y',
    },
  ],
  KH: [
    {
      name: 'Bântéay Méanchey',
      code: 'KH-01',
    },
    {
      name: 'Krâchéh',
      code: 'KH-10',
    },
    {
      name: 'Môndól Kiri',
      code: 'KH-11',
    },
    {
      name: 'Phnum Pénh',
      code: 'KH-12',
    },
    {
      name: 'Preăh Vihéar',
      code: 'KH-13',
    },
    {
      name: 'Prey Vêng',
      code: 'KH-14',
    },
    {
      name: 'Poŭthĭsăt',
      code: 'KH-15',
    },
    {
      name: 'Rôtânôkiri',
      code: 'KH-16',
    },
    {
      name: 'Siĕmréab',
      code: 'KH-17',
    },
    {
      name: 'Preăh Sihanouk',
      code: 'KH-18',
    },
    {
      name: 'Stueng Traeng',
      code: 'KH-19',
    },
    {
      name: 'Bătdâmbâng',
      code: 'KH-2',
    },
    {
      name: 'Svay Riĕng',
      code: 'KH-20',
    },
    {
      name: 'Takêv',
      code: 'KH-21',
    },
    {
      name: 'Ŏtdâr Méanchey',
      code: 'KH-22',
    },
    {
      name: 'Kêb',
      code: 'KH-23',
    },
    {
      name: 'Pailĭn',
      code: 'KH-24',
    },
    {
      name: 'Tbong Khmŭm',
      code: 'KH-25',
    },
    {
      name: 'Kâmpóng Cham',
      code: 'KH-3',
    },
    {
      name: 'Kâmpóng Chhnăng',
      code: 'KH-4',
    },
    {
      name: 'Kâmpóng Spœ',
      code: 'KH-5',
    },
    {
      name: 'Kâmpóng Thum',
      code: 'KH-6',
    },
    {
      name: 'Kâmpôt',
      code: 'KH-7',
    },
    {
      name: 'Kândal',
      code: 'KH-8',
    },
    {
      name: 'Kaôh Kŏng',
      code: 'KH-9',
    },
  ],
  KI: [
    {
      name: 'Gilbert Islands',
      code: 'KI-G',
    },
    {
      name: 'Line Islands',
      code: 'KI-L',
    },
    {
      name: 'Phoenix Islands',
      code: 'KI-P',
    },
  ],
  KM: [
    {
      name: 'Anjouan',
      code: 'KM-A',
    },
    {
      name: 'Grande Comore',
      code: 'KM-G',
    },
    {
      name: 'Mohéli',
      code: 'KM-M',
    },
  ],
  KN: [
    {
      name: 'Christ Church Nichola Town',
      code: 'KN-01',
    },
    {
      name: 'Saint Anne Sandy Point',
      code: 'KN-02',
    },
    {
      name: 'Saint George Basseterre',
      code: 'KN-03',
    },
    {
      name: 'Saint George Gingerland',
      code: 'KN-04',
    },
    {
      name: 'Saint James Windward',
      code: 'KN-05',
    },
    {
      name: 'Saint John Capisterre',
      code: 'KN-06',
    },
    {
      name: 'Saint John Figtree',
      code: 'KN-07',
    },
    {
      name: 'Saint Mary Cayon',
      code: 'KN-08',
    },
    {
      name: 'Saint Paul Capisterre',
      code: 'KN-09',
    },
    {
      name: 'Saint Paul Charlestown',
      code: 'KN-10',
    },
    {
      name: 'Saint Peter Basseterre',
      code: 'KN-11',
    },
    {
      name: 'Saint Thomas Lowland',
      code: 'KN-12',
    },
    {
      name: 'Saint Thomas Middle Island',
      code: 'KN-13',
    },
    {
      name: 'Trinity Palmetto Point',
      code: 'KN-15',
    },
  ],
  KP: [
    {
      name: 'Phyeongyang',
      code: 'KP-01',
    },
    {
      name: 'Phyeongannamto',
      code: 'KP-02',
    },
    {
      name: 'Phyeonganpukto',
      code: 'KP-03',
    },
    {
      name: 'Jakangto',
      code: 'KP-04',
    },
    {
      name: 'Hwanghainamto',
      code: 'KP-05',
    },
    {
      name: 'Hwanghaipukto',
      code: 'KP-06',
    },
    {
      name: 'Kangwǒn-do',
      code: 'KP-07',
    },
    {
      name: 'Hamkyeongnamto',
      code: 'KP-08',
    },
    {
      name: 'Hamkyeongpukto',
      code: 'KP-09',
    },
    {
      name: 'Ryangkangto',
      code: 'KP-10',
    },
    {
      name: 'Rasǒn',
      code: 'KP-13',
    },
    {
      name: 'Namp’o',
      code: 'KP-14',
    },
  ],
  KR: [
    {
      name: 'Seoul-teukbyeolsi',
      code: 'KR-11',
    },
    {
      name: 'Busan-gwangyeoksi',
      code: 'KR-26',
    },
    {
      name: 'Daegu-gwangyeoksi',
      code: 'KR-27',
    },
    {
      name: 'Incheon-gwangyeoksi',
      code: 'KR-28',
    },
    {
      name: 'Gwangju-gwangyeoksi',
      code: 'KR-29',
    },
    {
      name: 'Daejeon-gwangyeoksi',
      code: 'KR-30',
    },
    {
      name: 'Ulsan-gwangyeoksi',
      code: 'KR-31',
    },
    {
      name: 'Gyeonggi-do',
      code: 'KR-41',
    },
    {
      name: 'Gangwon-do',
      code: 'KR-42',
    },
    {
      name: 'Chungcheongbuk-do',
      code: 'KR-43',
    },
    {
      name: 'Chungcheongnam-do',
      code: 'KR-44',
    },
    {
      name: 'Jeollabuk-do',
      code: 'KR-45',
    },
    {
      name: 'Jeollanam-do',
      code: 'KR-46',
    },
    {
      name: 'Gyeongsangbuk-do',
      code: 'KR-47',
    },
    {
      name: 'Gyeongsangnam-do',
      code: 'KR-48',
    },
    {
      name: 'Jeju-teukbyeoljachido',
      code: 'KR-49',
    },
    {
      name: 'Sejong',
      code: 'KR-50',
    },
  ],
  KW: [
    {
      name: 'Al Aḩmadī',
      code: 'KW-AH',
    },
    {
      name: 'Al Farwānīyah',
      code: 'KW-FA',
    },
    {
      name: 'Ḩawallī',
      code: 'KW-HA',
    },
    {
      name: 'Al Jahrā’',
      code: 'KW-JA',
    },
    {
      name: 'Al ‘Āşimah',
      code: 'KW-KU',
    },
    {
      name: 'Mubārak al Kabīr',
      code: 'KW-MU',
    },
  ],
  KZ: [
    {
      name: 'Aqmola oblysy',
      code: 'KZ-AKM',
    },
    {
      name: 'Aqtöbe oblysy',
      code: 'KZ-AKT',
    },
    {
      name: 'Almaty',
      code: 'KZ-ALA',
    },
    {
      name: 'Almaty oblysy',
      code: 'KZ-ALM',
    },
    {
      name: 'Astana',
      code: 'KZ-AST',
    },
    {
      name: 'Atyraū oblysy',
      code: 'KZ-ATY',
    },
    {
      name: 'Bayqongyr',
      code: 'KZ-BAY',
    },
    {
      name: 'Qaraghandy oblysy',
      code: 'KZ-KAR',
    },
    {
      name: 'Qostanay oblysy',
      code: 'KZ-KUS',
    },
    {
      name: 'Qyzylorda oblysy',
      code: 'KZ-KZY',
    },
    {
      name: 'Mangghystaū oblysy',
      code: 'KZ-MAN',
    },
    {
      name: 'Pavlodar oblysy',
      code: 'KZ-PAV',
    },
    {
      name: 'Soltüstik Qazaqstan oblysy',
      code: 'KZ-SEV',
    },
    {
      name: 'Shymkent',
      code: 'KZ-SHY',
    },
    {
      name: 'Shyghys Qazaqstan oblysy',
      code: 'KZ-VOS',
    },
    {
      name: 'Türkistan oblysy',
      code: 'KZ-YUZ',
    },
    {
      name: 'Batys Qazaqstan oblysy',
      code: 'KZ-ZAP',
    },
    {
      name: 'Zhambyl oblysy',
      code: 'KZ-ZHA',
    },
  ],
  LA: [
    {
      name: 'Attapu',
      code: 'LA-AT',
    },
    {
      name: 'Bokèo',
      code: 'LA-BK',
    },
    {
      name: 'Bolikhamxai',
      code: 'LA-BL',
    },
    {
      name: 'Champasak',
      code: 'LA-CH',
    },
    {
      name: 'Houaphan',
      code: 'LA-HO',
    },
    {
      name: 'Khammouan',
      code: 'LA-KH',
    },
    {
      name: 'Louang Namtha',
      code: 'LA-LM',
    },
    {
      name: 'Louangphabang',
      code: 'LA-LP',
    },
    {
      name: 'Oudômxai',
      code: 'LA-OU',
    },
    {
      name: 'Phôngsali',
      code: 'LA-PH',
    },
    {
      name: 'Salavan',
      code: 'LA-SL',
    },
    {
      name: 'Savannakhét',
      code: 'LA-SV',
    },
    {
      name: 'Viangchan',
      code: 'LA-VI',
    },
    {
      name: 'Viangchan',
      code: 'LA-VT',
    },
    {
      name: 'Xaignabouli',
      code: 'LA-XA',
    },
    {
      name: 'Xékong',
      code: 'LA-XE',
    },
    {
      name: 'Xiangkhouang',
      code: 'LA-XI',
    },
    {
      name: 'Xaisômboun',
      code: 'LA-XS',
    },
  ],
  LB: [
    {
      name: '‘Akkār',
      code: 'LB-AK',
    },
    {
      name: 'Liban-Nord',
      code: 'LB-AS',
    },
    {
      name: 'Beyrouth',
      code: 'LB-BA',
    },
    {
      name: 'B‘alabak-Al Hirmil',
      code: 'LB-BH',
    },
    {
      name: 'Béqaa',
      code: 'LB-BI',
    },
    {
      name: 'Liban-Sud',
      code: 'LB-JA',
    },
    {
      name: 'Mont-Liban',
      code: 'LB-JL',
    },
    {
      name: 'Nabatîyé',
      code: 'LB-NA',
    },
  ],
  LC: [
    {
      name: 'Anse la Raye',
      code: 'LC-01',
    },
    {
      name: 'Castries',
      code: 'LC-02',
    },
    {
      name: 'Choiseul',
      code: 'LC-03',
    },
    {
      name: 'Dennery',
      code: 'LC-05',
    },
    {
      name: 'Gros Islet',
      code: 'LC-06',
    },
    {
      name: 'Laborie',
      code: 'LC-07',
    },
    {
      name: 'Micoud',
      code: 'LC-08',
    },
    {
      name: 'Soufrière',
      code: 'LC-10',
    },
    {
      name: 'Vieux Fort',
      code: 'LC-11',
    },
    {
      name: 'Canaries',
      code: 'LC-12',
    },
  ],
  LI: [
    {
      name: 'Balzers',
      code: 'LI-01',
    },
    {
      name: 'Eschen',
      code: 'LI-02',
    },
    {
      name: 'Gamprin',
      code: 'LI-03',
    },
    {
      name: 'Mauren',
      code: 'LI-04',
    },
    {
      name: 'Planken',
      code: 'LI-05',
    },
    {
      name: 'Ruggell',
      code: 'LI-06',
    },
    {
      name: 'Schaan',
      code: 'LI-07',
    },
    {
      name: 'Schellenberg',
      code: 'LI-08',
    },
    {
      name: 'Triesen',
      code: 'LI-09',
    },
    {
      name: 'Triesenberg',
      code: 'LI-10',
    },
    {
      name: 'Vaduz',
      code: 'LI-11',
    },
  ],
  LK: [
    {
      name: 'Western Province',
      code: 'LK-01',
    },
    {
      name: 'Colombo',
      code: 'LK-11',
    },
    {
      name: 'Gampaha',
      code: 'LK-12',
    },
    {
      name: 'Kalutara',
      code: 'LK-13',
    },
    {
      name: 'Central Province',
      code: 'LK-02',
    },
    {
      name: 'Kandy',
      code: 'LK-21',
    },
    {
      name: 'Matale',
      code: 'LK-22',
    },
    {
      name: 'Nuwara Eliya',
      code: 'LK-23',
    },
    {
      name: 'Southern Province',
      code: 'LK-03',
    },
    {
      name: 'Galle',
      code: 'LK-31',
    },
    {
      name: 'Matara',
      code: 'LK-32',
    },
    {
      name: 'Hambantota',
      code: 'LK-33',
    },
    {
      name: 'Northern Province',
      code: 'LK-04',
    },
    {
      name: 'Jaffna',
      code: 'LK-41',
    },
    {
      name: 'Kilinochchi',
      code: 'LK-42',
    },
    {
      name: 'Mannar',
      code: 'LK-43',
    },
    {
      name: 'Vavuniya',
      code: 'LK-44',
    },
    {
      name: 'Mullaittivu',
      code: 'LK-45',
    },
    {
      name: 'Eastern Province',
      code: 'LK-05',
    },
    {
      name: 'Batticaloa',
      code: 'LK-51',
    },
    {
      name: 'Ampara',
      code: 'LK-52',
    },
    {
      name: 'Trincomalee',
      code: 'LK-53',
    },
    {
      name: 'North Western Province',
      code: 'LK-06',
    },
    {
      name: 'Kurunegala',
      code: 'LK-61',
    },
    {
      name: 'Puttalam',
      code: 'LK-62',
    },
    {
      name: 'North Central Province',
      code: 'LK-07',
    },
    {
      name: 'Anuradhapura',
      code: 'LK-71',
    },
    {
      name: 'Polonnaruwa',
      code: 'LK-72',
    },
    {
      name: 'Uva Province',
      code: 'LK-08',
    },
    {
      name: 'Badulla',
      code: 'LK-81',
    },
    {
      name: 'Monaragala',
      code: 'LK-82',
    },
    {
      name: 'Sabaragamuwa Province',
      code: 'LK-09',
    },
    {
      name: 'Ratnapura',
      code: 'LK-91',
    },
    {
      name: 'Kegalla',
      code: 'LK-92',
    },
  ],
  LR: [
    {
      name: 'Bong',
      code: 'LR-BG',
    },
    {
      name: 'Bomi',
      code: 'LR-BM',
    },
    {
      name: 'Grand Cape Mount',
      code: 'LR-CM',
    },
    {
      name: 'Grand Bassa',
      code: 'LR-GB',
    },
    {
      name: 'Grand Gedeh',
      code: 'LR-GG',
    },
    {
      name: 'Grand Kru',
      code: 'LR-GK',
    },
    {
      name: 'Gbarpolu',
      code: 'LR-GP',
    },
    {
      name: 'Lofa',
      code: 'LR-LO',
    },
    {
      name: 'Margibi',
      code: 'LR-MG',
    },
    {
      name: 'Montserrado',
      code: 'LR-MO',
    },
    {
      name: 'Maryland',
      code: 'LR-MY',
    },
    {
      name: 'Nimba',
      code: 'LR-NI',
    },
    {
      name: 'River Gee',
      code: 'LR-RG',
    },
    {
      name: 'River Cess',
      code: 'LR-RI',
    },
    {
      name: 'Sinoe',
      code: 'LR-SI',
    },
  ],
  LS: [
    {
      name: 'Maseru',
      code: 'LS-A',
    },
    {
      name: 'Butha-Buthe',
      code: 'LS-B',
    },
    {
      name: 'Leribe',
      code: 'LS-C',
    },
    {
      name: 'Berea',
      code: 'LS-D',
    },
    {
      name: 'Mafeteng',
      code: 'LS-E',
    },
    {
      name: "Mohale's Hoek",
      code: 'LS-F',
    },
    {
      name: 'Quthing',
      code: 'LS-G',
    },
    {
      name: "Qacha's Nek",
      code: 'LS-H',
    },
    {
      name: 'Mokhotlong',
      code: 'LS-J',
    },
    {
      name: 'Thaba-Tseka',
      code: 'LS-K',
    },
  ],
  LT: [
    {
      name: 'Alytaus apskritis',
      code: 'LT-AL',
    },
    {
      name: 'Klaipėdos apskritis',
      code: 'LT-KL',
    },
    {
      name: 'Kauno apskritis',
      code: 'LT-KU',
    },
    {
      name: 'Marijampolės apskritis',
      code: 'LT-MR',
    },
    {
      name: 'Panevėžio apskritis',
      code: 'LT-PN',
    },
    {
      name: 'Šiaulių apskritis',
      code: 'LT-SA',
    },
    {
      name: 'Tauragės apskritis',
      code: 'LT-TA',
    },
    {
      name: 'Telšių apskritis',
      code: 'LT-TE',
    },
    {
      name: 'Utenos apskritis',
      code: 'LT-UT',
    },
    {
      name: 'Vilniaus apskritis',
      code: 'LT-VL',
    },
  ],
  LU: [
    {
      name: 'Capellen',
      code: 'LU-CA',
    },
    {
      name: 'Clerf',
      code: 'LU-CL',
    },
    {
      name: 'Diekirch',
      code: 'LU-DI',
    },
    {
      name: 'Echternach',
      code: 'LU-EC',
    },
    {
      name: 'Esch an der Alzette',
      code: 'LU-ES',
    },
    {
      name: 'Grevenmacher',
      code: 'LU-GR',
    },
    {
      name: 'Luxemburg',
      code: 'LU-LU',
    },
    {
      name: 'Mersch',
      code: 'LU-ME',
    },
    {
      name: 'Redingen',
      code: 'LU-RD',
    },
    {
      name: 'Remich',
      code: 'LU-RM',
    },
    {
      name: 'Vianden',
      code: 'LU-VD',
    },
    {
      name: 'Wiltz',
      code: 'LU-WI',
    },
  ],
  LV: [
    {
      name: 'Aglonas novads',
      code: 'LV-001',
    },
    {
      name: 'Aizkraukles novads',
      code: 'LV-002',
    },
    {
      name: 'Aizputes novads',
      code: 'LV-003',
    },
    {
      name: 'Aknīstes novads',
      code: 'LV-004',
    },
    {
      name: 'Alojas novads',
      code: 'LV-005',
    },
    {
      name: 'Alsungas novads',
      code: 'LV-006',
    },
    {
      name: 'Alūksnes novads',
      code: 'LV-007',
    },
    {
      name: 'Amatas novads',
      code: 'LV-008',
    },
    {
      name: 'Apes novads',
      code: 'LV-009',
    },
    {
      name: 'Auces novads',
      code: 'LV-010',
    },
    {
      name: 'Ādažu novads',
      code: 'LV-011',
    },
    {
      name: 'Babītes novads',
      code: 'LV-012',
    },
    {
      name: 'Baldones novads',
      code: 'LV-013',
    },
    {
      name: 'Baltinavas novads',
      code: 'LV-014',
    },
    {
      name: 'Balvu novads',
      code: 'LV-015',
    },
    {
      name: 'Bauskas novads',
      code: 'LV-016',
    },
    {
      name: 'Beverīnas novads',
      code: 'LV-017',
    },
    {
      name: 'Brocēnu novads',
      code: 'LV-018',
    },
    {
      name: 'Burtnieku novads',
      code: 'LV-019',
    },
    {
      name: 'Carnikavas novads',
      code: 'LV-020',
    },
    {
      name: 'Cesvaines novads',
      code: 'LV-021',
    },
    {
      name: 'Cēsu novads',
      code: 'LV-022',
    },
    {
      name: 'Ciblas novads',
      code: 'LV-023',
    },
    {
      name: 'Dagdas novads',
      code: 'LV-024',
    },
    {
      name: 'Daugavpils novads',
      code: 'LV-025',
    },
    {
      name: 'Dobeles novads',
      code: 'LV-026',
    },
    {
      name: 'Dundagas novads',
      code: 'LV-027',
    },
    {
      name: 'Durbes novads',
      code: 'LV-028',
    },
    {
      name: 'Engures novads',
      code: 'LV-029',
    },
    {
      name: 'Ērgļu novads',
      code: 'LV-030',
    },
    {
      name: 'Garkalnes novads',
      code: 'LV-031',
    },
    {
      name: 'Grobiņas novads',
      code: 'LV-032',
    },
    {
      name: 'Gulbenes novads',
      code: 'LV-033',
    },
    {
      name: 'Iecavas novads',
      code: 'LV-034',
    },
    {
      name: 'Ikšķiles novads',
      code: 'LV-035',
    },
    {
      name: 'Ilūkstes novads',
      code: 'LV-036',
    },
    {
      name: 'Inčukalna novads',
      code: 'LV-037',
    },
    {
      name: 'Jaunjelgavas novads',
      code: 'LV-038',
    },
    {
      name: 'Jaunpiebalgas novads',
      code: 'LV-039',
    },
    {
      name: 'Jaunpils novads',
      code: 'LV-040',
    },
    {
      name: 'Jelgavas novads',
      code: 'LV-041',
    },
    {
      name: 'Jēkabpils novads',
      code: 'LV-042',
    },
    {
      name: 'Kandavas novads',
      code: 'LV-043',
    },
    {
      name: 'Kārsavas novads',
      code: 'LV-044',
    },
    {
      name: 'Kocēnu novads',
      code: 'LV-045',
    },
    {
      name: 'Kokneses novads',
      code: 'LV-046',
    },
    {
      name: 'Krāslavas novads',
      code: 'LV-047',
    },
    {
      name: 'Krimuldas novads',
      code: 'LV-048',
    },
    {
      name: 'Krustpils novads',
      code: 'LV-049',
    },
    {
      name: 'Kuldīgas novads',
      code: 'LV-050',
    },
    {
      name: 'Ķeguma novads',
      code: 'LV-051',
    },
    {
      name: 'Ķekavas novads',
      code: 'LV-052',
    },
    {
      name: 'Lielvārdes novads',
      code: 'LV-053',
    },
    {
      name: 'Limbažu novads',
      code: 'LV-054',
    },
    {
      name: 'Līgatnes novads',
      code: 'LV-055',
    },
    {
      name: 'Līvānu novads',
      code: 'LV-056',
    },
    {
      name: 'Lubānas novads',
      code: 'LV-057',
    },
    {
      name: 'Ludzas novads',
      code: 'LV-058',
    },
    {
      name: 'Madonas novads',
      code: 'LV-059',
    },
    {
      name: 'Mazsalacas novads',
      code: 'LV-060',
    },
    {
      name: 'Mālpils novads',
      code: 'LV-061',
    },
    {
      name: 'Mārupes novads',
      code: 'LV-062',
    },
    {
      name: 'Mērsraga novads',
      code: 'LV-063',
    },
    {
      name: 'Naukšēnu novads',
      code: 'LV-064',
    },
    {
      name: 'Neretas novads',
      code: 'LV-065',
    },
    {
      name: 'Nīcas novads',
      code: 'LV-066',
    },
    {
      name: 'Ogres novads',
      code: 'LV-067',
    },
    {
      name: 'Olaines novads',
      code: 'LV-068',
    },
    {
      name: 'Ozolnieku novads',
      code: 'LV-069',
    },
    {
      name: 'Pārgaujas novads',
      code: 'LV-070',
    },
    {
      name: 'Pāvilostas novads',
      code: 'LV-071',
    },
    {
      name: 'Pļaviņu novads',
      code: 'LV-072',
    },
    {
      name: 'Preiļu novads',
      code: 'LV-073',
    },
    {
      name: 'Priekules novads',
      code: 'LV-074',
    },
    {
      name: 'Priekuļu novads',
      code: 'LV-075',
    },
    {
      name: 'Raunas novads',
      code: 'LV-076',
    },
    {
      name: 'Rēzeknes novads',
      code: 'LV-077',
    },
    {
      name: 'Riebiņu novads',
      code: 'LV-078',
    },
    {
      name: 'Rojas novads',
      code: 'LV-079',
    },
    {
      name: 'Ropažu novads',
      code: 'LV-080',
    },
    {
      name: 'Rucavas novads',
      code: 'LV-081',
    },
    {
      name: 'Rugāju novads',
      code: 'LV-082',
    },
    {
      name: 'Rundāles novads',
      code: 'LV-083',
    },
    {
      name: 'Rūjienas novads',
      code: 'LV-084',
    },
    {
      name: 'Salas novads',
      code: 'LV-085',
    },
    {
      name: 'Salacgrīvas novads',
      code: 'LV-086',
    },
    {
      name: 'Salaspils novads',
      code: 'LV-087',
    },
    {
      name: 'Saldus novads',
      code: 'LV-088',
    },
    {
      name: 'Saulkrastu novads',
      code: 'LV-089',
    },
    {
      name: 'Sējas novads',
      code: 'LV-090',
    },
    {
      name: 'Siguldas novads',
      code: 'LV-091',
    },
    {
      name: 'Skrīveru novads',
      code: 'LV-092',
    },
    {
      name: 'Skrundas novads',
      code: 'LV-093',
    },
    {
      name: 'Smiltenes novads',
      code: 'LV-094',
    },
    {
      name: 'Stopiņu novads',
      code: 'LV-095',
    },
    {
      name: 'Strenču novads',
      code: 'LV-096',
    },
    {
      name: 'Talsu novads',
      code: 'LV-097',
    },
    {
      name: 'Tērvetes novads',
      code: 'LV-098',
    },
    {
      name: 'Tukuma novads',
      code: 'LV-099',
    },
    {
      name: 'Vaiņodes novads',
      code: 'LV-100',
    },
    {
      name: 'Valkas novads',
      code: 'LV-101',
    },
    {
      name: 'Varakļānu novads',
      code: 'LV-102',
    },
    {
      name: 'Vārkavas novads',
      code: 'LV-103',
    },
    {
      name: 'Vecpiebalgas novads',
      code: 'LV-104',
    },
    {
      name: 'Vecumnieku novads',
      code: 'LV-105',
    },
    {
      name: 'Ventspils novads',
      code: 'LV-106',
    },
    {
      name: 'Viesītes novads',
      code: 'LV-107',
    },
    {
      name: 'Viļakas novads',
      code: 'LV-108',
    },
    {
      name: 'Viļānu novads',
      code: 'LV-109',
    },
    {
      name: 'Zilupes novads',
      code: 'LV-110',
    },
    {
      name: 'Daugavpils',
      code: 'LV-DGV',
    },
    {
      name: 'Jelgava',
      code: 'LV-JEL',
    },
    {
      name: 'Jēkabpils',
      code: 'LV-JKB',
    },
    {
      name: 'Jūrmala',
      code: 'LV-JUR',
    },
    {
      name: 'Liepāja',
      code: 'LV-LPX',
    },
    {
      name: 'Rēzekne',
      code: 'LV-REZ',
    },
    {
      name: 'Rīga',
      code: 'LV-RIX',
    },
    {
      name: 'Ventspils',
      code: 'LV-VEN',
    },
    {
      name: 'Valmiera',
      code: 'LV-VMR',
    },
  ],
  LY: [
    {
      name: 'Banghāzī',
      code: 'LY-BA',
    },
    {
      name: 'Al Buţnān',
      code: 'LY-BU',
    },
    {
      name: 'Darnah',
      code: 'LY-DR',
    },
    {
      name: 'Ghāt',
      code: 'LY-GT',
    },
    {
      name: 'Al Jabal al Akhḑar',
      code: 'LY-JA',
    },
    {
      name: 'Al Jabal al Gharbī',
      code: 'LY-JG',
    },
    {
      name: 'Al Jafārah',
      code: 'LY-JI',
    },
    {
      name: 'Al Jufrah',
      code: 'LY-JU',
    },
    {
      name: 'Al Kufrah',
      code: 'LY-KF',
    },
    {
      name: 'Al Marqab',
      code: 'LY-MB',
    },
    {
      name: 'Mişrātah',
      code: 'LY-MI',
    },
    {
      name: 'Al Marj',
      code: 'LY-MJ',
    },
    {
      name: 'Murzuq',
      code: 'LY-MQ',
    },
    {
      name: 'Nālūt',
      code: 'LY-NL',
    },
    {
      name: 'An Nuqāţ al Khams',
      code: 'LY-NQ',
    },
    {
      name: 'Sabhā',
      code: 'LY-SB',
    },
    {
      name: 'Surt',
      code: 'LY-SR',
    },
    {
      name: 'Ţarābulus',
      code: 'LY-TB',
    },
    {
      name: 'Al Wāḩāt',
      code: 'LY-WA',
    },
    {
      name: 'Wādī al Ḩayāt',
      code: 'LY-WD',
    },
    {
      name: 'Wādī ash Shāţi’',
      code: 'LY-WS',
    },
    {
      name: 'Az Zāwiyah',
      code: 'LY-ZA',
    },
  ],
  MA: [
    {
      name: 'Tanger-Tétouan-Al Hoceïma',
      code: 'MA-01',
    },
    {
      name: "L'Oriental",
      code: 'MA-02',
    },
    {
      name: 'Fès-Meknès',
      code: 'MA-03',
    },
    {
      name: 'Rabat-Salé-Kénitra',
      code: 'MA-04',
    },
    {
      name: 'Béni Mellal-Khénifra',
      code: 'MA-05',
    },
    {
      name: 'Casablanca-Settat',
      code: 'MA-06',
    },
    {
      name: 'Marrakech-Safi',
      code: 'MA-07',
    },
    {
      name: 'Drâa-Tafilalet',
      code: 'MA-08',
    },
    {
      name: 'Souss-Massa',
      code: 'MA-09',
    },
    {
      name: 'Guelmim-Oued Noun',
      code: 'MA-10',
    },
    {
      name: 'Laâyoune-Sakia El Hamra',
      code: 'MA-11',
    },
    {
      name: 'Dakhla-Oued Ed-Dahab',
      code: 'MA-12',
    },
  ],
  MC: [
    {
      name: 'La Colle',
      code: 'MC-CL',
    },
    {
      name: 'La Condamine',
      code: 'MC-CO',
    },
    {
      name: 'Fontvieille',
      code: 'MC-FO',
    },
    {
      name: 'La Gare',
      code: 'MC-GA',
    },
    {
      name: 'Jardin Exotique',
      code: 'MC-JE',
    },
    {
      name: 'Larvotto',
      code: 'MC-LA',
    },
    {
      name: 'Malbousquet',
      code: 'MC-MA',
    },
    {
      name: 'Monte-Carlo',
      code: 'MC-MC',
    },
    {
      name: 'Moneghetti',
      code: 'MC-MG',
    },
    {
      name: 'Monaco-Ville',
      code: 'MC-MO',
    },
    {
      name: 'Moulins',
      code: 'MC-MU',
    },
    {
      name: 'Port-Hercule',
      code: 'MC-PH',
    },
    {
      name: 'Sainte-Dévote',
      code: 'MC-SD',
    },
    {
      name: 'La Source',
      code: 'MC-SO',
    },
    {
      name: 'Spélugues',
      code: 'MC-SP',
    },
    {
      name: 'Saint-Roman',
      code: 'MC-SR',
    },
    {
      name: 'Vallon de la Rousse',
      code: 'MC-VR',
    },
  ],
  MD: [
    {
      name: 'Anenii Noi',
      code: 'MD-AN',
    },
    {
      name: 'Bălți',
      code: 'MD-BA',
    },
    {
      name: 'Bender [Tighina]',
      code: 'MD-BD',
    },
    {
      name: 'Briceni',
      code: 'MD-BR',
    },
    {
      name: 'Basarabeasca',
      code: 'MD-BS',
    },
    {
      name: 'Cahul',
      code: 'MD-CA',
    },
    {
      name: 'Călărași',
      code: 'MD-CL',
    },
    {
      name: 'Cimișlia',
      code: 'MD-CM',
    },
    {
      name: 'Criuleni',
      code: 'MD-CR',
    },
    {
      name: 'Căușeni',
      code: 'MD-CS',
    },
    {
      name: 'Cantemir',
      code: 'MD-CT',
    },
    {
      name: 'Chișinău',
      code: 'MD-CU',
    },
    {
      name: 'Dondușeni',
      code: 'MD-DO',
    },
    {
      name: 'Drochia',
      code: 'MD-DR',
    },
    {
      name: 'Dubăsari',
      code: 'MD-DU',
    },
    {
      name: 'Edineț',
      code: 'MD-ED',
    },
    {
      name: 'Fălești',
      code: 'MD-FA',
    },
    {
      name: 'Florești',
      code: 'MD-FL',
    },
    {
      name: 'Găgăuzia, Unitatea teritorială autonomă',
      code: 'MD-GA',
    },
    {
      name: 'Glodeni',
      code: 'MD-GL',
    },
    {
      name: 'Hîncești',
      code: 'MD-HI',
    },
    {
      name: 'Ialoveni',
      code: 'MD-IA',
    },
    {
      name: 'Leova',
      code: 'MD-LE',
    },
    {
      name: 'Nisporeni',
      code: 'MD-NI',
    },
    {
      name: 'Ocnița',
      code: 'MD-OC',
    },
    {
      name: 'Orhei',
      code: 'MD-OR',
    },
    {
      name: 'Rezina',
      code: 'MD-RE',
    },
    {
      name: 'Rîșcani',
      code: 'MD-RI',
    },
    {
      name: 'Șoldănești',
      code: 'MD-SD',
    },
    {
      name: 'Sîngerei',
      code: 'MD-SI',
    },
    {
      name: 'Stînga Nistrului, unitatea teritorială din',
      code: 'MD-SN',
    },
    {
      name: 'Soroca',
      code: 'MD-SO',
    },
    {
      name: 'Strășeni',
      code: 'MD-ST',
    },
    {
      name: 'Ștefan Vodă',
      code: 'MD-SV',
    },
    {
      name: 'Taraclia',
      code: 'MD-TA',
    },
    {
      name: 'Telenești',
      code: 'MD-TE',
    },
    {
      name: 'Ungheni',
      code: 'MD-UN',
    },
  ],
  ME: [
    {
      name: 'Andrijevica',
      code: 'ME-01',
    },
    {
      name: 'Bar',
      code: 'ME-02',
    },
    {
      name: 'Berane',
      code: 'ME-03',
    },
    {
      name: 'Bijelo Polje',
      code: 'ME-04',
    },
    {
      name: 'Budva',
      code: 'ME-05',
    },
    {
      name: 'Cetinje',
      code: 'ME-06',
    },
    {
      name: 'Danilovgrad',
      code: 'ME-07',
    },
    {
      name: 'Herceg-Novi',
      code: 'ME-08',
    },
    {
      name: 'Kolašin',
      code: 'ME-09',
    },
    {
      name: 'Kotor',
      code: 'ME-10',
    },
    {
      name: 'Mojkovac',
      code: 'ME-11',
    },
    {
      name: 'Nikšić',
      code: 'ME-12',
    },
    {
      name: 'Plav',
      code: 'ME-13',
    },
    {
      name: 'Pljevlja',
      code: 'ME-14',
    },
    {
      name: 'Plužine',
      code: 'ME-15',
    },
    {
      name: 'Podgorica',
      code: 'ME-16',
    },
    {
      name: 'Rožaje',
      code: 'ME-17',
    },
    {
      name: 'Šavnik',
      code: 'ME-18',
    },
    {
      name: 'Tivat',
      code: 'ME-19',
    },
    {
      name: 'Ulcinj',
      code: 'ME-20',
    },
    {
      name: 'Žabljak',
      code: 'ME-21',
    },
    {
      name: 'Gusinje',
      code: 'ME-22',
    },
    {
      name: 'Petnjica',
      code: 'ME-23',
    },
    {
      name: 'Tuzi',
      code: 'ME-24',
    },
  ],
  MH: [
    {
      name: 'Ailuk',
      code: 'MH-ALK',
    },
    {
      name: 'Ailinglaplap',
      code: 'MH-ALL',
    },
    {
      name: 'Arno',
      code: 'MH-ARN',
    },
    {
      name: 'Aur',
      code: 'MH-AUR',
    },
    {
      name: 'Ebon',
      code: 'MH-EBO',
    },
    {
      name: 'Enewetak & Ujelang',
      code: 'MH-ENI',
    },
    {
      name: 'Jabat',
      code: 'MH-JAB',
    },
    {
      name: 'Jaluit',
      code: 'MH-JAL',
    },
    {
      name: 'Bikini & Kili',
      code: 'MH-KIL',
    },
    {
      name: 'Ralik chain',
      code: 'MH-L',
    },
    {
      name: 'Lae',
      code: 'MH-LAE',
    },
    {
      name: 'Lib',
      code: 'MH-LIB',
    },
    {
      name: 'Likiep',
      code: 'MH-LIK',
    },
    {
      name: 'Majuro',
      code: 'MH-MAJ',
    },
    {
      name: 'Maloelap',
      code: 'MH-MAL',
    },
    {
      name: 'Mejit',
      code: 'MH-MEJ',
    },
    {
      name: 'Mili',
      code: 'MH-MIL',
    },
    {
      name: 'Namdrik',
      code: 'MH-NMK',
    },
    {
      name: 'Namu',
      code: 'MH-NMU',
    },
    {
      name: 'Rongelap',
      code: 'MH-RON',
    },
    {
      name: 'Ratak chain',
      code: 'MH-T',
    },
    {
      name: 'Ujae',
      code: 'MH-UJA',
    },
    {
      name: 'Utrik',
      code: 'MH-UTI',
    },
    {
      name: 'Wotho',
      code: 'MH-WTH',
    },
    {
      name: 'Wotje',
      code: 'MH-WTJ',
    },
  ],
  MG: [
    {
      name: 'Toamasina',
      code: 'MG-A',
    },
    {
      name: 'Antsiranana',
      code: 'MG-D',
    },
    {
      name: 'Fianarantsoa',
      code: 'MG-F',
    },
    {
      name: 'Mahajanga',
      code: 'MG-M',
    },
    {
      name: 'Antananarivo',
      code: 'MG-T',
    },
    {
      name: 'Toliara',
      code: 'MG-U',
    },
  ],
  MK: [
    {
      name: 'Veles',
      code: 'MK-101',
    },
    {
      name: 'Gradsko',
      code: 'MK-102',
    },
    {
      name: 'Demir Kapija',
      code: 'MK-103',
    },
    {
      name: 'Kavadarci',
      code: 'MK-104',
    },
    {
      name: 'Lozovo',
      code: 'MK-105',
    },
    {
      name: 'Negotino',
      code: 'MK-106',
    },
    {
      name: 'Rosoman',
      code: 'MK-107',
    },
    {
      name: 'Sveti Nikole',
      code: 'MK-108',
    },
    {
      name: 'Čaška',
      code: 'MK-109',
    },
    {
      name: 'Berovo',
      code: 'MK-201',
    },
    {
      name: 'Vinica',
      code: 'MK-202',
    },
    {
      name: 'Delčevo',
      code: 'MK-203',
    },
    {
      name: 'Zrnovci',
      code: 'MK-204',
    },
    {
      name: 'Karbinci',
      code: 'MK-205',
    },
    {
      name: 'Kočani',
      code: 'MK-206',
    },
    {
      name: 'Makedonska Kamenica',
      code: 'MK-207',
    },
    {
      name: 'Pehčevo',
      code: 'MK-208',
    },
    {
      name: 'Probištip',
      code: 'MK-209',
    },
    {
      name: 'Češinovo-Obleševo',
      code: 'MK-210',
    },
    {
      name: 'Štip',
      code: 'MK-211',
    },
    {
      name: 'Vevčani',
      code: 'MK-301',
    },
    {
      name: 'Debar',
      code: 'MK-303',
    },
    {
      name: 'Debrca',
      code: 'MK-304',
    },
    {
      name: 'Makedonski Brod',
      code: 'MK-308',
    },
    {
      name: 'Ohrid',
      code: 'MK-310',
    },
    {
      name: 'Plasnica',
      code: 'MK-311',
    },
    {
      name: 'Struga',
      code: 'MK-312',
    },
    {
      name: 'Centar Župa',
      code: 'MK-313',
    },
    {
      name: 'Bogdanci',
      code: 'MK-401',
    },
    {
      name: 'Bosilovo',
      code: 'MK-402',
    },
    {
      name: 'Valandovo',
      code: 'MK-403',
    },
    {
      name: 'Vasilevo',
      code: 'MK-404',
    },
    {
      name: 'Gevgelija',
      code: 'MK-405',
    },
    {
      name: 'Dojran',
      code: 'MK-406',
    },
    {
      name: 'Konče',
      code: 'MK-407',
    },
    {
      name: 'Novo Selo',
      code: 'MK-408',
    },
    {
      name: 'Radoviš',
      code: 'MK-409',
    },
    {
      name: 'Strumica',
      code: 'MK-410',
    },
    {
      name: 'Bitola',
      code: 'MK-501',
    },
    {
      name: 'Demir Hisar',
      code: 'MK-502',
    },
    {
      name: 'Dolneni',
      code: 'MK-503',
    },
    {
      name: 'Krivogaštani',
      code: 'MK-504',
    },
    {
      name: 'Kruševo',
      code: 'MK-505',
    },
    {
      name: 'Mogila',
      code: 'MK-506',
    },
    {
      name: 'Novaci',
      code: 'MK-507',
    },
    {
      name: 'Prilep',
      code: 'MK-508',
    },
    {
      name: 'Resen',
      code: 'MK-509',
    },
    {
      name: 'Bogovinje',
      code: 'MK-601',
    },
    {
      name: 'Brvenica',
      code: 'MK-602',
    },
    {
      name: 'Vrapčište',
      code: 'MK-603',
    },
    {
      name: 'Gostivar',
      code: 'MK-604',
    },
    {
      name: 'Želino',
      code: 'MK-605',
    },
    {
      name: 'Jegunovce',
      code: 'MK-606',
    },
    {
      name: 'Mavrovo i Rostuše',
      code: 'MK-607',
    },
    {
      name: 'Tearce',
      code: 'MK-608',
    },
    {
      name: 'Tetovo',
      code: 'MK-609',
    },
    {
      name: 'Kratovo',
      code: 'MK-701',
    },
    {
      name: 'Kriva Palanka',
      code: 'MK-702',
    },
    {
      name: 'Kumanovo',
      code: 'MK-703',
    },
    {
      name: 'Lipkovo',
      code: 'MK-704',
    },
    {
      name: 'Rankovce',
      code: 'MK-705',
    },
    {
      name: 'Staro Nagoričane',
      code: 'MK-706',
    },
    {
      name: 'Aerodrom',
      code: 'MK-801',
    },
    {
      name: 'Aračinovo',
      code: 'MK-802',
    },
    {
      name: 'Butel',
      code: 'MK-803',
    },
    {
      name: 'Gazi Baba',
      code: 'MK-804',
    },
    {
      name: 'Gjorče Petrov',
      code: 'MK-805',
    },
    {
      name: 'Zelenikovo',
      code: 'MK-806',
    },
    {
      name: 'Ilinden',
      code: 'MK-807',
    },
    {
      name: 'Karpoš',
      code: 'MK-808',
    },
    {
      name: 'Kisela Voda',
      code: 'MK-809',
    },
    {
      name: 'Petrovec',
      code: 'MK-810',
    },
    {
      name: 'Saraj',
      code: 'MK-811',
    },
    {
      name: 'Sopište',
      code: 'MK-812',
    },
    {
      name: 'Studeničani',
      code: 'MK-813',
    },
    {
      name: 'Centar',
      code: 'MK-814',
    },
    {
      name: 'Čair',
      code: 'MK-815',
    },
    {
      name: 'Čučer-Sandevo',
      code: 'MK-816',
    },
    {
      name: 'Štip',
      code: 'MK-817',
    },
  ],
  ML: [
    {
      name: 'Kayes',
      code: 'ML-01',
    },
    {
      name: 'Taoudénit',
      code: 'ML-10',
    },
    {
      name: 'Koulikoro',
      code: 'ML-02',
    },
    {
      name: 'Sikasso',
      code: 'ML-03',
    },
    {
      name: 'Ségou',
      code: 'ML-04',
    },
    {
      name: 'Mopti',
      code: 'ML-05',
    },
    {
      name: 'Tombouctou',
      code: 'ML-06',
    },
    {
      name: 'Gao',
      code: 'ML-07',
    },
    {
      name: 'Kidal',
      code: 'ML-08',
    },
    {
      name: 'Ménaka',
      code: 'ML-09',
    },
    {
      name: 'Bamako',
      code: 'ML-BKO',
    },
  ],
  MM: [
    {
      name: 'Sagaing',
      code: 'MM-01',
    },
    {
      name: 'Bago',
      code: 'MM-02',
    },
    {
      name: 'Magway',
      code: 'MM-03',
    },
    {
      name: 'Mandalay',
      code: 'MM-04',
    },
    {
      name: 'Tanintharyi',
      code: 'MM-05',
    },
    {
      name: 'Yangon',
      code: 'MM-06',
    },
    {
      name: 'Ayeyarwady',
      code: 'MM-07',
    },
    {
      name: 'Kachin',
      code: 'MM-08',
    },
    {
      name: 'Kayah',
      code: 'MM-12',
    },
    {
      name: 'Kayin',
      code: 'MM-13',
    },
    {
      name: 'Chin',
      code: 'MM-14',
    },
    {
      name: 'Mon',
      code: 'MM-15',
    },
    {
      name: 'Rakhine',
      code: 'MM-16',
    },
    {
      name: 'Shan',
      code: 'MM-17',
    },
    {
      name: 'Nay Pyi Taw',
      code: 'MM-18',
    },
  ],
  MN: [
    {
      name: 'Orhon',
      code: 'MN-35',
    },
    {
      name: 'Darhan uul',
      code: 'MN-37',
    },
    {
      name: 'Hentiy',
      code: 'MN-39',
    },
    {
      name: 'Hövsgöl',
      code: 'MN-41',
    },
    {
      name: 'Hovd',
      code: 'MN-43',
    },
    {
      name: 'Uvs',
      code: 'MN-46',
    },
    {
      name: 'Töv',
      code: 'MN-47',
    },
    {
      name: 'Selenge',
      code: 'MN-49',
    },
    {
      name: 'Sühbaatar',
      code: 'MN-51',
    },
    {
      name: 'Ömnögovĭ',
      code: 'MN-53',
    },
    {
      name: 'Övörhangay',
      code: 'MN-55',
    },
    {
      name: 'Dzavhan',
      code: 'MN-57',
    },
    {
      name: 'Dundgovĭ',
      code: 'MN-59',
    },
    {
      name: 'Dornod',
      code: 'MN-61',
    },
    {
      name: 'Dornogovĭ',
      code: 'MN-63',
    },
    {
      name: 'Govĭ-Sümber',
      code: 'MN-64',
    },
    {
      name: 'Govĭ-Altay',
      code: 'MN-65',
    },
    {
      name: 'Bulgan',
      code: 'MN-67',
    },
    {
      name: 'Bayanhongor',
      code: 'MN-69',
    },
    {
      name: 'Bayan-Ölgiy',
      code: 'MN-71',
    },
    {
      name: 'Arhangay',
      code: 'MN-73',
    },
    {
      name: 'Ulaanbaatar',
      code: 'MN-01',
    },
  ],
  MR: [
    {
      name: 'Hodh ech Chargui',
      code: 'MR-01',
    },
    {
      name: 'Hodh el Gharbi',
      code: 'MR-02',
    },
    {
      name: 'Assaba',
      code: 'MR-03',
    },
    {
      name: 'Gorgol',
      code: 'MR-04',
    },
    {
      name: 'Brakna',
      code: 'MR-05',
    },
    {
      name: 'Trarza',
      code: 'MR-06',
    },
    {
      name: 'Adrar',
      code: 'MR-07',
    },
    {
      name: 'Dakhlet Nouâdhibou',
      code: 'MR-08',
    },
    {
      name: 'Tagant',
      code: 'MR-09',
    },
    {
      name: 'Guidimaka',
      code: 'MR-10',
    },
    {
      name: 'Tiris Zemmour',
      code: 'MR-11',
    },
    {
      name: 'Inchiri',
      code: 'MR-12',
    },
    {
      name: 'Nuwākshūţ al Gharbīyah',
      code: 'MR-13',
    },
    {
      name: 'Nuwākshūţ ash Shamālīyah',
      code: 'MR-14',
    },
    {
      name: 'Nuwākshūţ al Janūbīyah',
      code: 'MR-15',
    },
  ],
  MT: [
    {
      name: 'Attard',
      code: 'MT-01',
    },
    {
      name: 'Balzan',
      code: 'MT-02',
    },
    {
      name: 'Birgu',
      code: 'MT-03',
    },
    {
      name: 'Birkirkara',
      code: 'MT-04',
    },
    {
      name: 'Birżebbuġa',
      code: 'MT-05',
    },
    {
      name: 'Bormla',
      code: 'MT-06',
    },
    {
      name: 'Dingli',
      code: 'MT-07',
    },
    {
      name: 'Fgura',
      code: 'MT-08',
    },
    {
      name: 'Floriana',
      code: 'MT-09',
    },
    {
      name: 'Fontana',
      code: 'MT-10',
    },
    {
      name: 'Gudja',
      code: 'MT-11',
    },
    {
      name: 'Gżira',
      code: 'MT-12',
    },
    {
      name: 'Għajnsielem',
      code: 'MT-13',
    },
    {
      name: 'Għarb',
      code: 'MT-14',
    },
    {
      name: 'Għargħur',
      code: 'MT-15',
    },
    {
      name: 'Għasri',
      code: 'MT-16',
    },
    {
      name: 'Għaxaq',
      code: 'MT-17',
    },
    {
      name: 'Ħamrun',
      code: 'MT-18',
    },
    {
      name: 'Iklin',
      code: 'MT-19',
    },
    {
      name: 'Isla',
      code: 'MT-20',
    },
    {
      name: 'Kalkara',
      code: 'MT-21',
    },
    {
      name: 'Kerċem',
      code: 'MT-22',
    },
    {
      name: 'Kirkop',
      code: 'MT-23',
    },
    {
      name: 'Lija',
      code: 'MT-24',
    },
    {
      name: 'Luqa',
      code: 'MT-25',
    },
    {
      name: 'Marsa',
      code: 'MT-26',
    },
    {
      name: 'Marsaskala',
      code: 'MT-27',
    },
    {
      name: 'Marsaxlokk',
      code: 'MT-28',
    },
    {
      name: 'Mdina',
      code: 'MT-29',
    },
    {
      name: 'Mellieħa',
      code: 'MT-30',
    },
    {
      name: 'Mġarr',
      code: 'MT-31',
    },
    {
      name: 'Mosta',
      code: 'MT-32',
    },
    {
      name: 'Mqabba',
      code: 'MT-33',
    },
    {
      name: 'Msida',
      code: 'MT-34',
    },
    {
      name: 'Mtarfa',
      code: 'MT-35',
    },
    {
      name: 'Munxar',
      code: 'MT-36',
    },
    {
      name: 'Nadur',
      code: 'MT-37',
    },
    {
      name: 'Naxxar',
      code: 'MT-38',
    },
    {
      name: 'Paola',
      code: 'MT-39',
    },
    {
      name: 'Pembroke',
      code: 'MT-40',
    },
    {
      name: 'Pietà',
      code: 'MT-41',
    },
    {
      name: 'Qala',
      code: 'MT-42',
    },
    {
      name: 'Qormi',
      code: 'MT-43',
    },
    {
      name: 'Qrendi',
      code: 'MT-44',
    },
    {
      name: 'Rabat Għawdex',
      code: 'MT-45',
    },
    {
      name: 'Rabat Malta',
      code: 'MT-46',
    },
    {
      name: 'Safi',
      code: 'MT-47',
    },
    {
      name: 'San Ġiljan',
      code: 'MT-48',
    },
    {
      name: 'San Ġwann',
      code: 'MT-49',
    },
    {
      name: 'San Lawrenz',
      code: 'MT-50',
    },
    {
      name: 'San Pawl il-Baħar',
      code: 'MT-51',
    },
    {
      name: 'Sannat',
      code: 'MT-52',
    },
    {
      name: 'Santa Luċija',
      code: 'MT-53',
    },
    {
      name: 'Santa Venera',
      code: 'MT-54',
    },
    {
      name: 'Siġġiewi',
      code: 'MT-55',
    },
    {
      name: 'Sliema',
      code: 'MT-56',
    },
    {
      name: 'Swieqi',
      code: 'MT-57',
    },
    {
      name: "Ta' Xbiex",
      code: 'MT-58',
    },
    {
      name: 'Tarxien',
      code: 'MT-59',
    },
    {
      name: 'Valletta',
      code: 'MT-60',
    },
    {
      name: 'Xagħra',
      code: 'MT-61',
    },
    {
      name: 'Xewkija',
      code: 'MT-62',
    },
    {
      name: 'Xgħajra',
      code: 'MT-63',
    },
    {
      name: 'Żabbar',
      code: 'MT-64',
    },
    {
      name: 'Żebbuġ Għawdex',
      code: 'MT-65',
    },
    {
      name: 'Żebbuġ Malta',
      code: 'MT-66',
    },
    {
      name: 'Żejtun',
      code: 'MT-67',
    },
    {
      name: 'Żurrieq',
      code: 'MT-68',
    },
  ],
  MU: [
    {
      name: 'Agalega Islands',
      code: 'MU-AG',
    },
    {
      name: 'Black River',
      code: 'MU-BL',
    },
    {
      name: 'Beau Bassin-Rose Hill',
      code: 'MU-BR',
    },
    {
      name: 'Cargados Carajos Shoals',
      code: 'MU-CC',
    },
    {
      name: 'Curepipe',
      code: 'MU-CU',
    },
    {
      name: 'Flacq',
      code: 'MU-FL',
    },
    {
      name: 'Grand Port',
      code: 'MU-GP',
    },
    {
      name: 'Moka',
      code: 'MU-MO',
    },
    {
      name: 'Pamplemousses',
      code: 'MU-PA',
    },
    {
      name: 'Port Louis',
      code: 'MU-PL',
    },
    {
      name: 'Port Louis',
      code: 'MU-PU',
    },
    {
      name: 'Plaines Wilhems',
      code: 'MU-PW',
    },
    {
      name: 'Quatre Bornes',
      code: 'MU-QB',
    },
    {
      name: 'Rodrigues Island',
      code: 'MU-RO',
    },
    {
      name: 'Rivière du Rempart',
      code: 'MU-RR',
    },
    {
      name: 'Savanne',
      code: 'MU-SA',
    },
    {
      name: 'Vacoas-Phoenix',
      code: 'MU-VP',
    },
  ],
  MV: [
    {
      name: 'Addu City',
      code: 'MV-01',
    },
    {
      name: 'North Ari Atoll',
      code: 'MV-02',
    },
    {
      name: 'Faadhippolhu',
      code: 'MV-03',
    },
    {
      name: 'Felidhu Atoll',
      code: 'MV-04',
    },
    {
      name: 'Hahdhunmathi',
      code: 'MV-05',
    },
    {
      name: 'North Thiladhunmathi',
      code: 'MV-07',
    },
    {
      name: 'Kolhumadulu',
      code: 'MV-08',
    },
    {
      name: 'Mulaku Atoll',
      code: 'MV-12',
    },
    {
      name: 'North Maalhosmadulu',
      code: 'MV-13',
    },
    {
      name: 'North Nilandhe Atoll',
      code: 'MV-14',
    },
    {
      name: 'South Nilandhe Atoll',
      code: 'MV-17',
    },
    {
      name: 'South Maalhosmadulu',
      code: 'MV-20',
    },
    {
      name: 'South Thiladhunmathi',
      code: 'MV-23',
    },
    {
      name: 'North Miladhunmadulu',
      code: 'MV-24',
    },
    {
      name: 'South Miladhunmadulu',
      code: 'MV-25',
    },
    {
      name: 'Male Atoll',
      code: 'MV-26',
    },
    {
      name: 'North Huvadhu Atoll',
      code: 'MV-27',
    },
    {
      name: 'South Huvadhu Atoll',
      code: 'MV-28',
    },
    {
      name: 'Fuvammulah',
      code: 'MV-29',
    },
  ],
  MW: [
    {
      name: 'Central Region',
      code: 'MW-C',
    },
    {
      name: 'Northern Region',
      code: 'MW-N',
    },
    {
      name: 'Southern Region',
      code: 'MW-S',
    },
  ],
  MX: [
    {
      name: 'Aguascalientes',
      code: 'MX-AG',
    },
    {
      name: 'Baja California',
      code: 'MX-BC',
    },
    {
      name: 'Baja California Sur',
      code: 'MX-BS',
    },
    {
      name: 'Chihuahua',
      code: 'MX-CH',
    },
    {
      name: 'Colima',
      code: 'MX-CL',
    },
    {
      name: 'Campeche',
      code: 'MX-CM',
    },
    {
      name: 'Coahuila',
      code: 'MX-CO',
    },
    {
      name: 'Chiapas',
      code: 'MX-CS',
    },
    {
      name: 'Federal District',
      code: 'MX-DF',
    },
    {
      name: 'Durango',
      code: 'MX-DG',
    },
    {
      name: 'Guerrero',
      code: 'MX-GR',
    },
    {
      name: 'Guanajuato',
      code: 'MX-GT',
    },
    {
      name: 'Hidalgo',
      code: 'MX-HG',
    },
    {
      name: 'Jalisco',
      code: 'MX-JA',
    },
    {
      name: 'Mexico State',
      code: 'MX-ME',
    },
    {
      name: 'Michoacán',
      code: 'MX-MI',
    },
    {
      name: 'Morelos',
      code: 'MX-MO',
    },
    {
      name: 'Nayarit',
      code: 'MX-NA',
    },
    {
      name: 'Nuevo León',
      code: 'MX-NL',
    },
    {
      name: 'Oaxaca',
      code: 'MX-OA',
    },
    {
      name: 'Puebla',
      code: 'MX-PB',
    },
    {
      name: 'Querétaro',
      code: 'MX-QE',
    },
    {
      name: 'Quintana Roo',
      code: 'MX-QR',
    },
    {
      name: 'Sinaloa',
      code: 'MX-SI',
    },
    {
      name: 'San Luis Potosí',
      code: 'MX-SL',
    },
    {
      name: 'Sonora',
      code: 'MX-SO',
    },
    {
      name: 'Tabasco',
      code: 'MX-TB',
    },
    {
      name: 'Tlaxcala',
      code: 'MX-TL',
    },
    {
      name: 'Tamaulipas',
      code: 'MX-TM',
    },
    {
      name: 'Veracruz',
      code: 'MX-VE',
    },
    {
      name: 'Yucatán',
      code: 'MX-YU',
    },
    {
      name: 'Zacatecas',
      code: 'MX-ZA',
    },
    {
      name: 'Aguascalientes',
      code: 'MX-AGU',
    },
    {
      name: 'Baja California',
      code: 'MX-BCN',
    },
    {
      name: 'Baja California Sur',
      code: 'MX-BCS',
    },
    {
      name: 'Campeche',
      code: 'MX-CAM',
    },
    {
      name: 'Chihuahua',
      code: 'MX-CHH',
    },
    {
      name: 'Chiapas',
      code: 'MX-CHP',
    },
    {
      name: 'Ciudad de México',
      code: 'MX-CMX',
    },
    {
      name: 'Coahuila de Zaragoza',
      code: 'MX-COA',
    },
    {
      name: 'Colima',
      code: 'MX-COL',
    },
    {
      name: 'Durango',
      code: 'MX-DUR',
    },
    {
      name: 'Guerrero',
      code: 'MX-GRO',
    },
    {
      name: 'Guanajuato',
      code: 'MX-GUA',
    },
    {
      name: 'Hidalgo',
      code: 'MX-HID',
    },
    {
      name: 'Jalisco',
      code: 'MX-JAL',
    },
    {
      name: 'México',
      code: 'MX-MEX',
    },
    {
      name: 'Michoacán de Ocampo',
      code: 'MX-MIC',
    },
    {
      name: 'Morelos',
      code: 'MX-MOR',
    },
    {
      name: 'Nayarit',
      code: 'MX-NAY',
    },
    {
      name: 'Nuevo León',
      code: 'MX-NLE',
    },
    {
      name: 'Oaxaca',
      code: 'MX-OAX',
    },
    {
      name: 'Puebla',
      code: 'MX-PUE',
    },
    {
      name: 'Querétaro',
      code: 'MX-QUE',
    },
    {
      name: 'Quintana Roo',
      code: 'MX-ROO',
    },
    {
      name: 'Sinaloa',
      code: 'MX-SIN',
    },
    {
      name: 'San Luis Potosí',
      code: 'MX-SLP',
    },
    {
      name: 'Sonora',
      code: 'MX-SON',
    },
    {
      name: 'Tabasco',
      code: 'MX-TAB',
    },
    {
      name: 'Tamaulipas',
      code: 'MX-TAM',
    },
    {
      name: 'Tlaxcala',
      code: 'MX-TLA',
    },
    {
      name: 'Veracruz de Ignacio de la Llave',
      code: 'MX-VER',
    },
    {
      name: 'Yucatán',
      code: 'MX-YUC',
    },
    {
      name: 'Zacatecas',
      code: 'MX-ZAC',
    },
  ],
  MY: [
    {
      name: 'Johor',
      code: 'MY-01',
    },
    {
      name: 'Kedah',
      code: 'MY-02',
    },
    {
      name: 'Kelantan',
      code: 'MY-03',
    },
    {
      name: 'Melaka',
      code: 'MY-04',
    },
    {
      name: 'Negeri Sembilan',
      code: 'MY-05',
    },
    {
      name: 'Pahang',
      code: 'MY-06',
    },
    {
      name: 'Pulau Pinang',
      code: 'MY-07',
    },
    {
      name: 'Perak',
      code: 'MY-08',
    },
    {
      name: 'Perlis',
      code: 'MY-09',
    },
    {
      name: 'Selangor',
      code: 'MY-10',
    },
    {
      name: 'Terengganu',
      code: 'MY-11',
    },
    {
      name: 'Sabah',
      code: 'MY-12',
    },
    {
      name: 'Sarawak',
      code: 'MY-13',
    },
    {
      name: 'Wilayah Persekutuan Kuala Lumpur',
      code: 'MY-14',
    },
    {
      name: 'Wilayah Persekutuan Labuan',
      code: 'MY-15',
    },
    {
      name: 'Wilayah Persekutuan Putrajaya',
      code: 'MY-16',
    },
  ],
  MZ: [
    {
      name: 'Niassa',
      code: 'MZ-A',
    },
    {
      name: 'Manica',
      code: 'MZ-B',
    },
    {
      name: 'Gaza',
      code: 'MZ-G',
    },
    {
      name: 'Inhambane',
      code: 'MZ-I',
    },
    {
      name: 'Maputo',
      code: 'MZ-L',
    },
    {
      name: 'Maputo',
      code: 'MZ-MPM',
    },
    {
      name: 'Nampula',
      code: 'MZ-N',
    },
    {
      name: 'Cabo Delgado',
      code: 'MZ-P',
    },
    {
      name: 'Zambézia',
      code: 'MZ-Q',
    },
    {
      name: 'Sofala',
      code: 'MZ-S',
    },
    {
      name: 'Tete',
      code: 'MZ-T',
    },
  ],
  NA: [
    {
      name: 'Zambezi',
      code: 'NA-CA',
    },
    {
      name: 'Erongo',
      code: 'NA-ER',
    },
    {
      name: 'Hardap',
      code: 'NA-HA',
    },
    {
      name: 'Karas',
      code: 'NA-KA',
    },
    {
      name: 'Kavango East',
      code: 'NA-KE',
    },
    {
      name: 'Khomas',
      code: 'NA-KH',
    },
    {
      name: 'Kunene',
      code: 'NA-KU',
    },
    {
      name: 'Kavango West',
      code: 'NA-KW',
    },
    {
      name: 'Otjozondjupa',
      code: 'NA-OD',
    },
    {
      name: 'Omaheke',
      code: 'NA-OH',
    },
    {
      name: 'Oshana',
      code: 'NA-ON',
    },
    {
      name: 'Omusati',
      code: 'NA-OS',
    },
    {
      name: 'Oshikoto',
      code: 'NA-OT',
    },
    {
      name: 'Ohangwena',
      code: 'NA-OW',
    },
  ],
  NE: [
    {
      name: 'Agadez',
      code: 'NE-01',
    },
    {
      name: 'Diffa',
      code: 'NE-02',
    },
    {
      name: 'Dosso',
      code: 'NE-03',
    },
    {
      name: 'Maradi',
      code: 'NE-04',
    },
    {
      name: 'Tahoua',
      code: 'NE-05',
    },
    {
      name: 'Tillabéri',
      code: 'NE-06',
    },
    {
      name: 'Zinder',
      code: 'NE-07',
    },
    {
      name: 'Niamey',
      code: 'NE-08',
    },
  ],
  NG: [
    {
      name: 'Abia',
      code: 'NG-AB',
    },
    {
      name: 'Adamawa',
      code: 'NG-AD',
    },
    {
      name: 'Akwa Ibom',
      code: 'NG-AK',
    },
    {
      name: 'Anambra',
      code: 'NG-AN',
    },
    {
      name: 'Bauchi',
      code: 'NG-BA',
    },
    {
      name: 'Benue',
      code: 'NG-BE',
    },
    {
      name: 'Borno',
      code: 'NG-BO',
    },
    {
      name: 'Bayelsa',
      code: 'NG-BY',
    },
    {
      name: 'Cross River',
      code: 'NG-CR',
    },
    {
      name: 'Delta',
      code: 'NG-DE',
    },
    {
      name: 'Ebonyi',
      code: 'NG-EB',
    },
    {
      name: 'Edo',
      code: 'NG-ED',
    },
    {
      name: 'Ekiti',
      code: 'NG-EK',
    },
    {
      name: 'Enugu',
      code: 'NG-EN',
    },
    {
      name: 'Abuja Federal Capital Territory',
      code: 'NG-FC',
    },
    {
      name: 'Gombe',
      code: 'NG-GO',
    },
    {
      name: 'Imo',
      code: 'NG-IM',
    },
    {
      name: 'Jigawa',
      code: 'NG-JI',
    },
    {
      name: 'Kaduna',
      code: 'NG-KD',
    },
    {
      name: 'Kebbi',
      code: 'NG-KE',
    },
    {
      name: 'Kano',
      code: 'NG-KN',
    },
    {
      name: 'Kogi',
      code: 'NG-KO',
    },
    {
      name: 'Katsina',
      code: 'NG-KT',
    },
    {
      name: 'Kwara',
      code: 'NG-KW',
    },
    {
      name: 'Lagos',
      code: 'NG-LA',
    },
    {
      name: 'Nasarawa',
      code: 'NG-NA',
    },
    {
      name: 'Niger',
      code: 'NG-NI',
    },
    {
      name: 'Ogun',
      code: 'NG-OG',
    },
    {
      name: 'Ondo',
      code: 'NG-ON',
    },
    {
      name: 'Osun',
      code: 'NG-OS',
    },
    {
      name: 'Oyo',
      code: 'NG-OY',
    },
    {
      name: 'Plateau',
      code: 'NG-PL',
    },
    {
      name: 'Rivers',
      code: 'NG-RI',
    },
    {
      name: 'Sokoto',
      code: 'NG-SO',
    },
    {
      name: 'Taraba',
      code: 'NG-TA',
    },
    {
      name: 'Yobe',
      code: 'NG-YO',
    },
    {
      name: 'Zamfara',
      code: 'NG-ZA',
    },
  ],
  NI: [
    {
      name: 'Costa Caribe Norte',
      code: 'NI-AN',
    },
    {
      name: 'Costa Caribe Sur',
      code: 'NI-AS',
    },
    {
      name: 'Boaco',
      code: 'NI-BO',
    },
    {
      name: 'Carazo',
      code: 'NI-CA',
    },
    {
      name: 'Chinandega',
      code: 'NI-CI',
    },
    {
      name: 'Chontales',
      code: 'NI-CO',
    },
    {
      name: 'Estelí',
      code: 'NI-ES',
    },
    {
      name: 'Granada',
      code: 'NI-GR',
    },
    {
      name: 'Jinotega',
      code: 'NI-JI',
    },
    {
      name: 'León',
      code: 'NI-LE',
    },
    {
      name: 'Madriz',
      code: 'NI-MD',
    },
    {
      name: 'Managua',
      code: 'NI-MN',
    },
    {
      name: 'Masaya',
      code: 'NI-MS',
    },
    {
      name: 'Matagalpa',
      code: 'NI-MT',
    },
    {
      name: 'Nueva Segovia',
      code: 'NI-NS',
    },
    {
      name: 'Rivas',
      code: 'NI-RI',
    },
    {
      name: 'Río San Juan',
      code: 'NI-SJ',
    },
  ],
  NL: [
    {
      name: 'Drenthe',
      code: 'NL-DR',
    },
    {
      name: 'Flevoland',
      code: 'NL-FL',
    },
    {
      name: 'Fryslân',
      code: 'NL-FR',
    },
    {
      name: 'Gelderland',
      code: 'NL-GE',
    },
    {
      name: 'Groningen',
      code: 'NL-GR',
    },
    {
      name: 'Limburg',
      code: 'NL-LI',
    },
    {
      name: 'Noord-Brabant',
      code: 'NL-NB',
    },
    {
      name: 'Noord-Holland',
      code: 'NL-NH',
    },
    {
      name: 'Overijssel',
      code: 'NL-OV',
    },
    {
      name: 'Utrecht',
      code: 'NL-UT',
    },
    {
      name: 'Zeeland',
      code: 'NL-ZE',
    },
    {
      name: 'Zuid-Holland',
      code: 'NL-ZH',
    },
  ],
  NO: [
    {
      name: 'Østfold',
      code: 'NO-01',
    },
    {
      name: 'Akershus',
      code: 'NO-02',
    },
    {
      name: 'Oslo',
      code: 'NO-03',
    },
    {
      name: 'Hedmark',
      code: 'NO-04',
    },
    {
      name: 'Oppland',
      code: 'NO-05',
    },
    {
      name: 'Buskerud',
      code: 'NO-06',
    },
    {
      name: 'Vestfold',
      code: 'NO-07',
    },
    {
      name: 'Telemark',
      code: 'NO-08',
    },
    {
      name: 'Aust-Agder',
      code: 'NO-09',
    },
    {
      name: 'Vest-Agder',
      code: 'NO-10',
    },
    {
      name: 'Rogaland',
      code: 'NO-11',
    },
    {
      name: 'Hordaland',
      code: 'NO-12',
    },
    {
      name: 'Sogn og Fjordane',
      code: 'NO-14',
    },
    {
      name: 'Møre og Romsdal',
      code: 'NO-15',
    },
    {
      name: 'Nordland',
      code: 'NO-18',
    },
    {
      name: 'Troms',
      code: 'NO-19',
    },
    {
      name: 'Finnmark',
      code: 'NO-20',
    },
    {
      name: 'Svalbard',
      code: 'NO-21',
    },
    {
      name: 'Jan Mayen',
      code: 'NO-22',
    },
    {
      name: 'Trøndelag',
      code: 'NO-50',
    },
  ],
  NP: [
    {
      name: 'Bagmati',
      code: 'NP-BA',
    },
    {
      name: 'Bheri',
      code: 'NP-BH',
    },
    {
      name: 'Dhawalagiri',
      code: 'NP-DH',
    },
    {
      name: 'Gandaki',
      code: 'NP-GA',
    },
    {
      name: 'Janakpur',
      code: 'NP-JA',
    },
    {
      name: 'Karnali',
      code: 'NP-KA',
    },
    {
      name: 'Kosi',
      code: 'NP-KO',
    },
    {
      name: 'Lumbini',
      code: 'NP-LU',
    },
    {
      name: 'Mahakali',
      code: 'NP-MA',
    },
    {
      name: 'Mechi',
      code: 'NP-ME',
    },
    {
      name: 'Narayani',
      code: 'NP-NA',
    },
    {
      name: 'Rapti',
      code: 'NP-RA',
    },
    {
      name: 'Sagarmatha',
      code: 'NP-SA',
    },
    {
      name: 'Seti',
      code: 'NP-SE',
    },
  ],
  NR: [
    {
      name: 'Aiwo',
      code: 'NR-01',
    },
    {
      name: 'Anabar',
      code: 'NR-02',
    },
    {
      name: 'Anetan',
      code: 'NR-03',
    },
    {
      name: 'Anibare',
      code: 'NR-04',
    },
    {
      name: 'Baitsi',
      code: 'NR-05',
    },
    {
      name: 'Boe',
      code: 'NR-06',
    },
    {
      name: 'Buada',
      code: 'NR-07',
    },
    {
      name: 'Denigomodu',
      code: 'NR-08',
    },
    {
      name: 'Ewa',
      code: 'NR-09',
    },
    {
      name: 'Ijuw',
      code: 'NR-10',
    },
    {
      name: 'Meneng',
      code: 'NR-11',
    },
    {
      name: 'Nibok',
      code: 'NR-12',
    },
    {
      name: 'Uaboe',
      code: 'NR-13',
    },
    {
      name: 'Yaren',
      code: 'NR-14',
    },
  ],
  NZ: [
    {
      name: 'Auckland',
      code: 'NZ-AUK',
    },
    {
      name: 'Bay of Plenty',
      code: 'NZ-BOP',
    },
    {
      name: 'Canterbury',
      code: 'NZ-CAN',
    },
    {
      name: 'Chatham Islands Territory',
      code: 'NZ-CIT',
    },
    {
      name: 'Gisborne',
      code: 'NZ-GIS',
    },
    {
      name: "Hawke's Bay",
      code: 'NZ-HKB',
    },
    {
      name: 'Marlborough',
      code: 'NZ-MBH',
    },
    {
      name: 'Manawatu-Wanganui',
      code: 'NZ-MWT',
    },
    {
      name: 'Nelson',
      code: 'NZ-NSN',
    },
    {
      name: 'Northland',
      code: 'NZ-NTL',
    },
    {
      name: 'Otago',
      code: 'NZ-OTA',
    },
    {
      name: 'Southland',
      code: 'NZ-STL',
    },
    {
      name: 'Tasman',
      code: 'NZ-TAS',
    },
    {
      name: 'Taranaki',
      code: 'NZ-TKI',
    },
    {
      name: 'Wellington',
      code: 'NZ-WGN',
    },
    {
      name: 'West Coast',
      code: 'NZ-WTC',
    },
  ],
  OM: [
    {
      name: 'Janūb al Bāţinah',
      code: 'OM-BJ',
    },
    {
      name: 'Shamāl al Bāţinah',
      code: 'OM-BS',
    },
    {
      name: 'Al Buraymī',
      code: 'OM-BU',
    },
    {
      name: 'Ad Dākhilīyah',
      code: 'OM-DA',
    },
    {
      name: 'Masqaţ',
      code: 'OM-MA',
    },
    {
      name: 'Musandam',
      code: 'OM-MU',
    },
    {
      name: 'Janūb ash Sharqīyah',
      code: 'OM-SJ',
    },
    {
      name: 'Shamāl ash Sharqīyah',
      code: 'OM-SS',
    },
    {
      name: 'Al Wusţá',
      code: 'OM-WU',
    },
    {
      name: 'Az̧ Z̧āhirah',
      code: 'OM-ZA',
    },
    {
      name: 'Z̧ufār',
      code: 'OM-ZU',
    },
  ],
  PA: [
    {
      name: 'Bocas del Toro',
      code: 'PA-01',
    },
    {
      name: 'Panamá Oeste',
      code: 'PA-10',
    },
    {
      name: 'Coclé',
      code: 'PA-02',
    },
    {
      name: 'Colón',
      code: 'PA-03',
    },
    {
      name: 'Chiriquí',
      code: 'PA-04',
    },
    {
      name: 'Darién',
      code: 'PA-05',
    },
    {
      name: 'Herrera',
      code: 'PA-06',
    },
    {
      name: 'Los Santos',
      code: 'PA-07',
    },
    {
      name: 'Panamá',
      code: 'PA-08',
    },
    {
      name: 'Veraguas',
      code: 'PA-09',
    },
    {
      name: 'Emberá',
      code: 'PA-EM',
    },
    {
      name: 'Guna Yala',
      code: 'PA-KY',
    },
    {
      name: 'Ngöbe-Buglé',
      code: 'PA-NB',
    },
  ],
  PE: [
    {
      name: 'Amazonas',
      code: 'PE-AMA',
    },
    {
      name: 'Ancash',
      code: 'PE-ANC',
    },
    {
      name: 'Apurímac',
      code: 'PE-APU',
    },
    {
      name: 'Arequipa',
      code: 'PE-ARE',
    },
    {
      name: 'Ayacucho',
      code: 'PE-AYA',
    },
    {
      name: 'Cajamarca',
      code: 'PE-CAJ',
    },
    {
      name: 'El Callao',
      code: 'PE-CAL',
    },
    {
      name: 'Cusco',
      code: 'PE-CUS',
    },
    {
      name: 'Huánuco',
      code: 'PE-HUC',
    },
    {
      name: 'Huancavelica',
      code: 'PE-HUV',
    },
    {
      name: 'Ica',
      code: 'PE-ICA',
    },
    {
      name: 'Junín',
      code: 'PE-JUN',
    },
    {
      name: 'La Libertad',
      code: 'PE-LAL',
    },
    {
      name: 'Lambayeque',
      code: 'PE-LAM',
    },
    {
      name: 'Lima',
      code: 'PE-LIM',
    },
    {
      name: 'Loreto',
      code: 'PE-LOR',
    },
    {
      name: 'Madre de Dios',
      code: 'PE-MDD',
    },
    {
      name: 'Moquegua',
      code: 'PE-MOQ',
    },
    {
      name: 'Pasco',
      code: 'PE-PAS',
    },
    {
      name: 'Piura',
      code: 'PE-PIU',
    },
    {
      name: 'Puno',
      code: 'PE-PUN',
    },
    {
      name: 'San Martín',
      code: 'PE-SAM',
    },
    {
      name: 'Tacna',
      code: 'PE-TAC',
    },
    {
      name: 'Tumbes',
      code: 'PE-TUM',
    },
    {
      name: 'Ucayali',
      code: 'PE-UCA',
    },
  ],
  PG: [
    {
      name: 'Chimbu',
      code: 'PG-CPK',
    },
    {
      name: 'Central',
      code: 'PG-CPM',
    },
    {
      name: 'East New Britain',
      code: 'PG-EBR',
    },
    {
      name: 'Eastern Highlands',
      code: 'PG-EHG',
    },
    {
      name: 'Enga',
      code: 'PG-EPW',
    },
    {
      name: 'East Sepik',
      code: 'PG-ESW',
    },
    {
      name: 'Gulf',
      code: 'PG-GPK',
    },
    {
      name: 'Hela',
      code: 'PG-HLA',
    },
    {
      name: 'Jiwaka',
      code: 'PG-JWK',
    },
    {
      name: 'Milne Bay',
      code: 'PG-MBA',
    },
    {
      name: 'Morobe',
      code: 'PG-MPL',
    },
    {
      name: 'Madang',
      code: 'PG-MPM',
    },
    {
      name: 'Manus',
      code: 'PG-MRL',
    },
    {
      name: 'National Capital District',
      code: 'PG-NCD',
    },
    {
      name: 'New Ireland',
      code: 'PG-NIK',
    },
    {
      name: 'Northern',
      code: 'PG-NPP',
    },
    {
      name: 'Bougainville',
      code: 'PG-NSB',
    },
    {
      name: 'West Sepik',
      code: 'PG-SAN',
    },
    {
      name: 'Southern Highlands',
      code: 'PG-SHM',
    },
    {
      name: 'West New Britain',
      code: 'PG-WBK',
    },
    {
      name: 'Western Highlands',
      code: 'PG-WHM',
    },
    {
      name: 'Western',
      code: 'PG-WPD',
    },
  ],
  PH: [
    {
      name: 'National Capital Region',
      code: 'PH-00',
    },
    {
      name: 'Ilocos',
      code: 'PH-01',
    },
    {
      name: 'Cagayan Valley',
      code: 'PH-02',
    },
    {
      name: 'Central Luzon',
      code: 'PH-03',
    },
    {
      name: 'Bicol',
      code: 'PH-05',
    },
    {
      name: 'Western Visayas',
      code: 'PH-06',
    },
    {
      name: 'Central Visayas',
      code: 'PH-07',
    },
    {
      name: 'Eastern Visayas',
      code: 'PH-08',
    },
    {
      name: 'Zamboanga Peninsula',
      code: 'PH-09',
    },
    {
      name: 'Northern Mindanao',
      code: 'PH-10',
    },
    {
      name: 'Davao',
      code: 'PH-11',
    },
    {
      name: 'Soccsksargen',
      code: 'PH-12',
    },
    {
      name: 'Caraga',
      code: 'PH-13',
    },
    {
      name: 'Autonomous Region in Muslim Mindanao',
      code: 'PH-14',
    },
    {
      name: 'Cordillera Administrative Region',
      code: 'PH-15',
    },
    {
      name: 'Calabarzon',
      code: 'PH-40',
    },
    {
      name: 'Mimaropa',
      code: 'PH-41',
    },
    {
      name: 'Abra',
      code: 'PH-ABR',
    },
    {
      name: 'Agusan del Norte',
      code: 'PH-AGN',
    },
    {
      name: 'Agusan del Sur',
      code: 'PH-AGS',
    },
    {
      name: 'Aklan',
      code: 'PH-AKL',
    },
    {
      name: 'Albay',
      code: 'PH-ALB',
    },
    {
      name: 'Antique',
      code: 'PH-ANT',
    },
    {
      name: 'Apayao',
      code: 'PH-APA',
    },
    {
      name: 'Aurora',
      code: 'PH-AUR',
    },
    {
      name: 'Bataan',
      code: 'PH-BAN',
    },
    {
      name: 'Basilan',
      code: 'PH-BAS',
    },
    {
      name: 'Benguet',
      code: 'PH-BEN',
    },
    {
      name: 'Biliran',
      code: 'PH-BIL',
    },
    {
      name: 'Bohol',
      code: 'PH-BOH',
    },
    {
      name: 'Batangas',
      code: 'PH-BTG',
    },
    {
      name: 'Batanes',
      code: 'PH-BTN',
    },
    {
      name: 'Bukidnon',
      code: 'PH-BUK',
    },
    {
      name: 'Bulacan',
      code: 'PH-BUL',
    },
    {
      name: 'Cagayan',
      code: 'PH-CAG',
    },
    {
      name: 'Camiguin',
      code: 'PH-CAM',
    },
    {
      name: 'Camarines Norte',
      code: 'PH-CAN',
    },
    {
      name: 'Capiz',
      code: 'PH-CAP',
    },
    {
      name: 'Camarines Sur',
      code: 'PH-CAS',
    },
    {
      name: 'Catanduanes',
      code: 'PH-CAT',
    },
    {
      name: 'Cavite',
      code: 'PH-CAV',
    },
    {
      name: 'Cebu',
      code: 'PH-CEB',
    },
    {
      name: 'Compostela Valley',
      code: 'PH-COM',
    },
    {
      name: 'Davao Oriental',
      code: 'PH-DAO',
    },
    {
      name: 'Davao del Sur',
      code: 'PH-DAS',
    },
    {
      name: 'Davao del Norte',
      code: 'PH-DAV',
    },
    {
      name: 'Dinagat Islands',
      code: 'PH-DIN',
    },
    {
      name: 'Davao Occidental',
      code: 'PH-DVO',
    },
    {
      name: 'Eastern Samar',
      code: 'PH-EAS',
    },
    {
      name: 'Guimaras',
      code: 'PH-GUI',
    },
    {
      name: 'Ifugao',
      code: 'PH-IFU',
    },
    {
      name: 'Iloilo',
      code: 'PH-ILI',
    },
    {
      name: 'Ilocos Norte',
      code: 'PH-ILN',
    },
    {
      name: 'Ilocos Sur',
      code: 'PH-ILS',
    },
    {
      name: 'Isabela',
      code: 'PH-ISA',
    },
    {
      name: 'Kalinga',
      code: 'PH-KAL',
    },
    {
      name: 'Laguna',
      code: 'PH-LAG',
    },
    {
      name: 'Lanao del Norte',
      code: 'PH-LAN',
    },
    {
      name: 'Lanao del Sur',
      code: 'PH-LAS',
    },
    {
      name: 'Leyte',
      code: 'PH-LEY',
    },
    {
      name: 'La Union',
      code: 'PH-LUN',
    },
    {
      name: 'Marinduque',
      code: 'PH-MAD',
    },
    {
      name: 'Maguindanao',
      code: 'PH-MAG',
    },
    {
      name: 'Masbate',
      code: 'PH-MAS',
    },
    {
      name: 'Mindoro Occidental',
      code: 'PH-MDC',
    },
    {
      name: 'Mindoro Oriental',
      code: 'PH-MDR',
    },
    {
      name: 'Mountain Province',
      code: 'PH-MOU',
    },
    {
      name: 'Misamis Occidental',
      code: 'PH-MSC',
    },
    {
      name: 'Misamis Oriental',
      code: 'PH-MSR',
    },
    {
      name: 'Cotabato',
      code: 'PH-NCO',
    },
    {
      name: 'Negros Occidental',
      code: 'PH-NEC',
    },
    {
      name: 'Negros Oriental',
      code: 'PH-NER',
    },
    {
      name: 'Northern Samar',
      code: 'PH-NSA',
    },
    {
      name: 'Nueva Ecija',
      code: 'PH-NUE',
    },
    {
      name: 'Nueva Vizcaya',
      code: 'PH-NUV',
    },
    {
      name: 'Pampanga',
      code: 'PH-PAM',
    },
    {
      name: 'Pangasinan',
      code: 'PH-PAN',
    },
    {
      name: 'Palawan',
      code: 'PH-PLW',
    },
    {
      name: 'Quezon',
      code: 'PH-QUE',
    },
    {
      name: 'Quirino',
      code: 'PH-QUI',
    },
    {
      name: 'Rizal',
      code: 'PH-RIZ',
    },
    {
      name: 'Romblon',
      code: 'PH-ROM',
    },
    {
      name: 'Sarangani',
      code: 'PH-SAR',
    },
    {
      name: 'South Cotabato',
      code: 'PH-SCO',
    },
    {
      name: 'Siquijor',
      code: 'PH-SIG',
    },
    {
      name: 'Southern Leyte',
      code: 'PH-SLE',
    },
    {
      name: 'Sulu',
      code: 'PH-SLU',
    },
    {
      name: 'Sorsogon',
      code: 'PH-SOR',
    },
    {
      name: 'Sultan Kudarat',
      code: 'PH-SUK',
    },
    {
      name: 'Surigao del Norte',
      code: 'PH-SUN',
    },
    {
      name: 'Surigao del Sur',
      code: 'PH-SUR',
    },
    {
      name: 'Tarlac',
      code: 'PH-TAR',
    },
    {
      name: 'Tawi-Tawi',
      code: 'PH-TAW',
    },
    {
      name: 'Samar',
      code: 'PH-WSA',
    },
    {
      name: 'Zamboanga del Norte',
      code: 'PH-ZAN',
    },
    {
      name: 'Zamboanga del Sur',
      code: 'PH-ZAS',
    },
    {
      name: 'Zambales',
      code: 'PH-ZMB',
    },
    {
      name: 'Zamboanga Sibugay',
      code: 'PH-ZSI',
    },
  ],
  PK: [
    {
      name: 'Balochistan',
      code: 'PK-BA',
    },
    {
      name: 'Gilgit-Baltistan',
      code: 'PK-GB',
    },
    {
      name: 'Islamabad',
      code: 'PK-IS',
    },
    {
      name: 'Azad Jammu and Kashmir',
      code: 'PK-JK',
    },
    {
      name: 'Khyber Pakhtunkhwa',
      code: 'PK-KP',
    },
    {
      name: 'Punjab',
      code: 'PK-PB',
    },
    {
      name: 'Sindh',
      code: 'PK-SD',
    },
  ],
  PL: [
    {
      name: 'Dolnośląskie',
      code: 'PL-02',
    },
    {
      name: 'Kujawsko-pomorskie',
      code: 'PL-04',
    },
    {
      name: 'Lubelskie',
      code: 'PL-06',
    },
    {
      name: 'Lubuskie',
      code: 'PL-08',
    },
    {
      name: 'Łódzkie',
      code: 'PL-10',
    },
    {
      name: 'Małopolskie',
      code: 'PL-12',
    },
    {
      name: 'Mazowieckie',
      code: 'PL-14',
    },
    {
      name: 'Opolskie',
      code: 'PL-16',
    },
    {
      name: 'Podkarpackie',
      code: 'PL-18',
    },
    {
      name: 'Podlaskie',
      code: 'PL-20',
    },
    {
      name: 'Pomorskie',
      code: 'PL-22',
    },
    {
      name: 'Śląskie',
      code: 'PL-24',
    },
    {
      name: 'Świętokrzyskie',
      code: 'PL-26',
    },
    {
      name: 'Warmińsko-mazurskie',
      code: 'PL-28',
    },
    {
      name: 'Wielkopolskie',
      code: 'PL-30',
    },
    {
      name: 'Zachodniopomorskie',
      code: 'PL-32',
    },
  ],
  PS: [
    {
      name: 'Bethlehem',
      code: 'PS-BTH',
    },
    {
      name: 'Deir El Balah',
      code: 'PS-DEB',
    },
    {
      name: 'Gaza',
      code: 'PS-GZA',
    },
    {
      name: 'Hebron',
      code: 'PS-HBN',
    },
    {
      name: 'Jerusalem',
      code: 'PS-JEM',
    },
    {
      name: 'Jenin',
      code: 'PS-JEN',
    },
    {
      name: 'Jericho and Al Aghwar',
      code: 'PS-JRH',
    },
    {
      name: 'Khan Yunis',
      code: 'PS-KYS',
    },
    {
      name: 'Nablus',
      code: 'PS-NBS',
    },
    {
      name: 'North Gaza',
      code: 'PS-NGZ',
    },
    {
      name: 'Qalqilya',
      code: 'PS-QQA',
    },
    {
      name: 'Ramallah',
      code: 'PS-RBH',
    },
    {
      name: 'Rafah',
      code: 'PS-RFH',
    },
    {
      name: 'Salfit',
      code: 'PS-SLT',
    },
    {
      name: 'Tubas',
      code: 'PS-TBS',
    },
    {
      name: 'Tulkarm',
      code: 'PS-TKM',
    },
  ],
  PT: [
    {
      name: 'Aveiro',
      code: 'PT-01',
    },
    {
      name: 'Beja',
      code: 'PT-02',
    },
    {
      name: 'Braga',
      code: 'PT-03',
    },
    {
      name: 'Bragança',
      code: 'PT-04',
    },
    {
      name: 'Castelo Branco',
      code: 'PT-05',
    },
    {
      name: 'Coimbra',
      code: 'PT-06',
    },
    {
      name: 'Évora',
      code: 'PT-07',
    },
    {
      name: 'Faro',
      code: 'PT-08',
    },
    {
      name: 'Guarda',
      code: 'PT-09',
    },
    {
      name: 'Leiria',
      code: 'PT-10',
    },
    {
      name: 'Lisboa',
      code: 'PT-11',
    },
    {
      name: 'Portalegre',
      code: 'PT-12',
    },
    {
      name: 'Porto',
      code: 'PT-13',
    },
    {
      name: 'Santarém',
      code: 'PT-14',
    },
    {
      name: 'Setúbal',
      code: 'PT-15',
    },
    {
      name: 'Viana do Castelo',
      code: 'PT-16',
    },
    {
      name: 'Vila Real',
      code: 'PT-17',
    },
    {
      name: 'Viseu',
      code: 'PT-18',
    },
    {
      name: 'Região Autónoma dos Açores',
      code: 'PT-20',
    },
    {
      name: 'Região Autónoma da Madeira',
      code: 'PT-30',
    },
  ],
  PY: [
    {
      name: 'Concepción',
      code: 'PY-01',
    },
    {
      name: 'Alto Paraná',
      code: 'PY-10',
    },
    {
      name: 'Central',
      code: 'PY-11',
    },
    {
      name: 'Ñeembucú',
      code: 'PY-12',
    },
    {
      name: 'Amambay',
      code: 'PY-13',
    },
    {
      name: 'Canindeyú',
      code: 'PY-14',
    },
    {
      name: 'Presidente Hayes',
      code: 'PY-15',
    },
    {
      name: 'Alto Paraguay',
      code: 'PY-16',
    },
    {
      name: 'Boquerón',
      code: 'PY-19',
    },
    {
      name: 'San Pedro',
      code: 'PY-02',
    },
    {
      name: 'Cordillera',
      code: 'PY-03',
    },
    {
      name: 'Guairá',
      code: 'PY-04',
    },
    {
      name: 'Caaguazú',
      code: 'PY-05',
    },
    {
      name: 'Caazapá',
      code: 'PY-06',
    },
    {
      name: 'Itapúa',
      code: 'PY-07',
    },
    {
      name: 'Misiones',
      code: 'PY-08',
    },
    {
      name: 'Paraguarí',
      code: 'PY-09',
    },
    {
      name: 'Asunción',
      code: 'PY-ASU',
    },
  ],
  QA: [
    {
      name: 'Ad Dawḩah',
      code: 'QA-DA',
    },
    {
      name: 'Al Khawr wa adh Dhakhīrah',
      code: 'QA-KH',
    },
    {
      name: 'Ash Shamāl',
      code: 'QA-MS',
    },
    {
      name: 'Ar Rayyān',
      code: 'QA-RA',
    },
    {
      name: 'Ash Shīḩānīyah',
      code: 'QA-SH',
    },
    {
      name: 'Umm Şalāl',
      code: 'QA-US',
    },
    {
      name: 'Al Wakrah',
      code: 'QA-WA',
    },
    {
      name: 'Az̧ Z̧a‘āyin',
      code: 'QA-ZA',
    },
  ],
  RO: [
    {
      name: 'Alba',
      code: 'RO-AB',
    },
    {
      name: 'Argeș',
      code: 'RO-AG',
    },
    {
      name: 'Arad',
      code: 'RO-AR',
    },
    {
      name: 'București',
      code: 'RO-B',
    },
    {
      name: 'Bacău',
      code: 'RO-BC',
    },
    {
      name: 'Bihor',
      code: 'RO-BH',
    },
    {
      name: 'Bistrița-Năsăud',
      code: 'RO-BN',
    },
    {
      name: 'Brăila',
      code: 'RO-BR',
    },
    {
      name: 'Botoșani',
      code: 'RO-BT',
    },
    {
      name: 'Brașov',
      code: 'RO-BV',
    },
    {
      name: 'Buzău',
      code: 'RO-BZ',
    },
    {
      name: 'Cluj',
      code: 'RO-CJ',
    },
    {
      name: 'Călărași',
      code: 'RO-CL',
    },
    {
      name: 'Caraș-Severin',
      code: 'RO-CS',
    },
    {
      name: 'Constanța',
      code: 'RO-CT',
    },
    {
      name: 'Covasna',
      code: 'RO-CV',
    },
    {
      name: 'Dâmbovița',
      code: 'RO-DB',
    },
    {
      name: 'Dolj',
      code: 'RO-DJ',
    },
    {
      name: 'Gorj',
      code: 'RO-GJ',
    },
    {
      name: 'Galați',
      code: 'RO-GL',
    },
    {
      name: 'Giurgiu',
      code: 'RO-GR',
    },
    {
      name: 'Hunedoara',
      code: 'RO-HD',
    },
    {
      name: 'Harghita',
      code: 'RO-HR',
    },
    {
      name: 'Ilfov',
      code: 'RO-IF',
    },
    {
      name: 'Ialomița',
      code: 'RO-IL',
    },
    {
      name: 'Iași',
      code: 'RO-IS',
    },
    {
      name: 'Mehedinți',
      code: 'RO-MH',
    },
    {
      name: 'Maramureș',
      code: 'RO-MM',
    },
    {
      name: 'Mureș',
      code: 'RO-MS',
    },
    {
      name: 'Neamț',
      code: 'RO-NT',
    },
    {
      name: 'Olt',
      code: 'RO-OT',
    },
    {
      name: 'Prahova',
      code: 'RO-PH',
    },
    {
      name: 'Sibiu',
      code: 'RO-SB',
    },
    {
      name: 'Sălaj',
      code: 'RO-SJ',
    },
    {
      name: 'Satu Mare',
      code: 'RO-SM',
    },
    {
      name: 'Suceava',
      code: 'RO-SV',
    },
    {
      name: 'Tulcea',
      code: 'RO-TL',
    },
    {
      name: 'Timiș',
      code: 'RO-TM',
    },
    {
      name: 'Teleorman',
      code: 'RO-TR',
    },
    {
      name: 'Vâlcea',
      code: 'RO-VL',
    },
    {
      name: 'Vrancea',
      code: 'RO-VN',
    },
    {
      name: 'Vaslui',
      code: 'RO-VS',
    },
  ],
  RS: [
    {
      name: 'Severnobački okrug',
      code: 'RS-01',
    },
    {
      name: 'Srednjebanatski okrug',
      code: 'RS-02',
    },
    {
      name: 'Severnobanatski okrug',
      code: 'RS-03',
    },
    {
      name: 'Južnobanatski okrug',
      code: 'RS-04',
    },
    {
      name: 'Zapadnobački okrug',
      code: 'RS-05',
    },
    {
      name: 'Južnobački okrug',
      code: 'RS-06',
    },
    {
      name: 'Sremski okrug',
      code: 'RS-07',
    },
    {
      name: 'Mačvanski okrug',
      code: 'RS-08',
    },
    {
      name: 'Kolubarski okrug',
      code: 'RS-09',
    },
    {
      name: 'Podunavski okrug',
      code: 'RS-10',
    },
    {
      name: 'Braničevski okrug',
      code: 'RS-11',
    },
    {
      name: 'Šumadijski okrug',
      code: 'RS-12',
    },
    {
      name: 'Pomoravski okrug',
      code: 'RS-13',
    },
    {
      name: 'Borski okrug',
      code: 'RS-14',
    },
    {
      name: 'Zaječarski okrug',
      code: 'RS-15',
    },
    {
      name: 'Zlatiborski okrug',
      code: 'RS-16',
    },
    {
      name: 'Moravički okrug',
      code: 'RS-17',
    },
    {
      name: 'Raški okrug',
      code: 'RS-18',
    },
    {
      name: 'Rasinski okrug',
      code: 'RS-19',
    },
    {
      name: 'Nišavski okrug',
      code: 'RS-20',
    },
    {
      name: 'Toplički okrug',
      code: 'RS-21',
    },
    {
      name: 'Pirotski okrug',
      code: 'RS-22',
    },
    {
      name: 'Jablanički okrug',
      code: 'RS-23',
    },
    {
      name: 'Pčinjski okrug',
      code: 'RS-24',
    },
    {
      name: 'Kosovski okrug',
      code: 'RS-25',
    },
    {
      name: 'Pećki okrug',
      code: 'RS-26',
    },
    {
      name: 'Prizrenski okrug',
      code: 'RS-27',
    },
    {
      name: 'Kosovsko-Mitrovački okrug',
      code: 'RS-28',
    },
    {
      name: 'Kosovsko-Pomoravski okrug',
      code: 'RS-29',
    },
    {
      name: 'Kosovo-Metohija',
      code: 'RS-KM',
    },
    {
      name: 'Vojvodina',
      code: 'RS-VO',
    },
  ],
  RU: [
    {
      name: 'Adygeya, Respublika',
      code: 'RU-AD',
    },
    {
      name: 'Altay, Respublika',
      code: 'RU-AL',
    },
    {
      name: 'Altayskiy kray',
      code: 'RU-ALT',
    },
    {
      name: "Amurskaya oblast'",
      code: 'RU-AMU',
    },
    {
      name: "Arkhangel'skaya oblast'",
      code: 'RU-ARK',
    },
    {
      name: "Astrakhanskaya oblast'",
      code: 'RU-AST',
    },
    {
      name: 'Baškortostan, Respublika',
      code: 'RU-BA',
    },
    {
      name: "Belgorodskaya oblast'",
      code: 'RU-BEL',
    },
    {
      name: "Bryanskaya oblast'",
      code: 'RU-BRY',
    },
    {
      name: 'Buryatiya, Respublika',
      code: 'RU-BU',
    },
    {
      name: 'Čečenskaja Respublika',
      code: 'RU-CE',
    },
    {
      name: "Čeljabinskaja oblast'",
      code: 'RU-CHE',
    },
    {
      name: 'Čukotskij avtonomnyj okrug',
      code: 'RU-CHU',
    },
    {
      name: 'Čuvašskaja Respublika',
      code: 'RU-CU',
    },
    {
      name: 'Dagestan, Respublika',
      code: 'RU-DA',
    },
    {
      name: 'Ingušetija, Respublika',
      code: 'RU-IN',
    },
    {
      name: "Irkutskaya oblast'",
      code: 'RU-IRK',
    },
    {
      name: "Ivanovskaya oblast'",
      code: 'RU-IVA',
    },
    {
      name: 'Kamčatskij kraj',
      code: 'RU-KAM',
    },
    {
      name: 'Kabardino-Balkarskaya Respublika',
      code: 'RU-KB',
    },
    {
      name: 'Karačaevo-Čerkesskaja Respublika',
      code: 'RU-KC',
    },
    {
      name: 'Krasnodarskiy kray',
      code: 'RU-KDA',
    },
    {
      name: "Kemerovskaya oblast'",
      code: 'RU-KEM',
    },
    {
      name: "Kaliningradskaya oblast'",
      code: 'RU-KGD',
    },
    {
      name: "Kurganskaya oblast'",
      code: 'RU-KGN',
    },
    {
      name: 'Khabarovskiy kray',
      code: 'RU-KHA',
    },
    {
      name: 'Khanty-Mansiyskiy avtonomnyy okrug',
      code: 'RU-KHM',
    },
    {
      name: "Kirovskaya oblast'",
      code: 'RU-KIR',
    },
    {
      name: 'Khakasiya, Respublika',
      code: 'RU-KK',
    },
    {
      name: 'Kalmykiya, Respublika',
      code: 'RU-KL',
    },
    {
      name: "Kalužskaja oblast'",
      code: 'RU-KLU',
    },
    {
      name: 'Komi, Respublika',
      code: 'RU-KO',
    },
    {
      name: "Kostromskaya oblast'",
      code: 'RU-KOS',
    },
    {
      name: 'Kareliya, Respublika',
      code: 'RU-KR',
    },
    {
      name: "Kurskaya oblast'",
      code: 'RU-KRS',
    },
    {
      name: 'Krasnoyarskiy kray',
      code: 'RU-KYA',
    },
    {
      name: "Leningradskaya oblast'",
      code: 'RU-LEN',
    },
    {
      name: "Lipetskaya oblast'",
      code: 'RU-LIP',
    },
    {
      name: "Magadanskaya oblast'",
      code: 'RU-MAG',
    },
    {
      name: 'Mariy El, Respublika',
      code: 'RU-ME',
    },
    {
      name: 'Mordoviya, Respublika',
      code: 'RU-MO',
    },
    {
      name: "Moskovskaya oblast'",
      code: 'RU-MOS',
    },
    {
      name: 'Moskva',
      code: 'RU-MOW',
    },
    {
      name: "Murmanskaya oblast'",
      code: 'RU-MUR',
    },
    {
      name: 'Nenetskiy avtonomnyy okrug',
      code: 'RU-NEN',
    },
    {
      name: "Novgorodskaya oblast'",
      code: 'RU-NGR',
    },
    {
      name: "Nižegorodskaja oblast'",
      code: 'RU-NIZ',
    },
    {
      name: "Novosibirskaya oblast'",
      code: 'RU-NVS',
    },
    {
      name: "Omskaya oblast'",
      code: 'RU-OMS',
    },
    {
      name: "Orenburgskaya oblast'",
      code: 'RU-ORE',
    },
    {
      name: "Orlovskaya oblast'",
      code: 'RU-ORL',
    },
    {
      name: 'Permskiy kray',
      code: 'RU-PER',
    },
    {
      name: "Penzenskaya oblast'",
      code: 'RU-PNZ',
    },
    {
      name: 'Primorskiy kray',
      code: 'RU-PRI',
    },
    {
      name: "Pskovskaya oblast'",
      code: 'RU-PSK',
    },
    {
      name: "Rostovskaya oblast'",
      code: 'RU-ROS',
    },
    {
      name: "Ryazanskaya oblast'",
      code: 'RU-RYA',
    },
    {
      name: 'Sakha, Respublika',
      code: 'RU-SA',
    },
    {
      name: "Sakhalinskaya oblast'",
      code: 'RU-SAK',
    },
    {
      name: "Samarskaya oblast'",
      code: 'RU-SAM',
    },
    {
      name: "Saratovskaya oblast'",
      code: 'RU-SAR',
    },
    {
      name: 'Severnaya Osetiya, Respublika',
      code: 'RU-SE',
    },
    {
      name: "Smolenskaya oblast'",
      code: 'RU-SMO',
    },
    {
      name: 'Sankt-Peterburg',
      code: 'RU-SPE',
    },
    {
      name: "Stavropol'skiy kray",
      code: 'RU-STA',
    },
    {
      name: "Sverdlovskaya oblast'",
      code: 'RU-SVE',
    },
    {
      name: 'Tatarstan, Respublika',
      code: 'RU-TA',
    },
    {
      name: "Tambovskaya oblast'",
      code: 'RU-TAM',
    },
    {
      name: "Tomskaya oblast'",
      code: 'RU-TOM',
    },
    {
      name: "Tul'skaya oblast'",
      code: 'RU-TUL',
    },
    {
      name: "Tverskaya oblast'",
      code: 'RU-TVE',
    },
    {
      name: 'Tyva, Respublika',
      code: 'RU-TY',
    },
    {
      name: "Tyumenskaya oblast'",
      code: 'RU-TYU',
    },
    {
      name: 'Udmurtskaya Respublika',
      code: 'RU-UD',
    },
    {
      name: "Ul'yanovskaya oblast'",
      code: 'RU-ULY',
    },
    {
      name: "Volgogradskaya oblast'",
      code: 'RU-VGG',
    },
    {
      name: "Vladimirskaya oblast'",
      code: 'RU-VLA',
    },
    {
      name: "Vologodskaya oblast'",
      code: 'RU-VLG',
    },
    {
      name: "Voronežskaja oblast'",
      code: 'RU-VOR',
    },
    {
      name: 'Yamalo-Nenetskiy avtonomnyy okrug',
      code: 'RU-YAN',
    },
    {
      name: "Yaroslavskaya oblast'",
      code: 'RU-YAR',
    },
    {
      name: "Zabaykal'skiy kray",
      code: 'RU-ZAB',
    },
  ],
  RW: [
    {
      name: 'City of Kigali',
      code: 'RW-01',
    },
    {
      name: 'Eastern',
      code: 'RW-02',
    },
    {
      name: 'Northern',
      code: 'RW-03',
    },
    {
      name: 'Western',
      code: 'RW-04',
    },
    {
      name: 'Southern',
      code: 'RW-05',
    },
  ],
  SA: [
    {
      name: 'Ar Riyāḑ',
      code: 'SA-01',
    },
    {
      name: 'Makkah al Mukarramah',
      code: 'SA-02',
    },
    {
      name: 'Al Madīnah al Munawwarah',
      code: 'SA-03',
    },
    {
      name: 'Ash Sharqīyah',
      code: 'SA-04',
    },
    {
      name: 'Al Qaşīm',
      code: 'SA-05',
    },
    {
      name: "Ḩā'il",
      code: 'SA-06',
    },
    {
      name: 'Tabūk',
      code: 'SA-07',
    },
    {
      name: 'Al Ḩudūd ash Shamālīyah',
      code: 'SA-08',
    },
    {
      name: 'Jāzān',
      code: 'SA-09',
    },
    {
      name: 'Najrān',
      code: 'SA-10',
    },
    {
      name: 'Al Bāḩah',
      code: 'SA-11',
    },
    {
      name: 'Al Jawf',
      code: 'SA-12',
    },
    {
      name: 'Asīr',
      code: 'SA-14',
    },
  ],
  SB: [
    {
      name: 'Central',
      code: 'SB-CE',
    },
    {
      name: 'Choiseul',
      code: 'SB-CH',
    },
    {
      name: 'Guadalcanal',
      code: 'SB-GU',
    },
    {
      name: 'Isabel',
      code: 'SB-IS',
    },
    {
      name: 'Makira-Ulawa',
      code: 'SB-MK',
    },
    {
      name: 'Malaita',
      code: 'SB-ML',
    },
    {
      name: 'Rennell and Bellona',
      code: 'SB-RB',
    },
    {
      name: 'Temotu',
      code: 'SB-TE',
    },
    {
      name: 'Western',
      code: 'SB-WE',
    },
  ],
  SC: [
    {
      name: 'Anse Aux Pins',
      code: 'SC-01',
    },
    {
      name: 'Anse Boileau',
      code: 'SC-02',
    },
    {
      name: 'Anse Etoile',
      code: 'SC-03',
    },
    {
      name: 'Au Cap',
      code: 'SC-04',
    },
    {
      name: 'Anse Royale',
      code: 'SC-05',
    },
    {
      name: 'Baie Lazare',
      code: 'SC-06',
    },
    {
      name: 'Baie Sainte Anne',
      code: 'SC-07',
    },
    {
      name: 'Beau Vallon',
      code: 'SC-08',
    },
    {
      name: 'Bel Air',
      code: 'SC-09',
    },
    {
      name: 'Bel Ombre',
      code: 'SC-10',
    },
    {
      name: 'Cascade',
      code: 'SC-11',
    },
    {
      name: 'Glacis',
      code: 'SC-12',
    },
    {
      name: 'Grand Anse Mahe',
      code: 'SC-13',
    },
    {
      name: 'Grand Anse Praslin',
      code: 'SC-14',
    },
    {
      name: 'La Digue',
      code: 'SC-15',
    },
    {
      name: 'English River',
      code: 'SC-16',
    },
    {
      name: 'Mont Buxton',
      code: 'SC-17',
    },
    {
      name: 'Mont Fleuri',
      code: 'SC-18',
    },
    {
      name: 'Plaisance',
      code: 'SC-19',
    },
    {
      name: 'Pointe Larue',
      code: 'SC-20',
    },
    {
      name: 'Port Glaud',
      code: 'SC-21',
    },
    {
      name: 'Saint Louis',
      code: 'SC-22',
    },
    {
      name: 'Takamaka',
      code: 'SC-24',
    },
    {
      name: 'Roche Caiman',
      code: 'SC-25',
    },
    {
      name: 'Ile Perseverance I',
      code: 'SC-26',
    },
    {
      name: 'Ile Perseverance II',
      code: 'SC-27',
    },
  ],
  SD: [
    {
      name: 'Central Darfur',
      code: 'SD-DC',
    },
    {
      name: 'East Darfur',
      code: 'SD-DE',
    },
    {
      name: 'North Darfur',
      code: 'SD-DN',
    },
    {
      name: 'South Darfur',
      code: 'SD-DS',
    },
    {
      name: 'West Darfur',
      code: 'SD-DW',
    },
    {
      name: 'Gedaref',
      code: 'SD-GD',
    },
    {
      name: 'West Kordofan',
      code: 'SD-GK',
    },
    {
      name: 'Gezira',
      code: 'SD-GZ',
    },
    {
      name: 'Kassala',
      code: 'SD-KA',
    },
    {
      name: 'Khartoum',
      code: 'SD-KH',
    },
    {
      name: 'North Kordofan',
      code: 'SD-KN',
    },
    {
      name: 'South Kordofan',
      code: 'SD-KS',
    },
    {
      name: 'Blue Nile',
      code: 'SD-NB',
    },
    {
      name: 'Northern',
      code: 'SD-NO',
    },
    {
      name: 'River Nile',
      code: 'SD-NR',
    },
    {
      name: 'White Nile',
      code: 'SD-NW',
    },
    {
      name: 'Red Sea',
      code: 'SD-RS',
    },
    {
      name: 'Sennar',
      code: 'SD-SI',
    },
  ],
  SE: [
    {
      name: 'Stockholms län [SE-01]',
      code: 'SE-AB',
    },
    {
      name: 'Västerbottens län [SE-24]',
      code: 'SE-AC',
    },
    {
      name: 'Norrbottens län [SE-25]',
      code: 'SE-BD',
    },
    {
      name: 'Uppsala län [SE-03]',
      code: 'SE-C',
    },
    {
      name: 'Södermanlands län [SE-04]',
      code: 'SE-D',
    },
    {
      name: 'Östergötlands län [SE-05]',
      code: 'SE-E',
    },
    {
      name: 'Jönköpings län [SE-06]',
      code: 'SE-F',
    },
    {
      name: 'Kronobergs län [SE-07]',
      code: 'SE-G',
    },
    {
      name: 'Kalmar län [SE-08]',
      code: 'SE-H',
    },
    {
      name: 'Gotlands län [SE-09]',
      code: 'SE-I',
    },
    {
      name: 'Blekinge län [SE-10]',
      code: 'SE-K',
    },
    {
      name: 'Skåne län [SE-12]',
      code: 'SE-M',
    },
    {
      name: 'Hallands län [SE-13]',
      code: 'SE-N',
    },
    {
      name: 'Västra Götalands län [SE-14]',
      code: 'SE-O',
    },
    {
      name: 'Värmlands län [SE-17]',
      code: 'SE-S',
    },
    {
      name: 'Örebro län [SE-18]',
      code: 'SE-T',
    },
    {
      name: 'Västmanlands län [SE-19]',
      code: 'SE-U',
    },
    {
      name: 'Dalarnas län [SE-20]',
      code: 'SE-W',
    },
    {
      name: 'Gävleborgs län [SE-21]',
      code: 'SE-X',
    },
    {
      name: 'Västernorrlands län [SE-22]',
      code: 'SE-Y',
    },
    {
      name: 'Jämtlands län [SE-23]',
      code: 'SE-Z',
    },
  ],
  SG: [
    {
      name: 'Central Singapore',
      code: 'SG-01',
    },
    {
      name: 'North East',
      code: 'SG-02',
    },
    {
      name: 'North West',
      code: 'SG-03',
    },
    {
      name: 'South East',
      code: 'SG-04',
    },
    {
      name: 'South West',
      code: 'SG-05',
    },
  ],
  SH: [
    {
      name: 'Ascension',
      code: 'SH-AC',
    },
    {
      name: 'Saint Helena',
      code: 'SH-HL',
    },
    {
      name: 'Tristan da Cunha',
      code: 'SH-TA',
    },
  ],
  SI: [
    {
      name: 'Ajdovščina',
      code: 'SI-001',
    },
    {
      name: 'Beltinci',
      code: 'SI-002',
    },
    {
      name: 'Bled',
      code: 'SI-003',
    },
    {
      name: 'Bohinj',
      code: 'SI-004',
    },
    {
      name: 'Borovnica',
      code: 'SI-005',
    },
    {
      name: 'Bovec',
      code: 'SI-006',
    },
    {
      name: 'Brda',
      code: 'SI-007',
    },
    {
      name: 'Brezovica',
      code: 'SI-008',
    },
    {
      name: 'Brežice',
      code: 'SI-009',
    },
    {
      name: 'Tišina',
      code: 'SI-010',
    },
    {
      name: 'Celje',
      code: 'SI-011',
    },
    {
      name: 'Cerklje na Gorenjskem',
      code: 'SI-012',
    },
    {
      name: 'Cerknica',
      code: 'SI-013',
    },
    {
      name: 'Cerkno',
      code: 'SI-014',
    },
    {
      name: 'Črenšovci',
      code: 'SI-015',
    },
    {
      name: 'Črna na Koroškem',
      code: 'SI-016',
    },
    {
      name: 'Črnomelj',
      code: 'SI-017',
    },
    {
      name: 'Destrnik',
      code: 'SI-018',
    },
    {
      name: 'Divača',
      code: 'SI-019',
    },
    {
      name: 'Dobrepolje',
      code: 'SI-020',
    },
    {
      name: 'Dobrova-Polhov Gradec',
      code: 'SI-021',
    },
    {
      name: 'Dol pri Ljubljani',
      code: 'SI-022',
    },
    {
      name: 'Domžale',
      code: 'SI-023',
    },
    {
      name: 'Dornava',
      code: 'SI-024',
    },
    {
      name: 'Dravograd',
      code: 'SI-025',
    },
    {
      name: 'Duplek',
      code: 'SI-026',
    },
    {
      name: 'Gorenja vas-Poljane',
      code: 'SI-027',
    },
    {
      name: 'Gorišnica',
      code: 'SI-028',
    },
    {
      name: 'Gornja Radgona',
      code: 'SI-029',
    },
    {
      name: 'Gornji Grad',
      code: 'SI-030',
    },
    {
      name: 'Gornji Petrovci',
      code: 'SI-031',
    },
    {
      name: 'Grosuplje',
      code: 'SI-032',
    },
    {
      name: 'Šalovci',
      code: 'SI-033',
    },
    {
      name: 'Hrastnik',
      code: 'SI-034',
    },
    {
      name: 'Hrpelje-Kozina',
      code: 'SI-035',
    },
    {
      name: 'Idrija',
      code: 'SI-036',
    },
    {
      name: 'Ig',
      code: 'SI-037',
    },
    {
      name: 'Ilirska Bistrica',
      code: 'SI-038',
    },
    {
      name: 'Ivančna Gorica',
      code: 'SI-039',
    },
    {
      name: 'Izola',
      code: 'SI-040',
    },
    {
      name: 'Jesenice',
      code: 'SI-041',
    },
    {
      name: 'Juršinci',
      code: 'SI-042',
    },
    {
      name: 'Kamnik',
      code: 'SI-043',
    },
    {
      name: 'Kanal',
      code: 'SI-044',
    },
    {
      name: 'Kidričevo',
      code: 'SI-045',
    },
    {
      name: 'Kobarid',
      code: 'SI-046',
    },
    {
      name: 'Kobilje',
      code: 'SI-047',
    },
    {
      name: 'Kočevje',
      code: 'SI-048',
    },
    {
      name: 'Komen',
      code: 'SI-049',
    },
    {
      name: 'Koper',
      code: 'SI-050',
    },
    {
      name: 'Kozje',
      code: 'SI-051',
    },
    {
      name: 'Kranj',
      code: 'SI-052',
    },
    {
      name: 'Kranjska Gora',
      code: 'SI-053',
    },
    {
      name: 'Krško',
      code: 'SI-054',
    },
    {
      name: 'Kungota',
      code: 'SI-055',
    },
    {
      name: 'Kuzma',
      code: 'SI-056',
    },
    {
      name: 'Laško',
      code: 'SI-057',
    },
    {
      name: 'Lenart',
      code: 'SI-058',
    },
    {
      name: 'Lendava',
      code: 'SI-059',
    },
    {
      name: 'Litija',
      code: 'SI-060',
    },
    {
      name: 'Ljubljana',
      code: 'SI-061',
    },
    {
      name: 'Ljubno',
      code: 'SI-062',
    },
    {
      name: 'Ljutomer',
      code: 'SI-063',
    },
    {
      name: 'Logatec',
      code: 'SI-064',
    },
    {
      name: 'Loška Dolina',
      code: 'SI-065',
    },
    {
      name: 'Loški Potok',
      code: 'SI-066',
    },
    {
      name: 'Luče',
      code: 'SI-067',
    },
    {
      name: 'Lukovica',
      code: 'SI-068',
    },
    {
      name: 'Majšperk',
      code: 'SI-069',
    },
    {
      name: 'Maribor',
      code: 'SI-070',
    },
    {
      name: 'Medvode',
      code: 'SI-071',
    },
    {
      name: 'Mengeš',
      code: 'SI-072',
    },
    {
      name: 'Metlika',
      code: 'SI-073',
    },
    {
      name: 'Mežica',
      code: 'SI-074',
    },
    {
      name: 'Miren-Kostanjevica',
      code: 'SI-075',
    },
    {
      name: 'Mislinja',
      code: 'SI-076',
    },
    {
      name: 'Moravče',
      code: 'SI-077',
    },
    {
      name: 'Moravske Toplice',
      code: 'SI-078',
    },
    {
      name: 'Mozirje',
      code: 'SI-079',
    },
    {
      name: 'Murska Sobota',
      code: 'SI-080',
    },
    {
      name: 'Muta',
      code: 'SI-081',
    },
    {
      name: 'Naklo',
      code: 'SI-082',
    },
    {
      name: 'Nazarje',
      code: 'SI-083',
    },
    {
      name: 'Nova Gorica',
      code: 'SI-084',
    },
    {
      name: 'Novo Mesto',
      code: 'SI-085',
    },
    {
      name: 'Odranci',
      code: 'SI-086',
    },
    {
      name: 'Ormož',
      code: 'SI-087',
    },
    {
      name: 'Osilnica',
      code: 'SI-088',
    },
    {
      name: 'Pesnica',
      code: 'SI-089',
    },
    {
      name: 'Piran',
      code: 'SI-090',
    },
    {
      name: 'Pivka',
      code: 'SI-091',
    },
    {
      name: 'Podčetrtek',
      code: 'SI-092',
    },
    {
      name: 'Podvelka',
      code: 'SI-093',
    },
    {
      name: 'Postojna',
      code: 'SI-094',
    },
    {
      name: 'Preddvor',
      code: 'SI-095',
    },
    {
      name: 'Ptuj',
      code: 'SI-096',
    },
    {
      name: 'Puconci',
      code: 'SI-097',
    },
    {
      name: 'Rače-Fram',
      code: 'SI-098',
    },
    {
      name: 'Radeče',
      code: 'SI-099',
    },
    {
      name: 'Radenci',
      code: 'SI-100',
    },
    {
      name: 'Radlje ob Dravi',
      code: 'SI-101',
    },
    {
      name: 'Radovljica',
      code: 'SI-102',
    },
    {
      name: 'Ravne na Koroškem',
      code: 'SI-103',
    },
    {
      name: 'Ribnica',
      code: 'SI-104',
    },
    {
      name: 'Rogašovci',
      code: 'SI-105',
    },
    {
      name: 'Rogaška Slatina',
      code: 'SI-106',
    },
    {
      name: 'Rogatec',
      code: 'SI-107',
    },
    {
      name: 'Ruše',
      code: 'SI-108',
    },
    {
      name: 'Semič',
      code: 'SI-109',
    },
    {
      name: 'Sevnica',
      code: 'SI-110',
    },
    {
      name: 'Sežana',
      code: 'SI-111',
    },
    {
      name: 'Slovenj Gradec',
      code: 'SI-112',
    },
    {
      name: 'Slovenska Bistrica',
      code: 'SI-113',
    },
    {
      name: 'Slovenske Konjice',
      code: 'SI-114',
    },
    {
      name: 'Starše',
      code: 'SI-115',
    },
    {
      name: 'Sveti Jurij',
      code: 'SI-116',
    },
    {
      name: 'Šenčur',
      code: 'SI-117',
    },
    {
      name: 'Šentilj',
      code: 'SI-118',
    },
    {
      name: 'Šentjernej',
      code: 'SI-119',
    },
    {
      name: 'Šentjur',
      code: 'SI-120',
    },
    {
      name: 'Škocjan',
      code: 'SI-121',
    },
    {
      name: 'Škofja Loka',
      code: 'SI-122',
    },
    {
      name: 'Škofljica',
      code: 'SI-123',
    },
    {
      name: 'Šmarje pri Jelšah',
      code: 'SI-124',
    },
    {
      name: 'Šmartno ob Paki',
      code: 'SI-125',
    },
    {
      name: 'Šoštanj',
      code: 'SI-126',
    },
    {
      name: 'Štore',
      code: 'SI-127',
    },
    {
      name: 'Tolmin',
      code: 'SI-128',
    },
    {
      name: 'Trbovlje',
      code: 'SI-129',
    },
    {
      name: 'Trebnje',
      code: 'SI-130',
    },
    {
      name: 'Tržič',
      code: 'SI-131',
    },
    {
      name: 'Turnišče',
      code: 'SI-132',
    },
    {
      name: 'Velenje',
      code: 'SI-133',
    },
    {
      name: 'Velike Lašče',
      code: 'SI-134',
    },
    {
      name: 'Videm',
      code: 'SI-135',
    },
    {
      name: 'Vipava',
      code: 'SI-136',
    },
    {
      name: 'Vitanje',
      code: 'SI-137',
    },
    {
      name: 'Vodice',
      code: 'SI-138',
    },
    {
      name: 'Vojnik',
      code: 'SI-139',
    },
    {
      name: 'Vrhnika',
      code: 'SI-140',
    },
    {
      name: 'Vuzenica',
      code: 'SI-141',
    },
    {
      name: 'Zagorje ob Savi',
      code: 'SI-142',
    },
    {
      name: 'Zavrč',
      code: 'SI-143',
    },
    {
      name: 'Zreče',
      code: 'SI-144',
    },
    {
      name: 'Železniki',
      code: 'SI-146',
    },
    {
      name: 'Žiri',
      code: 'SI-147',
    },
    {
      name: 'Benedikt',
      code: 'SI-148',
    },
    {
      name: 'Bistrica ob Sotli',
      code: 'SI-149',
    },
    {
      name: 'Bloke',
      code: 'SI-150',
    },
    {
      name: 'Braslovče',
      code: 'SI-151',
    },
    {
      name: 'Cankova',
      code: 'SI-152',
    },
    {
      name: 'Cerkvenjak',
      code: 'SI-153',
    },
    {
      name: 'Dobje',
      code: 'SI-154',
    },
    {
      name: 'Dobrna',
      code: 'SI-155',
    },
    {
      name: 'Dobrovnik',
      code: 'SI-156',
    },
    {
      name: 'Dolenjske Toplice',
      code: 'SI-157',
    },
    {
      name: 'Grad',
      code: 'SI-158',
    },
    {
      name: 'Hajdina',
      code: 'SI-159',
    },
    {
      name: 'Hoče-Slivnica',
      code: 'SI-160',
    },
    {
      name: 'Hodoš',
      code: 'SI-161',
    },
    {
      name: 'Horjul',
      code: 'SI-162',
    },
    {
      name: 'Jezersko',
      code: 'SI-163',
    },
    {
      name: 'Komenda',
      code: 'SI-164',
    },
    {
      name: 'Kostel',
      code: 'SI-165',
    },
    {
      name: 'Križevci',
      code: 'SI-166',
    },
    {
      name: 'Lovrenc na Pohorju',
      code: 'SI-167',
    },
    {
      name: 'Markovci',
      code: 'SI-168',
    },
    {
      name: 'Miklavž na Dravskem Polju',
      code: 'SI-169',
    },
    {
      name: 'Mirna Peč',
      code: 'SI-170',
    },
    {
      name: 'Oplotnica',
      code: 'SI-171',
    },
    {
      name: 'Podlehnik',
      code: 'SI-172',
    },
    {
      name: 'Polzela',
      code: 'SI-173',
    },
    {
      name: 'Prebold',
      code: 'SI-174',
    },
    {
      name: 'Prevalje',
      code: 'SI-175',
    },
    {
      name: 'Razkrižje',
      code: 'SI-176',
    },
    {
      name: 'Ribnica na Pohorju',
      code: 'SI-177',
    },
    {
      name: 'Selnica ob Dravi',
      code: 'SI-178',
    },
    {
      name: 'Sodražica',
      code: 'SI-179',
    },
    {
      name: 'Solčava',
      code: 'SI-180',
    },
    {
      name: 'Sveta Ana',
      code: 'SI-181',
    },
    {
      name: 'Sveti Andraž v Slovenskih Goricah',
      code: 'SI-182',
    },
    {
      name: 'Šempeter-Vrtojba',
      code: 'SI-183',
    },
    {
      name: 'Tabor',
      code: 'SI-184',
    },
    {
      name: 'Trnovska Vas',
      code: 'SI-185',
    },
    {
      name: 'Trzin',
      code: 'SI-186',
    },
    {
      name: 'Velika Polana',
      code: 'SI-187',
    },
    {
      name: 'Veržej',
      code: 'SI-188',
    },
    {
      name: 'Vransko',
      code: 'SI-189',
    },
    {
      name: 'Žalec',
      code: 'SI-190',
    },
    {
      name: 'Žetale',
      code: 'SI-191',
    },
    {
      name: 'Žirovnica',
      code: 'SI-192',
    },
    {
      name: 'Žužemberk',
      code: 'SI-193',
    },
    {
      name: 'Šmartno pri Litiji',
      code: 'SI-194',
    },
    {
      name: 'Apače',
      code: 'SI-195',
    },
    {
      name: 'Cirkulane',
      code: 'SI-196',
    },
    {
      name: 'Kosanjevica na Krki',
      code: 'SI-197',
    },
    {
      name: 'Makole',
      code: 'SI-198',
    },
    {
      name: 'Mokronog-Trebelno',
      code: 'SI-199',
    },
    {
      name: 'Poljčane',
      code: 'SI-200',
    },
    {
      name: 'Renče-Vogrsko',
      code: 'SI-201',
    },
    {
      name: 'Središče ob Dravi',
      code: 'SI-202',
    },
    {
      name: 'Straža',
      code: 'SI-203',
    },
    {
      name: 'Sveta Trojica v Slovenskih Goricah',
      code: 'SI-204',
    },
    {
      name: 'Sveti Tomaž',
      code: 'SI-205',
    },
    {
      name: 'Šmarješke Toplice',
      code: 'SI-206',
    },
    {
      name: 'Gorje',
      code: 'SI-207',
    },
    {
      name: 'Log-Dragomer',
      code: 'SI-208',
    },
    {
      name: 'Rečica ob Savinji',
      code: 'SI-209',
    },
    {
      name: 'Sveti Jurij v Slovenskih Goricah',
      code: 'SI-210',
    },
    {
      name: 'Šentrupert',
      code: 'SI-211',
    },
    {
      name: 'Mirna',
      code: 'SI-212',
    },
    {
      name: 'Ankaran',
      code: 'SI-213',
    },
  ],
  SK: [
    {
      name: 'Banskobystrický kraj',
      code: 'SK-BC',
    },
    {
      name: 'Bratislavský kraj',
      code: 'SK-BL',
    },
    {
      name: 'Košický kraj',
      code: 'SK-KI',
    },
    {
      name: 'Nitriansky kraj',
      code: 'SK-NI',
    },
    {
      name: 'Prešovský kraj',
      code: 'SK-PV',
    },
    {
      name: 'Trnavský kraj',
      code: 'SK-TA',
    },
    {
      name: 'Trenčiansky kraj',
      code: 'SK-TC',
    },
    {
      name: 'Žilinský kraj',
      code: 'SK-ZI',
    },
  ],
  SL: [
    {
      name: 'Eastern',
      code: 'SL-E',
    },
    {
      name: 'Northern',
      code: 'SL-N',
    },
    {
      name: 'North Western',
      code: 'SL-NW',
    },
    {
      name: 'Southern',
      code: 'SL-S',
    },
    {
      name: 'Western Area',
      code: 'SL-W',
    },
  ],
  SM: [
    {
      name: 'Acquaviva',
      code: 'SM-01',
    },
    {
      name: 'Chiesanuova',
      code: 'SM-02',
    },
    {
      name: 'Domagnano',
      code: 'SM-03',
    },
    {
      name: 'Faetano',
      code: 'SM-04',
    },
    {
      name: 'Fiorentino',
      code: 'SM-05',
    },
    {
      name: 'Borgo Maggiore',
      code: 'SM-06',
    },
    {
      name: 'San Marino',
      code: 'SM-07',
    },
    {
      name: 'Montegiardino',
      code: 'SM-08',
    },
    {
      name: 'Serravalle',
      code: 'SM-09',
    },
  ],
  SN: [
    {
      name: 'Diourbel',
      code: 'SN-DB',
    },
    {
      name: 'Dakar',
      code: 'SN-DK',
    },
    {
      name: 'Fatick',
      code: 'SN-FK',
    },
    {
      name: 'Kaffrine',
      code: 'SN-KA',
    },
    {
      name: 'Kolda',
      code: 'SN-KD',
    },
    {
      name: 'Kédougou',
      code: 'SN-KE',
    },
    {
      name: 'Kaolack',
      code: 'SN-KL',
    },
    {
      name: 'Louga',
      code: 'SN-LG',
    },
    {
      name: 'Matam',
      code: 'SN-MT',
    },
    {
      name: 'Sédhiou',
      code: 'SN-SE',
    },
    {
      name: 'Saint-Louis',
      code: 'SN-SL',
    },
    {
      name: 'Tambacounda',
      code: 'SN-TC',
    },
    {
      name: 'Thiès',
      code: 'SN-TH',
    },
    {
      name: 'Ziguinchor',
      code: 'SN-ZG',
    },
  ],
  SO: [
    {
      name: 'Awdal',
      code: 'SO-AW',
    },
    {
      name: 'Bakool',
      code: 'SO-BK',
    },
    {
      name: 'Banaadir',
      code: 'SO-BN',
    },
    {
      name: 'Bari',
      code: 'SO-BR',
    },
    {
      name: 'Bay',
      code: 'SO-BY',
    },
    {
      name: 'Galguduud',
      code: 'SO-GA',
    },
    {
      name: 'Gedo',
      code: 'SO-GE',
    },
    {
      name: 'Hiiraan',
      code: 'SO-HI',
    },
    {
      name: 'Jubbada Dhexe',
      code: 'SO-JD',
    },
    {
      name: 'Jubbada Hoose',
      code: 'SO-JH',
    },
    {
      name: 'Mudug',
      code: 'SO-MU',
    },
    {
      name: 'Nugaal',
      code: 'SO-NU',
    },
    {
      name: 'Sanaag',
      code: 'SO-SA',
    },
    {
      name: 'Shabeellaha Dhexe',
      code: 'SO-SD',
    },
    {
      name: 'Shabeellaha Hoose',
      code: 'SO-SH',
    },
    {
      name: 'Sool',
      code: 'SO-SO',
    },
    {
      name: 'Togdheer',
      code: 'SO-TO',
    },
    {
      name: 'Woqooyi Galbeed',
      code: 'SO-WO',
    },
  ],
  SR: [
    {
      name: 'Brokopondo',
      code: 'SR-BR',
    },
    {
      name: 'Commewijne',
      code: 'SR-CM',
    },
    {
      name: 'Coronie',
      code: 'SR-CR',
    },
    {
      name: 'Marowijne',
      code: 'SR-MA',
    },
    {
      name: 'Nickerie',
      code: 'SR-NI',
    },
    {
      name: 'Paramaribo',
      code: 'SR-PM',
    },
    {
      name: 'Para',
      code: 'SR-PR',
    },
    {
      name: 'Saramacca',
      code: 'SR-SA',
    },
    {
      name: 'Sipaliwini',
      code: 'SR-SI',
    },
    {
      name: 'Wanica',
      code: 'SR-WA',
    },
  ],
  SS: [
    {
      name: 'Northern Bahr el Ghazal',
      code: 'SS-BN',
    },
    {
      name: 'Western Bahr el Ghazal',
      code: 'SS-BW',
    },
    {
      name: 'Central Equatoria',
      code: 'SS-EC',
    },
    {
      name: 'Eastern Equatoria',
      code: 'SS-EE',
    },
    {
      name: 'Western Equatoria',
      code: 'SS-EW',
    },
    {
      name: 'Jonglei',
      code: 'SS-JG',
    },
    {
      name: 'Lakes',
      code: 'SS-LK',
    },
    {
      name: 'Upper Nile',
      code: 'SS-NU',
    },
    {
      name: 'Unity',
      code: 'SS-UY',
    },
    {
      name: 'Warrap',
      code: 'SS-WR',
    },
  ],
  ST: [
    {
      name: 'Príncipe',
      code: 'ST-P',
    },
    {
      name: 'São Tomé',
      code: 'ST-S',
    },
  ],
  SV: [
    {
      name: 'Ahuachapán',
      code: 'SV-AH',
    },
    {
      name: 'Cabañas',
      code: 'SV-CA',
    },
    {
      name: 'Chalatenango',
      code: 'SV-CH',
    },
    {
      name: 'Cuscatlán',
      code: 'SV-CU',
    },
    {
      name: 'La Libertad',
      code: 'SV-LI',
    },
    {
      name: 'Morazán',
      code: 'SV-MO',
    },
    {
      name: 'La Paz',
      code: 'SV-PA',
    },
    {
      name: 'Santa Ana',
      code: 'SV-SA',
    },
    {
      name: 'San Miguel',
      code: 'SV-SM',
    },
    {
      name: 'Sonsonate',
      code: 'SV-SO',
    },
    {
      name: 'San Salvador',
      code: 'SV-SS',
    },
    {
      name: 'San Vicente',
      code: 'SV-SV',
    },
    {
      name: 'La Unión',
      code: 'SV-UN',
    },
    {
      name: 'Usulután',
      code: 'SV-US',
    },
  ],
  SY: [
    {
      name: 'Dimashq',
      code: 'SY-DI',
    },
    {
      name: "Dar'ā",
      code: 'SY-DR',
    },
    {
      name: 'Dayr az Zawr',
      code: 'SY-DY',
    },
    {
      name: 'Al Ḩasakah',
      code: 'SY-HA',
    },
    {
      name: 'Ḩimş',
      code: 'SY-HI',
    },
    {
      name: 'Ḩalab',
      code: 'SY-HL',
    },
    {
      name: 'Ḩamāh',
      code: 'SY-HM',
    },
    {
      name: 'Idlib',
      code: 'SY-ID',
    },
    {
      name: 'Al Lādhiqīyah',
      code: 'SY-LA',
    },
    {
      name: 'Al Qunayţirah',
      code: 'SY-QU',
    },
    {
      name: 'Ar Raqqah',
      code: 'SY-RA',
    },
    {
      name: 'Rīf Dimashq',
      code: 'SY-RD',
    },
    {
      name: "As Suwaydā'",
      code: 'SY-SU',
    },
    {
      name: 'Ţarţūs',
      code: 'SY-TA',
    },
  ],
  SZ: [
    {
      name: 'Hhohho',
      code: 'SZ-HH',
    },
    {
      name: 'Lubombo',
      code: 'SZ-LU',
    },
    {
      name: 'Manzini',
      code: 'SZ-MA',
    },
    {
      name: 'Shiselweni',
      code: 'SZ-SH',
    },
  ],
  TD: [
    {
      name: 'Batha',
      code: 'TD-BA',
    },
    {
      name: 'Bahr el Ghazal',
      code: 'TD-BG',
    },
    {
      name: 'Borkou',
      code: 'TD-BO',
    },
    {
      name: 'Chari-Baguirmi',
      code: 'TD-CB',
    },
    {
      name: 'Ennedi-Est',
      code: 'TD-EE',
    },
    {
      name: 'Ennedi-Ouest',
      code: 'TD-EO',
    },
    {
      name: 'Guéra',
      code: 'TD-GR',
    },
    {
      name: 'Hadjer Lamis',
      code: 'TD-HL',
    },
    {
      name: 'Kanem',
      code: 'TD-KA',
    },
    {
      name: 'Lac',
      code: 'TD-LC',
    },
    {
      name: 'Logone-Occidental',
      code: 'TD-LO',
    },
    {
      name: 'Logone-Oriental',
      code: 'TD-LR',
    },
    {
      name: 'Mandoul',
      code: 'TD-MA',
    },
    {
      name: 'Moyen-Chari',
      code: 'TD-MC',
    },
    {
      name: 'Mayo-Kebbi-Est',
      code: 'TD-ME',
    },
    {
      name: 'Mayo-Kebbi-Ouest',
      code: 'TD-MO',
    },
    {
      name: 'Ville de Ndjamena',
      code: 'TD-ND',
    },
    {
      name: 'Ouaddaï',
      code: 'TD-OD',
    },
    {
      name: 'Salamat',
      code: 'TD-SA',
    },
    {
      name: 'Sila',
      code: 'TD-SI',
    },
    {
      name: 'Tandjilé',
      code: 'TD-TA',
    },
    {
      name: 'Tibesti',
      code: 'TD-TI',
    },
    {
      name: 'Wadi Fira',
      code: 'TD-WF',
    },
  ],
  TG: [
    {
      name: 'Centrale',
      code: 'TG-C',
    },
    {
      name: 'Kara',
      code: 'TG-K',
    },
    {
      name: 'Maritime',
      code: 'TG-M',
    },
    {
      name: 'Plateaux',
      code: 'TG-P',
    },
    {
      name: 'Savanes',
      code: 'TG-S',
    },
  ],
  TH: [
    {
      name: 'Krung Thep Maha Nakhon',
      code: 'TH-10',
    },
    {
      name: 'Samut Prakan',
      code: 'TH-11',
    },
    {
      name: 'Nonthaburi',
      code: 'TH-12',
    },
    {
      name: 'Pathum Thani',
      code: 'TH-13',
    },
    {
      name: 'Phra Nakhon Si Ayutthaya',
      code: 'TH-14',
    },
    {
      name: 'Ang Thong',
      code: 'TH-15',
    },
    {
      name: 'Lop Buri',
      code: 'TH-16',
    },
    {
      name: 'Sing Buri',
      code: 'TH-17',
    },
    {
      name: 'Chai Nat',
      code: 'TH-18',
    },
    {
      name: 'Saraburi',
      code: 'TH-19',
    },
    {
      name: 'Chon Buri',
      code: 'TH-20',
    },
    {
      name: 'Rayong',
      code: 'TH-21',
    },
    {
      name: 'Chanthaburi',
      code: 'TH-22',
    },
    {
      name: 'Trat',
      code: 'TH-23',
    },
    {
      name: 'Chachoengsao',
      code: 'TH-24',
    },
    {
      name: 'Prachin Buri',
      code: 'TH-25',
    },
    {
      name: 'Nakhon Nayok',
      code: 'TH-26',
    },
    {
      name: 'Sa Kaeo',
      code: 'TH-27',
    },
    {
      name: 'Nakhon Ratchasima',
      code: 'TH-30',
    },
    {
      name: 'Buri Ram',
      code: 'TH-31',
    },
    {
      name: 'Surin',
      code: 'TH-32',
    },
    {
      name: 'Si Sa Ket',
      code: 'TH-33',
    },
    {
      name: 'Ubon Ratchathani',
      code: 'TH-34',
    },
    {
      name: 'Yasothon',
      code: 'TH-35',
    },
    {
      name: 'Chaiyaphum',
      code: 'TH-36',
    },
    {
      name: 'Amnat Charoen',
      code: 'TH-37',
    },
    {
      name: 'Bueng Kan',
      code: 'TH-38',
    },
    {
      name: 'Nong Bua Lam Phu',
      code: 'TH-39',
    },
    {
      name: 'Khon Kaen',
      code: 'TH-40',
    },
    {
      name: 'Udon Thani',
      code: 'TH-41',
    },
    {
      name: 'Loei',
      code: 'TH-42',
    },
    {
      name: 'Nong Khai',
      code: 'TH-43',
    },
    {
      name: 'Maha Sarakham',
      code: 'TH-44',
    },
    {
      name: 'Roi Et',
      code: 'TH-45',
    },
    {
      name: 'Kalasin',
      code: 'TH-46',
    },
    {
      name: 'Sakon Nakhon',
      code: 'TH-47',
    },
    {
      name: 'Nakhon Phanom',
      code: 'TH-48',
    },
    {
      name: 'Mukdahan',
      code: 'TH-49',
    },
    {
      name: 'Chiang Mai',
      code: 'TH-50',
    },
    {
      name: 'Lamphun',
      code: 'TH-51',
    },
    {
      name: 'Lampang',
      code: 'TH-52',
    },
    {
      name: 'Uttaradit',
      code: 'TH-53',
    },
    {
      name: 'Phrae',
      code: 'TH-54',
    },
    {
      name: 'Nan',
      code: 'TH-55',
    },
    {
      name: 'Phayao',
      code: 'TH-56',
    },
    {
      name: 'Chiang Rai',
      code: 'TH-57',
    },
    {
      name: 'Mae Hong Son',
      code: 'TH-58',
    },
    {
      name: 'Nakhon Sawan',
      code: 'TH-60',
    },
    {
      name: 'Uthai Thani',
      code: 'TH-61',
    },
    {
      name: 'Kamphaeng Phet',
      code: 'TH-62',
    },
    {
      name: 'Tak',
      code: 'TH-63',
    },
    {
      name: 'Sukhothai',
      code: 'TH-64',
    },
    {
      name: 'Phitsanulok',
      code: 'TH-65',
    },
    {
      name: 'Phichit',
      code: 'TH-66',
    },
    {
      name: 'Phetchabun',
      code: 'TH-67',
    },
    {
      name: 'Ratchaburi',
      code: 'TH-70',
    },
    {
      name: 'Kanchanaburi',
      code: 'TH-71',
    },
    {
      name: 'Suphan Buri',
      code: 'TH-72',
    },
    {
      name: 'Nakhon Pathom',
      code: 'TH-73',
    },
    {
      name: 'Samut Sakhon',
      code: 'TH-74',
    },
    {
      name: 'Samut Songkhram',
      code: 'TH-75',
    },
    {
      name: 'Phetchaburi',
      code: 'TH-76',
    },
    {
      name: 'Prachuap Khiri Khan',
      code: 'TH-77',
    },
    {
      name: 'Nakhon Si Thammarat',
      code: 'TH-80',
    },
    {
      name: 'Krabi',
      code: 'TH-81',
    },
    {
      name: 'Phangnga',
      code: 'TH-82',
    },
    {
      name: 'Phuket',
      code: 'TH-83',
    },
    {
      name: 'Surat Thani',
      code: 'TH-84',
    },
    {
      name: 'Ranong',
      code: 'TH-85',
    },
    {
      name: 'Chumphon',
      code: 'TH-86',
    },
    {
      name: 'Songkhla',
      code: 'TH-90',
    },
    {
      name: 'Satun',
      code: 'TH-91',
    },
    {
      name: 'Trang',
      code: 'TH-92',
    },
    {
      name: 'Phatthalung',
      code: 'TH-93',
    },
    {
      name: 'Pattani',
      code: 'TH-94',
    },
    {
      name: 'Yala',
      code: 'TH-95',
    },
    {
      name: 'Narathiwat',
      code: 'TH-96',
    },
  ],
  TJ: [
    {
      name: 'Dushanbe',
      code: 'TJ-DU',
    },
    {
      name: 'Kŭhistoni Badakhshon',
      code: 'TJ-GB',
    },
    {
      name: 'Khatlon',
      code: 'TJ-KT',
    },
    {
      name: 'nohiyahoi tobei jumhurí',
      code: 'TJ-RA',
    },
    {
      name: 'Sughd',
      code: 'TJ-SU',
    },
  ],
  TL: [
    {
      name: 'Aileu',
      code: 'TL-AL',
    },
    {
      name: 'Ainaro',
      code: 'TL-AN',
    },
    {
      name: 'Baucau',
      code: 'TL-BA',
    },
    {
      name: 'Bobonaro',
      code: 'TL-BO',
    },
    {
      name: 'Cova Lima',
      code: 'TL-CO',
    },
    {
      name: 'Díli',
      code: 'TL-DI',
    },
    {
      name: 'Ermera',
      code: 'TL-ER',
    },
    {
      name: 'Lautém',
      code: 'TL-LA',
    },
    {
      name: 'Liquiça',
      code: 'TL-LI',
    },
    {
      name: 'Manufahi',
      code: 'TL-MF',
    },
    {
      name: 'Manatuto',
      code: 'TL-MT',
    },
    {
      name: 'Oé-Cusse Ambeno',
      code: 'TL-OE',
    },
    {
      name: 'Viqueque',
      code: 'TL-VI',
    },
  ],
  TM: [
    {
      name: 'Ahal',
      code: 'TM-A',
    },
    {
      name: 'Balkan',
      code: 'TM-B',
    },
    {
      name: 'Daşoguz',
      code: 'TM-D',
    },
    {
      name: 'Lebap',
      code: 'TM-L',
    },
    {
      name: 'Mary',
      code: 'TM-M',
    },
    {
      name: 'Aşgabat',
      code: 'TM-S',
    },
  ],
  TN: [
    {
      name: 'Tunis',
      code: 'TN-11',
    },
    {
      name: "L'Ariana",
      code: 'TN-12',
    },
    {
      name: 'Ben Arous',
      code: 'TN-13',
    },
    {
      name: 'La Manouba',
      code: 'TN-14',
    },
    {
      name: 'Nabeul',
      code: 'TN-21',
    },
    {
      name: 'Zaghouan',
      code: 'TN-22',
    },
    {
      name: 'Bizerte',
      code: 'TN-23',
    },
    {
      name: 'Béja',
      code: 'TN-31',
    },
    {
      name: 'Jendouba',
      code: 'TN-32',
    },
    {
      name: 'Le Kef',
      code: 'TN-33',
    },
    {
      name: 'Siliana',
      code: 'TN-34',
    },
    {
      name: 'Kairouan',
      code: 'TN-41',
    },
    {
      name: 'Kasserine',
      code: 'TN-42',
    },
    {
      name: 'Sidi Bouzid',
      code: 'TN-43',
    },
    {
      name: 'Sousse',
      code: 'TN-51',
    },
    {
      name: 'Monastir',
      code: 'TN-52',
    },
    {
      name: 'Mahdia',
      code: 'TN-53',
    },
    {
      name: 'Sfax',
      code: 'TN-61',
    },
    {
      name: 'Gafsa',
      code: 'TN-71',
    },
    {
      name: 'Tozeur',
      code: 'TN-72',
    },
    {
      name: 'Kébili',
      code: 'TN-73',
    },
    {
      name: 'Gabès',
      code: 'TN-81',
    },
    {
      name: 'Médenine',
      code: 'TN-82',
    },
    {
      name: 'Tataouine',
      code: 'TN-83',
    },
  ],
  TO: [
    {
      name: 'Eua',
      code: 'TO-01',
    },
    {
      name: "Ha'apai",
      code: 'TO-02',
    },
    {
      name: 'Niuas',
      code: 'TO-03',
    },
    {
      name: 'Tongatapu',
      code: 'TO-04',
    },
    {
      name: "Vava'u",
      code: 'TO-05',
    },
  ],
  TR: [
    {
      name: 'Adana',
      code: 'TR-01',
    },
    {
      name: 'Adıyaman',
      code: 'TR-02',
    },
    {
      name: 'Afyonkarahisar',
      code: 'TR-03',
    },
    {
      name: 'Ağrı',
      code: 'TR-04',
    },
    {
      name: 'Amasya',
      code: 'TR-05',
    },
    {
      name: 'Ankara',
      code: 'TR-06',
    },
    {
      name: 'Antalya',
      code: 'TR-07',
    },
    {
      name: 'Artvin',
      code: 'TR-08',
    },
    {
      name: 'Aydın',
      code: 'TR-09',
    },
    {
      name: 'Balıkesir',
      code: 'TR-10',
    },
    {
      name: 'Bilecik',
      code: 'TR-11',
    },
    {
      name: 'Bingöl',
      code: 'TR-12',
    },
    {
      name: 'Bitlis',
      code: 'TR-13',
    },
    {
      name: 'Bolu',
      code: 'TR-14',
    },
    {
      name: 'Burdur',
      code: 'TR-15',
    },
    {
      name: 'Bursa',
      code: 'TR-16',
    },
    {
      name: 'Çanakkale',
      code: 'TR-17',
    },
    {
      name: 'Çankırı',
      code: 'TR-18',
    },
    {
      name: 'Çorum',
      code: 'TR-19',
    },
    {
      name: 'Denizli',
      code: 'TR-20',
    },
    {
      name: 'Diyarbakır',
      code: 'TR-21',
    },
    {
      name: 'Edirne',
      code: 'TR-22',
    },
    {
      name: 'Elazığ',
      code: 'TR-23',
    },
    {
      name: 'Erzincan',
      code: 'TR-24',
    },
    {
      name: 'Erzurum',
      code: 'TR-25',
    },
    {
      name: 'Eskişehir',
      code: 'TR-26',
    },
    {
      name: 'Gaziantep',
      code: 'TR-27',
    },
    {
      name: 'Giresun',
      code: 'TR-28',
    },
    {
      name: 'Gümüşhane',
      code: 'TR-29',
    },
    {
      name: 'Hakkâri',
      code: 'TR-30',
    },
    {
      name: 'Hatay',
      code: 'TR-31',
    },
    {
      name: 'Isparta',
      code: 'TR-32',
    },
    {
      name: 'Mersin',
      code: 'TR-33',
    },
    {
      name: 'İstanbul',
      code: 'TR-34',
    },
    {
      name: 'İzmir',
      code: 'TR-35',
    },
    {
      name: 'Kars',
      code: 'TR-36',
    },
    {
      name: 'Kastamonu',
      code: 'TR-37',
    },
    {
      name: 'Kayseri',
      code: 'TR-38',
    },
    {
      name: 'Kırklareli',
      code: 'TR-39',
    },
    {
      name: 'Kırşehir',
      code: 'TR-40',
    },
    {
      name: 'Kocaeli',
      code: 'TR-41',
    },
    {
      name: 'Konya',
      code: 'TR-42',
    },
    {
      name: 'Kütahya',
      code: 'TR-43',
    },
    {
      name: 'Malatya',
      code: 'TR-44',
    },
    {
      name: 'Manisa',
      code: 'TR-45',
    },
    {
      name: 'Kahramanmaraş',
      code: 'TR-46',
    },
    {
      name: 'Mardin',
      code: 'TR-47',
    },
    {
      name: 'Muğla',
      code: 'TR-48',
    },
    {
      name: 'Muş',
      code: 'TR-49',
    },
    {
      name: 'Nevşehir',
      code: 'TR-50',
    },
    {
      name: 'Niğde',
      code: 'TR-51',
    },
    {
      name: 'Ordu',
      code: 'TR-52',
    },
    {
      name: 'Rize',
      code: 'TR-53',
    },
    {
      name: 'Sakarya',
      code: 'TR-54',
    },
    {
      name: 'Samsun',
      code: 'TR-55',
    },
    {
      name: 'Siirt',
      code: 'TR-56',
    },
    {
      name: 'Sinop',
      code: 'TR-57',
    },
    {
      name: 'Sivas',
      code: 'TR-58',
    },
    {
      name: 'Tekirdağ',
      code: 'TR-59',
    },
    {
      name: 'Tokat',
      code: 'TR-60',
    },
    {
      name: 'Trabzon',
      code: 'TR-61',
    },
    {
      name: 'Tunceli',
      code: 'TR-62',
    },
    {
      name: 'Şanlıurfa',
      code: 'TR-63',
    },
    {
      name: 'Uşak',
      code: 'TR-64',
    },
    {
      name: 'Van',
      code: 'TR-65',
    },
    {
      name: 'Yozgat',
      code: 'TR-66',
    },
    {
      name: 'Zonguldak',
      code: 'TR-67',
    },
    {
      name: 'Aksaray',
      code: 'TR-68',
    },
    {
      name: 'Bayburt',
      code: 'TR-69',
    },
    {
      name: 'Karaman',
      code: 'TR-70',
    },
    {
      name: 'Kırıkkale',
      code: 'TR-71',
    },
    {
      name: 'Batman',
      code: 'TR-72',
    },
    {
      name: 'Şırnak',
      code: 'TR-73',
    },
    {
      name: 'Bartın',
      code: 'TR-74',
    },
    {
      name: 'Ardahan',
      code: 'TR-75',
    },
    {
      name: 'Iğdır',
      code: 'TR-76',
    },
    {
      name: 'Yalova',
      code: 'TR-77',
    },
    {
      name: 'Karabük',
      code: 'TR-78',
    },
    {
      name: 'Kilis',
      code: 'TR-79',
    },
    {
      name: 'Osmaniye',
      code: 'TR-80',
    },
    {
      name: 'Düzce',
      code: 'TR-81',
    },
  ],
  TT: [
    {
      name: 'Arima',
      code: 'TT-ARI',
    },
    {
      name: 'Chaguanas',
      code: 'TT-CHA',
    },
    {
      name: 'Couva-Tabaquite-Talparo',
      code: 'TT-CTT',
    },
    {
      name: 'Diego Martin',
      code: 'TT-DMN',
    },
    {
      name: 'Mayaro-Rio Claro',
      code: 'TT-MRC',
    },
    {
      name: 'Penal-Debe',
      code: 'TT-PED',
    },
    {
      name: 'Port of Spain',
      code: 'TT-POS',
    },
    {
      name: 'Princes Town',
      code: 'TT-PRT',
    },
    {
      name: 'Point Fortin',
      code: 'TT-PTF',
    },
    {
      name: 'San Fernando',
      code: 'TT-SFO',
    },
    {
      name: 'Sangre Grande',
      code: 'TT-SGE',
    },
    {
      name: 'Siparia',
      code: 'TT-SIP',
    },
    {
      name: 'San Juan-Laventille',
      code: 'TT-SJL',
    },
    {
      name: 'Tobago',
      code: 'TT-TOB',
    },
    {
      name: 'Tunapuna-Piarco',
      code: 'TT-TUP',
    },
  ],
  TV: [
    {
      name: 'Funafuti',
      code: 'TV-FUN',
    },
    {
      name: 'Niutao',
      code: 'TV-NIT',
    },
    {
      name: 'Nukufetau',
      code: 'TV-NKF',
    },
    {
      name: 'Nukulaelae',
      code: 'TV-NKL',
    },
    {
      name: 'Nanumea',
      code: 'TV-NMA',
    },
    {
      name: 'Nanumaga',
      code: 'TV-NMG',
    },
    {
      name: 'Nui',
      code: 'TV-NUI',
    },
    {
      name: 'Vaitupu',
      code: 'TV-VAI',
    },
  ],
  TW: [
    {
      name: 'Changhua',
      code: 'TW-CHA',
    },
    {
      name: 'Chiayi',
      code: 'TW-CYI',
    },
    {
      name: 'Chiayi',
      code: 'TW-CYQ',
    },
    {
      name: 'Hsinchu',
      code: 'TW-HSQ',
    },
    {
      name: 'Hsinchu',
      code: 'TW-HSZ',
    },
    {
      name: 'Hualien',
      code: 'TW-HUA',
    },
    {
      name: 'Yilan',
      code: 'TW-ILA',
    },
    {
      name: 'Keelung',
      code: 'TW-KEE',
    },
    {
      name: 'Kaohsiung',
      code: 'TW-KHH',
    },
    {
      name: 'Kinmen',
      code: 'TW-KIN',
    },
    {
      name: 'Lienchiang',
      code: 'TW-LIE',
    },
    {
      name: 'Miaoli',
      code: 'TW-MIA',
    },
    {
      name: 'Nantou',
      code: 'TW-NAN',
    },
    {
      name: 'New Taipei',
      code: 'TW-NWT',
    },
    {
      name: 'Penghu',
      code: 'TW-PEN',
    },
    {
      name: 'Pingtung',
      code: 'TW-PIF',
    },
    {
      name: 'Taoyuan',
      code: 'TW-TAO',
    },
    {
      name: 'Tainan',
      code: 'TW-TNN',
    },
    {
      name: 'Taipei',
      code: 'TW-TPE',
    },
    {
      name: 'Taitung',
      code: 'TW-TTT',
    },
    {
      name: 'Taichung',
      code: 'TW-TXG',
    },
    {
      name: 'Yunlin',
      code: 'TW-YUN',
    },
  ],
  TZ: [
    {
      name: 'Arusha',
      code: 'TZ-01',
    },
    {
      name: 'Dar es Salaam',
      code: 'TZ-02',
    },
    {
      name: 'Dodoma',
      code: 'TZ-03',
    },
    {
      name: 'Iringa',
      code: 'TZ-04',
    },
    {
      name: 'Kagera',
      code: 'TZ-05',
    },
    {
      name: 'Kaskazini Pemba',
      code: 'TZ-06',
    },
    {
      name: 'Kaskazini Unguja',
      code: 'TZ-07',
    },
    {
      name: 'Kigoma',
      code: 'TZ-08',
    },
    {
      name: 'Kilimanjaro',
      code: 'TZ-09',
    },
    {
      name: 'Kusini Pemba',
      code: 'TZ-10',
    },
    {
      name: 'Kusini Unguja',
      code: 'TZ-11',
    },
    {
      name: 'Lindi',
      code: 'TZ-12',
    },
    {
      name: 'Mara',
      code: 'TZ-13',
    },
    {
      name: 'Mbeya',
      code: 'TZ-14',
    },
    {
      name: 'Mjini Magharibi',
      code: 'TZ-15',
    },
    {
      name: 'Morogoro',
      code: 'TZ-16',
    },
    {
      name: 'Mtwara',
      code: 'TZ-17',
    },
    {
      name: 'Mwanza',
      code: 'TZ-18',
    },
    {
      name: 'Pwani',
      code: 'TZ-19',
    },
    {
      name: 'Rukwa',
      code: 'TZ-20',
    },
    {
      name: 'Ruvuma',
      code: 'TZ-21',
    },
    {
      name: 'Shinyanga',
      code: 'TZ-22',
    },
    {
      name: 'Singida',
      code: 'TZ-23',
    },
    {
      name: 'Tabora',
      code: 'TZ-24',
    },
    {
      name: 'Tanga',
      code: 'TZ-25',
    },
    {
      name: 'Manyara',
      code: 'TZ-26',
    },
    {
      name: 'Geita',
      code: 'TZ-27',
    },
    {
      name: 'Katavi',
      code: 'TZ-28',
    },
    {
      name: 'Njombe',
      code: 'TZ-29',
    },
    {
      name: 'Simiyu',
      code: 'TZ-30',
    },
    {
      name: 'Songwe',
      code: 'TZ-31',
    },
  ],
  UA: [
    {
      name: 'Vinnytska oblast',
      code: 'UA-05',
    },
    {
      name: 'Volynska oblast',
      code: 'UA-07',
    },
    {
      name: 'Luhanska oblast',
      code: 'UA-09',
    },
    {
      name: 'Dnipropetrovska oblast',
      code: 'UA-12',
    },
    {
      name: 'Donetska oblast',
      code: 'UA-14',
    },
    {
      name: 'Zhytomyrska oblast',
      code: 'UA-18',
    },
    {
      name: 'Zakarpatska oblast',
      code: 'UA-21',
    },
    {
      name: 'Zaporizka oblast',
      code: 'UA-23',
    },
    {
      name: 'Ivano-Frankivska oblast',
      code: 'UA-26',
    },
    {
      name: 'Kyiv',
      code: 'UA-30',
    },
    {
      name: 'Kyivska oblast',
      code: 'UA-32',
    },
    {
      name: 'Kirovohradska oblast',
      code: 'UA-35',
    },
    {
      name: 'Sevastopol',
      code: 'UA-40',
    },
    {
      name: 'Lvivska oblast',
      code: 'UA-46',
    },
    {
      name: 'Mykolaivska oblast',
      code: 'UA-48',
    },
    {
      name: 'Odeska oblast',
      code: 'UA-51',
    },
    {
      name: 'Poltavska oblast',
      code: 'UA-53',
    },
    {
      name: 'Rivnenska oblast',
      code: 'UA-56',
    },
    {
      name: 'Sumska oblast',
      code: 'UA-59',
    },
    {
      name: 'Ternopilska oblast',
      code: 'UA-61',
    },
    {
      name: 'Kharkivska oblast',
      code: 'UA-63',
    },
    {
      name: 'Khersonska oblast',
      code: 'UA-65',
    },
    {
      name: 'Khmelnytska oblast',
      code: 'UA-68',
    },
    {
      name: 'Cherkaska oblast',
      code: 'UA-71',
    },
    {
      name: 'Chernihivska oblast',
      code: 'UA-74',
    },
    {
      name: 'Chernivetska oblast',
      code: 'UA-77',
    },
  ],
  UG: [
    {
      name: 'Central',
      code: 'UG-C',
    },
    {
      name: 'Eastern',
      code: 'UG-E',
    },
    {
      name: 'Northern',
      code: 'UG-N',
    },
    {
      name: 'Western',
      code: 'UG-W',
    },
  ],
  UM: [
    {
      name: 'Johnston Atoll',
      code: 'UM-67',
    },
    {
      name: 'Midway Islands',
      code: 'UM-71',
    },
    {
      name: 'Navassa Island',
      code: 'UM-76',
    },
    {
      name: 'Wake Island',
      code: 'UM-79',
    },
    {
      name: 'Baker Island',
      code: 'UM-81',
    },
    {
      name: 'Howland Island',
      code: 'UM-84',
    },
    {
      name: 'Jarvis Island',
      code: 'UM-86',
    },
    {
      name: 'Kingman Reef',
      code: 'UM-89',
    },
    {
      name: 'Palmyra Atoll',
      code: 'UM-95',
    },
  ],
  US: [
    {
      name: 'Armed Forces Americas',
      code: 'US-AA',
    },
    {
      name: 'Armed Forces Europe',
      code: 'US-AE',
    },
    {
      name: 'Alaska',
      code: 'US-AK',
    },
    {
      name: 'Alabama',
      code: 'US-AL',
    },
    {
      name: 'Armed Forces Pacific',
      code: 'US-AP',
    },
    {
      name: 'Arkansas',
      code: 'US-AR',
    },
    {
      name: 'American Samoa',
      code: 'US-AS',
    },
    {
      name: 'Arizona',
      code: 'US-AZ',
    },
    {
      name: 'California',
      code: 'US-CA',
    },
    {
      name: 'Colorado',
      code: 'US-CO',
    },
    {
      name: 'Connecticut',
      code: 'US-CT',
    },
    {
      name: 'District of Columbia',
      code: 'US-DC',
    },
    {
      name: 'Delaware',
      code: 'US-DE',
    },
    {
      name: 'Florida',
      code: 'US-FL',
    },
    {
      name: 'Federated Micronesia',
      code: 'US-FM',
    },
    {
      name: 'Georgia',
      code: 'US-GA',
    },
    {
      name: 'Guam',
      code: 'US-GU',
    },
    {
      name: 'Hawaii',
      code: 'US-HI',
    },
    {
      name: 'Iowa',
      code: 'US-IA',
    },
    {
      name: 'Idaho',
      code: 'US-ID',
    },
    {
      name: 'Illinois',
      code: 'US-IL',
    },
    {
      name: 'Indiana',
      code: 'US-IN',
    },
    {
      name: 'Kansas',
      code: 'US-KS',
    },
    {
      name: 'Kentucky',
      code: 'US-KY',
    },
    {
      name: 'Louisiana',
      code: 'US-LA',
    },
    {
      name: 'Massachusetts',
      code: 'US-MA',
    },
    {
      name: 'Maryland',
      code: 'US-MD',
    },
    {
      name: 'Maine',
      code: 'US-ME',
    },
    {
      name: 'Marshall Islands',
      code: 'US-MH',
    },
    {
      name: 'Michigan',
      code: 'US-MI',
    },
    {
      name: 'Minnesota',
      code: 'US-MN',
    },
    {
      name: 'Missouri',
      code: 'US-MO',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'US-MP',
    },
    {
      name: 'Mississippi',
      code: 'US-MS',
    },
    {
      name: 'Montana',
      code: 'US-MT',
    },
    {
      name: 'North Carolina',
      code: 'US-NC',
    },
    {
      name: 'North Dakota',
      code: 'US-ND',
    },
    {
      name: 'Nebraska',
      code: 'US-NE',
    },
    {
      name: 'New Hampshire',
      code: 'US-NH',
    },
    {
      name: 'New Jersey',
      code: 'US-NJ',
    },
    {
      name: 'New Mexico',
      code: 'US-NM',
    },
    {
      name: 'Nevada',
      code: 'US-NV',
    },
    {
      name: 'New York',
      code: 'US-NY',
    },
    {
      name: 'Ohio',
      code: 'US-OH',
    },
    {
      name: 'Oklahoma',
      code: 'US-OK',
    },
    {
      name: 'Oregon',
      code: 'US-OR',
    },
    {
      name: 'Pennsylvania',
      code: 'US-PA',
    },
    {
      name: 'Puerto Rico',
      code: 'US-PR',
    },
    {
      name: 'Palau',
      code: 'US-PW',
    },
    {
      name: 'Rhode Island',
      code: 'US-RI',
    },
    {
      name: 'South Carolina',
      code: 'US-SC',
    },
    {
      name: 'South Dakota',
      code: 'US-SD',
    },
    {
      name: 'Tennessee',
      code: 'US-TN',
    },
    {
      name: 'Texas',
      code: 'US-TX',
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'US-UM',
    },
    {
      name: 'Utah',
      code: 'US-UT',
    },
    {
      name: 'Virginia',
      code: 'US-VA',
    },
    {
      name: 'Virgin Islands, U.S.',
      code: 'US-VI',
    },
    {
      name: 'Vermont',
      code: 'US-VT',
    },
    {
      name: 'Washington',
      code: 'US-WA',
    },
    {
      name: 'Wisconsin',
      code: 'US-WI',
    },
    {
      name: 'West Virginia',
      code: 'US-WV',
    },
    {
      name: 'Wyoming',
      code: 'US-WY',
    },
  ],
  UY: [
    {
      name: 'Artigas',
      code: 'UY-AR',
    },
    {
      name: 'Canelones',
      code: 'UY-CA',
    },
    {
      name: 'Cerro Largo',
      code: 'UY-CL',
    },
    {
      name: 'Colonia',
      code: 'UY-CO',
    },
    {
      name: 'Durazno',
      code: 'UY-DU',
    },
    {
      name: 'Florida',
      code: 'UY-FD',
    },
    {
      name: 'Flores',
      code: 'UY-FS',
    },
    {
      name: 'Lavalleja',
      code: 'UY-LA',
    },
    {
      name: 'Maldonado',
      code: 'UY-MA',
    },
    {
      name: 'Montevideo',
      code: 'UY-MO',
    },
    {
      name: 'Paysandú',
      code: 'UY-PA',
    },
    {
      name: 'Río Negro',
      code: 'UY-RN',
    },
    {
      name: 'Rocha',
      code: 'UY-RO',
    },
    {
      name: 'Rivera',
      code: 'UY-RV',
    },
    {
      name: 'Salto',
      code: 'UY-SA',
    },
    {
      name: 'San José',
      code: 'UY-SJ',
    },
    {
      name: 'Soriano',
      code: 'UY-SO',
    },
    {
      name: 'Tacuarembó',
      code: 'UY-TA',
    },
    {
      name: 'Treinta y Tres',
      code: 'UY-TT',
    },
  ],
  UZ: [
    {
      name: 'Andijon',
      code: 'UZ-AN',
    },
    {
      name: 'Buxoro',
      code: 'UZ-BU',
    },
    {
      name: 'Farg‘ona',
      code: 'UZ-FA',
    },
    {
      name: 'Jizzax',
      code: 'UZ-JI',
    },
    {
      name: 'Namangan',
      code: 'UZ-NG',
    },
    {
      name: 'Navoiy',
      code: 'UZ-NW',
    },
    {
      name: 'Qashqadaryo',
      code: 'UZ-QA',
    },
    {
      name: 'Qoraqalpog‘iston Respublikasi',
      code: 'UZ-QR',
    },
    {
      name: 'Samarqand',
      code: 'UZ-SA',
    },
    {
      name: 'Sirdaryo',
      code: 'UZ-SI',
    },
    {
      name: 'Surxondaryo',
      code: 'UZ-SU',
    },
    {
      name: 'Toshkent',
      code: 'UZ-TK',
    },
    {
      name: 'Toshkent',
      code: 'UZ-TO',
    },
    {
      name: 'Xorazm',
      code: 'UZ-XO',
    },
  ],
  VC: [
    {
      name: 'Charlotte',
      code: 'VC-01',
    },
    {
      name: 'Saint Andrew',
      code: 'VC-02',
    },
    {
      name: 'Saint David',
      code: 'VC-03',
    },
    {
      name: 'Saint George',
      code: 'VC-04',
    },
    {
      name: 'Saint Patrick',
      code: 'VC-05',
    },
    {
      name: 'Grenadines',
      code: 'VC-06',
    },
  ],
  VE: [
    {
      name: 'Distrito Capital',
      code: 'VE-A',
    },
    {
      name: 'Anzoátegui',
      code: 'VE-B',
    },
    {
      name: 'Apure',
      code: 'VE-C',
    },
    {
      name: 'Aragua',
      code: 'VE-D',
    },
    {
      name: 'Barinas',
      code: 'VE-E',
    },
    {
      name: 'Bolívar',
      code: 'VE-F',
    },
    {
      name: 'Carabobo',
      code: 'VE-G',
    },
    {
      name: 'Cojedes',
      code: 'VE-H',
    },
    {
      name: 'Falcón',
      code: 'VE-I',
    },
    {
      name: 'Guárico',
      code: 'VE-J',
    },
    {
      name: 'Lara',
      code: 'VE-K',
    },
    {
      name: 'Mérida',
      code: 'VE-L',
    },
    {
      name: 'Miranda',
      code: 'VE-M',
    },
    {
      name: 'Monagas',
      code: 'VE-N',
    },
    {
      name: 'Nueva Esparta',
      code: 'VE-O',
    },
    {
      name: 'Portuguesa',
      code: 'VE-P',
    },
    {
      name: 'Sucre',
      code: 'VE-R',
    },
    {
      name: 'Táchira',
      code: 'VE-S',
    },
    {
      name: 'Trujillo',
      code: 'VE-T',
    },
    {
      name: 'Yaracuy',
      code: 'VE-U',
    },
    {
      name: 'Zulia',
      code: 'VE-V',
    },
    {
      name: 'Dependencias Federales',
      code: 'VE-W',
    },
    {
      name: 'Vargas',
      code: 'VE-X',
    },
    {
      name: 'Delta Amacuro',
      code: 'VE-Y',
    },
    {
      name: 'Amazonas',
      code: 'VE-Z',
    },
  ],
  VN: [
    {
      name: 'Lai Châu',
      code: 'VN-01',
    },
    {
      name: 'Lào Cai',
      code: 'VN-02',
    },
    {
      name: 'Hà Giang',
      code: 'VN-03',
    },
    {
      name: 'Cao Bằng',
      code: 'VN-04',
    },
    {
      name: 'Sơn La',
      code: 'VN-05',
    },
    {
      name: 'Yên Bái',
      code: 'VN-06',
    },
    {
      name: 'Tuyên Quang',
      code: 'VN-07',
    },
    {
      name: 'Lạng Sơn',
      code: 'VN-09',
    },
    {
      name: 'Quảng Ninh',
      code: 'VN-13',
    },
    {
      name: 'Hòa Bình',
      code: 'VN-14',
    },
    {
      name: 'Ninh Bình',
      code: 'VN-18',
    },
    {
      name: 'Thái Bình',
      code: 'VN-20',
    },
    {
      name: 'Thanh Hóa',
      code: 'VN-21',
    },
    {
      name: 'Nghệ An',
      code: 'VN-22',
    },
    {
      name: 'Hà Tĩnh',
      code: 'VN-23',
    },
    {
      name: 'Quảng Bình',
      code: 'VN-24',
    },
    {
      name: 'Quảng Trị',
      code: 'VN-25',
    },
    {
      name: 'Thừa Thiên-Huế',
      code: 'VN-26',
    },
    {
      name: 'Quảng Nam',
      code: 'VN-27',
    },
    {
      name: 'Kon Tum',
      code: 'VN-28',
    },
    {
      name: 'Quảng Ngãi',
      code: 'VN-29',
    },
    {
      name: 'Gia Lai',
      code: 'VN-30',
    },
    {
      name: 'Bình Định',
      code: 'VN-31',
    },
    {
      name: 'Phú Yên',
      code: 'VN-32',
    },
    {
      name: 'Đắk Lắk',
      code: 'VN-33',
    },
    {
      name: 'Khánh Hòa',
      code: 'VN-34',
    },
    {
      name: 'Lâm Đồng',
      code: 'VN-35',
    },
    {
      name: 'Ninh Thuận',
      code: 'VN-36',
    },
    {
      name: 'Tây Ninh',
      code: 'VN-37',
    },
    {
      name: 'Đồng Nai',
      code: 'VN-39',
    },
    {
      name: 'Bình Thuận',
      code: 'VN-40',
    },
    {
      name: 'Long An',
      code: 'VN-41',
    },
    {
      name: 'Bà Rịa - Vũng Tàu',
      code: 'VN-43',
    },
    {
      name: 'An Giang',
      code: 'VN-44',
    },
    {
      name: 'Đồng Tháp',
      code: 'VN-45',
    },
    {
      name: 'Tiền Giang',
      code: 'VN-46',
    },
    {
      name: 'Kiến Giang',
      code: 'VN-47',
    },
    {
      name: 'Vĩnh Long',
      code: 'VN-49',
    },
    {
      name: 'Bến Tre',
      code: 'VN-50',
    },
    {
      name: 'Trà Vinh',
      code: 'VN-51',
    },
    {
      name: 'Sóc Trăng',
      code: 'VN-52',
    },
    {
      name: 'Bắc Kạn',
      code: 'VN-53',
    },
    {
      name: 'Bắc Giang',
      code: 'VN-54',
    },
    {
      name: 'Bạc Liêu',
      code: 'VN-55',
    },
    {
      name: 'Bắc Ninh',
      code: 'VN-56',
    },
    {
      name: 'Bình Dương',
      code: 'VN-57',
    },
    {
      name: 'Bình Phước',
      code: 'VN-58',
    },
    {
      name: 'Cà Mau',
      code: 'VN-59',
    },
    {
      name: 'Hải Dương',
      code: 'VN-61',
    },
    {
      name: 'Hà Nam',
      code: 'VN-63',
    },
    {
      name: 'Hưng Yên',
      code: 'VN-66',
    },
    {
      name: 'Nam Định',
      code: 'VN-67',
    },
    {
      name: 'Phú Thọ',
      code: 'VN-68',
    },
    {
      name: 'Thái Nguyên',
      code: 'VN-69',
    },
    {
      name: 'Vĩnh Phúc',
      code: 'VN-70',
    },
    {
      name: 'Điện Biên',
      code: 'VN-71',
    },
    {
      name: 'Đắk Nông',
      code: 'VN-72',
    },
    {
      name: 'Hậu Giang',
      code: 'VN-73',
    },
    {
      name: 'Can Tho',
      code: 'VN-CT',
    },
    {
      name: 'Da Nang',
      code: 'VN-DN',
    },
    {
      name: 'Ha Noi',
      code: 'VN-HN',
    },
    {
      name: 'Hai Phong',
      code: 'VN-HP',
    },
    {
      name: 'Ho Chi Minh',
      code: 'VN-SG',
    },
  ],
  VU: [
    {
      name: 'Malampa',
      code: 'VU-MAP',
    },
    {
      name: 'Pénama',
      code: 'VU-PAM',
    },
    {
      name: 'Sanma',
      code: 'VU-SAM',
    },
    {
      name: 'Shéfa',
      code: 'VU-SEE',
    },
    {
      name: 'Taféa',
      code: 'VU-TAE',
    },
    {
      name: 'Torba',
      code: 'VU-TOB',
    },
  ],
  WF: [
    {
      name: 'Alo',
      code: 'WF-AL',
    },
    {
      name: 'Sigave',
      code: 'WF-SG',
    },
    {
      name: 'Uvea',
      code: 'WF-UV',
    },
  ],
  WS: [
    {
      name: "A'ana",
      code: 'WS-AA',
    },
    {
      name: 'Aiga-i-le-Tai',
      code: 'WS-AL',
    },
    {
      name: 'Atua',
      code: 'WS-AT',
    },
    {
      name: "Fa'asaleleaga",
      code: 'WS-FA',
    },
    {
      name: "Gaga'emauga",
      code: 'WS-GE',
    },
    {
      name: 'Gagaifomauga',
      code: 'WS-GI',
    },
    {
      name: 'Palauli',
      code: 'WS-PA',
    },
    {
      name: "Satupa'itea",
      code: 'WS-SA',
    },
    {
      name: 'Tuamasaga',
      code: 'WS-TU',
    },
    {
      name: "Va'a-o-Fonoti",
      code: 'WS-VF',
    },
    {
      name: 'Vaisigano',
      code: 'WS-VS',
    },
  ],
  YE: [
    {
      name: 'Abyan',
      code: 'YE-AB',
    },
    {
      name: '‘Adan',
      code: 'YE-AD',
    },
    {
      name: '‘Amrān',
      code: 'YE-AM',
    },
    {
      name: 'Al Bayḑā’',
      code: 'YE-BA',
    },
    {
      name: 'Aḑ Ḑāli‘',
      code: 'YE-DA',
    },
    {
      name: 'Dhamār',
      code: 'YE-DH',
    },
    {
      name: 'Ḩaḑramawt',
      code: 'YE-HD',
    },
    {
      name: 'Ḩajjah',
      code: 'YE-HJ',
    },
    {
      name: 'Al Ḩudaydah',
      code: 'YE-HU',
    },
    {
      name: 'Ibb',
      code: 'YE-IB',
    },
    {
      name: 'Al Jawf',
      code: 'YE-JA',
    },
    {
      name: 'Laḩij',
      code: 'YE-LA',
    },
    {
      name: 'Ma’rib',
      code: 'YE-MA',
    },
    {
      name: 'Al Mahrah',
      code: 'YE-MR',
    },
    {
      name: 'Al Maḩwīt',
      code: 'YE-MW',
    },
    {
      name: 'Raymah',
      code: 'YE-RA',
    },
    {
      name: 'Amānat al ‘Āşimah [city]',
      code: 'YE-SA',
    },
    {
      name: 'Şāʻdah',
      code: 'YE-SD',
    },
    {
      name: 'Shabwah',
      code: 'YE-SH',
    },
    {
      name: 'Şanʻā’',
      code: 'YE-SN',
    },
    {
      name: 'Arkhabīl Suquţrá',
      code: 'YE-SU',
    },
    {
      name: 'Tāʻizz',
      code: 'YE-TA',
    },
  ],
  ZA: [
    {
      name: 'Eastern Cape',
      code: 'ZA-EC',
    },
    {
      name: 'Free State',
      code: 'ZA-FS',
    },
    {
      name: 'Gauteng',
      code: 'ZA-GP',
    },
    {
      name: 'Kwazulu-Natal',
      code: 'ZA-KZN',
    },
    {
      name: 'Limpopo',
      code: 'ZA-LP',
    },
    {
      name: 'Mpumalanga',
      code: 'ZA-MP',
    },
    {
      name: 'Northern Cape',
      code: 'ZA-NC',
    },
    {
      name: 'North-West',
      code: 'ZA-NW',
    },
    {
      name: 'Western Cape',
      code: 'ZA-WC',
    },
  ],
  ZM: [
    {
      name: 'Western',
      code: 'ZM-01',
    },
    {
      name: 'Central',
      code: 'ZM-02',
    },
    {
      name: 'Eastern',
      code: 'ZM-03',
    },
    {
      name: 'Luapula',
      code: 'ZM-04',
    },
    {
      name: 'Northern',
      code: 'ZM-05',
    },
    {
      name: 'North-Western',
      code: 'ZM-06',
    },
    {
      name: 'Southern',
      code: 'ZM-07',
    },
    {
      name: 'Copperbelt',
      code: 'ZM-08',
    },
    {
      name: 'Lusaka',
      code: 'ZM-09',
    },
    {
      name: 'Muchinga',
      code: 'ZM-10',
    },
  ],
  ZW: [
    {
      name: 'Bulawayo',
      code: 'ZW-BU',
    },
    {
      name: 'Harare',
      code: 'ZW-HA',
    },
    {
      name: 'Manicaland',
      code: 'ZW-MA',
    },
    {
      name: 'Mashonaland Central',
      code: 'ZW-MC',
    },
    {
      name: 'Mashonaland East',
      code: 'ZW-ME',
    },
    {
      name: 'Midlands',
      code: 'ZW-MI',
    },
    {
      name: 'Matabeleland North',
      code: 'ZW-MN',
    },
    {
      name: 'Matabeleland South',
      code: 'ZW-MS',
    },
    {
      name: 'Masvingo',
      code: 'ZW-MV',
    },
    {
      name: 'Mashonaland West',
      code: 'ZW-MW',
    },
  ],
};
