import { Category } from './types';
import { ultramicro_and_micro, semimicro, analytical, precision, highCapacity, highCapacityMicro } from './modules';

export const categories: Category[] = [
  ultramicro_and_micro,
  highCapacityMicro,
  semimicro,
  analytical,
  precision,
  highCapacity,
];

export * from './shields';
export * from './softwares';
export * from './displays';
