import React, { createContext } from 'react';
import { QuoteRequestDTOContextEnum } from './QuoteRequestDTOContextEnum';

const PlatformContext = createContext<QuoteRequestDTOContextEnum>(QuoteRequestDTOContextEnum.Sartorius);

export interface PlatformProviderProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

export type appDomain = QuoteRequestDTOContextEnum;

export const PlatformContextProvider: React.FC<PlatformProviderProps> = (props) => {
  const { children } = props;

  let value: appDomain = QuoteRequestDTOContextEnum.Sartorius;

  const domain = window.location.origin;
  if (
    domain === process.env.REACT_APP_VWR_URL ||
    domain === process.env.REACT_APP_VWR_DEV_URL ||
    domain === process.env.REACT_APP_VWR_QA_URL
  ) {
    value = QuoteRequestDTOContextEnum.Vwr;
  } else if (
    domain === process.env.REACT_APP_TF_URL ||
    domain === process.env.REACT_APP_TF_DEV_URL ||
    domain === process.env.REACT_APP_TF_QA_URL
  ) {
    value = QuoteRequestDTOContextEnum.Tf;
  }

  return <PlatformContext.Provider value={value}>{children}</PlatformContext.Provider>;
};

export default PlatformContext;
