import { categories } from '../data';
import { Accessory, Category, DocumentationAndService, WeighingModule } from '../data/types';

export interface ModuleDetails {
  categoryName: Category['name'];
  maxCapacity: string;
  scaleInterval: string;
  compatibleAccessories: Accessory[] | null;
  documentationsAndServices: DocumentationAndService[] | null;
}

export function getCategoryByModule(findModule: WeighingModule): Category | undefined {
  return categories.find((category) =>
    category.modules.find(
      (module) =>
        module.name === findModule.name &&
        module.maxCapacity.every((value, index) => value === findModule.maxCapacity[index]) &&
        module.scaleInterval.every((value, index) => value === findModule.scaleInterval[index]),
    ),
  );
}

export function getModuleDetails(moduleName: WeighingModule['name']): ModuleDetails | undefined {
  let foundModule: WeighingModule | undefined;

  const matchingCategory = categories.find((category) => {
    foundModule = category.modules.find((module) => module.name === moduleName);
    return !!foundModule;
  });

  if (foundModule && matchingCategory) {
    return {
      categoryName: matchingCategory.name,
      maxCapacity: foundModule.maxCapacity.join(' | '),
      scaleInterval: foundModule.scaleInterval.join(' | '),
      compatibleAccessories: foundModule.compatibleAccessories ? foundModule.compatibleAccessories : null,
      documentationsAndServices: foundModule.documentationsAndServices ? foundModule.documentationsAndServices : null,
    };
  }

  return undefined;
}
