import { useRecoilState } from 'recoil';
import { configurationState, navigationStepStatusState } from '../../../states';
import { useEffect } from 'react';
import { Configuration, DisplayModule, SoftwareOption } from '../../../data/types';
import { useHistory } from 'react-router-dom';
import { sendGoogleTagManagerEvent, trackPageImpression } from '../../../services/googleTagManager';
import { UISoftwareEvent } from '../../../types/tagManagerEvents';
import { NavigationSlugs } from '../../../types/navigation';
import { UseUserInterfaceSelection } from '../types';
import { getCompatibleDisplayNames } from '../../../services/displays';
import { getCategoryByModule } from '../../../services/modules';

export const useUserInterfaceSelection = (): UseUserInterfaceSelection => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [navigationStepStatus, setNavigationStepStatus] = useRecoilState(navigationStepStatusState);
  const history = useHistory();

  useEffect(() => {
    trackPageImpression(3);
  }, []);

  useEffect(() => {
    return () => {
      trackUserInterfaceSelection();
    };
    // eslint-disable-next-line
  }, [config]);

  const trackUserInterfaceSelection = () => {
    if (history.location.pathname.includes('/step4')) {
      const software = config.softwareOptions?.map((so: SoftwareOption) => so.name).join(', ') || undefined;
      const softwareName = config.softwarePackage?.label || undefined;
      const userInterface = config.displayModule?.label || undefined;

      const trackingEvent: UISoftwareEvent = {
        event: 'cubisCalculatorSelection',
        'cubis-config-slug': NavigationSlugs[3],
        'cubis-config-step': 3,
        'cubis-config-software': software,
        'cubis-config-software-name': softwareName,
        'cubis-config-user-interface': userInterface,
      };

      sendGoogleTagManagerEvent(trackingEvent);
    }
  };

  let compatibleDisplayNames: string[] | null = null;
  if (config.module) {
    compatibleDisplayNames = getCompatibleDisplayNames(config.module);
  }

  const setDisplayModule = (displayModule: DisplayModule) => {
    const newConfig: Configuration = {
      ...config,
      displayModule,
    };

    // reset software packages and options when "Essentials" module selected
    if (displayModule.name === 'MCE') {
      newConfig.softwarePackage = undefined;
      newConfig.softwareOptions = undefined;
      newConfig.hardwarePackage = undefined;
    }

    if (
      displayModule.name === 'MCA' &&
      newConfig.module &&
      getCategoryByModule(newConfig.module)?.id === 'highCapacityMicro'
    ) {
      newConfig.hardwarePackage = {
        name: 'AutomatedDraft',
        label: 'Automated Draft Shield with built in activated Ionizer (HWL)',
        code: 'HWL',
        showOnProductNumber: true,
      };
    } else {
      newConfig.hardwarePackage = undefined;
    }

    setConfig(newConfig);
  };

  const onDisplayModuleSelect = (selectedDisplayModule: DisplayModule) => {
    return function () {
      setDisplayModule(selectedDisplayModule);
      setNavigationStepStatus({ ...navigationStepStatus, step3: 'complete' });
    };
  };

  const onUndoSelection = (): void => {
    setConfig({
      ...config,
      displayModule: undefined,
      softwarePackage: undefined,
      softwareOptions: undefined,
      hardwarePackage: undefined,
    });
    setNavigationStepStatus({ ...navigationStepStatus, step3: 'not_set' });
  };

  return {
    config,
    compatibleDisplayNames,
    onUndoSelection,
    onDisplayModuleSelect,
  };
};
