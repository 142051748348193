import { CButton, CCol, CRow } from '@coreui/react';
import React from 'react';
import styles from './RequestQuote.module.scss';

interface SelectionBoxProps {
  title: string;
  borderBottom?: boolean;
  onEdit?: () => void;
}

const SelectionBox: React.FC<SelectionBoxProps> = (props): JSX.Element => {
  const { title, borderBottom = true, children, onEdit } = props;
  const isEditable = !!onEdit;

  return (
    <div className={`${styles.selectionBox} font-2xl pb-2 mb-4 ${borderBottom ? 'border-bottom pb-4' : ''}`}>
      <CRow className="mb-2">
        <CCol lg="10">
          <div>
            <strong>{title}</strong>
          </div>
        </CCol>
        {isEditable && (
          <CCol lg="2" className="text-right">
            <CButton className="p-0 align-top" onClick={onEdit} title={title} data-testid={`${title}-edit`}>
              <i className="icon-edit" />
            </CButton>
          </CCol>
        )}
      </CRow>
      {children}
    </div>
  );
};

export default SelectionBox;
