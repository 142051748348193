import { WeighingModule } from '../../../data/types';
import { maxCapacityGroup, scaleIntervalGroup } from '../types';

export const availableMaxCapacity = (modules: WeighingModule[]): maxCapacityGroup[] => {
  const ret: maxCapacityGroup[] = [];
  modules.forEach((module) => {
    const maxCapacity = module.maxCapacity.join(' | ');
    const group = ret.find((m) => m.name === maxCapacity);
    if (group) {
      group.modules.push(module);
    } else {
      ret.push({
        name: maxCapacity,
        maxCapacity: module.maxCapacity,
        modules: new Array(module),
      });
    }
  });
  return ret;
};

export const sortMaxCapcityGroups = (a: maxCapacityGroup, b: maxCapacityGroup): number => {
  return a.maxCapacity[a.maxCapacity.length - 1] === b.maxCapacity[b.maxCapacity.length - 1]
    ? b.maxCapacity.length - a.maxCapacity.length
    : a.maxCapacity[a.maxCapacity.length - 1] - b.maxCapacity[b.maxCapacity.length - 1];
};

export const availableScaleIntervalGroup = (modules: WeighingModule[]): scaleIntervalGroup[] => {
  const ret: scaleIntervalGroup[] = [];
  modules.forEach((module) => {
    const scaleInterval = module.scaleInterval.join(' | ');
    const group = ret.find((m) => m.name === scaleInterval);
    if (group) {
      group.modules.push(module);
    } else {
      ret.push({
        name: scaleInterval,
        scaleInterval: module.scaleInterval,
        modules: new Array(module),
      });
    }
  });
  return ret;
};

export const sortScaleDivisionGroups = (a: scaleIntervalGroup, b: scaleIntervalGroup): number => {
  const countDiv = a.scaleInterval.length - b.scaleInterval.length;
  if (countDiv === 0) {
    return a.scaleInterval[0] - b.scaleInterval[0];
  } else {
    return countDiv;
  }
};
