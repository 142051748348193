import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { configurationState, navigationStepStatusState } from '../../../states';
import { computeStatus } from '../../../services/requestQuote';
import { getModuleDetails } from '../../../services/modules';
import { getProduct } from '../../../services/products';
import { getFirstNotCompleteStep } from '../../../services/navigation';
import { UseSelectionSummary } from '../types';

export const useSelectionSummary = (): UseSelectionSummary => {
  const history = useHistory();
  const [configuration] = useRecoilState(configurationState);
  const [navigation] = useRecoilState(navigationStepStatusState);
  const status = computeStatus(navigation);
  const moduleDetails = configuration.module ? getModuleDetails(configuration.module?.name) : undefined;

  let product = undefined;
  if (status === 'complete') {
    product = getProduct(configuration);
  }

  const goToStep = (stepNumber: number) => {
    return function () {
      history.push(`/step${stepNumber}`);
    };
  };

  const onCompleteConfigurationClick = () => {
    const step = getFirstNotCompleteStep(navigation);
    history.push(`/step${step}`);
  };

  return {
    configuration,
    moduleDetails,
    goToStep,
    product,
    onCompleteConfigurationClick,
  };
};
