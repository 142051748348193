import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { shields } from './defaults';

const module_70200s: WeighingModule = {
  name: '70200S',
  scaleInterval: [1000],
  maxCapacity: [70200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_70200s;
