import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_1203s: WeighingModule = {
  name: '1203S',
  scaleInterval: [1],
  maxCapacity: [1200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_1203s;
