import React from 'react';
import { useTranslation } from 'react-i18next';

const UserInterfaceHelpText: React.FC = (): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <div>
      <p>{t('helpText.step3.description')}</p>
      <br />
      <p className="help-text-paragraph-title">{t('helpText.step3.p1.title')}:</p>
      <ul>
        <li>{t('helpText.step3.p1.item1')}</li>
        <li>{t('helpText.step3.p1.item2')}</li>
        <li>{t('helpText.step3.p1.item3')}</li>
        <li>{t('helpText.step3.p1.item4')}</li>
        <li>{t('helpText.step3.p1.item5')}</li>
        <ul>
          <li>{t('helpText.step3.p1.item6')}</li>
          <li>{t('helpText.step3.p1.item7')}</li>
          <li>{t('helpText.step3.p1.item8')}</li>
          <li>{t('helpText.step3.p1.item9')}</li>
          <li>{t('helpText.step3.p1.item10')}</li>
          <li>{t('helpText.step3.p1.item11')}</li>
          <li>{t('helpText.step3.p1.item12')}</li>
          <li>{t('helpText.step3.p1.item13')}</li>
        </ul>
      </ul>
      <p className="help-text-paragraph-title">{t('helpText.step3.p2.title')}:</p>
      <ul>
        <li>{t('helpText.step3.p2.item1')}</li>
        <li>{t('helpText.step3.p2.item2')}</li>
        <li>{t('helpText.step3.p2.item3')}</li>
      </ul>
    </div>
  );
};

export default UserInterfaceHelpText;
