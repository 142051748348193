import { Certificate } from '../types';
import i18next from 'i18next';

const t = i18next.getFixedT(null, 'common');

const createCertificate = (
  name: string,
  validForCountries: true | string[],
  invalidForCountries: string[] | undefined,
): Certificate => {
  return {
    name,
    get label() {
      return t(`certificate.${this.name}`);
    },
    validForCountries: validForCountries,
    invalidForCountries: invalidForCountries,
  };
};

export const s00 = createCertificate('SØØ', true, ['JP', 'KR', 'CN', 'IN', 'BR', 'RU']);
export const s01 = createCertificate('SØ1', ['JP', 'KR'], undefined);
export const ceu = createCertificate('CEU', true, ['JP', 'CN', 'IN', 'BR', 'RU']);
export const cfr = createCertificate('CFR', ['FR'], undefined);
export const ccn = createCertificate('CCN', ['CN'], undefined);
export const obr = createCertificate('OBR', ['BR'], undefined);
export const oin = createCertificate('OIN', ['IN'], undefined);
export const oru = createCertificate('ORU', ['RU'], undefined);
export const ojp = createCertificate('OJP', ['JP'], undefined);

export const certificates: Certificate[] = [s00, s01, ceu, cfr, ccn, obr, oin, oru, ojp];
export const certificatesForSemiMicro125P: Certificate[] = [s00, s01, ccn];

export default certificates;
