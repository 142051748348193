import './scss/style.scss';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CContainer } from '@coreui/react';
import { useRecoilState } from 'recoil';
import Header from './components/Header';
import Step1 from './pages/step1';
import Step2 from './pages/step2';
import Step3 from './pages/step3';
import Step4 from './pages/step4';
import Step5 from './pages/step5';
import Success from './pages/Success';
import { currentStepState, isCacheClearedState, showNavigationState } from './states';
import { Footer } from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageRedirect from './components/LanguageRedirect';
import { PlatformContextProvider } from './context/PlatformContext';
import MobileHeader from './components/MobileHeader';
import UserCentricsManager from './UserCentricsManager';

function App(): JSX.Element {
  const [isCacheCleared, setIsCacheCleared] = useRecoilState<boolean>(isCacheClearedState);
  const [showNavigation] = useRecoilState(showNavigationState);
  const [currentStep] = useRecoilState(currentStepState);
  const location = useLocation();

  const url = location.pathname + 'step1' + location.search ?? '/';

  const initialCacheClear = () => {
    if (!isCacheCleared && caches) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      setIsCacheCleared(true);
    }
  };

  initialCacheClear();

  return (
    <I18nextProvider i18n={i18next}>
      <PlatformContextProvider>
        <div className="c-app c-default-layout">
          <ScrollToTop />
          <UserCentricsManager />
          {/* Sidebar */}
          <div className="c-wrapper">
            <div className="header-wrapper">
              <Header showNavigation={showNavigation} />
            </div>
            <div className="mobileHeader-wrapper">
              <MobileHeader currentStep={currentStep} />
            </div>
            <div className="c-body">
              <main className="c-main">
                <CContainer>
                  <Switch>
                    <Route path="/:lang/step1" component={Step1} />
                    <Route path="/:lang/step2" component={Step2} />
                    <Route path="/:lang/step3" component={Step3} />
                    <Route path="/:lang/step4" component={Step4} />
                    <Route path="/:lang/step5" component={Step5} />
                    <Route path="/:lang/success" component={Success} />
                    <Route path="\/(step[1-5]|success)" component={LanguageRedirect} />
                    <Redirect to={url} from="/" />
                  </Switch>
                </CContainer>
              </main>
            </div>
            <Footer />
          </div>
        </div>
      </PlatformContextProvider>
    </I18nextProvider>
  );
}

export default App;
