import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { shields } from './defaults';

const module_36200s: WeighingModule = {
  name: '36200S',
  scaleInterval: [1000],
  maxCapacity: [36200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_36200s;
