import { UseRecommendation } from '../types';
import { Configuration, Product } from '../../../data/types';
import recommendationsMap from './recommendationsMap.json';
import { getModuleDetails } from '../../../services/modules';
import { getProduct } from '../../../services/products';
import { createHardwareOption } from '../../../data/hardwares';
import { createSoftwarePackage, softwares } from '../../../data';
import { getAllDraftShields } from '../../../services/draftShield';
import { NavigationStepStatus } from '../../../types/navigation';

export const useRecommendationSummary = (
  configuration: Configuration,
  status: NavigationStepStatus,
  isHookTurnedOn: boolean,
): UseRecommendation => {
  let recommendedProduct: Product | undefined = undefined;
  let recommendedConfiguration: Configuration | undefined = undefined;

  // isHookTurnedOn exists because hooks shouldn't be called conditionally
  // if isHookTurnedOn is false we won't do anything in this hook
  if (isHookTurnedOn) {
    if (status === 'complete') {
      const configurationAndRecommendation = recommendationsMap.find(
        (x) =>
          x.configuration.Approval === configuration.certificate?.name &&
          x.configuration.Display === configuration.displayModule?.name &&
          x.configuration.DraftShield === configuration.draftShield?.name &&
          x.configuration.MaxCapacity.length === configuration.module?.maxCapacity.length &&
          x.configuration.MaxCapacity.every((value, index) => value === configuration.module?.maxCapacity[index]) &&
          x.configuration.ScaleInterval.every((value, index) => value === configuration.module?.scaleInterval[index]),
      );

      if (
        configurationAndRecommendation &&
        configuration.module &&
        configuration.displayModule &&
        configuration.draftShield &&
        configuration.certificate
      ) {
        const moduleNameMatch = /^(MCA|MCE)(.*?)-/.exec(
          configurationAndRecommendation.recommendation.MTSRecommendation,
        );
        recommendedConfiguration = {
          ...configuration,
          module: {
            maxCapacity: configurationAndRecommendation.recommendation.MaxCapacity,
            scaleInterval: configurationAndRecommendation.recommendation.ScaleInterval,
            name: moduleNameMatch ? moduleNameMatch[2] : '',
            compatibleShields: configuration.module.compatibleShields,
            possibleCertificates: configuration.module.possibleCertificates,
          },
          displayModule: {
            ...configuration.displayModule,
          },
          draftShield: getAllDraftShields().find(
            (x) => x.name === configurationAndRecommendation.recommendation.DraftShield,
          ),
          certificate: {
            ...configuration.certificate,
          },
          softwarePackage: configurationAndRecommendation.recommendation.SoftwarePackages?.length
            ? createSoftwarePackage(configurationAndRecommendation.recommendation.SoftwarePackages, softwares)
            : undefined,
          hardwarePackage: configurationAndRecommendation.recommendation.HardwareLicences?.length
            ? createHardwareOption(configurationAndRecommendation.recommendation.HardwareLicences, true)
            : undefined,
        };

        recommendedProduct = getProduct(recommendedConfiguration);
      }
    }
  }

  const recommendedModuleDetails = recommendedConfiguration?.module
    ? getModuleDetails(recommendedConfiguration.module?.name)
    : undefined;

  return {
    recommendedConfiguration,
    recommendedProduct,
    recommendedModuleDetails,
  };
};
