import React from 'react';
import { CContainer, CFooter } from '@coreui/react';
import styles from './Footer.module.scss';
import PlatformContext from './../context/PlatformContext';
import { QuoteRequestDTOContextEnum } from '../context/QuoteRequestDTOContextEnum';

export const Footer = (): React.ReactElement => {
  const platform = React.useContext(PlatformContext);
  const isThermoFisher = QuoteRequestDTOContextEnum.Tf === platform;
  if (isThermoFisher) return <></>;

  return (
    <CFooter fixed={false}>
      <CContainer>
        <h3 className={styles.headline}>General Information</h3>
        <ul className={styles.list}>
          <li>
            <a href="https://www.sartorius.com/en/imprint" target="_blank" title="Imprint">
              Imprint
            </a>
          </li>
          <li>
            <a href="https://www.sartorius.com/en/disclaimer" target="_blank" title="Disclaimer">
              Disclaimer
            </a>
          </li>
          <li>
            <a
              href="https://www.sartorius.com/en/general-terms-and-conditions"
              target="_blank"
              title="Terms & Conditions">
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="https://www.sartorius.com/en/legal-documents" target="_blank" title="Legal Documents">
              Legal Documents
            </a>
          </li>
          <li>
            <a href="https://www.sartorius.com/en/data-protection" target="_blank" title="Data Protection">
              Data Protection
            </a>
          </li>
          <li>
            <a href="https://www.sartorius.com/en/cookie-policy" target="_blank" title="Cookie Policy">
              Cookie Policy
            </a>
          </li>
        </ul>
        <p className={styles.copyright}>© {new Date().getFullYear()} Sartorius AG</p>
      </CContainer>
    </CFooter>
  )
};
