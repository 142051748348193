import { WeighingModule } from '../../types';
import { shields } from './defaults';
import { certificates } from '../../certificates';

const module_2203p: WeighingModule = {
  name: '2203P',
  scaleInterval: [1, 10],
  maxCapacity: [1010, 2200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_2203p;
