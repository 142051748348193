import certificates from '../../certificates';
import { WeighingModule } from '../../types';
import { shields } from './defaults';

const module_8201s: WeighingModule = {
  name: '8201S',
  scaleInterval: [100],
  maxCapacity: [8200],
  compatibleShields: shields,
  possibleCertificates: certificates,
};

export default module_8201s;
