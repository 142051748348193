import { CModalFooter, CModal, CModalHeader, CModalBody } from '@coreui/react';
import React from 'react';
import { NavigationStepsEnum } from '../types/navigation';

interface ConfigurationWarningProps {
  show: boolean;
  title: string;
  buttons: JSX.Element;
  onClose: () => void;
}

export function computeProceedToStepTitle(pathName: string): string {
  if (pathName) {
    const path = pathName.substring(1);
    if (path in NavigationStepsEnum || path === 'step5') {
      return `configurationWarning.proceedTo.${path}`;
    }
  }
  return 'configurationWarning.proceed';
}

const ConfigurationWarning: React.FC<ConfigurationWarningProps> = (props): JSX.Element => {
  const { show, title, onClose, children, buttons } = props;

  return (
    <CModal show={show} onClose={onClose} centered={true} className="configuration-warning">
      <CModalHeader closeButton>
        <span className="justify-content-start">
          <i className="icon-warning lead" /> {title}
        </span>
      </CModalHeader>
      <CModalBody>{children}</CModalBody>
      <CModalFooter>{buttons}</CModalFooter>
    </CModal>
  );
};

export default ConfigurationWarning;
